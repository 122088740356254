import React, { useState, useEffect, useRef } from "react";
import { Modal, Alert } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import swal from "sweetalert";
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  loadingToggleAction,
  signupAction,
  verify_SignupOTP
} from '../../store/actions/AuthActions';
import { resendOTPSignup } from "../../services/AuthService";
import { useTranslation } from 'react-i18next';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useNavigate, useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import PlansomLogo from "../../images/PlansomLogo2.png";
import TermsAndConditions from "../layouts/StoreFront/TermsAndConditions";
export const LocationDisplayReg = () => {
  const location = useLocation()
  return <div data-testid="location-display-reg">{location?.pathname}</div>
}

function Register(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [first_name, setFirstname] = useState('');
  const [last_name, setLastname] = useState('');
  const [discountCode, setDiscountCode] = useState()
  let errorsObj = { email: '', password: '',first_name:'', last_name:'',discountCode:'' };
  const [errors, setErrors] = useState(errorsObj);
  const [disableEmail, setDisableEmail] = useState(false);
  const [msg, setMsg] = useState('');
  const [openOtp, setOtp] = useState(false);
  const [btn, setBtn] = useState('Verify email');
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [showTerms, setShowTerms] = useState(false);
  const [showTerm, setShowTerm] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const stateData = useSelector(state => state);
  const [isAccepted, setIsAccepted] = useState(false)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  useEffect(()=>{
    console.log("ppppp register page")
  },[])

  useEffect(() => {
    setShowTerms(showTerms)
  }, [showTerms])


  useEffect(() => {
    if (isAccepted) {
      handle()
    }
  }, [isAccepted, value])

  const handle = () => {
    setIsAccepted(true)
  };

  useEffect(() => {
    setOtp(false);
    if (location?.state?.email !== null
      && location?.state?.email !== undefined
      && location?.state?.email !== '') {
      setEmail(location?.state?.email)
      setBtn('Create account')
      setDisableEmail(true);
    }
  }, []);

  useEffect(() => {
    if (stateData?.auth.auth.message) {
      setMsg(stateData.auth.auth.message)
    }
    if (stateData?.auth.errorMessage) {
      setMsg(stateData.auth.errorMessage)
    }
  }, [stateData]);

  useEffect(() => {
    if (stateData?.auth.auth.errorMessage) {
      setMsg(stateData?.auth?.auth?.errorMessage)
    }
  }, [stateData?.auth?.auth?.errorMessage]);

  useEffect(() => {
    if (msg.includes('User Signed up Successfully. Please enter the code sent to')) {
      setOtp(true);
    }
    if (msg.includes('Incorrect code. Please try again.')) {
      setErrors('Incorrect code. Please try again.');
    }
  }, [msg]);
  const dispatch = useDispatch();
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const otpSubmit = () => {
    dispatch(verify_SignupOTP(value, email, navigate, localStorage.getItem("plansom_bot_id")));
  };

  const setotp = (value) => {
    setValue(value)
  }

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    var verifyPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])*(?=.*?[#?!@$%^&*-])*.{8,}$/;
    var testt = true;
    testt = verifyPassword.test(values.password);
    const errorObj = { ...errorsObj };
    
    if (first_name === '') {
      errorObj.first_name = 'First name is Required';
      error = true;
      // swal('Oops', errorObj.email, "error");
      setErrors(errorObj)
    }
    else if (last_name === '') {
      errorObj.last_name = 'Last name is Required';
      error = true;
      // swal('Oops', errorObj.email, "error");
      setErrors(errorObj)
    }
    else if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
      // swal('Oops', errorObj.email, "error");
      setErrors(errorObj)
    }
    else if (values.password === '') {
      errorObj.password = 'Password is Required';
      error = true;
      setErrors(errorObj)
      // swal('Oops', errorObj.password, "error");
    }
    
    else if (testt === false) {
      errorObj.password = 'Password should have atleast one special character and one captial letter. Passwords must be at least 8 characters long';
      error = true;
    }
    else if (showTerms == false) {
      swal('Oops', "Please accept terms and conditions", "error");
    }
    setErrors(errorObj);
    if (error) return;
    if (showTerms) {
      dispatch(loadingToggleAction(true));
      dispatch(signupAction(first_name, last_name, email, values.password,discountCode, timezone, navigate));
    }
  }

  const resendOTP = () => {
    const data = resendOTPSignup(email);
    data?.then(async (res) => {
      // console.log('GoalDetailsssssss', res);
    })
    data?.catch(async (err) => {
      // console.log('error deleteTask',err)
    })
  };

  return (
    <>
     <head>
        <meta name="robots" content="index, follow" />
       
      </head>
      <Modal {...props}
        className="fade bd-example-modal-lg"
        show={showTerm}
        size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      // centered
      >
        <Modal.Header>
          <h3>Terms and conditions</h3>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2 "
            onClick={() => { setShowTerm(false) }}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="m-2 mt-0"><p>
            <TermsAndConditions></TermsAndConditions>
          </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-1 m-1">
          <Button className="figma-btn text-white" size="sm" onClick={() => { setShowTerm(false) }}>
            Ok
          </Button>
          {!showTerms && <Button className="figma-btn text-white" size="sm" onClick={() => { setShowTerms(() => true); setShowTerm(false); console.log("showTerms", showTerms) }}>
            Accept
          </Button>}
        </Modal.Footer>
      </Modal>

      <div className="authincation h-100 float-center">
        <div className="container">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form" style={{ padding: "2.125rem 3.125rem" }}>
                      <div className="text-center mb-3">
                        <Link to="/login">
                          <div data-testid="image" className="text-center mb-4">
                            <img src={PlansomLogo} style={{ height: '80px', width: '80px', padding: "1px", margin: "1px 5px 2px 40px" }} alt="plansom-logo" title="Plansom-logo" />
                          </div>
                        </Link>
                      </div>

                      {!openOtp &&
                        <>
                          <h1 className="text-center mb-2">Create account</h1>
                          <p className="" style={{ color: "#717994" }}>Enter your information below to create a new account.</p>

                          <form onSubmit={onSignUp}>
                            <div className="form-group mb-3">
                              <h5>First name</h5>
                              <input
                                type="text"
                                defaultValue={first_name}
                                onChange={(e) => setFirstname(e.target.value)}
                                className="figma-input p"
                                placeholder="First name"
                              />
                            </div>
                            {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                            <div className="form-group mb-3">
                              <h5>Last name</h5>
                              <input
                                type="text"
                                defaultValue={last_name}
                                onChange={(e) => setLastname(e.target.value)}
                                className="figma-input p"
                                placeholder="Last name"
                              />
                            </div>
                            {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                            <div className="form-group mb-3" hidden={disableEmail == true}>
                              <h5>Email</h5>
                              <input
                                defaultValue={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="figma-input p"
                                placeholder="Email"
                              />
                            </div>
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                            <div className="form-group mb-3">
                              <h5>Password</h5>
                              <Input disableUnderline
                                type={values.showPassword ? "text" : "password"}
                                onChange={handlePasswordChange("password")}
                                value={values.password}
                                className="figma-input p"
                                placeholder="Password"
                                style={{ padding: "3px 10px" }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </div>
                            {errors.password && <div className="text-danger" >{errors.password}</div>}
                            <div className="form-group mb-3">
                              <h5>Discount code</h5>
                              <input
                                defaultValue={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                                className="figma-input p"
                                placeholder="Discount code"
                              />
                            </div>
                 
                            <div className="form-row d-flex justify-content-between mt-4 mb-1">
                              <div className="form-group mb-3">
                                <div className="custom-control custom-checkbox ml-1">
                                  <input type="checkbox" className="form-check-input me-1" id="basic_checkbox_1" checked={showTerms} value={showTerms} onClick={(e) => { setShowTerms(e.target.checked); }} />
                                  <label className="form-check-label" htmlFor="basic_checkbox_1" style={{ color: "#717994" }}>Accept
                                    <a
                                      className="figma-link"
                                      style={{ textDecoration: "underline", paddingBottom: "5px",marginLeft:"5px"}}
                                      href="/terms-and-conditions"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Terms and Conditions
                                    </a>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="text-center">
                              <button
                                type="submit"
                                className="figma-btn text-white mb-2 w-100"
                              //btn btn-blue btn-block
                              >
                                {btn}
                              </button>
                            </div>
                          </form>

                         
                          <div className="text-center new-account mt-1 mb-1">
                            <p className="" style={{ color: "#717994" }} >
                              Already have an account?{" "}
                              <Link className="figma-link" to="/login">
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </>
                      }


                      {openOtp && <>
                        <div className='align-items-center mt-5'>
                          <div className="text-center">
                            <h3 className='text-center fs-30'>Enter code</h3>
                            <p style={{ color: "#717994" }}>We sent a code to <span style={{ color: "#424C70" }}>{email}</span></p>
                          </div>

                          <div className='inputs d-flex flex-row justify-content-center' style={{ height: 'auto', width: '100%' }}>
                            <center>
                              <OtpInput
                                value={value}
                                className="figma-otp-box" // otp-input bg-white m-2 mx-2 text-lg focus:outline-none focus:shadow-outline border border-gray-300 rounded-sm  block w-full appearance-none leading-normal otp"
                                inputStyle={{ height: 60, width: 60 }}
                                onChange={(e) => {
                                  setotp(e);
                                }}
                                numInputs={6}
                              /></center>
                          </div>

                          {
                            stateData.auth.errorMessage &&
                            <>
                              <Alert
                                variant="danger"
                                className=" text-center alert-dismissible danger"
                              >
                                <svg
                                  viewBox='0 0 24 24'
                                  width='24'
                                  height='24'
                                  stroke='currentColor'
                                  strokeWidth='2'
                                  fill='none'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='me-2'
                                >
                                  <circle cx='12' cy='12' r='10'></circle>
                                  <line x1='9' y1='9' x2='9.01' y2='9'></line>
                                  <line x1='15' y1='9' x2='15.01' y2='9'></line>
                                  <path d="M165.3,169.8a48,48,0,0,0-74.6,0" />
                                </svg>
                                <div className="text-danger fs-12 h3">{stateData.auth.errorMessage}</div>
                              </Alert>
                            </>
                          }


                          <div className="text-center d-grid mt-4">
                            <button className="figma-btn text-white w-100 btn-blue" onClick={otpSubmit}>
                              {t('Sign up')}
                            </button>
                          </div>
                          <div className="text-center new-account mt-3">
                            <p className="p-0 m-0">
                              <span style={{ color: "#717994" }}>Didn't receive the mail?</span>
                              <Link className="figma-link" to="" onClick={resendOTP}>
                                &nbsp;Click to resend
                              </Link>
                            </p>
                            <p className="m-0 p-0" >
                              <span style={{ color: "#717994" }}>Already have an account?{" "}</span>
                              <Link className="figma-link" to="/login">
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </div>
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        buttonStyle={{ backgroundColor: "green", color: 'white' }}
        buttonText="Accept and close"
        cookieName="Plansom.com"
        style={{ background: "#393E41" }}
        enableDeclineButton
        declineButtonStyle={{ backgroundColor: '#343a40' }}
        onAccept={() => { setIsAccepted(true); }}
      >
        We make use of first and third party cookies to improve our user experience. By using this website, you agree with our cookies policy.
      </CookieConsent>

    </>

  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

