import StoreHeaders from "./StoreHeaders"
import { Row, Col } from "react-bootstrap"
import React, { useContext, useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getAllBotQuestions } from "../../../services/messageService";
import { getCSVFile } from "../../../services/messageService";


function Solutions(){
  const [showFullText, setShowFullText] = useState(false);
   const [loggedInUser, setLoggedIn] = useState(localStorage?.getItem("loggedIn"));
   const [isLoadingQuestions, setIsLoadinQuestions] = useState(false);
   const [messages, setMessages] = useState([]);
   const lastMessageRef = useRef(null);
   const [token, setToken] = useState("");
   const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [botQuestions, setBotQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [limitMessage, SetLimitMessage] = useState(false);
  const [success, setSuccess] = useState(false)
  const [showError, setShowError] = useState(false);
  const [errorMessage, seErrorMessage] = useState("AI processing for the strategy is complete. You'll be notified via email shortly");
  const [showMoreStrategiesMessage, setShowMoreStrategiesMessage] = useState(false);
  const [csvFileUrl, setCsvFileUrl] = useState(null);
  const [invalidAnswers, setInvalidAnswers] = useState(false);
  const  [ goal, setGoal] = useState(null)
  const [answerQuestion, setAnswerQuestion] = useState([])
  const [orgId, setOrgId] = useState(localStorage.getItem('organization_id'));
  const [questionsLength, SetQuestionsLength] = useState()

  const audioRef = useRef(null);
  
  const storedValue = localStorage.getItem('answerQuestion');
const defaultValue = JSON.stringify( [])
  const [gettingQuestionAnswer, setGettingQuestionAnswer] = useState(
    storedValue !== null ? storedValue : defaultValue
  );

  useEffect(()=>{
    setOrgId(localStorage.getItem('organization_id'))
      },[localStorage.getItem('organization_id')])
    
      useEffect(()=>{
       console.log(messages,"messages")
        // setMessages(JSON.parse(gettingQuestionAnswer))
        console.log("sdsdsdsddsd", JSON.parse(gettingQuestionAnswer))
        let botAnswerSet = []
        JSON.parse(gettingQuestionAnswer).forEach(element => {
          console.log(element,"oooooo")
        if (element?.answer){
          console.log("esrdftg",element?.answer)
         
          botAnswerSet.push({text : element?.question, user : false})
          botAnswerSet.push({text : element?.answer, user : true})
        }
        if(orgId){
        setMessages(botAnswerSet)
        
        }
          });
        
      },[gettingQuestionAnswer, orgId])
  
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, showMoreStrategiesMessage]);
  

 

  const renderAlertText = () => {
    const fullText = `
        Just answer the 6 simple questions in the chatbot and we will generate a strategy for you.
        Your strategy will be uploaded to Plansom with goals and tasks, where you can edit and customize it.
        Once set, Plansom will help you track your strategy. We will help you win by focusing on doing the most important work first.
      `;
    if (showFullText) {
      return <p className="fs-16">{fullText}</p>;
    } else {
      const shortenedText = fullText.slice(0, 100);
      return (
        <>
          <p className="fs-16">{fullText}
          {/* <span
            onClick={() => setShowFullText(true)}
            style={{ cursor: "pointer" }}
          >...</span> */}
          </p>
        </>
      );
    }
    return null;
    
  };

  // const getBotQuestions = () => {
  //   if (userInfoDetails?.data?.accessToken) {
  //     setIsLoadinQuestions(true);
  //     const data = getAllBotQuestions(userInfoDetails?.data?.accessToken)
  //       .then((response) => {
  //         console.log(response?.data?.data,"555555555")
  //         setBotQuestions(response?.data?.data);
  //         setIsLoadinQuestions(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching bot questions:", error);
  //       });
  //   }
  // };

  const getBotQuestions = () => {
    // if (userInfoDetails?.data?.accessToken) {
      setIsLoadinQuestions(true);
      const data = getAllBotQuestions()
        .then((response) => {
          SetQuestionsLength(response?.data?.data?.length)
          console.log(response,"resssssss")
          setIsLoadinQuestions(false);
          setBotQuestions(response?.data?.data);
          
        })
        .catch((error) => {
          console.error("Error fetching bot questions:", error);
        });
    // }
  };

  // useEffect(() => {
  //   if (loggedInUser && !botQuestions.length) {
  //     setToken(userInfoDetails?.data?.accessToken);
  //     getBotQuestions(userInfoDetails?.data?.accessToken);
  //   }
  // }, [loggedInUser]);
  useEffect(() => {
    console.log(botQuestions.length,"botQuestions")
    if (loggedInUser == 'true' && !botQuestions.length && !(JSON.parse(gettingQuestionAnswer).length)) {
      setToken(userInfoDetails?.data?.accessToken);
      // getBotQuestions(userInfoDetails?.data?.accessToken);
      getBotQuestions()
    } else if(loggedInUser == 'true' && !(JSON.parse(gettingQuestionAnswer).length)  ){
      getBotQuestions()
    }
    else if(loggedInUser == "false" || loggedInUser == null){
      getBotQuestions()
    }
    // else if(loggedInUser == 'true' && (JSON.parse(gettingQuestionAnswer).length)  ){
    //   getBotQuestions()
    // }

    // setMessages(gettingQuestionAnswer)
  }, [loggedInUser]);

  // const handleUserMessage = (message) => {
  //   if (token === undefined) {
  //     // dispatch(addUserAnswer(message));
  //     // localStorage.setItem("userChatAnswers", message)
  //     navigate("/login", {
  //       state: {
  //         answers: JSON.stringify(message),
  //       },
  //     });

  //     // const answersParam = encodeURIComponent(JSON.stringify(message));
  //     // window.location.href = `/login?answers=${answersParam}`;

  //     // window.location.href = '/login'

  //     console.length("anssdsffsffs",answers.length)

  //     if(answers.length == 5){

  //       // Define your state object
  //  const state = {
  //    answers: JSON.stringify(answerQuestion),
  //  };
   
  //  // Encode the state object into a query parameter
  //  const encodedState = encodeURIComponent(JSON.stringify(state));
   
  //  // Append the encoded state to the URL
  //  // setTimeout(() => {
  //    window.location.href = `/login?state=${encodedState}`;
  //  // }, 1000);
   
   
   
   
  //        // window.location.href = '/login'
  //        }
  //   }

  //   if (!user) {
  //     if (currentQuestionIndex < botQuestions.length) {

  //       const question = `Q${currentQuestionIndex + 1}: ${botQuestions[currentQuestionIndex]?.question}`;
  //       const answer = `Ans: ${message}`;

  //       setMessages((prevMessages) => [
  //         ...prevMessages,
  //         { text: message, user: true },
  //       ]);

  //       setAnswers((prevAnswers) => [...prevAnswers, { question, answer }]);
  //       setAnswerQuestion((prevAnswers) => [...prevAnswers, { question, answer }]);
  //       setCurrentQuestionIndex(currentQuestionIndex + 1);
  //     }
  //   }
  // };


  const handleUserMessage = (message) => {
   
    if ((loggedInUser == "false" || loggedInUser==null) ) {
      console.log("anssss", answers.length)
      // dispatch(addUserAnswer(message));
      // localStorage.setItem("userChatAnswers", message)
      // navigate("/login", {
      //   state: {
      //     answers: JSON.stringify(message),
      //   },
      // });

      // const answersParam = encodeURIComponent(JSON.stringify(message));
      // window.location.href = `/login?answers=${answersParam}`;
      if(answers.length+1== questionsLength){

     // Define your state object
const state = {
  answers: JSON.stringify(answerQuestion),
};

// Encode the state object into a query parameter
const encodedState = encodeURIComponent(JSON.stringify(state));

// Append the encoded state to the URL
setTimeout(() => {
  window.location.href = `/login?state=${encodedState}`;
}, 500);




      // window.location.href = '/login'
      }
    }

    if (!user) {
      if (currentQuestionIndex < botQuestions.length) {

        const question = `Q${currentQuestionIndex + 1}: ${botQuestions[currentQuestionIndex]?.question}`;
        const answer = `Ans: ${message}`;

        setMessages((prevMessages) => [
          ...prevMessages,
          { text: message, user: true },
        ]);

        setAnswers((prevAnswers) => [...prevAnswers, { question, answer }]);

        setAnswerQuestion((prevAnswers) => [...prevAnswers, { question, answer }]);

       
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const handleBotResponse = (response) => {
    const { formattedQuestion } = formatQuestion(
      botQuestions[currentQuestionIndex]?.question,
      currentQuestionIndex,
      botQuestions.length
    );
    const storedAnswers = localStorage.getItem("userChatAnswers");
    if (storedAnswers) {
      const storedAnswerMessage = `${storedAnswers}`;
      const question = `Q${currentQuestionIndex + 1}: ${botQuestions[currentQuestionIndex]?.question}`;
      const answer = `Ans: ${storedAnswers}`;
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: formattedQuestion, user: false },
        { text: storedAnswerMessage, user: true },
      ]);
      setAnswers((prevAnswers) => [...prevAnswers, { question, answer }]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      localStorage.removeItem("userChatAnswers")
    }
    else {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: formattedQuestion, user: false },
      ]);
    }
    setUser(false);
  };

  useEffect(() => {
    if (currentQuestionIndex < botQuestions.length && !user) {
      setTimeout(
        () => handleBotResponse(botQuestions[currentQuestionIndex]?.question),
        1000
      );
    }

  }, [currentQuestionIndex, user, botQuestions]);
 


  const formatQuestion = (question, currentQuestionIndex, totalQuestions) => {
    const formattedQuestion = (
      <>
        <strong>Q{currentQuestionIndex + 1}/{totalQuestions}:</strong> {question}
      </>
    );
    return { formattedQuestion, originalQuestion: question };
  };

  const renderMoreStrategiesMessage = () => {
    if (showMoreStrategiesMessage) {
      return (
        <div className="bot-message">
          Do you want to make more strategies?
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5px",
            }}
          >
            <button
              className="figma-btn text-white"
              onClick={() => handleButtonClick("yes")}
              style={{
                backgroundColor: "#7DC2A7",
                marginRight: "10px",
                padding: "2px",
                borderRadius: "2px",
                width: "50px",
              }}
            >
              Yes
            </button>
            <button
              className="figma-btn text-white"
              onClick={() => handleButtonClick("no")}
              style={{
                backgroundColor: "#E58697",
                padding: "3px",
                borderRadius: "2px",
                width: "50px",
              }}
            >
              No
            </button>
          </div>
        </div >
      );
    }
    return null;
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (currentQuestionIndex === 6 && !user) {
  //       setAnswers((prevAnswers) => [
  //         ...prevAnswers,
  //         {
  //           question: botQuestions[currentQuestionIndex]?.question,
  //           answer: messages[messages.length - 1].text,
  //         },
  //       ]);
  //       setIsLoading(true);
  //       try {
  //         const response = await getCSVFile(answers, token);
  //         let retryCount = 0;
  //         const fileUrl = response?.data?.data?.url;
  //         console.log("RESPONSE", fileUrl);
  //         const retrySecondApi = async () => {
  //           try {
  //             console.log("retry Count 1", retryCount);
  //             const response2 = await fetch(fileUrl);
  //             console.log("retry Count 2", response2);
  //             console.log("retry Count 2", response2.status)

  //             if (response2.status == 200) {
  //               console.log("999",response2)
  //               clearInterval(retryInterval);
  //               setisloading(false);
  //               setGoal(response?.data?.data?.goal);
  //               setCsvFileUrl(fileUrl);
  //               setShowMoreStrategiesMessage(true);
  //               setSuccess(true)
  //               // audioRef.current.play();
                
  //             }
  //           }
  //           catch (error) {
  //             console.log("retry Count error", error)
  //           }
  //         }
  //         const maxRetries = 30
  //         console.log("retry Count", retryCount);
  //         setisloading(true);
  //         const retryInterval = setInterval(async () => {
  //           if (retryCount < maxRetries) {
  //             retrySecondApi();
  //             retryCount++;
  //           } else {
  //             clearInterval(retryInterval);
  //             setisloading(false);
  //             setShowError(true);
  //             setShowMoreStrategiesMessage(true);
  //           }
  //         }, 8000);
  //         // if (fileUrl) {
  //         //   setGoal(response?.data?.data?.goal);
  //         //   setCsvFileUrl(fileUrl);
  //         //   setShowMoreStrategiesMessage(true);
  //         //   audioRef.current.play();
  //         // }
  //       } catch (error) {
  //         if (error?.response?.data?.message === "you have reached daily limit") {
  //           SetLimitMessage(true);
  //         } else if (error?.response?.data?.message?.includes("cannot generate")) {
  //           setInvalidAnswers(true);
  //           setMessages((prevMessages) => [
  //             ...prevMessages,
  //             { text: error?.response?.data?.message, user: false },
  //           ]);

  //           setCurrentQuestionIndex(0);
  //           setAnswers([]);
  //           setShowMoreStrategiesMessage(false);
  //           setUser(false);
  //           setCsvFileUrl(null);
  //           setShowError(false);
  //           setRetrying(false);
  //           setInvalidAnswers(false);
  //         }
  //         else {
  //           handleButtonClick("error");
  //           seErrorMessage(error?.response?.data?.message);
  //           setShowError(true);
  //           setShowMoreStrategiesMessage(true);
  //           // setRetrying(true);
  //           console.error("Error fetching CSV file:", error);
  //         }
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [currentQuestionIndex, user, messages]);

  // useEffect(() => {
  //   console.log(JSON.parse(gettingQuestionAnswer) , "888888888888")
  //   const fetchData = async () => {
  //     if ((currentQuestionIndex === questionsLength )&& !user) {
  //       console.log("gggggggggggggggggggg1",  userInfoDetails?.data?.accessToken)
  //       setAnswers((prevAnswers) => [
  //         ...prevAnswers,
  //         {
  //           question: botQuestions[currentQuestionIndex]?.question,
  //           answer: messages[messages.length - 1].text,
  //         },
  //       ]);

  //       setAnswerQuestion((prevAnswers) => [
  //         ...prevAnswers,
  //         {
  //           question: botQuestions[currentQuestionIndex]?.question,
  //           answer: messages[messages.length - 1].text,
  //         },
  //       ]);

  //       localStorage.setItem('answerQuestion', JSON.stringify(answerQuestion));
  //       setIsLoading(true);
  //       try {
  //         console.log("nnnnnnnnsssss")
  //        console.log(answers,"ooooooooooooooo")
  //        let setAns
  //       //  if(JSON.parse(gettingQuestionAnswer).length === 6 && orgId ){
  //       //   setAns = JSON.parse(gettingQuestionAnswer)
  //       //  }else{
  //       //   setAns = answers
  //       //  }
          
  //         const response = await getCSVFile(answers, userInfoDetails?.data?.accessToken);
  //         let retryCount = 0;
  //         const fileUrl = response?.data?.data?.url;
  //         console.log("RESPONSE", fileUrl);
  //         // localStorage.removeItem('answerQuestion')
  //         const retrySecondApi = async () => {
  //           try {
  //             console.log("retry Count 1", retryCount);
  //             const response2 = await fetch(fileUrl);
  //             console.log("retry Count 2", response2);
  //             console.log("retry Count 2", response2.status)

  //             if (response2.status == 200) {
  //               console.log("999",response2)
  //               clearInterval(retryInterval);
  //               setisloading(false);
  //               setGoal(response?.data?.data?.goal);
  //               setCsvFileUrl(fileUrl);
  //               setShowMoreStrategiesMessage(true);
  //               setSuccess(true)
  //               localStorage.removeItem('answerQuestion')
  //               // audioRef.current.play();
                
  //             }
  //           }
  //           catch (error) {
  //             console.log("retry Count error", error)
  //           }
  //         }
  //         const maxRetries = 30
  //         console.log("retry Count", retryCount);
  //         setisloading(true);
  //         const retryInterval = setInterval(async () => {
  //           if (retryCount < maxRetries) {
  //             retrySecondApi();
  //             retryCount++;
  //           } else {
  //             clearInterval(retryInterval);
  //             setisloading(false);
  //             setShowError(true);
  //             setShowMoreStrategiesMessage(true);
  //           }
  //         }, 8000);
  //         // if (fileUrl) {
  //         //   setGoal(response?.data?.data?.goal);
  //         //   setCsvFileUrl(fileUrl);
  //         //   setShowMoreStrategiesMessage(true);
  //         //   audioRef.current.play();
  //         // }
  //       } catch (error) {
  //         if (error?.response?.data?.message === "you have reached daily limit") {
  //           SetLimitMessage(true);
  //         } else if (error?.response?.data?.message?.includes("cannot generate")) {
  //           setInvalidAnswers(true);
  //           setMessages((prevMessages) => [
  //             ...prevMessages,
  //             { text: error?.response?.data?.message, user: false },
  //           ]);

  //           setCurrentQuestionIndex(0);
  //           setAnswers([]);
  //           setShowMoreStrategiesMessage(false);
  //           setUser(false);
  //           setCsvFileUrl(null);
  //           setShowError(false);
  //           setRetrying(false);
  //           setInvalidAnswers(false);
  //         }
  //         else {
  //           handleButtonClick("error");
  //           seErrorMessage(error?.response?.data?.message);
  //           setShowError(true);
  //           setShowMoreStrategiesMessage(true);
  //           // setRetrying(true);
  //           console.error("Error fetching CSV file:", error);
  //         }
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     }else if(JSON.parse(gettingQuestionAnswer).length === questionsLength && !user && orgId){
  //       console.log(JSON.parse(gettingQuestionAnswer).length,"gggggggggggggggggggg2")
  //       try {
  //         console.log("nnnnnnnnsssss")
  //        console.log(answers,"ooooooooooooooo")
  //        let setAns
  //       //  if(JSON.parse(gettingQuestionAnswer).length === 6 && orgId ){
  //       //   setAns = JSON.parse(gettingQuestionAnswer)
  //       //  }else{
  //       //   setAns = answers
  //       //  }
          
  //         const response = await getCSVFile(JSON.parse(gettingQuestionAnswer), userInfoDetails?.data?.accessToken);
         
  //         let retryCount = 0;
  //         const fileUrl = response?.data?.data?.url;
  //         console.log("RESPONSE", fileUrl);
  //         const retrySecondApi = async () => {
  //           try {
  //             console.log("retry Count 1", retryCount);
  //             const response2 = await fetch(fileUrl);
  //             console.log("retry Count 2", response2);
  //             console.log("retry Count 2", response2.status)

  //             if (response2.status == 200) {
  //               console.log("999",response2)
  //               clearInterval(retryInterval);
  //               setisloading(false);
  //               setGoal(response?.data?.data?.goal);
  //               setCsvFileUrl(fileUrl);
  //               setShowMoreStrategiesMessage(true);
                
  //               setSuccess(true)
  //               localStorage.removeItem('answerQuestion')
  //               // audioRef.current.play();
                
  //             }
  //           }
  //           catch (error) {
  //             console.log("retry Count error", error)
  //           }
  //         }
  //         const maxRetries = 30
  //         console.log("retry Count", retryCount);
  //         setisloading(true);
  //         const retryInterval = setInterval(async () => {
  //           if (retryCount < maxRetries) {
  //             retrySecondApi();
  //             retryCount++;
  //           } else {
  //             clearInterval(retryInterval);
  //             setisloading(false);
  //             setShowError(true);
  //             setShowMoreStrategiesMessage(true);
  //           }
  //         }, 8000);
  //         // if (fileUrl) {
  //         //   setGoal(response?.data?.data?.goal);
  //         //   setCsvFileUrl(fileUrl);
  //         //   setShowMoreStrategiesMessage(true);
  //         //   audioRef.current.play();
  //         // }
  //       } catch (error) {
  //         if (error?.response?.data?.message === "you have reached daily limit") {
  //           SetLimitMessage(true);
  //         } else if (error?.response?.data?.message?.includes("cannot generate")) {
  //           setInvalidAnswers(true);
  //           setMessages((prevMessages) => [
  //             ...prevMessages,
  //             { text: error?.response?.data?.message, user: false },
  //           ]);

  //           setCurrentQuestionIndex(0);
  //           setAnswers([]);
  //           setShowMoreStrategiesMessage(false);
  //           setUser(false);
  //           setCsvFileUrl(null);
  //           setShowError(false);
  //           setRetrying(false);
  //           setInvalidAnswers(false);
  //         }
  //         else {
  //           handleButtonClick("error");
  //           seErrorMessage(error?.response?.data?.message);
  //           setShowError(true);
  //           setShowMoreStrategiesMessage(true);
  //           // setRetrying(true);
  //           console.error("Error fetching CSV file:", error);
  //         }
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [currentQuestionIndex, user, messages, orgId]);

  useEffect(() => {
    console.log(JSON.parse(gettingQuestionAnswer) , "888888888888")
    const fetchData = async () => {
      if ((currentQuestionIndex === questionsLength )&& !user) {
        console.log("gggggggggggggggggggg1")
        setAnswers((prevAnswers) => [
          ...prevAnswers,
          {
            question: botQuestions[currentQuestionIndex]?.question,
            answer: messages[messages.length - 1].text,
          },
        ]);

        setAnswerQuestion((prevAnswers) => [
          ...prevAnswers,
          {
            question: botQuestions[currentQuestionIndex]?.question,
            answer: messages[messages.length - 1].text,
          },
        ]);

        localStorage.setItem('answerQuestion', JSON.stringify(answerQuestion));
        setIsLoading(true);
        try {
          console.log("nnnnnnnnsssss")
         console.log(answers,"ooooooooooooooo")
         let setAns
        //  if(JSON.parse(gettingQuestionAnswer).length === 6 && orgId ){
        //   setAns = JSON.parse(gettingQuestionAnswer)
        //  }else{
        //   setAns = answers
        //  }
        if(userInfoDetails?.data?.accessToken) {
            
          
          const response = await getCSVFile(answers, userInfoDetails?.data?.accessToken);
          let retryCount = 0;
          const fileUrl = response?.data?.data?.url;
          console.log("RESPONSE", fileUrl);
     
          // localStorage.removeItem('answerQuestion')
          const retrySecondApi = async () => {
            try {
              console.log("retry Count 1", retryCount);
              const response2 = await fetch(fileUrl);
              console.log("retry Count 2", response2);
              console.log("retry Count 2", response2.status)

              if (response2.status == 200) {
                console.log("999",response2)
                clearInterval(retryInterval);
                setisloading(false);
                setGoal(response?.data?.data?.goal);
                setCsvFileUrl(fileUrl);
                setShowMoreStrategiesMessage(true);
                setSuccess(true)
                localStorage.removeItem('answerQuestion')
                audioRef.current.play();
                
              }
            }
            catch (error) {
              console.log("retry Count error", error)
            }
          }
          const maxRetries = 30
          console.log("retry Count", retryCount);
          setisloading(true);
          const retryInterval = setInterval(async () => {
            if (retryCount < maxRetries) {
              retrySecondApi();
              retryCount++;
            } else {
              clearInterval(retryInterval);
              setisloading(false);
              setShowError(true);
              setShowMoreStrategiesMessage(true);
            }
          }, 8000);
          // if (fileUrl) {
          //   setGoal(response?.data?.data?.goal);
          //   setCsvFileUrl(fileUrl);
          //   setShowMoreStrategiesMessage(true);
          //   audioRef.current.play();
          // }

        }
        } catch (error) {
          if (error?.response?.data?.message === "you have reached daily limit") {
            SetLimitMessage(true);
          } else if (error?.response?.data?.message?.includes("cannot generate")) {
            setInvalidAnswers(true);
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: error?.response?.data?.message, user: false },
            ]);

            setCurrentQuestionIndex(0);
            setAnswers([]);
            setShowMoreStrategiesMessage(false);
            setUser(false);
            setCsvFileUrl(null);
            setShowError(false);
            setRetrying(false);
            setInvalidAnswers(false);
          }
          else {
            handleButtonClick("error");
            seErrorMessage(error?.response?.data?.message);
            setShowError(true);
            setShowMoreStrategiesMessage(true);
            // setRetrying(true);
            console.error("Error fetching CSV file:", error);
          }
        } finally {
          setIsLoading(false);
        }
      }else if(JSON.parse(gettingQuestionAnswer).length && !user && orgId){
        console.log(JSON.parse(gettingQuestionAnswer).length,"gggggggggggggggggggg2")
        try {
          console.log("nnnnnnnnsssss")
         console.log(answers,"ooooooooooooooo")
         let setAns
        //  if(JSON.parse(gettingQuestionAnswer).length === 6 && orgId ){
        //   setAns = JSON.parse(gettingQuestionAnswer)
        //  }else{
        //   setAns = answers
        //  }
          
          const response = await getCSVFile(JSON.parse(gettingQuestionAnswer), userInfoDetails?.data?.accessToken);
         
          let retryCount = 0;
          const fileUrl = response?.data?.data?.url;
          console.log("RESPONSE", fileUrl);
          const retrySecondApi = async () => {
            try {
              console.log("retry Count 1", retryCount);
              const response2 = await fetch(fileUrl);
              console.log("retry Count 2", response2);
              console.log("retry Count 2", response2.status)

              if (response2.status == 200) {
                console.log("999",response2)
                clearInterval(retryInterval);
                setisloading(false);
                setGoal(response?.data?.data?.goal);
                setCsvFileUrl(fileUrl);
                setShowMoreStrategiesMessage(true);
                
                setSuccess(true)
                localStorage.removeItem('answerQuestion')
                audioRef.current.play();
                
              }
            }
            catch (error) {
              console.log("retry Count error", error)
            }
          }
          const maxRetries = 30
          console.log("retry Count", retryCount);
          setisloading(true);
          const retryInterval = setInterval(async () => {
            if (retryCount < maxRetries) {
              retrySecondApi();
              retryCount++;
            } else {
              clearInterval(retryInterval);
              setisloading(false);
              setShowError(true);
              setShowMoreStrategiesMessage(true);
            }
          }, 8000);
          // if (fileUrl) {
          //   setGoal(response?.data?.data?.goal);
          //   setCsvFileUrl(fileUrl);
          //   setShowMoreStrategiesMessage(true);
          //   audioRef.current.play();
          // }
        } catch (error) {
          if (error?.response?.data?.message === "you have reached daily limit") {
            SetLimitMessage(true);
          } else if (error?.response?.data?.message?.includes("cannot generate")) {
            setInvalidAnswers(true);
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: error?.response?.data?.message, user: false },
            ]);

            setCurrentQuestionIndex(0);
            setAnswers([]);
            setShowMoreStrategiesMessage(false);
            setUser(false);
            setCsvFileUrl(null);
            setShowError(false);
            setRetrying(false);
            setInvalidAnswers(false);
          }
          else {
            handleButtonClick("error");
            seErrorMessage(error?.response?.data?.message);
            setShowError(true);
            setShowMoreStrategiesMessage(true);
            // setRetrying(true);
            console.error("Error fetching CSV file:", error);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [currentQuestionIndex, user, messages, orgId]);


  const handleButtonClick = (response) => {
    if (response === "yes") {
      if(JSON.parse(gettingQuestionAnswer).length === questionsLength){
        getBotQuestions()
      }
      setGettingQuestionAnswer(defaultValue)
      localStorage.removeItem('answerQuestion')
      setCurrentQuestionIndex(0);
      setMessages([]);
      setAnswers([]);
      setShowMoreStrategiesMessage(false);
      setUser(false);
      setCsvFileUrl(null);
      setShowError(false);
    } else if (response === "no") {
      // HideChatBot();
      if(JSON.parse(gettingQuestionAnswer).length === questionsLength){
        getBotQuestions()
      }
      setGettingQuestionAnswer(defaultValue)
      localStorage.removeItem('answerQuestion')
      setCurrentQuestionIndex(0);
      setMessages([]);
      setAnswers([]);
      setShowMoreStrategiesMessage(false);
      setUser(false);
      setCsvFileUrl(null);
      setShowError(false);
    } else if (response === "error" && !invalidAnswers) {
      // if (retryCount < 3) {
      //   setRetrying(true);
      //   retryApiCall(botQuestions[0].question, retryCount);
      // } else {
      //   setRetrying(true);
      //   retryApiCall(botQuestions[0].question, retryCount);
      // }
    }
  };

  

return(
  <Row className="m-0 p-0 bg-white">
        <Row className="m-0 p-0">
        <div class="col-sm-1 m-0 p-0 col-width col"></div>
          {/* <Col className="col-sm-1 m-0 p-0 col-width"></Col> */}
          <StoreHeaders />
          <div style={{marginTop:"7rem",paddingRight:"6%",paddingLeft:"8%"}}>
          <Row style={{ }} >
          <p className="quote1">
                    <span className="quote1-sub-0">
                    <h1 className="quote1-sub-0 ">Build an AI plan <span style={{color:"#4dbfdb"}}>in one minute </span></h1>
                    </span>
                    <span className="quote1-sub-1">
                    <h2 className="quote1-sub-1">Answer 6 simple questions and Plansom will build you a plan to solve any problem</h2>
                    </span>
                  </p>
        </Row>
        {/* <Row>
        <p className="quote-text mt-1">
          Need a plan? Build an AI plan in 1 minute 
         </p>
         {renderAlertText()}
        </Row> */}
        {/* <Row>
        {(loggedInUser == "false" || loggedInUser == null || loggedInUser == false)  && (<div className="bot-message">
              <strong>Q1/6.</strong> To create a good strategy, I need to understand your business. Please describe your business.
            </div>)}
        </Row> */}
        <Row>
        {(isLoadingQuestions ?
              (
                <div className="loader" style={{ color: '#F3F4F6', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </div>
              ) : (
                <>
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={message.user ? "user-message mb-3 ml-6" : "bot-message mb-3 ml-6" }
                      ref={index === messages.length - 1 ? lastMessageRef : null}
                    >
                      <div className="quote-text ">
                      {message?.text}
                      </div>
                    </div>
                  ) )}

         {answers.length ==  (questionsLength+1) && !orgId && <>
             <h3 style={{color:"white"}}> Please login to continue...</h3>
         </>}

                    {(isLoading || isloading) && !retrying && (
                    <div className="loader" >Your strategy is cooking.This will take a little while as it's quite complicated. You can continue to use the app whilst you wait. Just come back here in a minute.&nbsp;<span className="spinner-grow spinner-grow-sm"></span></div>
                  )
                  }
                  {limitMessage && (
                    <>
                      <div className="bot-message">
                        <i className="fa fa-warning"></i> You have reached daily limit
                      </div>
                    </>
                  )}
                  {success && (
                    <>
                      <div className="bot-message">
                        <i className="fa fa-success"></i> Your strategy created successfully, click <a style={{color:"blue"}} href="/goals">here</a> to see your strategy
                      </div>
                    </>
                  )}
                  {showError && (
                    <>
                      <div className="bot-message">
                        {errorMessage}
                      </div>
                      <div>
                        {renderMoreStrategiesMessage()}
                      </div>
                    </>
                  )}
                  </>
                  ))}
        </Row>
        <Row style={{marginBottom:"50px"}}>
        <div className="quote-text chatbot-input mt-3">
            <input
              type="text"
              placeholder="Type a message..."
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== "" && !isLoading) {
                  handleUserMessage(e.target.value);
                  e.target.value = "";
                }
              }
            }
            />
          </div>
        </Row>
        </div>
        </Row>
    
      </Row>
)}
export default Solutions