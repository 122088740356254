import React from "react";

function RatingsIcon(props) {
  let isBlank = props?.num <= props?.ratingCount ? false : true;
  let fontWeightValue = !isBlank ? "1000" : "normal"; // Set fontWeight based on the condition

  return (
    <span
      className={
        props?.num <= props?.ratingCount
          ? "fa fa-star checked starsColor"
          : "fa fa-star"
      }
      style={{ fontWeight: fontWeightValue }}
    ></span>
  );
}

const Ratings = (props) => {
  const totalRating = [1, 2, 3, 4, 5];
  const ratingCount = props?.count;

  return (
    <>
      {totalRating.map((ele) => (
        <RatingsIcon num={ele} ratingCount={ratingCount} />
      ))}
    </>
  );
};

export default Ratings;
