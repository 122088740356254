import { getRevenueDetails } from "../../../services/AuthService"
import { useEffect, useState } from "react";

const Revenue = () =>{
    const userDetail = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetail);
    const [revenueDetail, setRevenueDetail] = useState()

    const getRevenueDetail = () =>{
    const data = getRevenueDetails(userInfoDetails?.data?.accessToken);
    data.then((res) => {
        console.log(res?.data?.data?.creation_revenue,"responsesssssssssssss")
        setRevenueDetail(res?.data?.data)
      
    })
    data.catch((err) => {
       
    });}

    useEffect(()=>{
        getRevenueDetail()
    },[])
    
    return(<>
    <div className="card p-4 m-4">
    <div className="h2">
        Revenue Details
    </div>
    <div className="mt-4">
        <span className="h4">Creation revenue : </span><span className="h4"> ${revenueDetail?.creation_revenue}</span>
    </div>
    <div className="mt-4">
        <span className="h4">Origination revenue : </span><span className="h4"> ${revenueDetail?.origination_revenue}</span>
    </div>
    <div className="mt-4">
        <span className="h4">Total revenue : </span><span className="h4"> ${revenueDetail?.total_revenue}</span>
    </div>
    

    </div>
    </>)
}
export default Revenue