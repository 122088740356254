import { RingLoader } from "react-spinners";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserdetail } from "../../../../src/store/actions/AuthActions";
import profilePicture from "../../../images/profile/profilePic.png";
import CryptoJS from "crypto-js";
import Select from "react-select";
import {
  sendOTPForEmail,
  verifyEmailAction,
  changePasswordAction,
  updateProfileAction,
  uploadProfilePic,
} from "../../../services/AuthService";
import { Row, Col, Tab, Nav, Modal, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Vector3 from "../../../images/vector/Vector3.png";
import Enc_Key from "../../config/index";
import { toast } from "react-toastify";
import { resendOTPChangeEmail } from "../../../services/AuthService";
import moment from "moment";
import swal from "sweetalert";
import { logout } from "../../../../src/store/actions/AuthActions";
import CoachSetting from "./CoachSetting";
import EmailNotification from "./EmailNotification";
import Teams from "./Teams";

const AppProfile = () => {
  const salt = Enc_Key.Enc_Key;
  const fileRef = useRef();
  const stateData = useSelector((state) => state);
  const navigate = useNavigate();
  const [userProfile, setUserDetails] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showOTPDiv, setOTPForm] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [passChanged, passwordChanged] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [OpenWarningModal, setOpenWarningModal] = useState();
  const [CheckEmail, setCheckEmail] = useState(false);
  const [CheckValidEmail, setCheckValidEmail] = useState(false);
  const [checkValidEmailError, setCheckValidEmailError] = useState()
  const [otpValue, setValue] = useState("");
  const [profilePic, setProfilePicture] = useState("");
  const { t } = useTranslation();
  let errorsObj = { confirmPass: "", pass: "", newPass: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [lunchStartTime, setLunchStartTime] = useState("");
  const [lunchEndTime, setLunchEndTime] = useState("");
  const [startDay, setStartDay] = useState({
    value: "Monday",
    label: "Monday",
  });
  const [endDay, setEndDay] = useState({ value: "Friday", label: "Friday" });
  const tabData = [
    {
      name: "Profile",
      icon: "home",
    },
    {
      name: "Change email",
      icon: "user",
    },
    {
      name: "Change password",
      icon: "user",
    },
    // {
    //   name : "My Mentor",
    //   icon : "user"
    // },
    {
      name : "Manage emails",
      icon : "user"
    },
    {
      name :"My Teams",
      icon : "user"
    }
  ];
  const Days = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];
  const initialPasswordState = {
    old_pass: "",
    new_pass: "",
    confirm_new_pass: "",
  };
  const [changePass, changePassword] = useState({
    old_pass: "",
    new_pass: "",
    confirm_new_pass: "",
  });
  const [updateProfile, setProfileInfo] = useState({
    first_name: "",
    last_name: "",
    start_day: "",
    end_day: "",
    lunch_start_time: "",
    lunch_end_time: "",
    start_time: "",
    end_time: "",
    lunch_breaks: "",
    working_days: "",
    daily_working_hours: "",
    weekly_working_hours: "",
    holidays: "",
    email: "",
    id: "",
    timezone: "",
  });
  const [formProfile, setFormProfile] = useState({ ...updateProfile });

  
 

  // console.log(formProfile.first_name, "8575787547")
  const [originalProfile, setOriginalProfile] = useState({ ...updateProfile });
  
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const userDetailss = useSelector((state) => state);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getUserdetail(userInfoDetails?.data?.id, userInfoDetails?.data?.accessToken));
  // }, [dispatch, userInfoDetails]);

  // useEffect(()=>{
  //   setFormProfile({ ...updateProfile })
  // },[updateProfile])


  

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
    dispatch(
      getUserdetail(userInfoDetails?.data?.id, userInfoDetails?.data?.accessToken)
    );
  }, []);

  useEffect(()=>{
    dispatch(
      getUserdetail(userInfoDetails?.data?.id, userInfoDetails?.data?.accessToken)
    );
  },[])

  useEffect(() => {
    setNewEmail(newEmail);
    if (newEmail) {
      setCheckEmail(false);
    }
    if (CheckValidEmail) {
      setCheckValidEmail(false);
    }
    if (showOTPDiv) setOTPForm(false);
  }, [newEmail]);

  const convertTime12to24 = (time12h) => {
    if (time12h) {
      const [time, modifier] = time12h?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      return `${hours}:${minutes}`;
    }
  };

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userDetails"));
    userInfo.data.first_name = stateData.auth?.userDetails?.data?.first_name;
    userInfo.data.last_name = stateData.auth?.userDetails?.data?.last_name;
    // userInfo.data.org_access = stateData.auth?.userDetails?.data?.org_access;
    if (profilePic) userInfo.data.profile_picture = profilePic;
    localStorage.setItem("userDetails", JSON.stringify(userInfo));
  }, [stateData.auth?.userDetails]);

  useEffect(() => {
    setEmailChanged(false);
    if (stateData.auth.successMessage === "Got UserDetails") {
      setUserDetails(stateData.auth.userDetails.data);
      const setUserData = {
        first_name: stateData.auth.userDetails?.data?.first_name,
        last_name: stateData.auth.userDetails?.data?.last_name,
        start_day: stateData.auth.userDetails?.data?.start_day,
        end_day: stateData.auth.userDetails?.data?.end_day,
        lunch_start_time: 
          stateData.auth.userDetails?.data?.lunch_start_time
        ,
        lunch_end_time: 
          stateData.auth.userDetails?.data?.lunch_end_time
        ,
        start_time: 
          stateData.auth.userDetails?.data?.start_time
        ,
        end_time:stateData.auth.userDetails?.data?.end_time,
        lunch_breaks: stateData.auth.userDetails?.data?.lunch_breaks,
        working_days: stateData.auth.userDetails?.data?.working_days,
        daily_working_hours:
          stateData.auth.userDetails?.data?.daily_working_hours,
        weekly_working_hours:
          stateData.auth.userDetails?.data?.weekly_working_hours,
        holidays: stateData.auth.userDetails?.data?.holidays,
        email: stateData.auth.userDetails?.data?.email,
        id: stateData.auth.userDetails?.data?.id,
        timezone: stateData.auth.userDetails?.data?.timezone,
      };
      setProfileInfo(setUserData);
      setFormProfile(setUserData)
      setOriginalProfile(setUserData)
      convertTime12to24(stateData.auth.userDetails?.data?.start_time);
      setStartTime(stateData.auth.userDetails?.data?.start_time);
      setEndTime(stateData.auth.userDetails?.data?.end_time);
      setLunchStartTime(stateData.auth.userDetails?.data?.lunch_start_time);
      setLunchEndTime(stateData.auth.userDetails?.data?.lunch_end_time);
      setOTPForm(false);
      setEmailChanged(false);
      passwordChanged(false);
      changePassword(initialPasswordState);
      setNewEmail("");
      if (stateData.auth.userDetails?.data?.profile_picture === null) {
        setProfilePicture(() => profilePicture);
      } else {
        let profile = stateData.auth.userDetails?.data?.profile_picture;
        setProfilePicture(() => profile);
      }
    }
  }, [stateData]);
 
  
  const handleChange = (e) => {
    const [file] = e.target.files;
    const data = uploadProfilePic(
      file,
      userInfoDetails.data.id,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      let profiles = res.data?.data?.profile_picture;
      setProfilePicture(() => profiles);
      dispatch(
        getUserdetail(userInfoDetails.data.id, userInfoDetails.data.accessToken)
      );
      setTimeout(() => {
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Your profile picture has been changed successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "profile-update",
            className: "toast-success-container toast-success-container-after ",
          }
        );
      }, 3000);
    });
    data?.catch(async (err) => {});
  };

  function sendOTP(e) {
    const data = sendOTPForEmail(newEmail, userInfoDetails.data.accessToken);
    data?.then(async (res) => {
      if (res.data.message === "Verification Code sent successfully") {
        setOTPForm(true);
      }
    });
    data?.catch(async (err) => {
      setCheckValidEmail(true);
      setCheckValidEmailError(err?.response?.data?.message)
    });
  }

  function emailChange() {
    verifyEmailAction(otpValue, userInfoDetails.data.accessToken)
      .then(async (res) => {
        setValue("")
        if (res.data.message === "User email updated successfully") {
          setOTPForm(false);
          setEmailChanged(true);
          localStorage.setItem(
            "userEmail",
            CryptoJS.AES.encrypt(
              JSON.stringify(res?.data?.data?.email),
              salt
            )?.toString()
          );
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Your email has been updated successfully!
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "profile-update",
              className:
                "toast-success-container toast-success-container-after ",
            }
          );
          localStorage.setItem("AutoLogin", false);
          dispatch(logout(navigate));

        }
        dispatch(getUserdetail(userInfoDetails?.data?.id, userInfoDetails?.data?.accessToken));
      })
      .catch(async (err) => {
        setEmailChanged(false);
        setValue("")
        swal("Oops", err?.response?.data?.message);
      });
  }
  const setotp = (value) => {
    setValue(value);
  };

  const handleProfileChange = (event) => {
    event.preventDefault();
    // const fieldName = event.target.getAttribute("name");
    // const fieldValue = event.target.value;
    // const newFormData = { ...updateProfile };
    // newFormData[fieldName] = fieldValue;

    const fieldName = event.target.getAttribute("name");
  const fieldValue = event.target.value;
  setFormProfile((prevProfile) => ({
    ...prevProfile,
    [fieldName]: fieldValue,
  }));

  console.log()
    
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    setErrors("")
    // const fieldName = event.target.getAttribute("name");
    // const fieldValue = event.target.value;
    // const newFormData = { ...changePass };
    // newFormData[fieldName] = fieldValue;
    // changePassword(newFormData);
    // const fieldName = event.target.getAttribute("name");
    // const fieldValue = event.target.value;
    const { name, value } = event.target;
    changePassword((prevState) => ({
      ...prevState,
      [name]: value
    }));
   

  };

  function chanePassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    console.log(changePass,"changePasswordSuccessfully")
  // Validate new password
  if (!passwordRegex.test(changePass.new_pass)) {
    errorObj.newPass = "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character*";
    error = true;
  }else if (changePass.new_pass !== changePass.confirm_new_pass) {
      errorObj.confirmPass =
        "New password and confirm new password must be same*";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    const data = changePasswordAction(
      changePass,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      // changePassword(initialPasswordState)
      console.log(res,"lkkaaaaaaaaaadddddddddddddd" )
      if (res.data.message === "Password reset successfully") {
        passwordChanged(true);
        dispatch(
          getUserdetail(
            userInfoDetails.data.id,
            userInfoDetails.data.accessToken
          )
        );
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Your password has been changed successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "profile-update",
            className: "toast-success-container toast-success-container-after ",
          }
        );
      }
    });
    data?.catch(async (err) => {
      // changePassword(initialPasswordState)
      swal("Oops", err?.response?.data?.message);
      // console.log(err.response.data.message,"lkkaaaaaaaaaadddddddddddddd")
      // errorObj.pass = err.response.data.message;
      // setErrors(errorObj);
      // error = true;
      // if (error) return;
     
     
    });
  }
  const clearStates = (e) => {
    console.log("AAAB")
    dispatch(
      getUserdetail(userInfoDetails.data.id, userInfoDetails.data.accessToken)
    );
    setValue("");
  };

  const editProfileInfo = (e) => {
    setEditButton(!editButton);
  };

  const submitUpdateInfo = (e) => {
    if (formProfile?.first_name?.length && formProfile?.last_name?.length) {
      const data = updateProfileAction(
        formProfile,
        formProfile?.start_day,
        formProfile?.end_day,
        formProfile?.start_time,
        formProfile?.end_time,
        formProfile?.lunch_start_time,
        formProfile?.lunch_end_time,
        userInfoDetails?.data?.accessToken
      );
      data?.then(async (res) => {
        setEditButton(false);
        dispatch(
          getUserdetail(
            userInfoDetails.data.id,
            userInfoDetails.data.accessToken
          )
        );
        setTimeout(() => {
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Your profile details has been updated successfully!
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "profile-update",
              className:
                "toast-success-container toast-success-container-after ",
            }
          );
        }, 3000);
      });
      data?.catch(async (err) => {
        toast(
          <>
            <div className="mx-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
              <div>
                <h2 style={{
                  // color: "#ffffff",
                  fontSize: "22px"
                }}>
                  Failed
                </h2>
                <h3 style={{
                  // color: "#ffffff",
                  fontSize: "18px"
                }}>
                  {err?.response?.data?.message}!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "thread-data",
            className: 'toast-error-container toast-error-container-after ',
          }
        )
      });
    }
  };

  const cancelUpdateInfo = (e) =>{
    
    setFormProfile({ ...originalProfile });
    
    setEditButton(false);
    // window.location.href="app-profile"
   
    // toast(
    //   <>
    //     <div className="m-1 d-flex">
    //       <i
    //         className="bi bi-check-circle fs-26 me-3"
    //         style={{ color: "#131F4C" }}
    //       ></i>
    //       <div>
    //         <h2
    //           style={{
    //             fontSize: "22px",
    //           }}
    //         >
    //           Cancelled
    //         </h2>
    //         <h3
    //           style={{
    //             fontSize: "18px",
    //           }}
    //         >
    //           Your profile update has been cancelled successfully!
    //         </h3>
    //       </div>
    //     </div>
    //   </>,
    //   {
    //     toastId: "profile-cancel",
    //     className: "toast-success-container toast-success-container-after ",
    //   }
    // );
  };

  const screenWidth = window.innerWidth;
  let inputStyles = {};

  if (screenWidth <= 320) {
    inputStyles = {
      height: 30,
      width: 25,
      className: 'small-screen-input',
    };
  } else if (screenWidth <= 375) {
    inputStyles = {
      height: 35,
      width: 30,
      className: 'medium-screen-input',
    };
  } else if (screenWidth <= 425) {
    inputStyles = {
      height: 40,
      width: 35,
      className: 'large-screen-input',
    };
  } else if (screenWidth <= 769) {
    inputStyles = {
      height: 50,
      width: 50,
      className: 'tap-screen-input',
    };
  }
  else {
    // Default styles for larger screens
    inputStyles = {
      height: 60,
      width: 60,
      className: 'default-input',
    };
  }

  const resendOTP = () => {
    console.log("123")
    const data = resendOTPChangeEmail(newEmail,userInfoDetails.data.accessToken);
    data?.then(async (res) => {
      toast(
        <>
        <div className="m-1 d-flex">
          <i
            class="bi bi-check-circle fs-26 me-3"
            style={{ color: "#131F4C" }}
          ></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Completed
            </h2>
            <h3
              style={{
                fontSize: "18px",
              }}
            >
              OTP sent successfully. Please check your email!
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "resend-otp-email-change",
        className:
          "toast-success-container toast-success-container-after ",
      }
    );
    })
    data?.catch(async (err) => {
      // console.log('error deleteTask',err)
    })
  };

 

  return (
    <>
      <Modal
        show={OpenWarningModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="change-email" title="change-email" src={Vector3} />
          <h4 className="fs-20 p-2">
            You will be logged out after changing email!
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            Are you sure you want to change email?{" "}
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/app-profile"
            onClick={() => {
              setOpenWarningModal(false);
              setValue("");
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              emailChange();
              setOpenWarningModal(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      {!tabData ? (
        <center>
          <div style={{ padding: "20px", margin: "300px" }}>
            <RingLoader
              color="#ff0000"
              size="100"
              loading
              cssOverride={{ opacity: 1 }}
            />
          </div>
        </center>
      ) : (
        <>
          <div className="row">
            {localStorage.getItem("previousLocation") !==
              localStorage.getItem("currentLocation") && (
              <span className="float-start ms-1 mb-1">
                <i
                  className="fa fa-reply fs-20"
                  title={`Navigate back to ${
                    localStorage.getItem("previousLocation").split("/")[1]
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(localStorage.getItem("previousLocation"));
                  }}
                />
              </span>
            )}
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="profile-info justify-content-center mt-5 pt-5">
                    <div
                      className="profile-photo"
                      style={{ display: "inline-flex" }}
                    >
                      <img
                        src={profilePic}
                        className="img-fluid rounded-circle my-0"
                        alt="profile-photo"
                        title="profile-photo"
                        onClick={() => fileRef.current.click()}
                      />
                      <p className="my-2 p-0 mt-5">
                        <Row style={{ width: "25px" }}>
                          <i
                            className="fas fa-pen mt-4 color-primary"
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            style={{
                              fontSize: "15px",
                              backgroundColor: "#356FD4",
                              padding: "5px 5px 5px 5px",
                              color: "white",
                              borderRadius: "30px",
                            }}
                            onClick={() => fileRef.current.click()}
                          ></i>
                        </Row>
                      </p>
                      <input
                        ref={fileRef}
                        onChange={handleChange}
                        multiple={false}
                        type="file"
                        accept="image/*"
                        hidden
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h4 className="text-center">
                      {" "}
                      {originalProfile.first_name} {originalProfile.last_name}{" "}
                      <br /> <p className="p">Email : {originalProfile.email}</p>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-12 card card-body"
                    style={{ height: "100%" }}
                  >
                    <Row>
                      <Tab.Container
                        defaultActiveKey={tabData[0].name.toLowerCase()}
                      >
                        <Col lg={3}>
                          <Nav as="ul" className="flex-column nav-pills mb-3">
                            {tabData.map((data, i) => (
                              <Nav.Item
                                as="li"
                                key={i}
                                style={{ cursor: "pointer" }}
                              >
                                <Nav.Link
                                  onClick={clearStates}
                                  eventKey={data.name.toLowerCase()}
                                  className="h5"
                                >
                                  {data.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        </Col>
                        <Col
                          lg={9}
                          style={{
                            backgroundColor: "#fffff",
                            borderRadius: 10,
                          }}
                        >
                          <Tab.Content className="ms-2">
                            {tabData.map((data, i) => (
                              <Tab.Pane
                                eventKey={data.name.toLowerCase()}
                                key={i}
                              >
                                
                                {data.name === "Profile" ? (
                                  <>
                                    {!editButton && (
                                      <button
                                        type="file"
                                        id="edit"
                                        name="img"
                                        accept="image/*"
                                        className="figma-btn text-white ms-3 my-4 profile-edit-button"
                                        onClick={editProfileInfo}
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                          width: "90px",
                                        }}
                                      >
                                        <i class="fas fa-pen me-2"></i>
                                        Edit
                                      </button>
                                    )}

                                    <div
                                      className={
                                        editButton === false
                                          ? "card profile-details-card"
                                          : "card shadow-lg"
                                      }
                                    >
                                      <div className="m-4 update-card">
                                        <div className="user-info">
                                          <h2 className="info-name">User information</h2>
                                        </div>
                                        <div className="card-body d-flex justify-content-end profile-details" >
                                          <div className="row">
                                            <div className="col-sm-6 col-12 profile-col-body">
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>First name</b>
                                                  </span>
                                                  <br />
                                                  <input
                                                    type="text"
                                                    name="first_name"
                                                    className="form-control mb-2"
                                                    placeholder="First name"
                                                    // defaultValue={
                                                    //   updateProfile?.first_name
                                                    // }
                                                    value={
                                                      formProfile?.first_name
                                                    }
                                                    disabled={!editButton}
                                                    style={
                                                      !editButton
                                                        ? {
                                                            backgroundColor:
                                                              "#f7f7f7",
                                                            height: "2.5rem",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff",
                                                            height: "2.5rem",
                                                          }
                                                    }
                                                    onChange={
                                                      handleProfileChange
                                                    }
                                                  />
                                                  {editButton &&
                                                    !formProfile?.first_name
                                                      .length && (
                                                      <span
                                                        className="m-0 p-0 "
                                                        style={{ color: "red" }}
                                                      >
                                                        Please enter user first
                                                        name*.
                                                      </span>
                                                    )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>last name</b>
                                                  </span>
                                                  <br />
                                                  <input
                                                    type="text"
                                                    name="last_name"
                                                    className="form-control mb-2"
                                                    placeholder="Last name"
                                                    value={
                                                      formProfile.last_name
                                                    }
                                                    disabled={!editButton}
                                                    style={
                                                      !editButton
                                                        ? {
                                                            backgroundColor:
                                                              "#f7f7f7",
                                                            height: "2.5rem",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff",
                                                            height: "2.5rem",
                                                          }
                                                    }
                                                    onChange={
                                                      handleProfileChange
                                                    }
                                                  />
                                                  {editButton &&
                                                    !formProfile?.last_name
                                                      .length && (
                                                      <span
                                                        className="m-0 p-0 "
                                                        style={{ color: "red" }}
                                                      >
                                                        Please enter user last
                                                        name*.
                                                      </span>
                                                    )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>Email</b>
                                                  </span>
                                                  <br />
                                                  <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control mb-2"
                                                    placeholder="Email"
                                                    value={formProfile.email}
                                                    disabled
                                                    style={{
                                                      backgroundColor:
                                                        "#f7f7f7",
                                                      height: "2.5rem",
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>Lunch break</b>
                                                  </span>
                                                  <br />
                                                  <input
                                                    type="text"
                                                    name="lunch_breaks"
                                                    className="form-control mb-2"
                                                    placeholder="Lunch breaks"
                                                    value={
                                                      formProfile.lunch_breaks
                                                    }
                                                    disabled={!editButton}
                                                    style={
                                                      !editButton
                                                        ? {
                                                            backgroundColor:
                                                              "#f7f7f7",
                                                            height: "2.5rem",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff",
                                                            height: "2.5rem",
                                                          }
                                                    }
                                                    onChange={
                                                      handleProfileChange
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>Start day</b>
                                                  </span>
                                                  <br />
                                                  <Select
                                                    name="start_day"
                                                    className="select rounded"
                                                    placeholder={
                                                      formProfile.start_day ==
                                                      null
                                                        ? "Select..."
                                                        : formProfile.start_day
                                                    }
                                                    // options={Days}
                                                    // defaultValue={Days[0]}
                                                    isDisabled={
                                                      !editButton ? true : false
                                                    }
                                                  
                                                    options={Days}
                                                    value={Days.find((day) => day.value === formProfile.start_day)}
                                                    onChange={(selectedOption) =>
                                                      setFormProfile({
                                                        ...formProfile,
                                                        start_day: selectedOption.value,
                                                      })
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </div>

                                            <div className="col-sm-6 col-12 profile-col-body">
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>Start time</b>
                                                  </span>
                                                  <br />
                                                  <div class="form-lable">
                                                    <TimePicker
                                                      // value={formProfile.start_time}
                                                      // placeholder={
                                                      //   formProfile.start_time
                                                      // }
                                                      value={formProfile.start_time ? moment(formProfile.start_time, 'HH:mm A') : null}
                                                      placeholder={formProfile.start_time ? formProfile.start_time : 'Select Time'}
                                                      ampm={false}
                                                      className="form-control mb-2"
                                                      showMinute={false}
                                                      showSecond={false}
                                                      focusOnOpen={true}
                                                      disabled={!editButton}
                                                      inputIcon={true}
                                                     
                                                      style={
                                                        !editButton
                                                          ? {
                                                              backgroundColor:
                                                                "#f7f7f7",
                                                              height: "2.5rem",
                                                            }
                                                          : {
                                                              backgroundColor:
                                                                "#ffffff",
                                                              height: "2.5rem",
                                                            }
                                                      }
                                                      // onChange={(e) => {

                                                      //   // setStartTime(
                                                      //   //   e
                                                      //   //     .format("LT")
                                                      //   //     .split(":")[0] +
                                                      //   //     ":00 " +
                                                      //   //     e
                                                      //   //       .format("LT")
                                                      //   //       .split(":")[1]
                                                      //   //       .split(" ")[1]
                                                      //   // );
                                                      //   setFormProfile((prevProfile) => ({
                                                      //     ...prevProfile,
                                                      //     start_time: e
                                                      //     .format("LT")
                                                      //     .split(":")[0] +
                                                      //     ":00 " +
                                                      //     e
                                                      //       .format("LT")
                                                      //       .split(":")[1]
                                                      //       .split(" ")[1],
                                                      //   }));
                                                      // }}
                                                      onChange={(e) => {
                                                        if (e && e.isValid()) {
                                                          setFormProfile((prevProfile) => ({
                                                            ...prevProfile,
                                                            start_time:
                                                              e.format('LT').split(':')[0] +
                                                              ':00 ' +
                                                              e.format('LT').split(':')[1].split(' ')[1],
                                                          }));
                                                        }
                                                      }}
                                                      clearIcon={formProfile.start_time ? <></> : undefined}
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>End time</b>
                                                  </span>
                                                  <br />
                                                  <TimePicker
                                                    value={formProfile.end_time ? moment(formProfile.end_time, 'HH:mm A') : null}
                                                    placeholder={formProfile.end_time ? formProfile.end_time : 'Select Time'}
                                                    use24Hours
                                                    ampm={false}
                                                    showMinute={false}
                                                    showSecond={false}
                                                    focusOnOpen={true}
                                                    className="form-control mb-2"
                                                    disabled={!editButton}
                                                    style={
                                                      !editButton
                                                        ? {
                                                            backgroundColor:
                                                              "#f7f7f7",
                                                            height: "2.5rem",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff",
                                                            height: "2.5rem",
                                                          }
                                                    }
                                                    // onChange={(e) =>
                                                    //   setEndTime(
                                                    //     e
                                                    //       .format("LT")
                                                    //       .split(":")[0] +
                                                    //       ":00 " +
                                                    //       e
                                                    //         .format("LT")
                                                    //         .split(":")[1]
                                                    //         .split(" ")[1]
                                                    //   )
                                                    // }
                                                    onChange={(e) => {
                                                      if (e && e.isValid()) {
                                                        setFormProfile((prevProfile) => ({
                                                          ...prevProfile,
                                                          end_time:
                                                            e.format('LT').split(':')[0] +
                                                            ':00 ' +
                                                            e.format('LT').split(':')[1].split(' ')[1],
                                                        }));
                                                      }
                                                    }}
                                                    clearIcon={formProfile.end_time ? <></> : undefined}
                                                  />
                                                 
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>Lunch start time</b>
                                                  </span>
                                                  <br />
                                                  <TimePicker
                                                    // placeholder={
                                                    //   formProfile.lunch_start_time
                                                    // }
                                                    value={formProfile.lunch_start_time ? moment(formProfile.lunch_start_time, 'HH:mm A') : null}
                                                    placeholder={formProfile.lunch_start_time ? formProfile.lunch_start_time : 'Select Time'}
                                                    use24Hours
                                                    className="form-control mb-2"
                                                    showMinute={false}
                                                    showSecond={false}
                                                    focusOnOpen={true}
                                                    disabled={!editButton}
                                                    ampm={false}
                                                    style={
                                                   
                                                      !editButton
                                                        ? {
                                                            backgroundColor:
                                                              "#f7f7f7",
                                                            height: "2.5rem",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff",
                                                            height: "2.5rem",
                                                          }
                                                    }
                                                    // onChange={(e) =>
                                                    //   setLunchStartTime(
                                                    //     e
                                                    //       .format("LT")
                                                    //       .split(":")[0] +
                                                    //       ":00 " +
                                                    //       e
                                                    //         .format("LT")
                                                    //         .split(":")[1]
                                                    //         .split(" ")[1]
                                                    //   )
                                                    // }
                                                    onChange={(e) => {
                                                      if (e && e.isValid()) {
                                                        setFormProfile((prevProfile) => ({
                                                          ...prevProfile,
                                                          lunch_start_time:
                                                            e.format('LT').split(':')[0] +
                                                            ':00 ' +
                                                            e.format('LT').split(':')[1].split(' ')[1],
                                                        }));
                                                      }
                                                    }}
                                                    clearIcon={formProfile.end_time ? <></> : undefined}
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>Lunch end time</b>
                                                  </span>
                                                  <br />
                                                  <TimePicker
                                                    // placeholder={
                                                    //   updateProfile.lunch_end_time
                                                    // }
                                                    value={formProfile.lunch_end_time ? moment(formProfile.lunch_end_time, 'HH:mm A') : null}
                                                    placeholder={formProfile.lunch_end_time ? formProfile.lunch_end_time : 'Select Time'}
                                                    use24Hours
                                                    className="form-control mb-2"
                                                    showMinute={false}
                                                    showSecond={false}
                                                    focusOnOpen={true}
                                                    disabled={!editButton}
                                                    ampm={false}
                                                    style={
                                                      !editButton
                                                        ? {
                                                            backgroundColor:
                                                              "#f7f7f7",
                                                            height: "2.5rem",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff",
                                                            height: "2.5rem",
                                                          }
                                                    }
                                                    // onChange={(e) =>
                                                    //   setLunchEndTime(
                                                    //     e
                                                    //       .format("LT")
                                                    //       .split(":")[0] +
                                                    //       ":00 " +
                                                    //       e
                                                    //         .format("LT")
                                                    //         .split(":")[1]
                                                    //         .split(" ")[1]
                                                    //   )
                                                    // }
                                                    onChange={(e) => {
                                                      if (e && e.isValid()) {
                                                        setFormProfile((prevProfile) => ({
                                                          ...prevProfile,
                                                          lunch_end_time:
                                                            e.format('LT').split(':')[0] +
                                                            ':00 ' +
                                                            e.format('LT').split(':')[1].split(' ')[1],
                                                        }));
                                                      }
                                                    }}
                                                    clearIcon={formProfile.end_time ? <></> : undefined}
                                                  />
                                                </td>
                                              </tr>

                                              <tr>
                                                <td className="profile-data">
                                                  <span className="h4">
                                                    <b>End day</b>
                                                  </span>
                                                  <br />
                                                  <Select
                                                    name="end_day"
                                                    placeholder={
                                                      formProfile.end_day ==
                                                      null
                                                        ? "Select..."
                                                        : formProfile.end_day
                                                    }
                                                    // options={Days}
                                                    className="select rounded "
                                                    // defaultValue={Days[4]}
                                                    isDisabled={
                                                      !editButton ? true : false
                                                    }
                                                    // onChange={(e) =>
                                                    //   setEndDay(e)
                                                    // }
                                                    options={Days}
                                                    value={Days.find((day) => day.value === formProfile?.end_day)}
                                                    onChange={(selectedOption) =>
                                                      setFormProfile({
                                                        ...formProfile,
                                                        end_day: selectedOption.value,
                                                      })
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </div>
                                          </div>
                                          <div className="text-center d-sm-flex justify-content-sm-between">
                                        <button
                                          className="figma-btn text-white my-2 profile-cancel-button m-1"
                                          hidden={!editButton}
                                          onClick={cancelUpdateInfo}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className="figma-btn text-white my-2 profile-save-button m-1"
                                          hidden={!editButton}
                                          onClick={submitUpdateInfo}
                                        >
                                          Save
                                        </button>
                                      </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div></div>
                                  </>
                                ) : (
                                  <>
                                 
                                  </>
                                )}
                                   {data.name === "Change email" ? (
                                      <>
                                        <div className="mt-2 ms-4 me-4 change-email">
                                          <h4 >Change email</h4>
                                          </div>  
                                          <Row>
                                            <Col sm={12} md={8} className="mt-2">
                                            
                                          <input
                                            type="email"
                                            // contenteditable="true"
                                            name="email"
                                            className="figma-input p new-email-input"
                                            style={{
                                              width: "100%",
                                              height:"100%"
                                              // display: "inline-flex",
                                            }}
                                            placeholder="Enter new email"
                                            // autocomplete="off"
                                            value={newEmail}
                                            onChange={(event) =>
                                              handleEmailChange(event)
                                            }
                                          />
                                          </Col>
                                          <Col sm={12} md={4} className="mt-2">
                                          <button 
                                            style={{ float: "right" }}
                                            className="figma-btn text-white"
                                            onClick={
                                              newEmail.length !== 0
                                                ? (e) => sendOTP(e)
                                                : (e) => {
                                                    setCheckEmail(true);
                                                  }
                                            }
                                          >
                                            Verify email
                                          </button>
                                          </Col>
                                          </Row>
                                       
                                        <>
                                          {CheckEmail && (
                                            <span
                                              className="ms-4"
                                              style={{ color: "red" }}
                                            >
                                              Please enter the email*
                                            </span>
                                          )}
                                          {CheckValidEmail && (
                                            <span
                                              className="ms-4"
                                              style={{ color: "red" }}
                                            >
                                              {checkValidEmailError}
                                            </span>
                                          )}
                                        </>

                                        {showOTPDiv &&
                                          !emailChanged &&
                                          newEmail != "" && (
                                            <div className="card m-4 shadow-lg text-center otp-card" style={{ maxWidth: "550px", margin: "auto" }}>
  <div className="m-4">
    <div className="center">
      <h2>Enter code</h2>
    </div>
    <div className="card-body">
      <div className="row justify-content-center">
        <div className="col-12">
          <p style={{ color: "#717994" }}>
            We sent a code to{" "}
            <span style={{ color: "#424C70" }}>
              {newEmail}
            </span>
          </p>
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-center align-items-center">
            <OtpInput
              value={otpValue}
              className="figma-otp-box input-otp-box"
              inputStyle={inputStyles}
              onChange={(e) => {
                setotp(e);
              }}
              inputProps={{
                className: 'input-otp',
              }}
              numInputs={6}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="d-flex flex-column align-items-center">
            <button
              variant="primary"
              className="figma-btn text-white mb-2 mt-3"
              onClick={(e) => setOpenWarningModal(true)}
            >
              {t("Change email")}
            </button>
            <p style={{ color: "#717994" }}>
              Didn't receive the email?
              <Link className="figma-link" to="" onClick={resendOTP}>
                &nbsp;Click to resend
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
                                      </div>


                                          )}
                                        {emailChanged && !showOTPDiv && (
                                          <Alert
                                            variant="primary"
                                            className=" text-center alert-dismissible fade show"
                                          >
                                            <svg
                                              viewBox="0 0 24 24"
                                              width="24"
                                              height="24"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              className="me-2"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                              <line
                                                x1="9"
                                                y1="9"
                                                x2="9.01"
                                                y2="9"
                                              ></line>
                                              <line
                                                x1="15"
                                                y1="9"
                                                x2="15.01"
                                                y2="9"
                                              ></line>
                                            </svg>
                                            <strong>
                                              Email changed successfully
                                            </strong>
                                          </Alert>
                                        )}
                                      </>
                                    ) : (
                                     <></>
                                    )}

                                  {data.name === "Change password" ? (
                                          <>
                                          <div className="m-4">
                                            {passChanged && (
                                              <>
                                                <Alert
                                                  variant="primary"
                                                  className=" text-center alert-dismissible fade show"
                                                >
                                                  <svg
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="me-2"
                                                  >
                                                    <circle
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                    ></circle>
                                                    <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                    <line
                                                      x1="9"
                                                      y1="9"
                                                      x2="9.01"
                                                      y2="9"
                                                    ></line>
                                                    <line
                                                      x1="15"
                                                      y1="9"
                                                      x2="15.01"
                                                      y2="9"
                                                    ></line>
                                                  </svg>
                                                  <strong>
                                                    Password changed successfully
                                                  </strong>
                                                </Alert>
                                              </>
                                            )}
                                            {!passChanged && (
                                              <>
                                                <span class="h2">
                                                  Change password
                                                </span>
                                                {/* {errors.pass !== "" && (
                                                  <Alert
                                                    variant="warning"
                                                    className=" text-center alert-dismissible fade show"
                                                  >
                                                    <svg
                                                      viewBox="0 0 24 24"
                                                      width="24"
                                                      height="24"
                                                      stroke="currentColor"
                                                      strokeWidth="2"
                                                      fill="none"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      className="me-2"
                                                    >
                                                      <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                      ></circle>
                                                      <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                      <line
                                                        x1="9"
                                                        y1="9"
                                                        x2="9.01"
                                                        y2="9"
                                                      ></line>
                                                      <line
                                                        x1="15"
                                                        y1="9"
                                                        x2="15.01"
                                                        y2="9"
                                                      ></line>
                                                    </svg>
                                                    <strong>{errors.pass}</strong>
                                                  </Alert>
                                                )} */}
                                                <form
                                                  onSubmit={chanePassword}
                                                  class="parsley-examples col-sm-6"
                                                >
                                                  <div class="mb-2">
                                                    <input
                                                      id="oldpass"
                                                      name="old_pass"
                                                      type="password"
                                                      placeholder="Old password"
                                                      required
                                                      onChange={
                                                        handleChangePassword
                                                      }
                                                      class="figma-input p"
                                                    />
  
                                                  </div>
                                                  <div class="mb-2">
                                                    <input
                                                      id="pass1"
                                                      name="new_pass"
                                                      type="password"
                                                      placeholder="Password"
                                                      required
                                                      class="figma-input p"
                                                      onChange={
                                                        handleChangePassword
                                                      }
                                                    />
                                                     {errors.newPass && (
                                                      <div className="mt-2 text-danger">
                                                        {errors.newPass}
                                                      </div>
                                                    )}
                                                  </div>
  
                                                  <div class="mb-2">
                                                    <input
                                                      data-parsley-equalto="#pass1"
                                                      name="confirm_new_pass"
                                                      type="password"
                                                      required
                                                      placeholder="Confirm password"
                                                      onChange={
                                                        handleChangePassword
                                                      }
                                                      class="figma-input p"
                                                      id="passWord2"
                                                    />
                                                    {errors.confirmPass && (
                                                      <div className="mt-2 text-danger">
                                                        {errors.confirmPass}
                                                      </div>
                                                    )}
                                                    {/* {errors.pass && (
                                                      <div className="mt-2 text-danger">
                                                        {errors.pass}
                                                      </div>
                                                    )} */}
                                                  </div>
                                                  <div className="text-center mt-2">
                                                    <button
                                                      class="figma-btn text-white "
                                                      type="submit"
                                                    >
                                                      Change password
                                                    </button>
                                                  </div>
                                                </form>
                                              </>
                                            )}
                                          </div>
                                        </>

                                  ):(<></>)}

                                  {/* {data.name === "My Mentor" ? (
                                    <div className="mb-2">
                                    <CoachSetting></CoachSetting>
                                    </div>
                                   ) : (<></>)} */}

                                   {data.name === "Manage emails" ? (
                                     <EmailNotification></EmailNotification> 
                                                        ) :(<></>)}
                                   {data.name === "My Teams" ? (<>
                                   <Teams></Teams>
                                   </>):(<></>)}


















                                

                                
                              </Tab.Pane>
                            ))}
                          </Tab.Content>{" "}
                        </Col>
                      </Tab.Container>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AppProfile;
