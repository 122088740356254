import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Row } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { listOfemailNotifications } from '../../../services/messageService';
import { emailNotificationUpdates } from '../../../services/messageService';
import { Box } from '@mui/material';


const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  

const EmailNotification = () =>{
    const userDetails = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetails);
    const [emailList, setEmailList] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [checked, setChecked] = useState(true);

    const handleChange = (index, id) => (event) => {
        const newEmailList = [...emailList];
        newEmailList[index].status = event.target.checked;
        setEmailList(newEmailList);

        const data = emailNotificationUpdates(id,userInfoDetails?.data?.accessToken)
    data?.then(async (res) => {
        console.log("Email responsesss", res?.data?.data )
        getAllEmailList()
     
    })
    data?.catch(async (err) => {
        console.log("Email responsesss", err )
    })

      };

  const getAllEmailList = () =>{
    const data = listOfemailNotifications(userInfoDetails?.data?.accessToken)
    data?.then(async (res) => {
        console.log("Email response", res?.data?.data )
        setEmailList(res?.data?.data)
     
    })
    data?.catch(async (err) => {
        console.log("Email response", err )
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  useEffect(()=>{
    getAllEmailList()
  },[])




    return(<>
  
      <div className="card p-4 mt-4" style={{ width: windowWidth > 768 ? '50%' : '100%', border: "1px solid #F3F4F6",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
 }}>
      <Typography variant="h5" className="mb-4">Email Notification</Typography>
      {emailList.map((email, index) => (
      <Grid container alignItems="center" spacing={2} key={index}>
        <Grid item xs={6}>
          <Typography variant="body1">{email?.email?.email_name}</Typography>
        </Grid>
        <Grid item xs={6}>
        <Grid container justifyContent="flex-end"> {/* Container to position toggle button at end */}
        <Box>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={email?.status}
                onChange={handleChange(index,email?.id )}
              />
            }
            label=""
         
          />
          </Box>
         
          </Grid>
        </Grid>
      </Grid>
      ))}
    </div>
    </>)
}
export default EmailNotification