import React from "react";
import Scheduler, { Resource, View } from "devextreme-react/scheduler";
import { Link } from "react-router-dom";
import { getDataForCalender } from "../../../services/CalendarService";
import { getOrgMem } from "../../../services/TeamService";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import profilePicture from "../../../images/profile/profilePic.png";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { rescheduledTask } from "../../../services/TaskService";
import { Modal, Button } from "react-bootstrap";
import ErrorBoundary from "../Menu/ErrorBoundary";
import MyCalendar from "../Menu/Calendar";
const currentDate = new Date();
const priorityGroups = ["typeId"];
const CustomClearText = () => "clear all";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

class EventCalendar extends React.Component {
  constructor(props) {
    super(props);
    const userDetail = localStorage.getItem("userDetails");
    this.userInfoDetails = JSON.parse(userDetail);
    this.state = {
      showToaster: false,
      showSpinner: false,
      orgMembers: [],
      dataSource: [],
      currentDate: new Date(),
      accessToken: this.userInfoDetails?.data?.accessToken,
      selectedUser: [],
      isDatePickerOpen: false,
      showCalendar: false,
      rescheduledTime: new Date(),
      selectedTaskStatus: [
        {
          label: "Scheduled",
          value: "scheduled",
        },
        {
          label: "Completed",
          value: "completed",
        },
      ],
      usersData: [],
      views: [
        {
          type: "day",
          name: "Day",
          groupOrientation: "horizontal",
          maxAppointmentsPerCell: "unlimited",
        },
        {
          type: "workWeek",
          name: "Week",
          groupOrientation: "horizontal",
          maxAppointmentsPerCell: "unlimited",
        },
        {
          type: "month",
          name: "Month",
          groupOrientation: "horizontal",
          maxAppointmentsPerCell: 3,
        },
      ],
      showModal: false,
      taskDetailsinfo: {}, // Assuming you have these details in the state
      taskOwner: {}, // Assuming you have these details in the state
    };
    this.taskStatusOptions = [
      {
        label: "Scheduled",
        value: "scheduled",
      },
      {
        label: "Completed",
        value: "completed",
      },
    ];

    this.taskColor = [
      {
        id: 1.1,
        color: "#A8D6C5",
      },
      {
        id: 1.2,
        color: "#F5D9B0",
      },
      {
        id: 1.3,
        color: "#EEAEBA",
      },
      {
        id: 2,
        color: "#9db7e4",
      },
    ];
    this.handleDateChange = this.handleDateChange.bind(this);
    this.convertToLocalTime = this.convertToLocalTime.bind(this);
    this.scheduleTask = this.scheduleTask.bind(this);
    this.setTaskRescheduling = this.setTaskRescheduling.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this.getOrg();
    setTimeout(() => {
      this.getData();
    }, 100);
  }

  componentDidUpdate(e) {}
  componentWillReceiveProps(props) {
    this.setState((prev) => prev);
    this.setState({ dataSource: this.state.dataSource });
  }

  changeHandler = (userSelected) => {
    this.setState(() => ({ selectedUser: userSelected }));
    setTimeout(() => {
      this.getData();
    }, 1000);
  };

  changeTaskStatusHandler = (userSelected) => {
    this.setState(() => ({ selectedTaskStatus: userSelected }));
    setTimeout(() => {
      this.getData();
    }, 1000);
  };

  getOrg = () => {
    const data = getOrgMem(this.state.accessToken);
    data?.then((res) => {
      let optionData = res?.data?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        if (option.first_name || option.last_name) {
          const data = {
            label: option.first_name + " " + option.last_name,
            value: option.first_name + " " + option.last_name,
            userId: option.id,
          };
          if (option?.id === this.userInfoDetails?.data?.id) {
            this.setState(() => ({ selectedUser: data }));
          }
          optionList.push(data);
        } else {
          const data = {
            label: option?.email,
            value: option?.email,
            userId: option?.id,
          };
          if (option?.id === this.userInfoDetails?.data?.id) {
            this.setState(() => ({ selectedUser: data }));
          }
          optionList.push(data);
        }
      });
      this.setState({ orgMembers: optionList });
    });
    data?.catch(async (err) => {});
  };

  getData = () => {
    this.setState(() => ({ showSpinner: true }));
    this.setState(() => ({ showToaster: false }));
    const { currentDate, accessToken } = this.state;
    const valueMonth = currentDate.getMonth() + 1;
    const valueYear = currentDate.getFullYear();
    let selectedUsersId = [this.userInfoDetails?.data?.id];
    console.log(selectedUsersId,"888888wwwwwww")
    if (this.state?.selectedUser.length) {
      this.state?.selectedUser?.forEach((element) => {
        selectedUsersId.push(element?.userId);
      });
    }

    let selectedTaskStatus = [];

    this.state?.selectedTaskStatus?.length &&
      this.state.selectedTaskStatus?.forEach((element) => {
        selectedTaskStatus?.push(element?.value);
      });

    const response = getDataForCalender(
      valueMonth,
      valueYear,
      selectedUsersId,
      selectedTaskStatus,
      accessToken
    );
    response.then((res) => {
      let result = [];
      this.setState(() => ({ showSpinner: false }));
      if (res?.data?.data?.length === 0) {
        this.setState(() => ({ showToaster: true }));
        setTimeout(() => {
          this.setState(() => ({ showToaster: false }));
        }, 10000);
      }
      res?.data?.data.forEach((element) => {
        let priorityId = 0;
        if (element?.status === "completed") {
          if (element.task.task_success === "Hit") {
            priorityId = 1.1;
          } else if (element.task.task_success === "Beat") {
            priorityId = 1.2;
          } else if (element.task.task_success === "Miss") {
            priorityId = 1.3;
          }
        } else {
          priorityId = 2;
        }

        result.push({
          ...element,
          text: element?.task?.name,
          startDate: new Date(element.startDate.dateTime.toString()),
          endDate: new Date(
            new Date(
              new Date(element.endDate.dateTime.toString()).setHours(
                new Date(element.endDate.dateTime.toString()).getHours()
              )
            )
          ),

          priorityId: priorityId,
          typeId: element?.owner?.id,
        });
      });

      this.setState({ dataSource: result });
    });
    response.catch(async (err) => {});
  };

  handleCurrentDateChange = (currentDate) => {
    this.setState({ currentDate }, () => this.getData());
  };

  getTwentyFourHourTime = (date) => {
    var d = new Date(date);
    return d.getHours() + ":" + d.getMinutes();
  };

  getTimeZone = (date) => {
    var today1 = new Date(date);
    let dates = today1.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return this.getTwentyFourHourTime(dates);
  };

  toastInfo = () =>
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Completed
            </h2>
            <h3
              style={{
                fontSize: "18px",
              }}
            >
              No task event present for this month!
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "",
        className: "toast-success-container toast-success-container-after ",
      }
    );

  convertToLocalTime(utcDateString) {
    const date = new Date(utcDateString);
    if (isNaN(date)) {
      return ''; // Return an empty string if the date is invalid
    }
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  }

  handleDateChange(date) {
    this.setTaskRescheduling(date);
    this.setState({ isDatePickerOpen: false }); // Close the date picker after date selection
    this.scheduleTask(this.props.taskDetails?.id, this.props.userInfoDetails.data?.accessToken, date);
  }

  scheduleTask(taskId, token, start) {
    const data = rescheduledTask(taskId, token, start);

    data?.then(async (res) => {
      this.setTaskRescheduledAt(start);
      console.log(res, "Task rescheduled successfully");
    }).catch(async (err) => {
      console.error(err, "Error rescheduling task");
    });
  }

  setTaskRescheduling(date) {
    // Your implementation here, for example:
    this.setState({ rescheduledTime: date.toISOString() });
  }

  setTaskRescheduledAt(date) {
    // Your implementation here, for example:
    this.setState({ rescheduledTime: date });
  }

  handleShowModal(appointmentData) {
    let taskOwn = {
      label: appointmentData?.owner?.first_name + " " + appointmentData?.owner?.last_name,
      value: appointmentData?.owner?.first_name + " " + appointmentData?.owner?.last_name,
      userId: appointmentData?.owner?.id
    }
    this.setState({  taskDetailsinfo : appointmentData?.task,taskOwner : taskOwn,rescheduledTime : new Date(appointmentData?.task?.rescheduledTime) || new Date()})
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.getData()
  }

  render() {
    const { isDatePickerOpen, rescheduledTime, showModal, taskDetailsinfo, taskOwner } = this.state;
    const appointmentTooltipRender = ({ appointmentData }) => {
      return (
        <div
          style={{
            textAlign: "start",
            backgroundColor: "#eff0f0",
            border: "1px solid #dedfe0",
          }}
          className="m-0 p-0"
        >
          <h3 className="text-center text-wrap p-2">{appointmentData?.text}</h3>
          <Row className="m-1">
            <Col xs={12} sm={4}>
              <span className="h4">Task creator </span>
            </Col>
            <Col xs={12} sm={8}>
              <div className="d-flex">
                <div className="chat-img1 mt-0 mx-2">
                  : &nbsp;&nbsp;
                  <img
                    src={
                      appointmentData.owner?.profile_picture === null
                        ? profilePicture
                        : appointmentData.owner?.profile_picture || profilePicture
                    }
                    alt="task-creator"
                    title="task-creator"
                  />
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <span className="p">
                    {appointmentData.creator?.first_name +
                      " " +
                      appointmentData.creator?.last_name}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-1">
            <Col xs={12} sm={4}>
              <span className="h4">Task owner </span>
            </Col>
            <Col xs={12} sm={8}>
              <div className="d-flex">
                <div className="chat-img1 mt-0 mx-2">
                  : &nbsp;&nbsp;
                  <img
                    src={
                      appointmentData?.creator?.profile_picture === null
                        ? profilePicture
                        : appointmentData?.creator?.profile_picture ||
                          profilePicture
                    }
                    alt="task-owner"
                    title="task-owner"

                  />
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <span className="p">
                    {appointmentData.owner?.first_name +
                      " " +
                      appointmentData.owner?.last_name}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-1">
            <Col xs={12} sm={4}>
              <span className="h4">Start time</span>
            </Col>
            <Col xs={12} sm={8}>
              <div className="d-flex">
                <div className="chat-img1 mt-0 mx-2">
                  : &nbsp;&nbsp;<i class="bi bi-clock fs-20"></i>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <span className="p">
                    {this.getTimeZone(appointmentData.startDate)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-1">
            <Col xs={12} sm={4}>
              <span className="h4">End time</span>
            </Col>
            <Col xs={12} sm={8}>
              <div className="d-flex">
                <div className="chat-img1 mt-0 mx-2">
                  : &nbsp;&nbsp;<i class="bi bi-clock fs-20"></i>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <span className="p">
                    {this.getTimeZone(appointmentData.endDate)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-1">
            <Col xs={12} sm={4}>
              <span className="h4">Task status</span>
            </Col>
            <Col xs={12} sm={8}>
              <div className="d-flex">
                <div className="d-flex mb-1 align-items-center">
                  &nbsp;&nbsp;: &nbsp;&nbsp;{" "}
                  <span className="p">{appointmentData.status}</span>
                </div>
                <div></div>
              </div>
            </Col>
          </Row>
          <Row className="m-1">
            <Col xs={12} sm={4}>
              <span className="h4">Re-schedule task </span>
            </Col> 
            <Col xs={12} sm={8}>
            &nbsp;&nbsp;: &nbsp;&nbsp;{" "}
              <i
                title="Open"
                className="bi bi-calendar fs-24"
                onClick={()=> {this.handleShowModal(appointmentData)}}
              ></i>
              <span>
              <span
  style={{ marginLeft: '10px', cursor: 'pointer' }}
  onClick={() => {
    console.log('Rescheduled Time:', appointmentData?.task?.rescheduledTime);
    const rescheduledTime = appointmentData?.task?.task_schedule ? new Date(appointmentData?.task?.task_schedule) : new Date();
    this.setState({
      taskDetailsinfo: appointmentData?.task,
      taskOwner: appointmentData?.owner,
      rescheduledTime,
      isDatePickerOpen: true
    });
  }}
>
  {this.convertToLocalTime(appointmentData?.task?.task_schedule)}
</span>

                {isDatePickerOpen && (
                  <DatePicker
                    selected={new Date(appointmentData?.task?.task_schedule)}
                    onChange={this.handleDateChange}
                    onClickOutside={() => this.setState({ isDatePickerOpen: false })} // Close the date picker when clicking outside
                    inline
                  />
                )}
              </span>
            </Col>
          </Row>
          <Row>
            <span className="text-end">
              Check it out
              <Link
                to=""
                className="figma-link sharp text-center"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.nav("/tasks", {
                    state: { calendarEvent: appointmentData?.task },
                  });
                }}
              >
                here...
              </Link>
            </span>
          </Row>
        </div>
      );
    };

    return (
      <>
        {this?.state?.showToaster && this.toastInfo()}

        <Row className="text-end m-0 p-0">
          <span>
            <span data-testid="color-code">
              <b>Completed :</b>
              <span className="mx-2">
                <i class="bi bi-circle-fill" style={{ color: "#A8D6C5" }}></i>{" "}
                &nbsp; Hit
              </span>
              <span className="mx-2">
                <i class="bi bi-circle-fill" style={{ color: "#F5D9B0" }}></i>{" "}
                &nbsp; Beat
              </span>
              <span className="mx-2">
                <i class="bi bi-circle-fill" style={{ color: "#EEAEBA" }}></i>{" "}
                &nbsp; Miss
              </span>
            </span>
            <span className="mx-2">
              <i class="bi bi-circle-fill" style={{ color: "#7792c2" }}></i>{" "}
              &nbsp; <b>Scheduled task</b>
            </span>
          </span>
        </Row>
        <Row className="mb-2 m-0 p-0">
          <Col className="col-12 col-sm-6 mb-2 mb-sm-0 calender_filter" data-testid="Task-status">
            <label className="Filtertext fs-18" data-testid="event-calendar">
              Task status :{" "}
            </label>
            <Select
              className="float-end fs-16 w-100"
              closeMenuOnSelect={false}
              components={{ ClearIndicator }}
              styles={{ clearIndicator: ClearIndicatorStyles }}
              defaultValue={this.state.selectedTaskStatus}
              onChange={this.changeTaskStatusHandler}
              data-testid="team-users"
              isMulti
              options={this.taskStatusOptions}
            />
          </Col>
          <Col className="col-12 col-sm-6 calender_filter" data-testid="Select-users">
            <label className="Filtertext fs-18">Select users : </label>
            <Select
              className="float-end fs-16 w-100"
              closeMenuOnSelect={false}
              components={{ ClearIndicator }}
              styles={{ clearIndicator: ClearIndicatorStyles }}
              value={this.state.selectedUser}
              onChange={this.changeHandler}
              data-testid="team-users"
              isMulti
              options={this.state.orgMembers}
            />
          </Col>
        </Row>

        {this.state.showSpinner ? (
          <div
            data-testid="loading-spinner"
            style={{
              position: "relative",
              zIndex: 11,
              top: "25vh",
            }}
            className="m-0 p-0"
          >
            <center className="p-0 m-0">
              <div
                data-testid="loading-spinner-dot"
                className="loading-dots--dot"
                style={{
                  height: "2vh",
                  width: "2vh",
                  borderRadius: "50%",
                  margin: "5px",
                }}
              ></div>
              <div
                className="loading-dots--dot"
                style={{
                  height: "2vh",
                  width: "2vh",
                  borderRadius: "50%",
                  margin: "5px",
                }}
              ></div>
              <div
                className="loading-dots--dot"
                style={{
                  height: "2vh",
                  width: "2vh",
                  borderRadius: "50%",
                  margin: "5px",
                }}
              ></div>
            </center>
          </div>
        ) : (
          ""
        )}

        <div
          data-testid="load-calendar"
          className="m-0 p-0"
          style={
            this.state.showSpinner
              ? { position: "relative", opacity: 0.4, zIndex: 2 }
              : {}
          }
        >
          <Scheduler
            timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
            dataSource={this?.state?.dataSource}
            views={this.state.views}
            defaultCurrentView="month"
            showAllDayPanel={true}
            defaultCurrentDate={currentDate}
            height={"80vh"}
            onCurrentDateChange={this.handleCurrentDateChange}
            editing={false}
            allowMultiple={true}
            firstDayOfWeek={0}
            className="bg-primary"
            appointmentTooltipRender={appointmentTooltipRender}
            shadeUntilCurrentTime={true}
            groups={priorityGroups}
            style={{ width: "100%" }}
          >
            <View type="day" />
            <View type="workWeek" />
            <View type="month" />
            <Resource
              dataSource={this.taskColor}
              fieldExpr="priorityId"
              label="Type"
              allowMultiple={true}
              useColorAsDefault={true}
              style={{ color: "#131F4C" }}
            />
          </Scheduler>
        </div>

        {/* Modal */}
        <Modal  className="fade bd-example-modal-lg"
        size="xl" show={showModal} onHide={this.handleCloseModal}>
          
          <Modal.Body>
            <ErrorBoundary>
              <MyCalendar
                taskDetailsinfo={this.state?.taskDetailsinfo}
                startDate={this.state?.rescheduledTime}
                setShowCalendar={this.handleCloseModal}
                userDeatils={this.userInfoDetails}
                taskOwner={this.state?.taskOwner}
                setTaskRescheduledAt={this.setTaskRescheduledAt}
              />
            </ErrorBoundary>
          </Modal.Body>
         
        </Modal>
      </>
    );
  }
}

export default EventCalendar;
