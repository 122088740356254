import { Card, CardContent, Typography, Grid, Box, FormControlLabel, RadioGroup, Radio, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { listOfGetCoachDetails, coachDtailsUpdateUpdates } from '../../../services/messageService';
import { Modal } from "react-bootstrap";
import Vector3 from "../../../images/vector/Vector3.png";
import { Link } from "react-router-dom";
import { deleteCoach } from '../../../services/messageService';
import { ToastContainer, toast } from "react-toastify";
import { Row } from "react-bootstrap";
import { createCoach } from '../../../services/messageService';


const CoachSetting = () => {
    const [coachData, setCoachData] = useState({ system: [], user: [] });
    const userDetails = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetails);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [SelectedGoalDelete, setSelectedGoalDelete] = useState();
    const [editModelShow, setShowEditModal] = useState(false)
    const [SelectedEditCoach, setSelectedEditCoach] = useState();
    const [editName, setEditName] = useState()
    const [createModal, setCreateModal] = useState(false)
    const [error, setError] = useState()
    const [coachName, setCoachName] = useState()
    const [coachDescription, setCoachDescription]=useState()
 
    const getCoachDetails = () => {
        const data = listOfGetCoachDetails(userInfoDetails?.data?.accessToken);
        data.then((res) => {
            setCoachData(res?.data?.data || { system: [], user: [] });
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        getCoachDetails();
    }, []);

    const handlerOfCreateCoach = (e) =>{
      e.preventDefault()
      setCreateModal(true)
    }


    const handleCreateCoach =(event) =>{
      event.preventDefault()
      setCreateModal(true)
      const data = createCoach(coachName, coachDescription, userInfoDetails?.data?.accessToken);
        data.then((res) => {
          getCoachDetails()
          setCreateModal(false)
            toast(
                <>
                  <div className="m-1 d-flex">
                    <i
                      class="bi bi-check-circle fs-26 me-3"
                      style={{ color: "#131F4C" }}
                    ></i>
                    <div>
                      <h2
                        style={{
                          // color: "#131F4C",
                          fontSize: "22px",
                        }}
                      >
                        Completed
                      </h2>
                      <h3
                        style={{
                          // color: "#ffffff",
                          fontSize: "18px",
                        }}
                      >
                        Your coach has been created successfully!
                      </h3>
                    </div>
                  </div>
                </>,
                {
                  toastId: "org_delete",
                  className: "toast-success-container toast-success-container-after ",
                }
              );
           
        }).catch((err) => {
            console.error(err);
            setError(err?.response?.data?.message)
            toast(
              <>
                <div className="m-1 d-flex">
                <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
                <div>
                  <h2  style={{
                    fontSize: "22px" }}>
                    Failed
                  </h2>
                  <h3 style={{
                    fontSize: "18px" }}>
                  {err?.response?.data?.message}
                  </h3>
                  </div>
                </div>
              </>,
              { 
                toastId: "task-create",
                className: 'toast-error-container toast-error-container-after ',
              }
            )
        });

    }


    const updateCoachDetails = (coach, isDefault) => {
        const data = coachDtailsUpdateUpdates(coach.id, isDefault, coach.name, userInfoDetails?.data?.accessToken);
        data.then((res) => {
            setCoachData(res?.data?.data || { system: [], user: [] });
            getCoachDetails();
            console.log(res?.data?.data, "Updated Coach Details");
            toast(
                <>
                  <div className="m-1 d-flex">
                    <i
                      class="bi bi-check-circle fs-26 me-3"
                      style={{ color: "#131F4C" }}
                    ></i>
                    <div>
                      <h2
                        style={{
                          // color: "#131F4C",
                          fontSize: "22px",
                        }}
                      >
                        Completed
                      </h2>
                      <h3
                        style={{
                          // color: "#ffffff",
                          fontSize: "18px",
                        }}
                      >
                        Your coach has been updated successfully!
                      </h3>
                    </div>
                  </div>
                </>,
                {
                  toastId: "org_delete",
                  className: "toast-success-container toast-success-container-after ",
                }
              );
           
        }).catch((err) => {
            console.error(err);
            toast(
              <>
                <div className="m-1 d-flex">
                <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
                <div>
                  <h2  style={{
                    fontSize: "22px" }}>
                    Failed
                  </h2>
                  <h3 style={{
                    fontSize: "18px" }}>
                  {err?.response?.data?.message}
                  </h3>
                  </div>
                </div>
              </>,
              { 
                toastId: "task-create",
                className: 'toast-error-container toast-error-container-after ',
              }
            )
        });
    };


    const handleChange = (category, coachs) => (event) => {
        const isDefault = event.target.value;
        setCoachData((prevData) => {
            const updatedData = { ...prevData };
           
            updatedData.system.forEach((coach) => {
                if (coach.id !== coachs?.id) coach.is_default = false;
            });
            updatedData.user.forEach((coach) => {
                if (coach.id !== coachs?.id) coach.is_default = false;
            });
           
            const coachCategory = updatedData[category];
            coachCategory.forEach((coach) => {
                if (coach.id === coachs?.id) coach.is_default = true;
            });
            return updatedData;
        });
        updateCoachDetails(coachs, isDefault);
    };

    const handleDelete = (event, coach) => {
        event.preventDefault();
        setSelectedGoalDelete(coach)
        setShowDeleteModal(true)
        
    };

    const handleDeleteCoach = (coach) => {
        const data = deleteCoach(coach?.id, userInfoDetails?.data?.accessToken);
        data.then((res) => {
            setShowDeleteModal(false)
            getCoachDetails()
            toast(
                <>
                  <div className="m-1 d-flex">
                    <i
                      class="bi bi-check-circle fs-26 me-3"
                      style={{ color: "#131F4C" }}
                    ></i>
                    <div>
                      <h2
                        style={{
                          // color: "#131F4C",
                          fontSize: "22px",
                        }}
                      >
                        Completed
                      </h2>
                      <h3
                        style={{
                          // color: "#ffffff",
                          fontSize: "18px",
                        }}
                      >
                        Your coach has been deleted successfully!
                      </h3>
                    </div>
                  </div>
                </>,
                {
                  toastId: "org_delete",
                  className: "toast-success-container toast-success-container-after ",
                }
              );
           
        }).catch((err) => {
            console.error(err);
            
        });
    };

    const handleEditNameChange = (event)=>{
        setEditName(event.target.value)
    }

    const handleEditClick = (event, coach) => {
        event.preventDefault();
        setSelectedEditCoach(coach)
        setEditName(coach?.name)
       setShowEditModal(true)
        
    };

    const handleSubmitEdit = (event) => {
        event.preventDefault()

        
        const data = coachDtailsUpdateUpdates(SelectedEditCoach?.id, SelectedEditCoach?.is_Default, editName, userInfoDetails?.data?.accessToken);
        data.then((res) => {
            setShowEditModal(false)
            setCoachData(res?.data?.data || { system: [], user: [] });
            getCoachDetails();
            console.log(res?.data?.data, "Updated Coach Details");
            toast(
                <>
                  <div className="m-1 d-flex">
                    <i
                      class="bi bi-check-circle fs-26 me-3"
                      style={{ color: "#131F4C" }}
                    ></i>
                    <div>
                      <h2
                        style={{
                          // color: "#131F4C",
                          fontSize: "22px",
                        }}
                      >
                        Completed
                      </h2>
                      <h3
                        style={{
                          // color: "#ffffff",
                          fontSize: "18px",
                        }}
                      >
                        Your coach has been Upadted successfully!
                      </h3>
                    </div>
                  </div>
                </>,
                {
                  toastId: "org_delete",
                  className: "toast-success-container toast-success-container-after ",
                }
              );
           
        }).catch((err) => {
            console.error(err);
            toast(
              <>
                <div className="m-1 d-flex">
                <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
                <div>
                  <h2  style={{
                    fontSize: "22px" }}>
                    Failed
                  </h2>
                  <h3 style={{
                    fontSize: "18px" }}>
                  {err?.response?.data?.message}
                  </h3>
                  </div>
                </div>
              </>,
              { 
                toastId: "task-create",
                className: 'toast-error-container toast-error-container-after ',
              }
            )
        });
    };

    


    return (
        <> 
       <Modal
        className="fade bd-example-modal-lg"
        show={createModal}
        size="lg"
        data-testid="modal3"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setCreateModal(false);
              setError("")
            }}
          ></button>
          <h2 className="text-center mb-4">Create coach </h2>
          <form onSubmit={(e) => handleCreateCoach(e)}>
            <Row className='mb-4'>

              <input
                type="text"
                required
                className="form-control"
                style={{ height: "37px" }}
                placeholder="Coach name"
                data-testid="coach-name"
                onChange={(e) => (setCoachName(e.target.value), setError(""))}
              />
              {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )}
            </Row>
            <Row>

<input
  type="text"
  required
  className="form-control"
  style={{ height: "37px" }}
  placeholder="Description"
  data-testid="coach-name"
  onChange={(e) => (setCoachDescription(e.target.value), setError(""))}
/>
{/* {error && (
  <div className="text-danger fs-12 ms-2">{error}</div>
)} */}
</Row>
            

            <div>
              <div className="text-center">
                <button
                  type="submit"
                  className="figma-btn text-white mt-3"
                  style={{ alignSelf: "flex-end" }}
                >
                  Create coach
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>  

         <Modal
        className="fade bd-example-modal-lg"
        show={editModelShow}
        size="lg"
        data-testid="modal4"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
                setShowEditModal(false)
                setEditName("");
            }}
          ></button>
          <h2 className="text-center mb-4">Edit Coach </h2>
          <form onSubmit={(e) => handleSubmitEdit(e)}>
            <Row>

              <input
                type="text"
                required
                className="form-control"
                style={{ height: "37px" }}
                placeholder="coach name"
                data-testid="coach name"
                value={editName}
                onChange={handleEditNameChange}
              />
              {/* {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )} */}
            </Row>

            <div>
              <div className="text-center">
                <button
                  type="submit"
                  className="figma-btn text-white mt-3"
                  style={{ alignSelf: "flex-end" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

        <Modal
        show={showDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="delete-modal" title="delete-modal" src={Vector3} />
          <h4 className="fs-20 p-2">Are you sure you want to delete Coach?</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this Coach - {SelectedGoalDelete?.name}!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/coach-setting"
            onClick={() => setShowDeleteModal(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
                handleDeleteCoach(SelectedGoalDelete)
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

        <Box sx={{ flexGrow: 1, padding: 2, }}>
            <Grid container spacing={2} display={"flex"} alignContent={"stretch"}>
                <Grid item xs={12} sm={12} lg={6}>
                    <Card style={{border: "1px solid #F3F4F6",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
}}>
                        <CardContent>
                            <Typography variant="h5" component="div" className='mb-4'>
                                System Coaches
                            </Typography>
                            <RadioGroup>
                                {(coachData.system || []).map(coach => (
                                    <FormControlLabel
                                        key={coach.id}
                                        value={coach.is_default.toString()}
                                        control={
                                            <Radio
                                                checked={coach.is_default}
                                                onChange={handleChange('system', coach)}
                                            />
                                        }
                                        label={`${coach.name}`}
                                    />
                                ))}
                            </RadioGroup>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                    <Card  style={{border: "1px solid #F3F4F6",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
}}>
                        <CardContent>
                            <Typography variant="h5" component="div" className='mb-4' >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5" component="div" className='mb-4'>
                                        User Coaches
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                   
                                    onClick={(e) => handlerOfCreateCoach(e)}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </Typography>
                            <RadioGroup>
                                {(Array.isArray(coachData.user) && coachData.user.length > 0) ? coachData.user.map(coach => (
                                    <Grid container alignItems="center" key={coach.id}>
                                        <Grid item xs={6} sm={6}>
                                            <FormControlLabel
                                                value={coach.is_default.toString()}
                                                control={
                                                    <Radio
                                                        checked={coach.is_default}
                                                        onChange={handleChange('user', coach)}
                                                    />
                                                }
                                                label={coach.name}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} container justifyContent="end">
                                            <button
                                                to=""
                                                className="outline-primary btn btn-primary me-2 float-end"
                                                onClick={(event) => {
                                                    handleEditClick(event, coach)
                                              }}
                                               
                                            >
                                                <i className="fa fa-pen"></i>
                                            </button>
                                            <button
                                                to=""
                                                className="outline-primary btn btn-primary me-2 float-end"
                                                onClick={(event) => {
                                                    handleDelete(event, coach)
                                              }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </Grid>
                                    </Grid>
                                )) : null}
                            </RadioGroup>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </Box>
        </>
    );
}

export default CoachSetting;
