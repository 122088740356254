import React, { useState , useRef} from "react";
import ReactSearchBox from "react-search-box";
import SearchModal from "./SearchModal";

function SearchBox() {
  const [modal, setModal] = useState(false);
  const inputRef = useRef(null);

  const handleInputFocus = () => {
    if (!modal) {
      setModal(true);
    }
  };

  const handleModalClose = () => {
    setModal(false);
    
    setTimeout(() => {
      const inputField = document.querySelector(".react-search-box-input");
      if (inputField) {
        inputField.blur();
      }
    }, 100);
  };

  return (
    <>
      <div className="p-0 m-0" onClick={()=>{handleInputFocus()}}>
        <ReactSearchBox
          placeholder="Search strategy store"
          // onFocus={handleInputFocus}
          autoFocus={modal}
          ref={inputRef}
          leftIcon={<i className="fa fa-search" style={{marginLeft: "10px", marginRight:"10px"}} />}
          dropDownHoverColor="#f2f3f4"
          inputBorderColor="#949596"
          inputFontColor="#131f4c"
        />
      </div>
    {
      modal && (
        <SearchModal
          modal={modal}
          setmodal={setModal}
          onClose={handleModalClose}
        />
      )}
    </>
  );
}

export default SearchBox;
