
import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  forgotPassword, verify_OTP, changePassword
} from '../../store/actions/AuthActions';
import PlansomLogo from "../../images/PlansomLogo2.png";
import { resendOTPSignup } from "../../services/AuthService";


export const LocationDisplayForgot = () => {
  const location = useLocation()
  return <div data-testid="location-display-forgotPass">{location?.pathname}</div>
}

const ForgotPassword = (props, { history }) => {
  let errorsObj = { password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [msg, setMsg] = useState('');
  const [openOtp, setOtp] = useState(false);
  const [verifyOtp, verifyOTP] = useState(false);
  const [changePass, setPass] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateData = useSelector(state => state);
  const [email, setEmail] = useState('');
  const [value, setValue] = useState('');
  const [password, setPassword] = useState('');

  console.log(stateData, "ppppp forgot password page")

  useEffect(()=>{
    console.log(stateData, "ppppp forgot password page")
  },[])

  useEffect(() => {
    if (stateData?.auth?.successMessage) {
      setMsg(stateData?.auth?.successMessage)
    }
  }, [stateData?.auth?.successMessage]);

  useEffect(() => {
    if (msg === 'OTP sent successfully') {
      setOtp(true);
    }
    if (msg === 'OTP verified successfully') {
      verifyOTP(true);
      setOtp(false);
    }
    if (msg === 'Password changed successfully') {
      verifyOTP(false);
      setOtp(false);
      setPass(true);
    }
  }, [msg]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email));
  };
  const onSubmitNewPassword = (e) => {
    e.preventDefault();
    let error = false;
    var verifyPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])*(?=.*?[#?!@$%^&*-])*.{8,}$/;
    var testt = true;
    testt = verifyPassword.test(password);
    const errorObj = { ...errorsObj };
    if (testt === false) {
      errorObj.password = 'Password should have atleast one number, one special character and one captial letter. passwords must be at least 8 characters long';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(changePassword(email, password));
  };
  const otpSubmit = () => {
    dispatch(verify_OTP(value, email));
  };

  const setotp = (value) => {
    setValue(value)
  }

  const resendOTP = (event) => {
    event.preventDefault()
    const data = resendOTPSignup(email);
    data?.then(async (res) => {
      // console.log('GoalDetailsssssss', res);
    })
    data?.catch(async (err) => {
      // console.log('error deleteTask',err)
    })
  };

  return (
    <div className="authincation h-100 float-center" style={{alignItems:"center"}}>
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-sm-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/dashboard">
                        <img src={PlansomLogo} alt="plansom-logo" title="plansom-logo" />
                      </Link>
                    </div>
                    <h3 className="text-center mb-2 fs-30">Forgot password?</h3>
                    <p className='text-center' style={{ color: "#717994" }}>No worries, we'll send you reset instructions.</p>
                    {!openOtp && !verifyOtp && !changePass && <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <h5>Email</h5>
                        <input
                          type="email"
                          defaultValue={email}
                          placeholder="Enter email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="figma-input p"
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          type="submit"
                          className="figma-btn text-white w-100"
                        >
                          Reset password
                        </button>
                      </div>
                      <div className='text-center mt-3'>
                        <Link className='figma-link' to="/login">Back to login</Link></div>
                    </form>
                    }

                    {openOtp && <div className='align-items-center'>
                      <h3 className='text-center mb-4'>Please enter the otp</h3>
                      <div className='inputs d-flex flex-row justify-content-center mt-2' style={{ height: 'auto', width: '100%' }}>
                        <center>
                          <OtpInput
                            value={value}
                            className="figma-otp-box"
                            inputStyle={{ height: 60, width: 60 }}
                            onChange={(e) => {
                              setotp(e);
                            }}
                            numInputs={6}
                          />
                        </center>
                      </div>

                      <div className="text-center d-grid mt-4">
                        <button className="figma-btn text-white w-100" onClick={otpSubmit}>
                          {t('Verify otp')}
                        </button>
                      </div>
                      <div className="text-center new-account mt-3">
                        <p className="p-0 m-0">
                          <span style={{ color: "#717994" }}>Didn't receive the mail?</span>
                          <Link className="figma-link" to="" onClick={(event) => resendOTP(event)}>
                            &nbsp;Click to resend
                          </Link>
                        </p>
                        <p className="m-0 p-0" >
                          <span style={{ color: "#717994" }}>Already have an account?{" "}</span>
                          <Link className="figma-link" to="/login">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>

                    }

                    {verifyOtp && !changePass && <div className='align-items-center'>
                      <form onSubmit={(e) => onSubmitNewPassword(e)} >
                        <div className="form-group">
                          <h5>Enter new password</h5>
                          <input
                            label={t('New password')}
                            type="password"
                            name="password"
                            defaultValue={password}
                            placeholder={t('Enter your new password')}
                            onChange={(e) => setPassword(e.target.value)}
                            className="figma-input p mb-3"
                          />
                          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}

                          <div className="d-grid text-center">
                            <button className='figma-btn text-white w-100' type="submit">
                              {t('Submit new password')}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    }

                    {changePass &&
                      <div>
                        <Alert variant="success" className="my-2" style={{ borderRadius: "0rem", backgroundColor: "#D4EBE2" }}>
                          <p className='text-center fs-20' style={{ color: "black" }}> Password changed successfully</p>
                        </Alert>
                        <div className="text-center new-account mt-3">
                          <p><span style={{ color: "#717994" }}>Click here to </span><Link className="figma-link" to="/login">Sign in</Link></p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
