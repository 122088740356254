import { Navigate, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import Template from "./Template";
import ChatbotQuestionSet from "./ChatbotQuestionSet";

const TemplateMenu = ()=>{
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState("tab1")

    const handleTab1 = ()=>{
        setActiveTab("tab1")
    }

    const handleTab2 = () =>{
        setActiveTab("tab2")
    }

    return(<>
    {/* <div className="d-flex col">
        <div className="col">
          {localStorage.getItem("previousLocation") !==
            localStorage.getItem("currentLocation") && (
            <span className="float-start me-3 mt-1">
              <i
                className="fa fa-reply fs-20"
                title={`Navigate back to ${
                  localStorage.getItem("previousLocation").split("/")[1]
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(localStorage.getItem("previousLocation"));
                }}
              />
            </span>
          )}
          <span className="h2">Organization </span>
        </div>
    
      </div> */}
      {/* <Row>
        <Col className="col-sm-12">
          <ul className="nav1 mt-4">
          <li
              className={activeTab === "tab1" ? "active" : ""}
              onClick={handleTab1}
            >
              Plan templates
            </li>
            <li
              className={activeTab === "tab2" ? "active" : ""}
              onClick={handleTab2}
            >
              Question templates
            </li>
           
          </ul>
        </Col>
      </Row> */}

      { activeTab === "tab1" ? (<>
<Template></Template>
      </>) : activeTab === "tab2" ? (<>
<ChatbotQuestionSet></ChatbotQuestionSet>
      </>) : (<></>)}


    </>)

}
export default TemplateMenu