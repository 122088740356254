import React from "react";

import PlansomLogo from "../../../images/vector/Frame.png";
import insta from "../../../images/vector/insta.png";
import linkdin from "../../../images/vector/linkdin.png";
import facebook from "../../../images/vector/facebook.png";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function StoreFooter({ positionTop, position }) {
  const navigate = useNavigate();
  return (
    // <div className="Store-footer" data-testid="store-footer" style={{
    //   top: positionTop, bottom: "0rem", position: "fixed", left: 0,
    //   right: 0,
    //   bottom: 0,
    // }}>
    <div className="Store-footer" data-testid="store-footer" style={{ top: positionTop, position: position }}>
      <div className="row m-0 p-0 Store-footer-content">
        <div className="col-sm-2 p-0 m-0 me-4">
          <div className="Store-footer-logo" onClick={(e) => navigate("/#")}>
            <img
              src={PlansomLogo}
              className="plansomLoggo"
              data-testid="PlansomLogo"
              alt="Plansom Logo"
              title="Plansom Logo"
            />
          </div>
          <div className="Store-footer-logo-Copyright">
            <span
              className="Copyright fs-14"
              style={{ fontFamily: "Open sans" }}
            >
              Copyright. <span className="mr-2 company-name">
              Plansom LTD
</span>
            </span>
            <div className="p-0 m-0"> registration number:758868 Address: 13 Upper Baggot Street, 2nd Floor, Dublin 4, D04 W7K5</div>
            
          </div>
        </div>
      
        <div className="col Store-footer-containts">
        <div className="Store-footer-containts-item itemAlignmentForMobile me-4">
  {/* <a
    href="/your-strategies-link"
    className="col-sm-2 fs-16"
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      // fontSize: "16px",
      textDecoration: "none", // To remove default underline
      color: "inherit", // To inherit the text color
    }}
  >
    Strategies
  </a> */}
  {/* <a
    href="/your-goals-link"
    className="col-sm-2 fs-16"
    onClick={(e) => navigate("/goals")}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Goals
  </a> */}
  <a
    href="/terms-and-conditions"
    className="col-sm-3 fs-16 footermargin"
    onClick={(e) => {
      e.preventDefault(); 
      navigate("/terms-and-conditions")}}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Terms and conditions
  </a>
  <a
    href="/privacy"
    className="col-sm-2 fs-16 footermargin"
    onClick={(e) => {  e.preventDefault(); navigate("/privacy")}}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Privacy
  </a>
  {/* <a
    href="/plansom-link"
    className="col-sm-2 fs-16"
    onClick={(e) => navigate("/#")}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Plansom
  </a> */}
  <a
    href="/cookie-policy"
    className="col-sm-2 fs-16 footermargin"
    onClick={(e) => { e.preventDefault(); navigate("/cookie-policy")}}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Cookie Policy
  </a>
  <a
    href="/about-us"
    className="col-sm-2 fs-16 footermargin"
    onClick={(e) => { e.preventDefault(); navigate("/about-us")}}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    About us
  </a>
  <a
    href="/plansom-partner"
    className="col-sm-2 fs-16 footermargin"
    onClick={(e) => { e.preventDefault(); navigate("/plansom-partner")}}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Plansom Partner
  </a>
  <a
    href="/contactUs-support"
    className="col-sm-2 fs-16 footermargin"
    onClick={(e) => { e.preventDefault(); navigate("/contactUs-support")}}
    style={{
      cursor: "pointer",
      fontFamily: "Open sans",
      textDecoration: "none",
      color: "inherit",
    }}
  >
    Contact us & Support
  </a>
</div>

        </div>
       
        {/* <div className="col-sm-2 Store-footer-followUs">
          <div className="col"></div>
          <div className="col w-100 float-end ">
            <div className="Store-footer-followUs-text">
              <span className="followUs-text fs-16">Follow us on</span>
            </div>
            <div className="Store-footer-followUs-logo-container ">
              <img
                src={insta}
                className="m-1"
                alt="Instagram"
                title="Instagram"
                style={{ height: "40px", width: "40px", cursor: "pointer" }}
                onClick={(e) => navigate("/#")}
              />
              <img
                src={facebook}
                className="m-1 "
                alt="Facebook"
                title="Facebook"
                style={{ height: "40px", width: "40px", cursor: "pointer" }}
                onClick={(e) => navigate("/#")}
              />
              <img
                src={linkdin}
                className="m-1"
                alt="LinkedIn"
                title="LinkedIn"
                style={{ height: "40px", width: "40px", cursor: "pointer" }}
                onClick={(e) => navigate("/#")}
              />
            </div>
          </div>
        </div> */}
      </div>
     
    </div>
  );
}

StoreFooter.propTypes = {
  positionTop: PropTypes.string,
  position: PropTypes.string,
};

export default StoreFooter;
