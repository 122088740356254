export const isAuthenticated = (state) => {
 const userDetail = localStorage?.getItem("userDetails");
const userInfoDetails = JSON.parse(userDetail);
    console.log(state?.auth?.auth?.accessToken,"Index 22222222")
    if (state?.auth?.auth?.accessToken) return true;
    // if( userInfoDetails?.data?.accessToken) return true;
    if(state?.auth?.auth?.accessToken === undefined) return false;
    return false;
};



// export const isAuthenticated = (state) => {
//     const userDetail = localStorage?.getItem("userDetails");
// const userInfoDetails = JSON.parse(userDetail);
//     console.log(userInfoDetails?.data?.accessToken,"Index 22222222")

//     if (state?.auth?.auth?.accessToken ) return true;
//     else if( userInfoDetails?.data?.accessToken) return true;
//     return false;
// };
