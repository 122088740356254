import React, { useEffect, useState } from "react";
import success from "../../../images/vector/success-payment.avif";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Badge from "../../../images/Strategy/Badge.jpg";
import { getInvoiceDetails } from "../../../services/StoreService";

const PaymentSucess = () => {
  const navigate = useNavigate();
  const handleContinue = (event) => {
    localStorage.removeItem("session_id");
    localStorage.removeItem("store");
    localStorage.removeItem("SubscriptionDetails");
    navigate("/home");
  };
  const [Show, setShow] = useState(false);
  const [ShowMessage, setShowMessage] = useState(false);
  const store = localStorage.getItem("store") ? JSON.parse(localStorage.getItem("store")) : null
  const selectedStore = store?.SelectedStrategy
    ? store?.SelectedStrategy ||
      JSON.parse(localStorage.getItem("SubscriptionDetails"))?.plan
    : null;
  console.log(selectedStore, "selectedStore");
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = userDetails ? JSON.parse(userDetails) : null;
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [paymentDetail, setPaymentDetail] = useState({});
  useEffect(() => {
    setShow(Show);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [Show]);

  const handleInvoice = (event) => {
    // window.location.replace(invoiceUrl);
    window.open(invoiceUrl, "_blank");
  };

  useEffect(() => {
    setShow(true);
    setShowMessage("Payment successfull!");

    if (localStorage.getItem("session_id")) {
      const data = getInvoiceDetails(
        localStorage.getItem("session_id"),
        userInfoDetails.data.accessToken
      );
      data?.then((res) => {
        setPaymentDetail(res?.data?.data);
        setInvoiceUrl(res.data?.data?.receipt_url);
      });
      data?.catch((err) => {
        console.log("1234567", err?.response?.data?.message);
      });
    }
  }, []);

  const successToast = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                // color: "#131F4C",
                fontSize: "22px",
              }}
            >
              Success
            </h2>
            <h3
              style={{
                // color: "#131F4C",
                fontSize: "18px",
              }}
            >
              {ShowMessage}
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "payment-success",
        className: "toast-success-container toast-success-container-after ",
      }
    );
  };

  return (
    <>
      {Show && ShowMessage && successToast()}

      <div className="row m-0 p-0 my-5 ">
        <div className="card d-flex p-5">
          <div className="row">
            {paymentDetail?.strategy?.name && paymentDetail?.quantity ? (
              <>
                <div className="col-sm-6 m-4 p-4">
                  {/* <div className="row">
          <img
            src={selectedStore?.image}
            className=""
            style={{ width: "100%", height: "100%", borderRadius: "10px" }}
          ></img>
        </div> */}
                  <div className="row mt-3">
                    <div className="h3 text-center">
                      {selectedStore?.plan_name}
                    </div>
                    <div className="p fs-16">{selectedStore?.discription}</div>
                  </div>
                  {/* <div className="row mt-4">
                    <div className="col d-flex">
                      <div className="h5 mt-1 me-5">Per user price :</div>
                      <div className="row p fs-18 mt-1">
                        {selectedStore?.per_user_price}&nbsp;$
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="h5 mt-1 me-5">Discount :</div>
                      <div className="row p fs-18 mt-1">
                        {selectedStore?.discount_percent}&nbsp;%
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <img
                       title="pay-success" alt="strategy-imf"
                      src={selectedStore?.image}
                      className=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                      }}
                    ></img>
                  </div>
                  <div className="row mt-3">
                    <div className="h3">{selectedStore?.name}</div>
                    <div className="p fs-16">{selectedStore?.description}</div>
                    <div className="row mt-4">
                      <div className="col d-flex">
                        <div className="h5 mt-4 me-5">No of license :</div>
                        <div className="row p fs-20 mt-4">
                          {paymentDetail?.quantity}&nbsp;
                        </div>
                      </div>
                      {/* <div className="col d-flex">
                        <div className="h5 mt-4 me-5">Amount :</div>
                        <div className="row p fs-20 mt-4">
                          {paymentDetail?.total_amount}
                          &nbsp;$
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col d-flex">
                      <div className="h5 mt-4 me-5">Payment by</div>
                      <div className="row">
                        <img
                         title="pay-success" alt="payment-by"
                          src={Badge}
                          style={{ height: "80px", width: "80px" }}
                        ></img>
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="h5 mt-4 me-5">Amount :</div>
                      <div className="row stripeCol27 fs-20 mt-4">
                        {paymentDetail?.total_amount}&nbsp;$
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : paymentDetail?.quantity ? (
              <div className="col-sm-6 p-4 m-4">
                {/* <div className="row">
            <img
              src={selectedStore?.image}
              className=""
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            ></img>
          </div> */}
                <div className="row mt-3">
                  <div className="h3 text-center">
                    {/* {paymentDetail?.buying_user_name} */}
                  </div>
                  <div className="p fs-16">{selectedStore?.discription}</div>
                </div>
                <div className="row mt-4">
                  <div className="col d-flex">
                    <div className="h5 mt-1 me-5">Plan type :</div>
                    <div className="row p fs-18 mt-1">
                      {paymentDetail?.plan_type}
                    </div>
                  </div>
                  {/* <div className="col d-flex">
                    <div className="h5 mt-1 me-5">Discount :</div>
                    <div className="row p fs-18 mt-1">
                      {selectedStore?.discount_percent}&nbsp;%
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col d-flex">
                    <div className="h5 mt-1 me-5">No of license :</div>
                    <div className="row p fs-18 mt-1">
                      {paymentDetail?.quantity}&nbsp;
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="h5 mt-1 me-5">Amount :</div>
                    <div className="row p fs-18 mt-1">
                      {paymentDetail?.total_amount}
                      &nbsp;$
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              paymentDetail?.strategy?.name && (
                <>
                  <div className="col-sm-6 m-4 p-4">
                    <div className="row">
                      <img
                        src={selectedStore?.image}
                        className=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                         title="pay-success" alt="payment-details"
                      ></img>
                    </div>
                    <div className="row mt-3 p-2">
                      <div className="h3">{selectedStore?.name}</div>
                      <div className="p fs-16">
                        {selectedStore?.description}
                      </div>
                    </div>
                    <div className="row mt-4">
                      {/* <div className="col d-flex">
                      <div className="h5 mt-4 me-5">Payment by</div>
                      <div className="row">
                        <img
                          src={Badge}
                          style={{ height: "80px", width: "80px" }}
                        ></img>
                      </div>
                    </div> */}
                      {/* <div className="col d-flex">
                      <div className="h5 mt-4 me-5">Amount :</div>
                      <div className="row stripeCol27 fs-20 mt-4">
                        {selectedStore?.price}&nbsp;$
                      </div>
                    </div> */}
                    </div>
                  </div>
                </>
              )
            )}

            <div className="col">
              {invoiceUrl && (
                <div className="my-2 text-end float-center ">
                  <button
                    // className="createOrganizationButton bg-primary "
                    onClick={handleInvoice}
                    type="submit"
                    className="figma-btn text-white text-start"
                    style={{
                      width: "auto",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      border: "none",
                    }}
                  >
                    View invoice
                  </button>
                </div>
              )}
              <div className="row ms-5">
                <img  title="pay-success" alt="payment-success" src={success}></img>
                <h4 className="text-center"> Payment Successfull</h4>
                <div className="row my-5 text-center float-center">
                  <button
                    className="createOrganizationButton bg-blue "
                    onClick={handleContinue}
                    type="submit"
                    style={{
                      border: "none",
                      background: "none",
                      alignContent: "center",
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSucess;
