import React, { useContext, useEffect, useState, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { getAllChatMessageByThreadID, getAllCountByOrg, getAllThreadsIdList } from "../../../services/messageService";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { useDispatch } from "react-redux";
import { taskLateCountUpdate } from "../../../store/actions/TaskActions";
import { allThreadsWithChatMessages, updateAllThreadsWithChatMessages, loadMoreThreads, createdNewThreadWithGoToChat } from "../../../store/actions/NotificationAction";

const SideBar = () => {
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch()
  const location = useLocation();
  const [toggleValue, setToggle] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(location?.state?.refreshCounter ? location?.state?.refreshCounter : false);
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const [unreadMessages, setUnreadMessages] = useState();
  const orgId = localStorage.getItem("organization_id");
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [orgMember, setOrgMember] = useState([]);
  const [allThreadsWithChat, setAllThreadsWithChat] = useState([]);
  const hasFetched = useRef(false);

  const navigate = useNavigate();

  async function getAllThreadRoomByIdData() {
    const data = getAllThreadsIdList(
      localStorage.getItem("organization_id"),
      userInfoDetails?.data?.accessToken
    );
    data?.then(
      await ((res) => {
        setOrgMember(res?.data?.data?.thread_id);
        dispatch(createdNewThreadWithGoToChat(false));
      })
    );
    data?.catch((err) => {
    });
  }

  useEffect(() => {

    const fetchChatMessagesInBatchesWithInterval = async (shouldFetchMore = false) => {
      const initialBatchSize = 10; // Number of records to fetch initially
      const subsequentBatchSize = 10; // Number of records to fetch subsequently
      const interval = 3000; // 3 seconds interval

      const batchSize = shouldFetchMore ? subsequentBatchSize : initialBatchSize;

      // Calculate the starting index for fetching
      const startIndex = shouldFetchMore ? stateData.messages.fetchNextThreadChats : 0;

      if (!shouldFetchMore) {
        // Clear previous data only during the initial fetch
        setAllThreadsWithChat([]);
      }

      const batch = orgMember?.slice(startIndex, startIndex + batchSize);

      if (!batch || batch.length === 0) {
        return;
      }

      const batchData = await Promise.all(
        batch.map((member) =>
          member
            ? getAllChatMessageByThreadID(
              member,
              0,
              10,
              userInfoDetails.data.accessToken
            ).catch(() => {
              return [];
            })
            : []
        )
      );

      const combinedData = batchData.flat();

      // Append batch data to the existing state
      setAllThreadsWithChat((prevData) => [...prevData, ...combinedData]);

      // Wait for the specified interval before proceeding to the next batch
      await new Promise((resolve) => setTimeout(resolve, interval));

      if (shouldFetchMore) {
        dispatch(loadMoreThreads(false));
      } else {
        hasFetched.current = true;
      }
    };

    const shouldFetchData = orgMember && !hasFetched.current;
    const shouldUpdate = stateData?.messages.loadMore;

    if (stateData.messages.updateAllThreadsWithChatMessages === true) {
      fetchChatMessagesInBatchesWithInterval(false); // Initial fetch
      dispatch(updateAllThreadsWithChatMessages(false));
    }

    if (shouldFetchData) {
      fetchChatMessagesInBatchesWithInterval(false); // Initial fetch
    }

    if (shouldUpdate) {
      fetchChatMessagesInBatchesWithInterval(true); // Fetch the next batch
      dispatch(updateAllThreadsWithChatMessages(false));
    }
  }, [
    orgMember,
    stateData.messages.updateAllThreadsWithChatMessages,
    stateData.messages.loadMore,
  ]);

  useEffect(() => {
    if (allThreadsWithChat.length > 0) {
      dispatch(allThreadsWithChatMessages(allThreadsWithChat));
    }
  }, [allThreadsWithChat, dispatch]);

  useEffect(() => {
    getAllThreadRoomByIdData();
  }, [])

  useEffect(() => {
    if (stateData.messages.createdNewThreadWithGoToChat) {
      getAllThreadRoomByIdData();
    }
  }, [stateData.messages.createdNewThreadWithGoToChat])


  useEffect(() => {
    setToggle(location?.state?.toggleSideBar);
  }, [location?.state?.toggleSideBar, toggleValue]);

  useEffect(() => {
    if (refreshCounter) {
      getMessagesByOrg();
    }
  }, refreshCounter)
  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  useEffect(() => {
    getMessagesByOrg();
    if (
      location?.state?.toggleSideBar === undefined ||
      location?.state === null
    ) {
      setToggle(false);
    }
  }, [location, stateData?.notification?.notificationId]);

  /// Path
  let path = window.location?.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu

  function getMessagesByOrg() {
    const data = getAllCountByOrg(orgId, userInfoDetails?.data?.accessToken);
    data?.then(async (res) => {
      if (res.data.message === "Badge counter rendered") {
        setUnreadMessages(res?.data?.data);
        setRefreshCounter(false);
      }
    });
    data?.catch(async (err) => {
      setRefreshCounter(false);
    });
  }

  const handleLinkClick = () => {
    dispatch(taskLateCountUpdate(false))
    localStorage.setItem("TaskScheduledLateCount", false);
  };

  const handleButtonClick = (event) => {
    dispatch(taskLateCountUpdate(true))
    event.preventDefault();
    event.stopPropagation();
    localStorage.setItem("TaskScheduledLateCount", true);
    navigate("/tasks");
  };
  return (
    <>
      {toggleValue === false || location?.state?.toggleSideBar === undefined ? (
        <div
          id="hidescrollbar"
          className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
            sidebarLayout.value === "horizontal" &&
            headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
            }`}
        >
          <PerfectScrollbar className="">
            <div className="bg-white dlabnav-scroll">
              <div className="mm-wrapper">
                <ul className="nav flex-column ">
                  <Link
                    className={path === "my-day" ? "active text-red" : ""}
                    to="/my-day"
                  >
                    {" "}
                    <li
                      className={
                        path === "my-day"
                          ? " nav-itemn active text-red background"
                          : "nav-itemn"
                      }
                    >
                      <i class="bi bi-sun-fill h3"></i>
                      &nbsp;&nbsp;My Day
                    </li>
                  </Link>
                  <Link
                    className={path === "ai-plan" ? "active text-red" : ""}
                    to="/ai-plan"
                  >
                    {" "}
                    <li
                      className={
                        path === "ai-plan"
                          ? " nav-itemn active text-red background"
                          : "nav-itemn"
                      }
                    >
                      <i class="bi bi-cpu h3"></i>
                      &nbsp;&nbsp; Ai Plan
                    </li>
                  </Link>
                  {/* <Link
                    className={path === "home" ? "active text-red" : ""}
                    to="/home"
                  >
                    {" "}
                    <li
                      className={
                        path === "home"
                          ? " nav-itemn active text-red background"
                          : "nav-itemn"
                      }
                    >
                      <i class="bi bi-house-door-fill h3"></i> &nbsp;Home
                    </li>
                  </Link> */}
                  <Link
                    className={path === "goals" ? "active     text-red" : ""}
                    to="/goals"
                  >
                    <li
                      className={
                        path === "goals"
                          ? " nav-itemn active text-red background"
                          : "nav-itemn "
                      }
                    >
                      <i class="bi bi-bullseye h3"></i> &nbsp;Goals
                    </li>
                  </Link>
                  {/* <Link
                    className={path === "tasks" ? "active text-red" : ""}
                    to="/tasks"
                  >
                    <li
                      className={
                        path === "tasks"
                          ? " nav-itemn active text-red background"
                          : "nav-itemn "
                      }
                    >
                      <i class="bi bi-list-stars h3"></i> &nbsp; Tasks
                      {unreadMessages?.task_counter >= 1 && (
                        <button
                          class="badge badge-notification position-absolute badge rounded-pill bg-danger"
                          style={{
                            float: "right",
                            display: "inline-block",
                            margin: "5px 0px 5px 2.3rem",
                          }}
                          onClick={() => {
                            localStorage.setItem(
                              "TaskScheduledLateCount",
                              true
                            );
                          }}
                        >
                          {unreadMessages?.task_counter}
                          <span class="visually-hidden">unread messages</span>
                        </button>
                      )}
                    </li>
                  </Link> */}

                  <Link
                    className={path === "tasks" ? "active text-red" : ""}
                    to="/tasks"
                    onClick={handleLinkClick} // Handle click on the Link
                  >
                    <li
                      className={
                        path === "tasks"
                          ? "nav-itemn active text-red background"
                          : "nav-itemn"
                      }
                    >
                      <i className="bi bi-list-stars h3"></i> &nbsp; Tasks
                      {unreadMessages?.task_counter >= 1 && (
                        <button
                          className="badge badge-notification position-absolute badge rounded-pill bg-danger"
                          style={{
                            float: "right",
                            display: "inline-block",
                            margin: "5px 0px 5px 2.3rem",
                          }}
                          onClick={handleButtonClick} // Handle click on the button
                        >
                          {unreadMessages?.task_counter}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </button>
                      )}
                    </li>
                  </Link>
                  <Link
                    className={path === "messages" ? "active text-red" : ""}
                    to="/messages"
                  >
                    <li
                      className={
                        path === "messages"
                          ? " nav-itemn active text-red background"
                          : "nav-itemn"
                      }
                    >
                      <i class="bi bi-chat-left-text-fill h3"></i> &nbsp;
                      Messages
                      {unreadMessages?.message_counter >= 1 && (
                        <span
                          class="badge badge-notification position-absolute badge rounded-pill bg-danger"
                          style={{
                            float: "right",
                            display: "inline-block",
                            margin: "5px 0px 5px 0.1rem",
                          }}
                        >
                          {unreadMessages?.message_counter}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      )}
                    </li>
                  </Link>
                  <Link
                    className={path === "calendar" ? "active text-red" : ""}
                    to="/calendar"
                  >
                    <li
                      className={
                        path === "calendar"
                          ? "nav-itemn active text-red background"
                          : "nav-itemn "
                      }
                    >
                      <i class="bi bi-calendar2-date h3"></i>&nbsp;
                      &nbsp;Calendar
                    </li>
                  </Link>
                  {/* <Link
                    className={path === "time" ? "active text-red" : ""}
                    to="/time"
                  >
                    <li
                      className={
                        path === "time"
                          ? "nav-itemn active text-red background"
                          : "nav-itemn "
                      }
                    >
                      <i class="bi bi-clock-history h3"></i>&nbsp; &nbsp;Time
                    </li>
                  </Link> */}
                  <Link
                    className={path === "track" ? "active text-red" : ""}
                    to="/track"
                  >
                    <li
                      className={
                        path === "track"
                          ? "nav-itemn active text-red background"
                          : "nav-itemn "
                      }
                    >
                      <i class="bi bi-clock-history h3"></i>&nbsp; &nbsp;Track
                    </li>
                  </Link>
                  {/* <Link
                    className={path === "performance" ? "active text-red" : ""}
                    to="/performance"
                  >
                    <li
                      className={
                        path === "performance"
                          ? "nav-itemn active text-red background"
                          : "nav-itemn"
                      }
                    >
                      <i class="bi bi-speedometer2 h3"></i>&nbsp;
                      &nbsp;Performance
                    </li>
                  </Link> */}
                  {/* <Link className={path === "league" ? "active text-red" : ""}  to="/league"> <li className={path === "league" ? "nav-itemn active text-red background" : "nav-itemn "} ><i class="bi bi-people-fill h3"></i>&nbsp; &nbsp;League</li></Link> */}
                  {/* <Link className={path === "pulse" ? "active text-red" : ""}  to="/pulse"><li className={path === "pulse" ? "nav-itemn active text-red background" : "nav-itemn "} ><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                    </svg>&nbsp; &nbsp;Pulse</li></Link> */}

                  {/* {userInfoDetails?.data?.user_role === "owner" &&
                    userInfoDetails?.data?.org_access === true && (
                      <Link
                        className={
                          path === "subscription-plan" ? "active text-red" : ""
                        }
                        to="/subscription-plan"
                      >
                        <li
                          className={
                            path === "subscription-plan"
                              ? "nav-itemn active text-red background"
                              : "nav-itemn "
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-currency-dollar"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                          </svg>
                          &nbsp; &nbsp;Subscription plans
                        </li>


                      </Link>
                    )} */}


                  {userInfoDetails?.data?.is_partner &&
                    (
                      <Link
                        className={
                          path === "revenue" ? "active text-red" : ""
                        }
                        to="/revenue"
                      >
                        <li
                          className={
                            path === "revenue"
                              ? "nav-itemn active text-red background"
                              : "nav-itemn "
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-revenue"
                            viewBox="0 0 16 16"
                          >

                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />

                            <path d="M8 0l2 2h-1v4h-2V2H6l2-2z" />
                          </svg>

                          &nbsp; &nbsp;revenue
                        </li>


                      </Link>
                    )}
                </ul>
                {/* <Card className="hover-card mt-2 ">
      <a href="/store-front" className="card-link nav-itemn">
        Go to store
      </a>
    </Card> */}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      ) : (
        <div
          id="hidescrollbar"
          className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
            sidebarLayout.value === "horizontal" &&
            headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
            }`}
        // style={{ width: '100px' }}
        >
          <PerfectScrollbar className="">
            <div className="bg-white dlabnav-scroll">
              <div className="mm-wrapper">
                <ul className="nav flex-column justify-content-center align-items-center">
                  <li
                    className={
                      path === "my-day"
                        ? " nav-itemn active text-red background nav-link"
                        : "nav-itemn nav-link"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "my-day" ? "active text-red " : ""}
                      to="/my-day"
                    >
                      {" "}
                      <i
                        className={
                          path === "my-day"
                            ? "bi bi-sun-fill h3 color" // Change this to sun icon
                            : "bi bi-sun-fill h3" // Default state
                        }
                      ></i>
                      {/* <span className="nav-text px-0 mx-0 "> Home</span> */}
                    </Link>
                  </li>
                  <li
                    className={
                      path === "ai-plan"
                        ? " nav-itemn active text-red background nav-link"
                        : "nav-itemn nav-link"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "ai-plan" ? "active text-red " : ""}
                      to="/ai-plan"
                    >
                      {" "}
                      <i
                        className={
                          path === "ai-plan"
                            ? "bi bi-cpu h3 color" // Change this to sun icon
                            : "bi bi-cpu h3" // Default state
                        }
                      ></i>
                      {/* <span className="nav-text px-0 mx-0 "> Home</span> */}
                    </Link>
                  </li>
                  {/* <li
                    className={
                      path === "home"
                        ? " nav-itemn active text-red background nav-link"
                        : "nav-itemn nav-link"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "home" ? "active text-red " : ""}
                      to="/home"
                    >
                      {" "}
                      <i
                        className={
                          path === "home"
                            ? "bi bi-house-door-fill h3 color"
                            : "bi bi-house-door-fill h3"
                        }
                      ></i>
                     
                    </Link>
                  </li> */}
                  <li
                    className={
                      path === "goals"
                        ? " nav-itemn active text-red background nav-link"
                        : "nav-itemn nav-link"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "goals" ? "active text-red" : ""}
                      to="/goals"
                    >
                      <i
                        class={
                          path === "goals"
                            ? "bi bi-bullseye h3 color"
                            : "bi bi-bullseye h3"
                        }
                      ></i>
                      {/* <span className="nav-text"> Goals</span> */}
                    </Link>
                  </li>
                  <li
                    className={
                      path === "tasks"
                        ? " nav-itemn active text-red background nav-link "
                        : "nav-itemn nav-link "
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "tasks" ? "active text-red" : ""}
                      to="/tasks"
                    >
                      <i
                        class={
                          path === "tasks"
                            ? "bi bi-list-stars h3 color"
                            : "bi bi-list-stars h3"
                        }
                      ></i>
                      {unreadMessages?.task_counter >= 1 && (
                        <span
                          class="badge badge-notification position-absolute badge rounded-pill bg-danger float-end"
                          style={{
                            float: "right",
                            display: "inline-block",
                            margin: "5px 0px 5px 5px",
                          }}
                        >
                          {unreadMessages?.task_counter}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      )}
                      {/* <span className="nav-text px-0 mx-0"> Task</span> */}
                    </Link>
                  </li>
                  <li
                    className={
                      path === "messages"
                        ? " nav-itemn active text-red background nav-link"
                        : "nav-itemn nav-link"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "messages" ? "active text-red " : ""}
                      to="/messages"
                    >
                      <i
                        class={
                          path === "messages"
                            ? "bi bi-chat-left-text-fill h3 color"
                            : "bi bi-chat-left-text-fill h3"
                        }
                      ></i>
                      {unreadMessages?.message_counter >= 1 && (
                        <span
                          class="badge badge-notification position-absolute badge rounded-pill bg-danger float-end"
                          style={{
                            float: "right",
                            display: "inline-block",
                            margin: "3px 0px 5px 5px",
                          }}
                        >
                          {unreadMessages?.message_counter}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      )}
                      {/* <span className="nav-text px-0 mx-0"> Messages</span> */}
                    </Link>
                  </li>
                  <li
                    className={
                      path === "calendar"
                        ? "nav-itemn h4 active text-red background nav-link"
                        : "nav-itemn nav-link h4"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "calendar" ? "active text-red " : ""}
                      to="/calendar"
                    >
                      <i
                        class={
                          path === "calendar"
                            ? "bi bi-calendar2-date h3 color"
                            : "bi bi-calendar2-date h3"
                        }
                      ></i>
                      {/* <span className="nav-text"> Calendar</span> */}
                    </Link>
                  </li>
                  {/* <li
                    className={
                      path === "time"
                        ? "nav-itemn h4 active text-red background nav-link"
                        : "nav-itemn nav-link h4"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "time" ? "active text-red" : ""}
                      to="/time"
                    >
                      <i
                        class={
                          path === "time"
                            ? "bi bi-clock-history h3 color"
                            : "bi bi-clock-history h3"
                        }
                      ></i>
                     
                    </Link>
                    </li> */}


                  <li
                    className={
                      path === "track"
                        ? "nav-itemn h4 active text-red background nav-link"
                        : "nav-itemn nav-link h4"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={path === "track" ? "active text-red " : ""}
                      to="/track"
                    >
                      <i
                        class={
                          path === "track"
                            ? "bi bi-clock-history h3 color"
                            : "bi bi-clock-history h3"
                        }
                      ></i>

                    </Link>
                  </li>

                  {/* <li
                    className={
                      path === "performance"
                        ? "nav-itemn h4 active text-red background nav-link"
                        : "nav-itemn nav-link h4"
                    }
                    style={{ padding: "15px" }}
                  >
                    <Link
                      className={
                        path === "performance" ? "active text-red" : ""
                      }
                      to="/performance"
                    >
                      <i
                        class={
                          path === "performance"
                            ? "bi bi-speedometer2 h3 color"
                            : "bi bi-speedometer2 h3"
                        }
                      ></i>
                   
                    </Link>
                  </li> */}
                  {/* <li className={path === "league" ? "nav-itemn h4 active text-red background nav-link" : "nav-itemn nav-link h4"} style={{ padding: "15px" }}><Link className={path === "league" ? "active text-red" : ""}  to="/league"><i class={path === "league" ? "bi bi-people-fill h3 color" : "bi bi-people-fill h3"}></i><span className="nav-text"> League</span></Link></li> */}
                  {/* <li className={path === "pulse" ? "nav-itemn h4 active text-red background nav-link" : "nav-itemn nav-link h4"} style={{ padding: "15px" }}><Link className={path === "pulse" ? "active text-red" : ""}  to="/pulse"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class={path === "pulse" ? "bi bi-activity h3 color" : "bi bi-activity h3"} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                    </svg><span className="nav-text"> Pulse</span></Link></li> */}

                  {/* {userInfoDetails?.data?.user_role === "owner" &&
                    userInfoDetails?.data?.org_access === true && (
                      <li
                        className={
                          path === "subscription-plan"
                            ? "nav-itemn h4 active text-red background nav-link"
                            : "nav-itemn nav-link h4"
                        }
                        style={{ padding: "15px" }}
                      >
                        <Link
                          className={
                            path === "subscription-plan"
                              ? "active text-red"
                              : ""
                          }
                          to="/subscription-plan"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-currency-dollar"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                          </svg>
                          <span className="nav-text">Subscription plans</span>
                        </Link>
                      </li>
                                  
                    )} */}

                  {userInfoDetails?.data?.is_partner && (
                    <li
                      className={
                        path === "revenue"
                          ? "nav-itemn h4 active text-red background nav-link"
                          : "nav-itemn nav-link h4"
                      }
                      style={{ padding: "15px" }}
                    >
                      <Link
                        className={
                          path === "revenue"
                            ? "active text-red"
                            : ""
                        }
                        to="/revenue"
                      >
                        {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-currency-dollar"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                          </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-revenue"
                          viewBox="0 0 16 16"
                        >

                          <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />

                          <path d="M8 0l2 2h-1v4h-2V2H6l2-2z" />
                        </svg>

                        {/* <span className="nav-text">Subscription plans</span> */}
                      </Link>
                    </li>

                  )}

                </ul>

                {/* <Card className="hover-card mt-2">
      <a href="/store-front" className="card-link nav-itemn" >
        Go to store
      </a>
    </Card> */}
              </div>

            </div>
          </PerfectScrollbar>
        </div>
      )}
    </>
  );
};

export default SideBar;
