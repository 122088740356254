import { useState, useEffect, useRef } from 'react';
import { memo } from "react";
import TaskSidebar from '../Component/TaskSidebar';
import GoalSideBar from '../Component/GoalSideBar';

const Sidebar = (props) => {
    const [viewMessage, setviewMessage] = useState(props?.data);
    useEffect(() => {
        setviewMessage(()=>props?.data)
    }, [props?.data])
    
    return (
        viewMessage?.task ?
            <TaskSidebar data = {viewMessage} close = {props?.close} refreshed = {()=>props?.refresh()} noti={props.noti}/> 
            :
            <GoalSideBar data = {viewMessage} close = {props?.close} refreshed = {()=>props?.refresh()} noti={props.noti}/>
    )
}

export default memo(Sidebar);