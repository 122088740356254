import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getHomeGraph } from "../../../services/GoalService";
import { Row, Col, Card } from "react-bootstrap";
import RedialApex from "./RedialApex";
import vector from "../../../images/svg/Vector.svg";
import vectorrs from "../../../images/svg/vectorrs.svg"
import Donut from "./Donut";
import Pagination from "../../components/Pagination/Pagination";
import { getGoalHomeGraph } from "../../../services/GoalService";
import { Home } from "@material-ui/icons";
import HomePieChart from "../Menu/HomePieChart";
import { memo } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class BarChart1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      startDate: "",
      endDate: "",
      seriesData: [],
      lableee: [],
      pagination: false,
      pageCount: 1,
      goToPage: null,
      graphGoalData:[]
    };
    this.userDetail = localStorage.getItem("userDetails");
    this.userInfoDetails = JSON.parse(this.userDetail);
    console.log(props, "PaginationthisstateProps::::::::::::::::::::")
  }
  componentDidMount() {
    this.getGraph();
    // this.getGoalGraph()
  }

  componentDidUpdate(prevProps) {
    console.log(prevProps?.scope, this.props?.scope ,"prevProps?.scope")
   if(prevProps?.scope !== this.props?.scope){
    // this.getGoalGraph()
   }
  }


  getGraph = (goPage = 0) => {
    const data = getHomeGraph("", this.userInfoDetails?.data?.accessToken, goPage);
    data?.then(async (res) => {
      if (res?.data?.data[0]?.completion_percent === 0) {
        res?.data?.data?.shift();
      }
      this.setState(() => ({
        // goToPage: goPage,
        // pageCount: Math.ceil(res?.data?.count / 10),
        // pagination: res?.data?.next || res?.data?.previous ? true : false,
        labels: res?.data?.data,
        seriesData: [
          res?.data?.data[res?.data?.data?.length - 1]?.hit_percent,
          res?.data?.data[res?.data?.data?.length - 1]?.beat_percent,
          res?.data?.data[res?.data?.data?.length - 1]?.miss_percent,
        ],
        lableee: [
          "Hit-" +
          String(res?.data?.data[res?.data?.data?.length - 1]?.hit_percent),
          "Beat-" +
          String(res?.data?.data[res?.data?.data?.length - 1]?.beat_percent),
          "Miss-" +
          String(res?.data?.data[res?.data?.data?.length - 1]?.miss_percent),
        ],
      }));

    });
    data?.catch(async (err) => {
      console.log("error getHomeGraph");
    });
  }

  // getGoalGraph = (goPage = 0) => {
  //   const data = getGoalHomeGraph(this.userInfoDetails?.data?.accessToken, goPage)
  //   data.then(async (res) => {
  //     console.log(res?.data?.data, "responsesssss")
  //     this.setState(() => ({
  //       goToPage: goPage,
  //       pageCount: Math.ceil(res?.data?.count / 5),
  //       pagination: res?.data?.next || res?.data?.previous ? true : false,
  //       graphGoalData : res?.data?.data
  //     }))
  //   })
  //   data.catch(async(err) =>{
  //     console.log(err, "error")

  //   })
    
  // }

  componentWillReceiveProps(newProps) {
  console.log(newProps?.scope, "7575757575575757575")
    if (newProps?.scope) {
      setTimeout(() => {
        console.log(newProps?.scope, "7575757575575757575")
        this.getGraph();
        
      }, 1000);
    }
    else {
     
      setTimeout(() => {
        if (this.state?.goToPage === 0) {
          console.log(newProps?.scope, "7575757575575757575")
          this.getGraph(this.state?.goToPage);
         
        }
      }, 2000);

    }
    this.setState({ labels: newProps?.graphData });
    this.setState({
      ...this.state,
      seriesData: [
        newProps?.graphData[newProps?.graphData?.length - 1]?.hit_percent,
        newProps.graphData[newProps?.graphData?.length - 1]?.beat_percent,
        newProps?.graphData[newProps?.graphData?.length - 1]?.miss_percent,
      ],
      lableee: [
        "Hit-" +
        String(
          newProps?.graphData[newProps?.graphData?.length - 1]?.hit_percent
        ),
        "Beat-" +
        String(
          newProps?.graphData[newProps?.graphData?.length - 1]?.beat_percent
        ),
        "Miss-" +
        String(
          newProps?.graphData[newProps?.graphData?.length - 1]?.miss_percent
        ),
      ],
    });
  }

  ChangePage = (pageNo) => {
    this.setState(() => ({ ...this.state, goToPage: pageNo }));
    this.getGraph(pageNo);
    // this.getGoalGraph(pageNo)
  }

  render() {
    const graphData = this.state?.labels;
    console.log(graphData , graphData[graphData?.length - 2]
      ?.completion_percent -
      graphData[graphData?.length - 1]
        ?.completion_percent ,"graphData")

        const color = graphData[graphData?.length - 2]?.completion_percent >
        graphData[graphData?.length - 1]?.completion_percent ? "#FF0000" : "#00FF00"; 
       
    const labels = [];
    const values = [];
    this?.state?.graphGoalData?.forEach((element) => {
      labels.push( element.goal);
      values.push(element.complete_hours);
    });

    console.log(labels, "99999999999")
    console.log(values, "99999999999oooo")

    const screenWidth = window.innerWidth;
    const barThickness = screenWidth >= 1020 ? 70 : 30;  
    const data = {
      defaultFontFamily: "Open Sans",
      labels: labels,
      
      datasets: [
        {
          label: "Completion hour",
          data: values,
          borderColor: "#4DBFDB",
          borderWidth: "0",
          backgroundColor: "#4DBFDB",
          hoverBackgroundColor: "#4DBFDB",
          barThickness: barThickness,
        },
      ],
    };
    const options = {
      plugins: {
        legend: false,
      },
      scales: {
        y: {
          min: 0,
          max: 1000,
        },

        x: {
          // Change here
          barPercentage: 1,
        },
      },
      responsive: true,
    };
    return (
      <>
        <Row>
          <Col xl={12}>
            <Card>
              <Row className="mx-2 mt-4">
                {localStorage.getItem("org_access") === "false" ? (
                  <h3>
                    You are just starting out. In future this graph will tell
                    you how long you need to complete your project, based on how
                    fast you are working
                  </h3>
                ) : (
                  <>
                    <h4>
                      You are{" "}
                      <span className="figma-link ">
                        {graphData[graphData.length - 1]?.completion_percent}%
                        complete
                      </span>
                      .
                      {this.props.week ? (
                        <>
                          {" "}
                          At this pace you will be done{" "}
                          <span className="figma-link ">
                            in {this.props.week || "-"} weeks
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </h4>
                  </>
                )}
              </Row>
              <Card.Body className="m-1">
                <Row className="h-100 w-100">
                  <Col xs={12} sm={8} md={4}  lg={4} xl={2} className="m-0 p-0">
                    <div className="d-flex p-0 justify-content-between m-0">
                      <div className="m-0 p-0 mt-1">
                        <h4 className="fs-18 font-w600 mb-1 text-nowrap filterText ms-3">
                          Complete
                        </h4>
                        <div className="d-flex align-items-center">
                          <div id="redial" className="m-0 p-0">
                            <RedialApex
                              value={
                                graphData[graphData?.length - 1]
                                  ?.completion_percent || 0
                              }
                              color={"#356FD4"}
                            />
                          </div>
                          <span className="d-block">
                            <h2 className="mb-0">
                              {" "}
                              {graphData[graphData?.length - 1]
                                ?.completion_percent || 0}
                              %
                            </h2>
                            <small className="d-block fs-16 font-w400  mt-2" style={{color}} >
                           { graphData[graphData?.length - 2]
                                    ?.completion_percent >
                                    graphData[graphData?.length - 1]
                                      ?.completion_percent    ?(<img
                                        className="float-start px-1"
                                        src={vector}
                                        alt="vector"
                                        title="vector"
                                        
                                      />):(  <img
                                className="float-start px-2"
                                src={vectorrs}
                                alt="vector"
                                title="vector"/>) }
                              <span className="me-2 text-nowrap">
                                {graphData[graphData?.length - 2]
                                  ?.completion_percent ==
                                  graphData[graphData?.length - 1]
                                    ?.completion_percent
                                  ? ""
                                  : graphData[graphData?.length - 2]
                                    ?.completion_percent >
                                    graphData[graphData?.length - 1]
                                      ?.completion_percent
                                    ? ""
                                    : "+"}
                                {graphData[graphData?.length - 1]
                                  ?.completion_percent -
                                  graphData[graphData?.length - 2]
                                    ?.completion_percent || 0}
                                %
                              </span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex p-0 justify-content-between">
                      <div className="m-0 p-0">
                        <h4 className="fs-18 font-w600 mb-2 text-nowrap filterText ms-3">
                          {" "}
                          On-time
                        </h4>
                        <div className="d-flex align-items-center">
                          <div id="redial" className="m-0 p-0">
                            <RedialApex
                              value={
                                graphData[graphData?.length - 1]
                                  ?.on_time_percent || 0
                              }
                              color={"#F2CB55"}
                            />
                          </div>
                          <span className="d-block">
                            <h2 className="mb-0">
                              {" "}
                              {graphData[graphData?.length - 1]
                                ?.on_time_percent || 0}
                              %
                            </h2>
                            <small className="d-block fs-16 font-w400  mt-2"  style={{
        color:
          graphData[graphData?.length - 2]?.on_time_percent >
          graphData[graphData?.length - 1]?.on_time_percent
            ? '#FF0000'
            : '#00FF00',
      }}>
                            {  graphData[graphData?.length - 2]
                                    ?.on_time_percent >
                                    graphData[graphData?.length - 1]
                                      ?.on_time_percent    ?( <img
                                        className="float-start px-1"
                                        src={vector}
                                        alt="vector"
                                        title="vector"
                                        
                                      />):(<img
                                        className="float-start px-2"
                                        src={vectorrs}
                                        alt="vector"
                                        title="vector"/>) }
                              <span className="me-2 text-nowrap">
                                {graphData[graphData?.length - 2]
                                  ?.on_time_percent ==
                                  graphData[graphData?.length - 1]?.on_time_percent
                                  ? ""
                                  : graphData[graphData?.length - 2]
                                    ?.on_time_percent >
                                    graphData[graphData?.length - 1]
                                      ?.on_time_percent
                                    ? ""
                                    : "+"}
                                {graphData[graphData?.length - 1]
                                  ?.on_time_percent -
                                  graphData[graphData?.length - 2]
                                    ?.on_time_percent || 0}
                                %
                              </span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="m-0 p-0">
                      <div className="m-0 p-0 ">
                        <div className="d-flex justify-content-between m-0 p-0">
                          <span className="h4 fs-18 font-w600 mb-2 text-nowrap filterText ms-3 ">
                            Wins
                          </span>
                          <span className=" h2 float-end ms-5 ps-3">
                            {graphData[graphData?.length - 1]?.win_percent}%
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center m-0 p-0">
                        <div id="redial">
                          <Donut
                            series={this.state.seriesData}
                            color={"#4DBFDB"}
                            lable={this.state.lableee}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={8} xl={10}  >
                    {/* <Bar
                      data={data}
                      height={150}
                      options={options}
                      className="h-100 w-100 px-1 ps-2"
                    /> */}
                    <HomePieChart scope={localStorage.getItem("timescope")}></HomePieChart>
                  </Col>
                  {/* <Row className="pt-3 border-0">
                    {this.state.pagination && (
                      <Pagination
                        pageCount={this.state.pageCount}
                        pageIndex={this.state.goToPage}
                        gotoPage={this.ChangePage}
                      />
                    )}
                  </Row> */}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default React.memo(BarChart1);

