import React, { useState } from 'react'
import Plan from './Plan'
import { useLocation } from 'react-router-dom'

export default function TeamDay() {
  const location = useLocation();
  const [team] = useState(location?.state?.team)

  return (
    <>
    <h1>Team's day - <span style={{fontSize:'20px'}}>{team?.name}</span></h1>
    <Plan route={"team-day"} teamId={team?.id}/>
    </>
  )
}
