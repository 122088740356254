import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import profilePicture from "../../../images/profile/profilePic.png";
import { inviteUser } from "../../../services/AuthService";
import { getOrgMem, getOrgInvitedMem } from "../../../services/TeamService";
import { RemoveUserByOrg } from "../../../services/OrganizationService";
import Vector from "../../../icons/bootstrap-icons/icons/Vector.svg";
import AccountImage from "../../../images/vector/account-plus.png";
import AccountClock from "../../../images/vector/account-clock.png";
import { toast } from "react-toastify";
import Vector3 from "../../../images/vector/Vector3.png";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../Component/LoadingSpinner";
import OrganizationList from "./OrganizationList";
import SubscriptionPlans from "./SubscriptionPlans";

const InviteUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const org_id = localStorage.getItem("organization_id");
  const stateData = useSelector((state) => state);
  const [orgMember, setOrgMember] = useState();
  const [orgInvitedMember, setOrgInvitedMember] = useState();
  const [activeTab, setActiveTab] = useState("tab3");
  const [userinvite, setUserinvite] = useState(false);
  const [userdelete, setUserDelete] = useState(false);
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [SelectedMemberDelete, setSelectedMemberDelete] = useState();
  const [OpenWarningModal, setOpenWarningModal] = useState();
  const [SelectedUser, setSelectedUser] = useState(location?.state?.user?.id);
  const [Show, setShow] = useState(false);
  const [enoughLiscenseMsg, setEnoughLiscenseMsg] = useState(false)
  const [needLiscense, SetNeedLiscense] = useState(false)

  const [error, setError] = useState("")


 
 

  console.log(userDetails, "KKKKKKKKKKKKKKKKKKKK")
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setShow(Show);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [Show]);

 

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
    orgMemberList();
    orgInvitedMemberList();
  }, []);

  useEffect(() => {
    setSelectedUser(location?.state?.user?.id);
  }, [location?.state?.user]);

  const [showModal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const options = [
    { value: "admin", label: "Admin" },
    { value: "partner", label: "Partner" },
    { value: "user", label: "User" },
  ];
  const [userRole, setValue] = useState({
    value: "user",
    label: "User",
  });
  
  const changeUser = (userRole) => {
    console.log(userRole, "99999999999")
    setValue(userRole);
  };

  console.log(userRole, userRole?.value,"1111111111111111")

  function inviteUserAction(e) {
    
    e.preventDefault();
    const data = inviteUser(
      email,
      userRole?.value,
      org_id,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      console.log("Invite user success:", res);
      if (
        res.data.message.includes(
          "An invitation link has been successfully sent"
        )
      ) {
        setUserinvite(true);
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      }
     
      setModal(false);
    setEmail("");
    setValue(userRole);
    });
    data?.catch(async (err) => {
      console.log("Invite user error:", err);
      console.log(err?.response?.data?.message, err, "error77777777777777777")
      setError(err?.response?.data?.message)
      setShow(true);
      if (err?.response?.data?.message === "Don't have enough license to invite users") {
        setShow(true);
        setUserinvite(false);
        setEnoughLiscenseMsg(true)
      }else if(err?.response?.data?.message === "You have already used all licenses. Still, some users have not accepted. Please wait for 24 hours or consider buying new licenses."){
        SetNeedLiscense(true)
      }
     
      setModal(false);
      setEmail("");
      setValue(userRole);
    });
   
    
  }

  function openInviteUser() {
    if (localStorage.getItem("organization_id") === null) {
      setOpenWarningModal(true);
    } else {
      setModal(true);
    }
  }

  function orgMemberList() {
    setLoader(true);
    const data = getOrgMem(userInfoDetails.data.accessToken);
    data?.then(async (res) => {
      if (res?.data?.message === "Organization Members") {
        setOrgMember(res.data.data);
        setLoader(false);
      }
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
  }

  function orgInvitedMemberList() {
    setLoader(true);
    const data = getOrgInvitedMem(userInfoDetails.data.accessToken);
    data?.then(async (res) => {
      console.log(res,"error user org listsssssssssssss")
      if (res.data.message === "List of invited users") {
        setOrgInvitedMember(res.data.data);
        setLoader(false);
      }
    });
    data?.catch(async (err) => {
      console.log("error user org list", err);
    });
  }
  const handleTab1 = () => {
    setActiveTab("tab1");
    orgMemberList();
  };
  const handleTab2 = () => {
    orgInvitedMemberList();
    setActiveTab("tab2");
  };

  const handleTab3 = () => {
    orgInvitedMemberList();
    setActiveTab("tab3");
  };


  const handleTab4 = () =>{
    orgInvitedMemberList();
    setActiveTab("tab4");
  }

 

  const selectedMemberForDelete = (event, id) => {
    event.preventDefault();
    setSelectedMemberDelete(id);
  };

  const deleteUser = () => {
    const data = RemoveUserByOrg(
      SelectedMemberDelete,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      orgMemberList();
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
  };
  const toastSuccess = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i
            class="bi bi-check-circle fs-26 me-3"
            style={{ color: "#131F4C" }}
          ></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Completed
            </h2>
            {userinvite ? (
              <h4>User invitaion link send successfully!</h4>
            ) : userdelete ? (
              <h4>User deleted successfully!</h4>
            ) : (
              ""
            )}
          </div>
        </div>
      </>,
      {
        toastId: "Completed",
        className: "toast-success-container toast-success-container-after ",
      }
    );
  };

  const toastFailed = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Failed
            </h2>
        {!enoughLiscenseMsg && !needLiscense && <h4>{ error}</h4>  }  
            {enoughLiscenseMsg && (
              <h4>
              
               Don't have enough license to invite users. Please click <Link to="/subscription-plan" state={{ routeTo: "true" }} style={{color:"blue"}}>
 here </Link> buy or update your subscription plan to get license!
              </h4>
            ) }

            {
              needLiscense && (
                <h4>
                  "You have already used all licenses. Still, some users have not accepted. Please wait for 24 hours or consider buying new licenses click  <Link to="/subscription-plan" state={{ routeTo: "true" }} style={{color:"blue"}}>
 here </Link>
               </h4>
              )
            }
          </div>
        </div>
      </>,
      {
        toastId: "upload-template",
        className: "toast-error-container toast-error-container-after ",
        autoClose: 5000, 
      }
    );
  };

  return (
    <>
      {userinvite && toastSuccess()}
      {Show && toastFailed()}

      <Modal
        show={OpenWarningModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        top
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="warning-modal" title="warning-modal" src={Vector3} />
          <h4 className="fs-20 p-2">Warning!</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            Please create organization or select default organization from
            header before inviting user in your organization
            <Link className="figma-link mt-2 me-3" to="/organization-list">
              &nbsp;here...
            </Link>
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <button
            className="figma-btn text-white"
            onClick={() => {
              setOpenWarningModal(false);
            }}
          >
            Ok
          </button>
        </div>
      </Modal>

      {/* Delete modal */}
      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="delete-modal" title="delete-modal" src={Vector3} />
          <h4 className="fs-20 p-2">
            Are you sure you want to remove member from organization?{" "}
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this member!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to=""
            onClick={(e) => {
              e.preventDefault();
              SetOpenDeleteModal(false);
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              {
                deleteUser();
                SetOpenDeleteModal(false);
              }
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={showModal} size="md">
        <Modal.Body>
          <form onSubmit={inviteUserAction}>
            <div className="d-flex">
              <div className="col">
                <h2 className="text-center">Invite user</h2>
              </div>
              <button
                type="button"
                className="btn-close float-end text-center mt-3"
                onClick={() => setModal(false)}
              ></button>
            </div>
            <div className="modal-body p-0">
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <h5 className="">Email</h5>
                    <div className="contact-name">
                      <input
                        type="email"
                        className="figma-input p"
                        autoComplete="off"
                        name="email"
                        required="required"
                        placeholder="example@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ height: "36px" }}
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="mb-3" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="figma-btn text-white"
                      style={{ width: "100%" }}
                    >
                      <h5 style={{ color: "#ffffff" }}>Invite</h5>
                    </button>
                  </div>
                  <div className="row mt-2">
                    <div className="">
                      <p className="p fs-14">
                        Want to invite an admin or a partner? Click here -
                        <div
                          className=""
                          style={{
                            display: "inline-flex",
                            borderRadius: "0rem",
                          }}
                        >
                          <Select
                            options={options}
                            styles={{
                              padding: "2px",
                              borderRadius: "0px",
                            }}
                            required="required"
                            defaultValue={options[2]}
                            onChange={changeUser}
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="d-flex col">
        <div className="col">
          {localStorage.getItem("previousLocation") !==
            localStorage.getItem("currentLocation") && (
            <span className="float-start me-3 mt-1">
              <i
                className="fa fa-reply fs-20"
                title={`Navigate back to ${
                  localStorage.getItem("previousLocation").split("/")[1]
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(localStorage.getItem("previousLocation"));
                }}
              />
            </span>
          )}
          <span className="h2">Organization </span>
        </div>
    
      </div>
      <Row>
        <Col className="col-sm-12">
          <ul className="nav1 mt-4">
          <li
              className={activeTab === "tab3" ? "active" : ""}
              onClick={handleTab3}
            >
              Organization
            </li>
            <li
              className={activeTab === "tab1" ? "active" : ""}
              onClick={handleTab1}
            >
              Organization user
            </li>
            <li
              className={activeTab === "tab2" ? "active" : ""}
              onClick={handleTab2}
            >
              Invited user
            </li>
            <li
              className={activeTab === "tab4" ? "active" : ""}
              onClick={handleTab4}
            >
              Subscription Plan
            </li>
          </ul>
        </Col>
      </Row>
      {loader ? (
        <center>
          <div style={{ padding: "20px", margin: "300px" }}>
            <LoadingSpinner/>
          </div>
        </center>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-12">
              {activeTab === "tab1" ? (
                <>
                <Row className="mb-4">
                {userInfoDetails?.data?.user_role === "owner" && <span className="float-end">
          <Link
            to=""
            onClick={openInviteUser}
            style={{ float: "right" }}
            className="btn btnUser"
          >
            <img
            alt="invite-user"
            title="invite-user"
              className="float-start fs-10 px-0 me-1"
              src={AccountImage}
            ></img>
            <span>Invite user</span>
          </Link>
        </span> }
                </Row>
                  <div className="row">
                    {orgMember
                      ? orgMember.map((member, index) => (
                          <div
                            className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6 items"
                            key={index}  
                          >
                            <div
                              className="card contact-bx item-content"
                              style={
                                member?.id === SelectedUser
                                  ? { border: "1px solid red" }
                                  : {}
                              }
                            >
                              <div className="card-body user-profile pb-0">
                                <div className="d-flex align-items-center">
                                  
                                  <div className="image-bx">
                                    <img
                                      src={
                                        member?.profile_picture === null
                                          ? profilePicture
                                          : member?.profile_picture
                                      }
                                      alt="member-profile"
                                      title="member-profile"
                                      className="rounded-circle"
                                    />
                                    <span className="active"></span>
                                  </div>
                                  <div className="ms-3 text-start" style={{ whiteSpace:"normal", wordBreak:"break-word"}}>
                                    <h4>
                                      {member?.first_name} {member?.last_name}
                                    </h4>
                                    <h4>
                                    {member?.user_role}
                                    </h4>
                                  </div>
                                  {stateData?.org?.baseOrg?.role === "owner" &&
                                    member?.id !==
                                      userInfoDetails?.data?.id && (
                                      <Dropdown className="ms-auto">
                                        <Dropdown.Toggle
                                          variant=""
                                          as="div"
                                          className="btn-link i-false"
                                        >
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <circle
                                              cx="12.4999"
                                              cy="3.5"
                                              r="2.5"
                                              fill="#A5A5A5"
                                            />
                                            <circle
                                              cx="12.4999"
                                              cy="11.5"
                                              r="2.5"
                                              fill="#A5A5A5"
                                            />
                                            <circle
                                              cx="12.4999"
                                              cy="19.5"
                                              r="2.5"
                                              fill="#A5A5A5"
                                            />
                                          </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          alignRight={true}
                                          className="dropdown-menu-right"
                                        >
                                          <Dropdown.Item
                                            className="text-danger"
                                            onClick={(event) => {
                                              selectedMemberForDelete(
                                                event,
                                                member?.id
                                              );
                                              SetOpenDeleteModal(true);
                                            }}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                </div>
                                <div className="py-3">
                                  <ul>
                                    <li>
                                      <Link
                                        to={"/messages"}
                                        state={{ UserId: member?.id }}
                                      >
                                        <img
                                        alt="member-email"
                                        title="member-email"
                                          className="float-start fs-10 px-0 me-3 mt-2"
                                          src={Vector}
                                        />
                                      </Link>
                                      <p style={{whiteSpace:"normal"}}>{member?.email}</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </>
              ) : activeTab === "tab2" ? (
                <>
                   <Row className="mb-4">
                {userInfoDetails?.data?.user_role === "owner" && <span className="float-end">
          <Link
            to=""
            onClick={openInviteUser}
            style={{ float: "right" }}
            className="btn btnUser"
          >
            <img
            alt="invite-user"
            title="invite-user"
              className="float-start fs-10 px-0 me-1"
              src={AccountImage}
            ></img>
            <span>Invite user</span>
          </Link>
        </span> }
                </Row>
                  <div className="row">
                    {orgInvitedMember && orgInvitedMember?.length != 0 ? (
                      orgInvitedMember.map(
                        (member, index) =>
                          member.status === "Invite sent" && (
                            <div
                              className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6 items"
                              key={index}
                            >
                              <div className="card contact-bx item-content">
                                <div className="card-body user-profile pb-0">
                                  <div className="d-flex align-items-center">
                                    <div className="image-bx">
                                      <img
                                        src={
                                          member.user.profile_picture === null
                                            ? profilePicture
                                            : member.user.profile_picture
                                        }
                                        alt="user-profile"
                                        title="user-profile"
                                        className="rounded-circle"
                                      />
                                      <span className="active"></span>
                                    </div>
                                    <div className="ms-3 text-start">
                                      <h4 className="fs-18 font-w600">
                                        {member.user.first_name}{" "}
                                        {member.user.last_name}
                                      </h4>
                                      <h4 className="fs-15 font-w400">
                                        {member.status ===
                                        "Invitation accepted" ? (
                                          <>
                                            <i
                                              class="bi bi-check h6"
                                              style={{
                                                color: "white",
                                                backgroundColor: "#09bd3c",
                                                borderRadius: "50%",
                                                marginRight: "2px",
                                              }}
                                            >
                                              {" "}
                                            </i>{" "}
                                            {member.status}
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="float-start me-2"
                                              src={AccountClock}
                                              alt="member-status"
                                              title="member-status"
                                            />{" "}
                                            {member.status}
                                          </>
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="py-3">
                                    <ul>
                                      <li>
                                        <Link to={"#"}>
                                          <img
                                            className="float-start fs-10 px-0 me-3 mt-2"
                                            src={Vector}
                                            alt="member-email"
                                            title="member-email"
                                          />
                                        </Link>
                                        <p>{member.email}</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div className="text-center p-3">
                        <span
                          style={{ fontSize: "20px", fontFamily: "Open Sans" }}
                        >
                          No Members Found !
                        </span>
                      </div>
                    )}
                  </div>
                </>
              ) : activeTab === "tab3" ? (<>
              <OrganizationList></OrganizationList>
              </>):(<>
              <SubscriptionPlans></SubscriptionPlans>
              </>)}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InviteUsers;
