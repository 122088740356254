import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card } from "react-bootstrap";
import PlannedObjectives from "./PlannedObjectives";
import PlannedTasks from "./PlannedTasks";
import { getMyDay } from "../../../services/OrganizationService";
import PlanMessages from "./PlanMessages";
import PlannedTeams from "./PlannedTeams";
import PlannedStatistics from "./PlannedStatistics";
import star from "../../../images/vector/Vector.jpg";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { getQuickWinsData } from "../../../services/OrganizationService";
import { set } from "date-fns";
import UmaAvtar from "../../../images/profile/Favicon.png";
import { taskFeedback } from "../../../services/TaskService";
import { toast } from "react-toastify";

import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CloseIcon from "@mui/icons-material/Close";
// import Stack from '@mui/material/Stack';
import { Stack } from "@mui/material";
import { BsEmojiLaughingFill } from "react-icons/bs";
import { BsEmojiSmileFill } from "react-icons/bs";
import { BsEmojiNeutralFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { CustomScrollbars } from "../Component/customScrollbarComponent";
import BarChartObjective from "../Chartjs/BarChartObjective";
import Streaks from "../Chartjs/streak";
import MyDayBarChartObjective from "../Chartjs/MyDayBarChartObjective";
import { useDispatch, useSelector } from "react-redux";
import AiCoach from "./AiCoach";
import TaskCard from "./TaskCard";
import { getAllGoalsByOrgID } from "../../../services/GoalService";

export default function Plan({ route, teamId }) {
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [dayDetails, setDayDetails] = useState(null);
  const [quikWinsData, setQuickWinsData] = useState([]);
  const [showingTask, setShowingTask] = useState(true);
  const [goals, setGoals] = useState([])

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  // Handle right-click (or normal click) to open the dropdown
  const handleClick = (event) => {
    event.preventDefault(); // Prevent default right-click menu if needed
    setAnchorEl(event.currentTarget);
  };

  const handleDoneButton = () => {
    getQuickWinsData();
    setShowingTask(false);
  };

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget); // Set the clicked element as the anchor
  };

  const getAllTheGoal = () => {
    const data = getAllGoalsByOrgID(
      userInfoDetails?.data?.accessToken,
      null,
      "",
      "",
      "",
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      3,
      "",
      "",
      ""
    );
    data?.then(async (res) => {
      setGoals(res?.data?.data);
      
    });
    data?.catch(async (err) => {
      console.log("error getAllGoalsByOrgID", err);
    });
  };
  useEffect(() => {
    getAllTheGoal();
  }, []);

  // Close the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getMyPanDetails(
      localStorage.getItem("scope"),
      localStorage.getItem("team_id")
    );
  }, []);

  const getMyPanDetails = async (scope, teamId) => {
    try {
      const data = await getMyDay(userInfoDetails?.data?.accessToken, teamId);
      setDayDetails(data?.data?.data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  function getfeedbackTask(task, selectedTaskStatus) {
    const data = taskFeedback(
      task?.id,
      userInfoDetails?.data?.accessToken,
      true,
      selectedTaskStatus,
      task?.task_effort
    );
    data?.then(async (res) => {
      setAnchorEl(null);
      getQuickWinsDatas();
      setShowingTask(true);
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    });
    data?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              className="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    });
  }

  const getQuickWinsDatas = () => {
    const data = getQuickWinsData(userInfoDetails?.data?.accessToken);
    data.then(async (res) => {
      setQuickWinsData(res?.data?.data);
    });
    data.catch(async (err) => {
      // setSubShowTableSpinner(false);
    });
  };

  useEffect(() => {
    getQuickWinsDatas();
  }, []);

  useEffect(() => {
    getMyPanDetails(
      localStorage.getItem("scope"),
      localStorage.getItem("team_id")
    );
  }, [
    localStorage.getItem("timescope"),
    localStorage.getItem("scope"),
    localStorage.getItem("team_id"),
  ]);

  function formatResponse(response) {
    // Replace headers
    response = response?.replace(/### (.*$)/gim, "<h3>$1</h3>");

    // Replace bold text
    response = response?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Replace inline code
    response = response?.replace(/`([^`]+)`/g, "<code>$1</code>");

    // Replace ordered lists
    response = response?.replace(/(?:\d+\..*\n?)+/g, function (match) {
      let items = match?.trim().split(/\n(?=\d+\.\s)/); // Split based on numbers
      let listItems = items
        ?.map((item) => {
          let number = item?.match(/^\d+/)[0]; // Extract number
          let content = item?.replace(/^\d+\.\s*/, ""); // Remove number
          return `<li><b>${number}</b>. ${content}</li>`;
        })
        .join("");
      return `<ol>${listItems}</ol>`;
    });

    // Replace line breaks with <br>
    response = response?.replace(/\n/g, "<br>");

    return response;
  }

  return (
    <>
    
    <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <TaskCard />
        </Grid>
        {goals?.length == 0 ? "":<Grid item xs={12} md={4}>
          <PlannedObjectives objectiveList={dayDetails?.objective_list} />
        </Grid>}
        <Grid item xs={12} md={4}>
          <AiCoach />
        </Grid>
      </Grid>
      <Grid container spacing={2} >
      <Grid item xs={12} md={12}>
        
        <Card>
          <Card.Body>
            {/* {dayDetails &&  */}
            <PlannedTasks
              taskList={dayDetails?.task_list}
              taskTitle={dayDetails?.task_title}
            />
            {/* } */}
          </Card.Body>
        </Card>
      </Grid>
      </Grid>
      {/* <Row className="mb-4"> */}
      
    </>
  );
}
