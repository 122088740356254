import React,{useState,useEffect} from 'react';
import { RingLoader } from 'react-spinners';
import { useLocation, Link, useNavigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const League = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem('previousLocation') === '/stripe' && sessionStorage.getItem("strategyImported") === "false") {
      toast.info(
        <div className="m-1">
          <h3 >Payment Pending for Strategy </h3>
           
        </div>
        ,
        { toastId: 'strategyPaymentFail' })
        sessionStorage.removeItem('strategyImported')
      }

  }, []);
    return(
        <>
         {localStorage.getItem("previousLocation") !== localStorage.getItem("currentLocation") && 
          <span className="">
            <i
              className="fa fa-reply fs-20"
              title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(localStorage.getItem("previousLocation"))
              }}
            />
          </span>}
        { true ? <center>
            <div style={{padding:'20px', margin:'300px'}} data-testid="loading-spinner">
            <RingLoader color="#ff0000" size='100' loading cssOverride={{opacity: 1}}/>
        </div>
      </center> : <></>
        }
        </>
        )
}

export default League;