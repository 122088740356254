import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSearchResults } from "../../../services/StoreService";
import star from "../../../images/vector/Vector.jpg";
import Ratings from "./Ratings";
import user1 from "../../../images/Strategy/1.jpg";
import LoadingSpinner from "../../components/Component/LoadingSpinner";
import Pagination from "../../components/Pagination/Pagination";
import { Row, Col } from 'react-bootstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getStrategyById } from "../../../services/StoreService";

function SubCategoryCard({ partner }) {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };


 const handleButtonClick = () =>{

  const stores = JSON.parse(localStorage.getItem("store")) || {};
    stores["navSelectedCategory"] = {};
    stores["selectedSubCategories"] = {};
    stores["SelectedStrategy"] = partner;
    localStorage.setItem("store", JSON.stringify(stores));
    console.log(partner,"token gg" )
 
    const data = getStrategyById (
      partner?.id,
    );
    data?.then(async (res) => {
      // const Id = res?.data?.data[0]?.name
      const Id = (res?.data?.data[0]?.name?.replace(/ /g, '-')).toLowerCase();

      navigate(`/product/${Id }`);

    });
    data?.catch(async (err) => {
      console.log("error ");
    });
  };

 


  return (
    <div >
 <div className="col our-strategy-card"
 style={{display:imageLoaded ? '' : 'none'}}
      // onClick={() => {
      //   const stores = JSON.parse(localStorage.getItem("store")) || {};
      //   stores["navSelectedCategory"] = {};
      //   stores["selectedSubCategories"] = {};
      //   stores["SelectedStrategy"] = partner;
      //   localStorage.setItem("store", JSON.stringify(stores));
      //   navigate(`/product-page?name=${partner?.name}`);
      // }}
      onClick={handleButtonClick}
    >
 
      <div className="image-container">
        <img
          alt="Categories-bg-image"
          title="Categories-bg-image"
          src={partner?.image || user1}
          className={`our-strategy-card-image ${imageLoaded ? "visible" : ""}`}
          onLoad={handleImageLoad}
          // style={{height:imageLoaded ? 'auto' : '220px'}}

        />
      

        <div className="overlay"></div>
      </div>
      <h3 className="strategy-card-header">{partner?.name}</h3>
      <div className="strategy-card-quote mb-2" data-testid="partner-card">
  {partner?.category ? partner?.category?.name : ""}
  {partner?.sub_category ? "/ " + partner?.sub_category?.name : ""}
  <br />

  {/* <div className="strategy-card-quote2 mb-2" data-testid="partner-card">
  {partner?.category ? partner?.category?.name : ""}
  {partner?.sub_category ? "/ " + partner?.sub_category?.name : ""}
  <br /> */}

  <div className="d-flex justify-content-between">
    <div className="mb-2">
      <Ratings count={partner?.ratings} data-testid="strategy-rating" />
    </div>

    <a
  href={`/product/${(partner?.name).replace(/ /g, '-').toLowerCase()}`}
  className="figma-btn learn-more-btn"
  style={{
    display: "inline-block", // To make it behave like a button
    textDecoration: "none", // To remove default link underline
    cursor: "pointer",
    // Copy the existing styles from the button
    width: "auto",
    fontWeight: 600,
    borderRadius: "5px",
  }}
  onClick={(e) => {
    e.preventDefault(); // Prevent the default link behavior
    const stores = JSON.parse(localStorage.getItem("store")) || {};
    stores["navSelectedCategory"] = {};
    stores["selectedSubCategories"] = {};
    stores["SelectedStrategy"] = partner;
    localStorage.setItem("store", JSON.stringify(stores));
    // navigate("/product-page");
  }}
>
  Learn more
</a>

  </div>
</div>

{/* </div> */}


     
    </div>
    </div>
   
  );
}

function SubCategories() {
  const navigate = useNavigate();
  const [goToPage, setGoToPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pagination, setPagination] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [loader, setLoader] = useState(true);

  const subStrategy = (selectedCategory) => {
    console.log(selectedCategory,"substrategy api 1 ddddddddddddd")
    const data = getSearchResults(null, selectedCategory, null);
    data?.then(async (res) => {
      console.log("substrategy api 2", res)
      setPageCount(Math.ceil(res?.data?.data?.product.length / 6));
      setPagination(res?.data?.next || res?.data?.previous ? true : false);
      setLoader(false);
      const datas = res?.data?.data?.product || [];
      setPartnersData(datas);
      // setPartnersData(res?.data?.data?.product)
    });
    data?.catch(async (err) => { });
  };

  useEffect(() => {
    console.log("substrategy api 4")
    let category = JSON.parse(localStorage.getItem("store"));
    const selectedCategoryName = category?.selectedSubCategories
      ? category?.selectedSubCategories?.name
      : category?.navSelectedCategory?.name;
      console.log("substrategy api 3",selectedCategoryName)
    if (selectedCategoryName) subStrategy(selectedCategoryName);
  }, [
    JSON.parse(localStorage.getItem("store"))?.selectedSubCategories?.name,
    JSON.parse(localStorage.getItem("store"))?.navSelectedCategory?.name,
  ]);

  useEffect(()=>{
    // const url = window.location.href
    // const regex = /\/category\/(\d+)\//;
    // const match = url.match(regex);
    
    setTimeout(() => {
      let category = JSON.parse(localStorage.getItem("store"));
      const selectedCategoryName = category?.selectedSubCategories
      ? category?.selectedSubCategories?.name
      : category?.navSelectedCategory?.name;
      console.log("substrategy api 3",selectedCategoryName)
    if (selectedCategoryName) subStrategy(selectedCategoryName);
    }, 1000);
   
//     if (match) {
//       var categoryId = match[1];
//       console.log("Category ID:", categoryId);
//       const data = getCategoryById(categoryId);
//       data?.then(((res) => {
//        console.log(res,"LLLLLLLLLLLLLLLLLLLLLLLLLL")
//       //  let stores = JSON.parse(localStorage.getItem("store"));
//       let localStorageValue = {};

// // Set the navSelectedCategory key with the specified value
// localStorageValue['navSelectedCategory'] = res.data.data;

// // Set the entire structure inside the store key
// localStorage.setItem('store', JSON.stringify(localStorageValue));
//         })
//       );
//       data?.catch(((err) => { 
//        console.log(err)
//       }));
//     } else {
//       console.log("No category ID found in the URL");
//     }

  },[window.location.href])
  return (
    <div className="our-strategy">
      {/* <img alt="strategy-bg-image" title="strategy-bg-image" src={star} className="our-services-icon" /> */}
      <LazyLoadImage
                   alt="strategy-bg-image"
                  title="strategy-bg-image" 
                  src={star}
                   className="our-services-icon"
                    />
      <span
        className="our-strategy-text px-3"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/product-page");
        }}
      >
        Our strategies
      </span>

      <div className="our-strategy-card-container" data-testid="sub-categories">
        {loader ? (
          <LoadingSpinner data={{ top: "9rem", left: "48%" }} />
        ) : (
          <Row className="card-container subcategoryCardContainer" >
            {/* {partnersData.map((partner, index) => (
              <SubCategoryCard key={index} partner={partner} />
            ))} */}
            {partnersData
              .slice(goToPage * 6, (goToPage + 1) * 6)
              .map((partner, index) => (
                <Col key={index} md={6} sm={6} lg={6} xl={4}>
                <SubCategoryCard key={index} partner={partner} />
                </Col>
              ))}
            {partnersData.length > 6 && (
              <div
                className="search-product-pagination m-0 p-0"
                data-testid="pagination"
              >
                <Pagination
                  pageCount={Math.ceil(partnersData.length / 9)}
                  pageIndex={goToPage}
                  gotoPage={setGoToPage}
                />
              </div>
            )}
          </Row>

        )}
      </div>
      
    </div>
  );
}

// function SubCategories() {
//   const navigate = useNavigate();
//   const [partnersData, setPartnersData] = useState([]);
//   const [loader, setLoader] = useState(true);

//   const subStrategy = (selectedCategory) => {
//     const data = getSearchResults(null, selectedCategory, null);
//     data?.then(async (res) => {
//       setLoader(false);
//       const datas = res?.data?.data?.product?.slice(0, 3) || [];
//       setPartnersData(datas);
//     });
//     data?.catch(async (err) => { });
//   };

//   useEffect(() => {
//     let category = JSON.parse(localStorage.getItem("store"));
//     const selectedCategoryName = category?.selectedSubCategories
//       ? category?.selectedSubCategories?.name
//       : category?.navSelectedCategory?.name;
//     if (selectedCategoryName) subStrategy(selectedCategoryName);
//   }, [
//     JSON.parse(localStorage.getItem("store"))?.selectedSubCategories?.name,
//     JSON.parse(localStorage.getItem("store"))?.navSelectedCategory?.name,
//   ]);

//   return (
//     <div className="our-strategy">
//       <img alt="background_image" title="background_image" src={star} className="our-services-icon" />
//       <span
//         className="our-strategy-text px-3"
//         style={{ cursor: "pointer" }}
//         onClick={() => {
//           navigate("/product-page");
//         }}
//       >
//         Our strategies
//       </span>

//       <div className="our-strategy-card-container" data-testid="sub-categories">
//         {loader ? (
//           <LoadingSpinner data={{ top: "9rem", left: "48%" }} />
//         ) : (
//           partnersData.map((partner, index) => (
//             <SubCategoryCard key={index} partner={partner} />
//           ))
//         )}
//       </div>
//     </div>
//   );
// }

export default SubCategories;
