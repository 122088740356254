import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getHomeGraph } from "../../../services/GoalService";
import { Row, Col, Card } from "react-bootstrap";
import RedialApex from "./RedialApex";
import vector from "../../../images/svg/Vector.svg";
import vectorrs from "../../../images/svg/vectorrs.svg"
import Donut from "./Donut";
import Pagination from "../../components/Pagination/Pagination";
import {  ButtonGroup,Dropdown } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class CompleteChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      startDate: "",
      endDate: "",
      seriesData: [],
      lableee: [],
      pagination: false,
      pageCount: 1,
      goToPage: null,
      circularLoader: false,
    };
    this.userDetail = localStorage.getItem("userDetails");
    this.userInfoDetails = JSON.parse(this.userDetail);
  }
  componentDidMount() {
    console.log("getgraph")
    this.getGraph();
  }

  

  getGraph = (goPage = 0) => {
    this.setState(() => ({
      circularLoader : true
    }))
    console.log(this.props?.trackScope, "LLLLLLTRACKSCOPEinCompletechartsssss", this.props?.trackScope)
    if(this.props?.goal_id || this.props?.trackScope != "objective"){
    const data = getHomeGraph("", this.userInfoDetails?.data?.accessToken, goPage,this.props?.goal_id, this.props?.trackScope, this.props?.team_id);
    data?.then(async (res) => {
      console.log(res,"hhshshjsjjshjdhjdjjdhjjdhjjd")
      if (res?.data?.data[0]?.completion_percent === 0) {
        res?.data?.data?.shift();
      }
      this.setState(() => ({
        circularLoader:false,
        goToPage: goPage,
        pageCount: Math.ceil(res?.data?.count / 10),
        pagination: res?.data?.next || res?.data?.previous ? true : false,
        labels: res?.data?.data,
        seriesData: [
          res?.data?.data[res?.data?.data?.length - 1]?.hit_percent,
          res?.data?.data[res?.data?.data?.length - 1]?.beat_percent,
          res?.data?.data[res?.data?.data?.length - 1]?.miss_percent,
        ],
        lableee: [
          "Hit-" +
          String(res?.data?.data[res?.data?.data?.length - 1]?.hit_percent),
          "Beat-" +
          String(res?.data?.data[res?.data?.data?.length - 1]?.beat_percent),
          "Miss-" +
          String(res?.data?.data[res?.data?.data?.length - 1]?.miss_percent),
        ],
      }));

    });
    data?.catch(async (err) => {
      console.log("error getHomeGraph");
      this.setState(() => ({
       
        seriesData: [],
        lableee: [],
      }));
    });
  }
  }

  componentWillReceiveProps(newProps) {
    console.log('componentWillReceivePropsnewProps',newProps?.scope)
    if (newProps?.scope === "custom") {
      console.log('componentWillReceiveProps 1')
      setTimeout(() => {
        this.getGraph();
      }, 1000);
    }
    else {
      console.log('componentWillReceiveProps 2')
      setTimeout(() => {
        // if (this.state?.goToPage === 0) {
          this.getGraph(this.state?.goToPage);
        // }
      }, 1000);

    }
    this.setState({ labels: newProps?.graphData });
    this.setState({
      ...this.state,
      seriesData: [
        newProps?.graphData[newProps?.graphData?.length - 1]?.hit_percent,
        newProps.graphData[newProps?.graphData?.length - 1]?.beat_percent,
        newProps?.graphData[newProps?.graphData?.length - 1]?.miss_percent,
      ],
      lableee: [
        "Hit-" +
        String(
          newProps?.graphData[newProps?.graphData?.length - 1]?.hit_percent
        ),
        "Beat-" +
        String(
          newProps?.graphData[newProps?.graphData?.length - 1]?.beat_percent
        ),
        "Miss-" +
        String(
          newProps?.graphData[newProps?.graphData?.length - 1]?.miss_percent
        ),
      ],
    });
  }

 


  ChangePage = (pageNo) => {
    this.setState(() => ({ ...this.state, goToPage: pageNo }));
    this.getGraph(pageNo);
  }

  render() {
    const graphData = this.state?.labels;
    console.log("graphDataInCompleteChart how i do", graphData)
    console.log(graphData , graphData[graphData?.length - 2]
      ?.completion_percent -
      graphData[graphData?.length - 1]
        ?.completion_percent ,"graphData")

        const color = graphData[graphData?.length - 2]?.completion_percent >
        graphData[graphData?.length - 1]?.completion_percent ? "#FF0000" : "#00FF00"; 
       
    const labels = [];
    const values = [];
    graphData?.forEach((element) => {
      labels.push("Week " + element?.week);
      values.push(element?.completion_percent);
    });

    const screenWidth = window?.innerWidth;
    const barThickness = screenWidth >= 1020 ? 70 : 30;  
    const data = {
      defaultFontFamily: "Open Sans",
      labels: labels,
      
      datasets: [
        {
          label: "Completion percent",
          data: values,
          borderColor: "#4DBFDB",
          borderWidth: "0",
          backgroundColor: "#4DBFDB",
          hoverBackgroundColor: "#4DBFDB",
          barThickness: barThickness,
        },
      ],
    };
    const options = {
      plugins: {
        legend: false,
      },
      scales: {
        y: {
          min: 0,
          max: 100,
        },

        x: {
          // Change here
          barPercentage: 1,
        },
      },
      responsive: true,
    };
    return (
      <>
        <Col xl={4} lg={4}>
        
      <Card data-testid="line-charts" style={{minHeight:"350px"}}>
      { this.state?.circularLoader ?<>
            <div className="p-0 m-0 text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight:"50%" }}>
          
          <CircularProgress  />
          
          </div></>
          
        :<>
        <Card.Header>
          <h4 className="card-title">% Complete</h4>
          <div className="text-end">
                {/* <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle variant="transparent" id="dropdown-split-basic">
                    <i className="bi bi-list fs-20" style={{ width: "20px", height: "20px", color: "#6e8192" }}></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item 
                    onClick={handleDownloadCSV
                    }>
                      Download CSV
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDownloadPNG}>
                      Download PNG
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDownloadSVG}>
                      Download SVG
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
        </Card.Header>
        <Card.Body>
          {/* {loading && (
            <div
              style={{ position: "relative", top: "50%" }}
              className="m-0 p-0 text-center"
              data-testid="loading-spinner"
            >
              <LoadingSpinner />
            </div>
          )} */}
       
      

<div className="d-flex p-0 justify-content-between m-0">
                      <div className="m-0 p-0 mt-1">
                        <h4 className="fs-18 font-w600 mb-1 text-nowrap filterText ms-3 " >
                          Complete
                        </h4>
                        <div className="d-flex align-items-center">
                          <div id="redial" className="m-0 p-0">
                            <RedialApex
                              value={
                                graphData && graphData?.length >= 1
                                ? graphData[graphData?.length - 1]?.completion_percent || 0
                                : 0
                              }
                              color={"#356FD4"}
                            />
                          </div>
                          <span className="d-block">
                            <h2 className="mb-0">
                              {" "}
                             { graphData && graphData?.length >= 1
                                ? graphData[graphData?.length - 1]?.completion_percent || 0
                                : 0}
                              %
                            </h2>
                            <small className="d-block fs-16 font-w400  mt-2" style={{color}} >
                           { graphData[graphData?.length - 2]
                                    ?.completion_percent >
                                    graphData[graphData?.length - 1]
                                      ?.completion_percent    ?(<img
                                        className="float-start px-1"
                                        src={vector}
                                        alt="vector"
                                        title="vector"
                                        
                                      />):(  <img
                                className="float-start px-2"
                                src={vectorrs}
                                alt="vector"
                                title="vector"/>) }
                              <span className="me-2 text-nowrap">
                                {graphData[graphData?.length - 2]
                                  ?.completion_percent ==
                                  graphData[graphData?.length - 1]
                                    ?.completion_percent
                                  ? ""
                                  : graphData[graphData?.length - 2]
                                    ?.completion_percent >
                                    graphData[graphData?.length - 1]
                                      ?.completion_percent
                                    ? ""
                                    : "+"}
                                {graphData[graphData?.length - 1]
                                  ?.completion_percent -
                                  graphData[graphData?.length - 2]
                                    ?.completion_percent || 0}
                                %
                              </span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
         
         
        </Card.Body>
        <Card.Footer  style={{border:"none"}}>
          <p style={{color:"white"}}> 
            
          </p>
        </Card.Footer>
        </>}
      </Card>
      
    </Col>
      </>
    );
  }
}

export default CompleteChart;


