import Plan from "./Plan"
import { useState } from "react";

const MyDay = () => {
   
    return (<>
        <Plan route={"my-day"} />
       
    </>)

}
export default MyDay


// import Plan from "./Plan";
// import { useState, useEffect } from "react";

// const MyDay = () => {
//     const [scope, setScope] = useState(localStorage.getItem("scope"));
//     const teamId = localStorage.getItem("team_id");

//     console.log(scope, "POPHOHO::::::::::::::::::::::::::::::::pop")

//     useEffect(() => {
//         setScope(localStorage.getItem("scope"))
//     }, [localStorage.getItem("scope")]);

//     return (
//         <>
//             {scope === "team" ? (
//                 <Plan route={"team"} teamId={teamId} />
//             ) : (
//                 <Plan route={scope} />
//             )}
//         </>
//     );
// };

// export default MyDay;
