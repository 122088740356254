import React, {useEffect} from "react";
import EventCalendar from "./EventCalendar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Calendar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('previousLocation') === '/stripe' && sessionStorage.getItem("strategyImported") === "false") {
      toast.info(
        <div className="m-1">
          <h3 >Payment Pending for Strategy </h3>
           
        </div>
        ,
        { toastId: 'strategyPaymentFail' })
        sessionStorage.removeItem('strategyImported')
      }

  }, []);

  return (
    <div className="mb-2">
      {localStorage.getItem("previousLocation") !== localStorage.getItem("currentLocation") && 
      <span className="">
        <i
          className="fa fa-reply fs-20"
          title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(localStorage.getItem("previousLocation"))
          }}
        />
      </span>}
      <EventCalendar nav={navigate}/>
    </div>
  );
};

export default Calendar;
