import React from "react";
import StoreHeaders from "./StoreHeaders";
import bgLogo from "../../../images/bgLogo.png";
import "../../../css/figma-style.css";
import { useNavigate } from "react-router";

function TermsAndConditions() {
  const navigate = useNavigate();
  return (
    <>
      <div className="p-0 m-0 w-100">
        <div className="p-0 m-0">
          <StoreHeaders />
        </div>
        <div
          className="bg-white m-0 p-0"
          style={{
            top: "70px",
            position: "absolute",
            backgroundColor: "#ffffff !important",
          }}
        >
          <div className="w-100 bg-white pb-5 mb-5">
            <div
              className="bg-white terms-margins"
              style={{
                top: "70px",
                position: "relative",
                marginLeft: "138px",
                marginRight: "130px",
                backgroundColor: "#ffffff !important",
              }}
            >
              <div
                className="mb-4"
                style={{ borderBottom: "1px solid #e2dfdf" }}
              >
                <span className="h1 me-2 terms-title">
                  <strong>Terms and conditions</strong>
                </span>
                <p className="fs-16 mt-0 ms-2">
                  Last updated on Sept-15-2023.
                </p>
              </div>

              <h2 className="fs-22 mt-4">
                <strong>1. Introduction</strong>
              </h2>
              <p className="fs-18 descForMobile">
                Welcome to Plansom.com (“Plansom”). These Terms and Conditions (“Terms”) govern your use of our Software-as-a-Service ("Services") platform and provide important information about your rights and obligations.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>2. Acceptance of Terms</strong>
              </h2>
              <p className="fs-18 descForMobile">
                By accessing, creating an account, or using theServices provided by Plansom, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use the Services.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>3.1.Subscription plans</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Plansom offers various subscription plans. Details of these plans, including pricing, features, and billing cycles, can be found on our website.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>3.2.Payment</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                By subscribing to our Services, you agree to pay the applicable fees as specified in your chosen subscription plan. Payment terms and methods will be outlined on our website.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>3.3. Billing and renewal</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Your subscription will automatically renew at the end of each billing cycle unless you cancel it. You are responsible for keeping your billing and contact information up to date.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>3.4 Monthly subscriptions</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Subscribers on a monthly plan will be billed on a recurring monthly basis. The subscription may be canceled at any time. Upon cancellation, you will retain access to the Services until the end of your current billing cycle. Organizations that have multiple users will be billed monthly for all users on the same day. All user accounts will be billed, regardless of if a particular user has actively accessed an account during the billing cycle.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>3.5 Annual subscriptions</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Subscribers who choose an annual subscription will be billed for the entire year upfront. If you cancel your annual subscription, you will continue to have access to the Services until the end of your prepaid year, after which the subscription will not renew. Organizations that have multiple users will be billed annually for all users on the same day. All user accounts will be billed, regardless of if a particular user has actively accessed an account during the billing cycle.
              </p>

              <h2 className="fs-22 mt-4">
                <strong>4. No refunds</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Payments made are non-refundable. Whether you use the Services or not, once a payment is made, you are not entitled to a refund. This applies to both monthly and annual subscription plans.
              </p>

              <h2 className="fs-22 mt-4">
                <strong>5. Service availability</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                While we aim to ensure the Services are always available, there will be times when the Services may be interrupted, e.g., for maintenance or upgrades. We will make every effort to communicate any anticipated downtime. Services interruption will not be compensated for.
              </p>

              <h2 className="fs-22 mt-4">
                <strong>6. Use of the services</strong>
              </h2>
              <h2 className="fs-22 mt-4">
                <strong>6.1.Eligibility</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                You must be at least 18 years old to use the Services. By using the Services, you represent and warrant that you meet this eligibility requirement.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>6.2.User account</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                To access and use the Services, you are required to create a user account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account. You are responsible for backing up your own data and Plansom is not responsible for any data loss. Notify Plansom immediately if you detect any unauthorized use of your account.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>6.3.Acceptable use</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                You agree not to use the Services for any unlawful or prohibited purposes. This includes, but is not limited to, activities that violate applicable laws, infringe upon the rights of others, or harm the security or integrity of the Services.
              </p>

              <h2 className="fs-22 mt-4">
                <strong>7. Termination</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                We reserve the right to terminate or suspend your account at any time, without notice, for any reason, including but not limited to a breach of these Terms and Conditions. If we do so without any breach on your part, we will provide a pro-rata refund for any unused portion of the month or year, as applicable.
              </p>

              <h2 className="fs-22 mt-4">
                <strong>8. Data privacy</strong>
              </h2>
              <p className="fs-18 descForMobile mb-0">
                Your use of the Services is governed by our Privacy Policy. By using the Services, you consent to the collection and use of your information as described in the Privacy Policy.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>9. Intellectual Property</strong>
              </h2>
              <h2 className="fs-22 mt-4">
                <strong>9.1.Ownership</strong>
              </h2>
              <p className="fs-18 descForMobile mb-0 ">
                All intellectual property rights, including trademarks, copyrights, patents, and proprietary algorithms, related to the Services, are the property of Plansom. Any data generated as a result of your use remains your intellectual property. However, Plansom retains the right to utilize aggregated and anonymized usage data to improve our Services, train our AI models, and for benchmarking purposes. This data will be stripped of personally identifiable information and will not be used to identify individual users.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>9.2.License</strong>
              </h2>
              <p className="fs-18 descForMobile mb-0 ">
                Subject to these Terms and Conditions, Plansom grants you a limited, non-exclusive, non-transferable license to use the Services for your internal business purposes. This license does not extend to the algorithms, methods, or models used by Plansom in the provision of the Services.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>10. Limitation of Liability</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                To the fullest extent permitted by applicable law, Plansom, its affiliates, directors, employees, or its licensors shall not be liable for (a) any indirect, incidental, exemplary punitive, or consequential damages of any kind whatsoever; (b) loss of profits, revenue, data, use, goodwill, or other intangible losses; (c) damages relating to your access to, use of, or inability to access or use the Services; (d) damages relating to any conduct or content of any third party or user using the Services, including without limitation, defamatory, offensive or illegal conduct or content; and/or (e) damages in any manner relating to any content. This limitation applies to all claims, whether based on warranty, contract, tort, or any other legal theory, whether or not Plansom has been informed of the possibility of such damage, and further where a remedy set forth herein is found to have failed its essential purpose.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>11. Disclaimer</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                The Service is provided "as-is" and "as available" without any warranties of any kind, either express or implied.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>12. Indemnification</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                You agree to defend, indemnify, and hold harmless Plansom, its officers, directors, employees, and agents from any claims or demands arising from your use of the Service or violation of these Terms.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>13. Changes to the Terms</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Plansom may update these Terms from time to time. Any changes will be effective immediately upon posting the updated Terms on our website. Your continued use of the Services after the posting of changes constitutes your acceptance of the modified terms.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>14. Governing law and dispute resolution</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                These Terms shall be governed by and construed in accordance with the laws of Hong Kong. Any dispute, controversy, difference or claim arising out of or relating to this contract, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre under the Hong Kong International Arbitration Centre Administered Arbitration rules in force when the Notice of Arbitration is submitted. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be one. The arbitration proceedings shall be conducted in English.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>15. Waiver and severability</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                If any provision is deemed invalid, the rest remain in effect. Any failure to enforce any provision of these terms is not a waiver of such provision.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>16. Entire agreement</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                These Terms, along with the Privacy Policy and the Cookie Policy, constitute the entire agreement between you and Plansom.
              </p>
              <h2 className="fs-22 mt-4">
                <strong>17. Contact information</strong>
              </h2>
              <p className=" fs-18 descForMobile mb-0">
                Plansom.com, is operated by CDSA Limited, a limited company organized under the laws of Hong Kong. Throughout the site, the terms “Plansom”, "we", "our", and "us" refer to CDSA Limited, trading as Plansom.com.
              </p>
              <p className=" fs-18 descForMobile mb-0">
                If you have any questions or concerns regarding these Terms and Conditions, please contact us at support [at] plansom [dot] com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
