import React, { createContext, useState } from "react";

const ChatbotContext = createContext();

const ChatbotProvider = ({ children }) => {
  const [chatbotShow, setChatbotShow] = useState(false);
  const updateState = (newValue) => {
    console.log(newValue, "newValue");
    setChatbotShow(newValue);
  };

  const contextValue = {
    chatbotShow,
    updateState,
  };

  return (
    <ChatbotContext.Provider value={contextValue}>
      {children}
    </ChatbotContext.Provider>
  );
};

export { ChatbotProvider, ChatbotContext };
