import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getSearchResults } from "../../../services/StoreService";
import { Link, useNavigate } from "react-router-dom";
import Ratings from "./Ratings";
import CircularRatings from "./CircularRatings";
import LoadingSpinner from "../../components/Component/LoadingSpinner";

function SearchModal(props) {
  const navigate = useNavigate();
  const [searchKey, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [scope, setScope] = useState("all");
  const [modal, setModal] = useState(props.modal);
  const [selectedCategories, setSelectedCategories] = useState({
    subCategories: [],
    price: {},
    type: [],
  });

  const [VideoInput, setVideoinput] = useState(false);
  const [DescriptionInput, setDescriptionInput] = useState(false);
  const [VideoInputText, setVideoinputText] = useState();
  const [DescriptionInputText, setDescriptionInputText] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialFilter = {
    category: [],
    sub_category: [],
    type: [],
    price: [],
    partners: [],
    review: [],
    features: [{ Include_videos: "", Include_description: "" }],
    effort: [],
    suitable_for: [],
    partner_support: [],
  };
  const [selectedFilter, setSelectedFilter] = useState(initialFilter);
  const initialData = [
    {
      title: "Category",
      value: "category",
      count: 0,
    },
    {
      title: "Sub-category",
      value: "sub_category",
      count: 0,
    },
    {
      title: "Type",
      value: "type",
      count: 0,
    },
    {
      title: "Price",
      value: "price",
      count: 0,
    },
    {
      title: "Partner",
      value: "partners",
      count: 0,
    },
    {
      title: "Review",
      value: "review",
      count: 0,
    },
    {
      title: "Features",
      value: "features",
      count: 0,
    },
    {
      title: "Effort",
      value: "effort",
      count: 0,
    },
    {
      title: "Suitable for",
      value: "header-searchboxsuitable_for",
      count: 0,
    },
    {
      title: "Partner support",
      value: "partner_support",
      count: 0,
    },
  ];
  const [accordionData, setAccordianData] = useState(initialData);
  const [min, setMin] = useState(selectedFilter?.price[0]?.min);
  const [max, setMax] = useState(selectedFilter?.price[0]?.max);
  useEffect(() => {
    setSearchResults(searchResults);
  }, [searchResults]);

  useEffect(() => {
    setSelectedFilter({
      ...selectedFilter,
      features: [
        {
          Include_videos: VideoInputText || "",
          Include_description: DescriptionInputText || "",
        },
      ],
    });
  }, [DescriptionInputText, VideoInputText]);

  useEffect(() => {
    for (const key in selectedFilter) {
      if (selectedFilter.hasOwnProperty(key)) {
        if (selectedFilter) {
          accordionData?.forEach((element) => {
            if (element?.count >= 1) {
              setIsFilter(true);
              setIsClearFilter(true);
            }
          });
        }
        const indexToReplace = accordionData.findIndex(
          (item) => item?.value === key
        );

        let updatedObject;
        if (indexToReplace !== -1) {
          if (key === "features") {
            updatedObject = {
              title: key
                ?.split("_")
                ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                ?.join(" "),
              value: key,
              count:
                selectedFilter[key][0]?.Include_videos?.length >= 1 &&
                  selectedFilter[key][0]?.Include_description?.length >= 1
                  ? 2
                  : selectedFilter[key][0]?.Include_videos?.length >= 1 ||
                    selectedFilter[key][0]?.Include_description?.length >= 1
                    ? 1
                    : 0,
            };
          } else {
            updatedObject = {
              title: key
                ?.split("_")
                ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                ?.join(" "),
              value: key,
              count: selectedFilter[key]?.length,
            };
          }
          accordionData[indexToReplace] = updatedObject;
        }
      }
    }
  }, [selectedFilter]);

  useEffect(() => {
    setSelectedCategories(selectedCategories);
  }, [selectedCategories?.subCategories, selectedCategories]);

  useEffect(() => {
    setSelectedCategories(selectedCategories);
  }, [selectedCategories?.price]);

  useEffect(() => {
    setModal(modal);
    if (!modal) {
      props.setmodal(modal);
    }
  }, [modal]);

  useEffect(() => {
    setScope(scope);
  }, [scope]);

  useEffect(() => {
    setSelectedFilter(() => selectedFilter);
    if (
      selectedFilter?.category?.length >= 1 ||
      selectedFilter?.sub_category?.length >= 1 ||
      selectedFilter.type?.length >= 1 ||
      selectedFilter?.price?.length >= 1 ||
      selectedFilter?.partners?.length >= 1 ||
      selectedFilter?.review?.length >= 1 ||
      selectedFilter?.features?.length >= 1 ||
      selectedFilter?.effort?.length >= 1 ||
      selectedFilter?.suitable_for?.length >= 1 ||
      selectedFilter?.partner_support?.length >= 1
    ) {
      setIsFilter(true);
      setIsClearFilter(true);
    } else {
      setIsFilter(false);
      setIsClearFilter(false);
    }
  }, [selectedFilter]);

  useEffect(() => {
    setSearchValue(searchKey);
    if (searchKey) handleSearchResults(scope, searchKey);
  }, [searchKey]);

  const handleSearchResults = (scope, value) => {
    setLoading(true);
    const data = getSearchResults(value, scope, selectedFilter);
    data?.then(async (res) => {
      if (res.data?.code === 200) {
        setLoading(false);
        setSearchResults(res?.data?.data);
      } else {
        setSearchResults([]);
      }
    });
    data?.catch(async (err) => {
      console.log("error user org list", err);
    });
  };

  const handleBlur = (event) => {
    setSearchValue(event.target.value);
  };

  const handleFocus = (event) => {
    event.target.value = searchKey;
  };

  return (
    <Modal className="fade customSearchModal" show={modal} id="custom-search-modal-store"  data-testid="search-modal">
      <Modal.Header className="pt-4 pb-4 mb-0" data-testid="header" style={{borderBottom:"0px"}}>
        <div className="search m-0 mb-2" style={{ width: "100%" }}>
          <input
            name="search_box"
            type="text" //id="form1"
            className="searchTerm px-2"
            style={{ backgroundColor: "#ffffff", borderColor: "#c7c5c5" }}
            placeholder="Search"
            value={searchKey}
            onChange={(event) => setSearchValue(event.target.value)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autoFocus
            data-testid="searchInput"
            autocomplete="off"
          />
          <button
            type="submit"
            data-testid="searchButton"
            className="searchButton"
            style={{ backgroundColor: "#ffffff", borderColor: "#c7c5c5" }}
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div className="text-end float-end m-0 p-0 ms-3">
          <button
            variant=""
            data-testid="btnclose"
            className="btn-close  float-end align-center btn-danger m-1 p-2"
            onClick={() => {
              props?.onClose();
            }}
          ></button>
        </div>
      </Modal.Header>
{searchKey && <Modal.Body className="my-0 py-0" data-testid="body">
        <PerfectScrollbar
          id="chartBox"
          data-testid="chartBox"
          className="widget-media dlab-scroll m-0 p-0"
          style={{ maxHeight: "80vh", minHeight: "70vh" }}
        >
          {loading ? (
            <LoadingSpinner data={{ top: "1rem" }} />
          ) : (
            ""
          )}
          {searchKey ? (
            <Row className="row m-0 p-0 mb-3">
              <Col className="" sm={12} md={3} lg={3} xl={3}
                style={{
                  // width: "15vw",
                  // margin: "1px",
                  border: "1px solid #dedbdb",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.16)",
                  
                }}
              >
                {(isFilter || isClearFilter) && (
                  <div
                    className=" d-flex row m-0 p-0 text-center my-3"
                    style={{ position: "relative", gap: "1%" }}
                  >
                    {isFilter && (
                      <Link
                        title="Click to apply filter for products"
                        className="col nav-link text-center p-0 py-2 text-wrap m-2"
                        href="#"
                        style={{
                          color: "#4cb5e3",
                          fontWeight: 600,
                          fontSize: "14px",
                          border: "1px solid #dedbdb",
                          borderRadius: "8px",
                          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.16)",
                        }}
                        onClick={(e) => {
                          handleSearchResults("all", searchKey);
                          setIsFilter(false);
                        }}
                      >
                        Apply
                      </Link>
                    )}
                    {isClearFilter && (
                      <Link
                        title="Click to remove applied filter for products"
                        className="col nav-link text-center p-0 py-2 text-wrap m-2"
                        href="#"
                        style={{
                          color: "#ec4a4a",
                          fontWeight: 600,
                          fontSize: "14px",
                          border: "1px solid #dedbdb",
                          borderRadius: "8px",
                          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.16)",
                        }}
                        onClick={(e) => {
                          setAccordianData(initialData);
                          handleSearchResults("all", searchKey);
                          setSelectedFilter(initialFilter);
                          setIsClearFilter(false);
                          setIsFilter(false);
                        }}
                      >
                        Clear All
                      </Link>
                    )}
                  </div>
                )}
                <PerfectScrollbar
                  id="chartBoxs"
                  className="widget-media dlab-scroll m-0 p-0 my-2"
                  style={{ maxHeight: "auto", minHeight: "auto" }}
                >
                  <>
                    <div className="accordion-content my-2 ">
                      {selectedFilter?.category?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-3"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.category?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Category</span>
                      {searchResults?.category?.length ? (
                        <PerfectScrollbar
                          id="chartBoxs"
                          className="widget-media dlab-scroll m-0 p-0"
                          style={{ maxHeight: "25vh", minHeight: "5vh" }}
                        >
                          {searchResults?.category?.map((element, index) => (
                            <div className="d-flex">
                              <input
                                type="checkbox"
                                name="Type 1"
                                value="Beginner"
                                id={element}
                                style={{ cursor: "pointer" }}
                                checked={selectedFilter?.category.includes(
                                  element
                                )}
                                className="mx-2 mb-2"
                                onClick={(e) => {
                                  if (e?.target?.checked) {
                                    let data = selectedFilter?.category;
                                    data.push(element);
                                    setSelectedFilter(() => ({
                                      ...selectedFilter,
                                      category: data,
                                    }));
                                  } else {
                                    let data = selectedFilter?.category;
                                    setSelectedFilter(() => ({
                                      ...selectedFilter,
                                      category: data.filter(
                                        (item) => item !== element
                                      ),
                                    }));
                                  }
                                }}
                              />
                              <label
                                htmlFor={element}
                                className="p fs-14"
                                label="Type 1"
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {element}
                              </label>
                              <br />
                            </div>
                          ))}
                        </PerfectScrollbar>
                      ) : (
                        <p className="p fs-14 my-3 text-center">
                          No filters available!{" "}
                        </p>
                      )}
                    </div>

                    {/* <div className="accordion-content p-0 m-0 my-2 ">
                      {selectedFilter?.sub_category?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-3"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.sub_category?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Sub-Categories</span>
                      {searchResults?.sub_category?.length ? (
                        <PerfectScrollbar
                          id="chartBoxs"
                          className="widget-media dlab-scroll m-0 p-0"
                          style={{ maxHeight: "25vh", minHeight: "5vh" }}
                        >
                          {searchResults?.sub_category?.map(
                            (element, index) => (
                              <div className="d-flex">
                                <input
                                  type="checkbox"
                                  name="Type 1"
                                  value="Beginner"
                                  id={element}
                                  style={{ cursor: "pointer" }}
                                  checked={selectedFilter?.sub_category.includes(
                                    element
                                  )}
                                  className="mx-2 mb-2"
                                  onClick={(e) => {
                                    if (e?.target?.checked) {
                                      let data = selectedFilter?.sub_category;
                                      data.push(element);
                                      setSelectedFilter(() => ({
                                        ...selectedFilter,
                                        sub_category: data,
                                      }));
                                    } else {
                                      let data = selectedFilter?.sub_category;
                                      setSelectedFilter(() => ({
                                        ...selectedFilter,
                                        sub_category: data.filter(
                                          (item) => item !== element
                                        ),
                                      }));
                                    }
                                  }}
                                />
                                <label
                                  htmlFor={element}
                                  className="p fs-14"
                                  style={{ cursor: "pointer" }}
                                >
                                  {" "}
                                  {element}
                                </label>
                                <br />
                              </div>
                            )
                          )}
                        </PerfectScrollbar>
                      ) : (
                        <p className="p fs-14 my-3 text-center">
                          No filters available!{" "}
                        </p>
                      )}
                    </div> */}
                    {/* <div
                      className="accordion-content p-0 m-0 my-2"
                      style={{ cursor: "pointer" }}
                    >
                      {selectedFilter?.type?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.type?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Type</span>

                      {searchResults?.type?.length ? (
                        searchResults?.type?.map((ele, index) => (
                          <div className="d-flex" key={index}>
                            <input
                              type="checkbox"
                              name="Type 1"
                              style={{ cursor: "pointer" }}
                              defaultValue={ele}
                              id={ele}
                              checked={selectedFilter?.type.includes(ele)}
                              className="mx-2 mb-2"
                              onClick={(e) => {
                                if (e?.target?.checked) {
                                  let data = selectedFilter?.type;
                                  data.push(ele);
                                  setSelectedFilter(() => ({
                                    ...selectedFilter,
                                    type: data,
                                  }));
                                } else {
                                  let data = selectedFilter?.type;
                                  setSelectedFilter(() => ({
                                    ...selectedFilter,
                                    type: data.filter((item) => item !== ele),
                                  }));
                                }
                              }}
                            />
                            <label htmlFor={ele} style={{ cursor: "pointer" }}>
                              {" "}
                              {ele?.charAt(0).toUpperCase() + ele.slice(1)}
                            </label>
                            <br />
                          </div>
                        ))
                      ) : (
                        <p className="p fs-14 my-3 text-center">
                          No filters available!{" "}
                        </p>
                      )}
                    </div> */}

                    {/* <div className="accordion-content ">
                      {selectedFilter?.suitable_for?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.suitable_for?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Suitable for</span>

                      {searchResults?.suitable_for?.length ? (
                        searchResults?.suitable_for?.map((element, index) => (
                          <div className="d-flex" key={index}>
                            <input
                              type="checkbox"
                              name="Type 1"
                              value="Premium"
                              id={element}
                              style={{ cursor: "pointer" }}
                              checked={selectedFilter?.suitable_for.includes(
                                element
                              )}
                              className="mx-2 mb-2"
                              onClick={(e) => {
                                if (e?.target?.checked) {
                                  let data = selectedFilter?.suitable_for;
                                  data.push(element);
                                  setSelectedFilter(() => ({
                                    ...selectedFilter,
                                    suitable_for: data,
                                  }));
                                } else {
                                  let data = selectedFilter?.suitable_for;
                                  setSelectedFilter(() => ({
                                    ...selectedFilter,
                                    suitable_for: data.filter(
                                      (item) => item !== element
                                    ),
                                  }));
                                }
                              }}
                            />
                            <label
                              htmlFor={element}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              {element?.charAt(0).toUpperCase() +
                                element.slice(1)}
                            </label>
                            <br />
                          </div>
                        ))
                      ) : (
                        <p className="p fs-14 my-3 text-center">
                          No filters available!{" "}
                        </p>
                      )}
                    </div> */}

                    {/* <div className="accordion-content ">
                      {selectedFilter?.review?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.review?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Ratings</span>
                      {[5, 4, 3, 2, 1]?.map((element, index) => (
                        <div className="d-flex" key={index}>
                          <input
                            type="checkbox"
                            name="Type 1"
                            value="5"
                            id={element}
                            style={{ cursor: "pointer" }}
                            checked={selectedFilter?.review.includes(element)}
                            className="mx-2 mb-2"
                            onClick={(e) => {
                              if (e?.target?.checked) {
                                let data = selectedFilter?.review;
                                data.push(element);
                                setSelectedFilter(() => ({
                                  ...selectedFilter,
                                  review: data,
                                }));
                              } else {
                                let data = selectedFilter?.review;
                                setSelectedFilter(() => ({
                                  ...selectedFilter,
                                  review: data.filter(
                                    (item) => item !== element
                                  ),
                                }));
                              }
                            }}
                          />
                          <Ratings count={element} />
                          <br />
                        </div>
                      ))}
                    </div> */}

                    {/* <div className="accordion-content ">
                      {selectedFilter?.partner_support?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.partner_support?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Partner support</span>

                      {searchResults?.partner_support?.length ? (
                        searchResults?.partner_support?.map(
                          (element, index) => (
                            <div className="d-flex" key={index}>
                              <input
                                type="checkbox"
                                name="Type 1"
                                value="5"
                                id={element}
                                style={{ cursor: "pointer" }}
                                checked={selectedFilter?.partner_support.includes(
                                  element
                                )}
                                className="mx-2 mb-2"
                                onClick={(e) => {
                                  if (e?.target?.checked) {
                                    let data = selectedFilter?.partner_support;
                                    data.push(element);
                                    setSelectedFilter(() => ({
                                      ...selectedFilter,
                                      partner_support: data,
                                    }));
                                  } else {
                                    let data = selectedFilter?.partner_support;
                                    setSelectedFilter(() => ({
                                      ...selectedFilter,
                                      partner_support: data.filter(
                                        (item) => item !== element
                                      ),
                                    }));
                                  }
                                }}
                              />
                              <label
                                htmlFor={element}
                                style={{ cursor: "pointer" }}
                              >
                                {element?.charAt(0).toUpperCase() +
                                  element.replace(/-/, " ").slice(1)}
                              </label>
                              <br />
                            </div>
                          )
                        )
                      ) : (
                        <p className="p fs-14 my-3 text-center">
                          No filters available!{" "}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="accordion-content p-0 m-0 my-2 ">
                      {selectedFilter?.partners?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.partners?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Partners</span>
                      {searchResults?.partners?.length ? (
                        <PerfectScrollbar
                          id="chartBoxs"
                          className="widget-media dlab-scroll m-0 p-0"
                          style={{ maxHeight: "25vh", minHeight: "5vh" }}
                        >
                          {searchResults?.partners?.map((element) => (
                            <div className="d-flex" key={element?.id}>
                              <input
                                type="checkbox"
                                name="Type 1"
                                value="Beginner"
                                id={element?.name}
                                style={{ cursor: "pointer" }}
                                checked={selectedFilter?.partners.includes(
                                  element?.id
                                )}
                                className="mx-2 mb-2"
                                onClick={(e) => {
                                  if (e?.target?.checked) {
                                    let data = selectedFilter?.partners;
                                    data.push(element?.id);
                                    setSelectedFilter(() => ({
                                      ...selectedFilter,
                                      partners: data,
                                    }));
                                  } else {
                                    let data = selectedFilter?.partners;
                                    setSelectedFilter(() => ({
                                      ...selectedFilter,
                                      partners: data.filter(
                                        (item) => item !== element?.id
                                      ),
                                    }));
                                  }
                                }}
                              />
                              <label
                                htmlFor={element?.name}
                                className="p fs-14"
                              >
                                {" "}
                                {element?.name}
                              </label>
                              <br />
                            </div>
                          ))}
                        </PerfectScrollbar>
                      ) : (
                        <p className="p fs-14 my-3 text-center">
                          No filters available!{" "}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="accordion-content p-0 m-0 my-2">
                      {selectedFilter?.effort?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.effort?.length}
                        </span>
                      )}
                      <span className="h4 my-3">Effort</span>
                      <input
                        type="number"
                        className="figma-input-num p p-2"
                        name="task_effort"
                        placeholder="Enter efforts in hours"
                        required
                        style={{ width: "80%" }}
                        autocomplete="off"
                        onChange={(e) => {
                          {
                            e.preventDefault();
                            setSelectedFilter({
                              ...selectedFilter,
                              effort: [parseFloat(e?.target?.value)],
                            });
                          }
                        }}
                      />
                    </div> */}

                    {/* <div className="accordion-content p-0 m-0 my-2 ">
                      {selectedFilter?.features?.length !== 0 && (
                        <span
                          className="float-end fs-6 badge badge-secondary p-0 m-1 mx-2"
                          style={{ height: "20px", width: "20px" }}
                        >
                          {selectedFilter?.features[0]?.Include_videos?.length >= 1 &&
                            selectedFilter?.features[0]?.Include_description?.length >= 1
                            ? 2
                            : selectedFilter?.features[0]?.Include_videos?.length >= 1 ||
                              selectedFilter?.features[0]?.Include_description?.length >= 1
                              ? 1
                              : ""}
                        </span>
                      )}
                      <span className="h4 my-3">Features</span>
                      
                      <div className="accordion-content p-0 m-0 my-2 ">
                        <div className="d-flex">
                          <input
                            data-testId="Features-checkbox"
                            type="checkbox"
                            name="Include_videos"
                            value="Include_videos"
                            style={{ cursor: "pointer" }}
                            className="mx-2 mb-2"
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setVideoinput(true);
                              } else {
                                setVideoinput(false);
                                setVideoinputText("");
                              }
                            }}
                          />
                          <label htmlFor="Include_videos">
                            {" "}
                            Include videos
                          </label>
                          <br />
                        </div>
                        {VideoInput ? (
                          <textarea
                            rows={4}
                            placeholder="Please enter video file name"
                            name="Include_videos"
                            defaultValue={VideoInputText}
                            className="my-2 w-100 p-2 figma-input"
                            onChange={(e) => {
                              setVideoinputText(e?.target?.value);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            name="Include_description"
                            value="Include_description"
                            className="mx-2 mb-2"
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setDescriptionInput(true);
                              } else {
                                setDescriptionInput(false);
                                setDescriptionInputText("");
                              }
                            }}
                          />
                          <label htmlFor="Include_description">
                            Include description{" "}
                          </label>
                          <br />
                        </div>
                        {DescriptionInput ? (
                          <textarea
                            name="Include_description"
                            rows={4}
                            placeholder="Please enter description "
                            className="w-100 p-2 figma-input my-2"
                            defaultValue={DescriptionInputText}
                            onChange={(e) => {
                              setDescriptionInputText(e?.target?.value);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div> */}

                    {/* <div className="accordion-content p-0 m-0 my-2 ">
                      <span className="h4 my-3">Price</span>
                      <div className="col m-1 p-0">
                        <input
                          type="number"
                          className="figma-input-num p p-1"
                          name="min"
                          placeholder="min"
                          required
                          defaultValue={min}
                          autocomplete="off"
                          onChange={(e) => {
                            setSelectedFilter({
                              ...selectedFilter,
                              price: [
                                {
                                  min: e?.target?.value,
                                  max: max,
                                },
                              ],
                            });
                            setMin(e?.target?.value);
                          }}
                        />
                      </div>
                      <div className="col m-1 p-0">
                        <input
                          type="number"
                          className="figma-input-num p p-1"
                          name="max"
                          placeholder="max"
                          required
                          autocomplete="off"
                          defaultValue={max}
                          onChange={(e) => {
                            setMax(e?.target?.value);
                            setSelectedFilter({
                              ...selectedFilter,
                              price: [
                                {
                                  min: min,
                                  max: e?.target?.value,
                                },
                              ],
                            });
                          }}
                        />
                      </div>
                      {Number(min) > Number(max) ||
                        Number(selectedFilter?.price[0]?.min) >
                        Number(selectedFilter?.price[0]?.max) ? (
                        <span className="text-red">
                          Minimum value should be less than maximum
                        </span>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </>
                </PerfectScrollbar>
              </Col>

              {searchResults?.product?.length === 0 ||
                searchResults?.suggestions?.length === 0 ? (
                <div className="col my-3">
                  <h4 className="text-center my-3">No data found</h4>
                </div>
              ) : (
                <Col className="" sm={12} md={9} lg={9} xl={9}>
                  <Row>
                  
                    {searchResults?.product
                      ?.slice(0, 3)
                      ?.map((element, index) => (
                        <Col className="m-0 p-2" sm={6} lg={4} md={6} xl={4}>
                        <div style={{height:"auto",boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.16)",transition: "box-shadow 0.5s ease-in-out", }}  onMouseOver={(e) => {
    e.currentTarget.style.boxShadow =
      "0px 0px 8px 0px rgba(0, 0, 0, 0.3)";
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.boxShadow =
      "0px 0px 4px 0px rgba(0, 0, 0, 0.16)";
  }} >
                        <div className=" ">
                          <Link
                            title="Click to go on product page"
                            className="nav-link text-center p-0  text-wrap"
                            // href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const stores =
                                JSON.parse(localStorage.getItem("store")) || {};
                              stores["navSelectedCategory"] = {};
                              stores["selectedSubCategories"] = {};
                              stores["SelectedStrategy"] = element;
                              localStorage.setItem(
                                "store",
                                JSON.stringify(stores)
                              );
                              setTimeout(() => {
                                navigate("/product-page");
                              }, 100);
                              props.setmodal(() => false);
                            }}
                          >
                            <div className="search-product-card-image">
                              <img
                                alt="search-product-card-image"
                                title="search-product-card-image"
                                src={element?.image}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  borderRadius: "10px 10px 0px 0px"
                                }}
                              />
                            </div>
                            <div className="search-product-card-heading">
                              <h4 style={{padding:"5px"}}>{element?.name || "No data"}</h4>
                            </div>
                            <div className="search-product-card-footer d-flex justify-content-between">
                              <div className="">
                                {/* <div className="search-product-card-price fs-16">
                                  US &nbsp;${element?.price}
                                </div> */}
                              </div>
                              <div className="m-0 text-end ">
                                <CircularRatings ratings={element?.ratings} />
                              </div>
                            </div>

                          </Link>
                        </div>
                        </div>
                        </Col>
                      ))}
             
                  </Row>
                  

                  {searchResults?.suggestions?.length >= 1 ? (
                    <div className="d-flex justify-content-center mt-3 mb-3">
                      <div className="col h4">Suggestions ( <Link
                          to={"/product-page"}
                          className="figma-link w-100"
                          style={{ width: "100px", cursor: "pointer" }}
                          onClick={() => {
                            const stores =
                              JSON.parse(localStorage.getItem("store")) || {};
                            stores["navSelectedCategory"] = {
                              name: "all",
                              id: "",
                            };
                            stores["selectedSubCategories"] = {};
                            stores["SelectedStrategy"] = {};
                            localStorage.setItem(
                              "store",
                              JSON.stringify(stores)
                            );
                            setTimeout(() => {
                              navigate("/product-page");
                            }, 100);
                            props.setmodal(() => false);
                          }}
                        >
                          See All
                        </Link>
                      )</div>
                      
                    </div>
                  ) : (
                    ""
                  )}
                  <Row
                    className=""
                    // style={{ columnGap: "30px", rowGap: "36px" }}
                  >
                    {searchResults?.suggestions
                      ?.slice(0, 6)
                      ?.map((element, index) => (
                        // <Col className="m-0 p-2" sm={12} lg={12} md={12} xl={6}>
                        // <div
                        //   className="suggestion-box"
                        //   style={{ cursor: "pointer",justifyContent: "center",
                        //   alignItems: "center"}}
                        //   onClick={() => {
                        //     const stores =
                        //       JSON.parse(localStorage.getItem("store")) || {};
                        //     stores["navSelectedCategory"] = {};
                        //     stores["selectedSubCategories"] = {};
                        //     stores["SelectedStrategy"] = element;
                        //     localStorage.setItem(
                        //       "store",
                        //       JSON.stringify(stores)
                        //     );
                        //     setTimeout(() => {
                        //       navigate("/product-page");
                        //     }, 100);
                        //     props.setmodal(() => false);
                        //   }}
                        // >
                        //           <div className="suggestion-box-contains">
                        //   <div className="suggestion-box-image">
                        //     <img
                        //       alt="suggestion-box"
                        // title="suggestion-box"
                        //       src={element?.image}
                        //       style={{
                        //         height: "100%",
                        //         width: "100%",
                        //       }}
                        //     />
                        //   </div>
                        //   <span className="mobile-suggestions">
                        //   <div className="suggestion-box-text w-100">
                        //     <div className="suggestion-box-text-align">
                        //       <div className="d-flex justify-content-center">
                        //         <h5 className="float-start">{element?.name}</h5>
                        //       </div>
                        //     </div>
                        //   </div>
                        //   <div className="d-flex justify-content-end"> {/* Updated this line */}
                        //     <CircularRatings ratings={element?.ratings} className="text-end" />
                        //   </div>
                        //   </span>
                         
                        // </div>
                        // </div>
                        // </Col>
                        <Col className="m-0 p-2" sm={6} lg={4} md={6} xl={4}>
                        <div style={{height:"auto",boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.16)",transition: "box-shadow 0.5s ease-in-out", }}  onMouseOver={(e) => {
    e.currentTarget.style.boxShadow =
      "0px 0px 8px 0px rgba(0, 0, 0, 0.3)";
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.boxShadow =
      "0px 0px 4px 0px rgba(0, 0, 0, 0.16)";
  }} >
                        <div className=" ">
                          <Link
                            title="Click to go on product page"
                            className="nav-link text-center p-0  text-wrap"
                            // href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const stores =
                                JSON.parse(localStorage.getItem("store")) || {};
                              stores["navSelectedCategory"] = {};
                              stores["selectedSubCategories"] = {};
                              stores["SelectedStrategy"] = element;
                              localStorage.setItem(
                                "store",
                                JSON.stringify(stores)
                              );
                              setTimeout(() => {
                                navigate("/product-page");
                              }, 100);
                              props.setmodal(() => false);
                            }}
                          >
                            <div className="search-product-card-image">
                              <img
                                alt="search-product-card-image"
                                title="search-product-card-image"
                                src={element?.image}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                  borderRadius: "10px 10px 0px 0px"
                                }}
                              />
                            </div>
                            <div className="search-product-card-heading">
                              <h4 style={{padding:"5px"}}>{element?.name || "No data"}</h4>
                            </div>
                            <div className="search-product-card-footer d-flex justify-content-between">
                              <div className="">
                                {/* <div className="search-product-card-price fs-16">
                                  US &nbsp;${element?.price}
                                </div> */}
                              </div>
                              <div className="m-0 text-end">
                                <CircularRatings ratings={element?.ratings} />
                              </div>
                            </div>

                          </Link>
                        </div>
                        </div>
                        </Col>
                      ))}
                  </Row>
                </Col>
              )}
            </Row>
          ) : (
            <div className="row my-3"></div>
          )}
        </PerfectScrollbar>
      </Modal.Body>}
      
    </Modal>
  );
}

export default SearchModal;
