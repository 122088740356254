import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getHomeGraph } from "../../../services/GoalService";
import { Col, Card } from "react-bootstrap";
import Donut from "./DonutWinsChart";
import { CircularProgress } from "@material-ui/core";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

class WinsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      startDate: "",
      endDate: "",
      seriesData: [0, 0, 0],
      lableee: ["Ok-0", "Epic-0", "Miss-0"],
      pagination: false,
      pageCount: 1,
      goToPage: null,
      circularLoader: false,
    };
    this.userDetail = localStorage.getItem("userDetails");
    this.userInfoDetails = JSON.parse(this.userDetail);
  }

  componentDidMount() {
    this.getGraph();
  }

  getGraph = async (goPage = 0) => {
    this.setState({ circularLoader: true });
    try {
      if (this.props?.goal_id || this.props?.trackScope !== "objective") {
        const data = await getHomeGraph(
          "",
          this.userInfoDetails?.data?.accessToken,
          goPage,
          this.props?.goal_id,
          this.props?.trackScope,
          this.props?.team_id
        );
        
        const graphData = data?.data?.data || [];
        if (graphData[0]?.completion_percent === 0) {
          graphData.shift();
        }

        this.setState({
          circularLoader: false,
          goToPage: goPage,
          pageCount: Math.ceil(data?.data?.count / 10),
          pagination: !!(data?.data?.next || data?.data?.previous),
          labels: graphData,
          seriesData: [
            graphData[graphData?.length - 1]?.hit_percent || 0,
            graphData[graphData?.length - 1]?.beat_percent || 0,
            graphData[graphData?.length - 1]?.miss_percent || 0,
          ],
          lableee: [
            "Ok-" + String(graphData[graphData?.length - 1]?.hit_percent || 0),
            "Epic-" + String(graphData[graphData?.length - 1]?.beat_percent || 0),
            "Miss-" + String(graphData[graphData?.length - 1]?.miss_percent || 0),
          ],
        });
      }
    } catch (err) {
      this.setState({
        circularLoader: false,
        seriesData: [0, 0, 0],
        lableee: ["Ok-0", "Epic-0", "Miss-0"],
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps?.scope === "custom") {
      setTimeout(() => this.getGraph(), 1000);
    } else {
      setTimeout(() => this.getGraph(this.state?.goToPage), 2000);
    }

    const graphData = newProps?.graphData || [];
    this.setState({
      labels: graphData,
      seriesData: [
        graphData[graphData?.length - 1]?.hit_percent || 0,
        graphData[graphData?.length - 1]?.beat_percent || 0,
        graphData[graphData?.length - 1]?.miss_percent || 0,
      ],
      lableee: [
        "Ok-" + String(graphData[graphData?.length - 1]?.hit_percent || 0),
        "Epic-" + String(graphData[graphData?.length - 1]?.beat_percent || 0),
        "Miss-" + String(graphData[graphData?.length - 1]?.miss_percent || 0),
      ],
    });
  }

  ChangePage = (pageNo) => {
    this.setState({ goToPage: pageNo });
    this.getGraph(pageNo);
  };

  render() {
    const { labels, circularLoader, seriesData, lableee } = this.state;
    const graphData = labels || [];
    const color =
      graphData[graphData?.length - 2]?.completion_percent >
      graphData[graphData?.length - 1]?.completion_percent
        ? "#FF0000"
        : "#00FF00";

    const barThickness = window.innerWidth >= 1020 ? 70 : 30;

    const data = {
      labels: labels.map((d) => "Week " + d?.week),
      datasets: [
        {
          label: "Completion percent",
          data: graphData.map((d) => d?.completion_percent || 0),
          backgroundColor: "#4DBFDB",
          barThickness,
        },
      ],
    };

    const options = {
      plugins: { legend: false },
      scales: {
        y: { min: 0, max: 100 },
        x: { barPercentage: 1 },
      },
      responsive: true,
    };

    return (
      <Col xl={4} lg={4}>
        <Card data-testid="line-charts" style={{ minHeight: "350px" }}>
          {circularLoader ? (
            <div className="text-center" style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Card.Header>
                <h4 className="card-title">% Wins</h4>
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <span className="h4">Wins</span>
                  <span className="h2">
                    {graphData[graphData?.length - 1]?.win_percent || 0}%
                  </span>
                </div>
                <Donut series={seriesData} color={"#4DBFDB"} lable={lableee} />
              </Card.Body>
            </>
          )}
        </Card>
      </Col>
    );
  }
}

export default WinsChart;

