import React from "react";
import ChatbotIcon from "./ChatbotIcon";
import Portal from "./Portal";
import { ChatbotProvider } from "./ChatboatContext";
import Chatbot from "../StoreFront/Chatbot";

function PortalApp() {
  return (
    <ChatbotProvider>
      <Chatbot></Chatbot>
      {/* <ChatbotIcon></ChatbotIcon> */}
    </ChatbotProvider>
  );
  // return <ChatbotIcon />;
}

export default PortalApp;
