import { act } from "react-test-renderer";
import { READ_NOTIFICATION_CONFIRMED_ACTION } from "../actions/NotificationAction";
import { FILE_UPLOAD_CONFIRMED_ACTION } from "../actions/NotificationAction";
import { GET_THREADS_CONFIRMED_ACTION } from "../actions/NotificationAction";
import { GET_THREADS_FAILED_ACTION } from "../actions/NotificationAction";
import { GET_STRATEGY_IMPORTED_ACTION } from "../actions/NotificationAction";
import { ALL_THREADS_WITH_CHAT } from "../actions/NotificationAction";
import { UPDATE_ALL_THREADS_WITH_CHAT } from "../actions/NotificationAction";
import { LOAD_MORE_THREADS } from "../actions/NotificationAction";
import { FETCH_NEXT_THREAD_CHATS } from "../actions/NotificationAction";
import { CREATED_NEW_THREAD_WITH_GOTO_CHAT } from "../actions/NotificationAction";

const initialState = {
    notificationId: "",
    FileUploadId: "",
    threadList: {},
    errorMessage: "",
    strategyImported: '',
    allThreadsWithChatMessages: [],
    updateAllThreadsWithChatMessages: false,
    loadMore: false,
    fetchNextThreadChats: 0,
    createdNewThreadWithGoToChat: false
};

export function ReadNotificationReducer(state = initialState, action) {
    if (action.type === READ_NOTIFICATION_CONFIRMED_ACTION) {
        return {
            ...state,
            notificationId: action.payload,
        };
    }
    return state;
}

export function FileUploadReducer(state = initialState, action) {
    if (action.type === FILE_UPLOAD_CONFIRMED_ACTION) {
        return {
            ...state,
            FileUploadId: action.payload,
        };
    }
    return state;
}
export function MessagesReducer(state = initialState, action) {
    if (action.type === GET_THREADS_CONFIRMED_ACTION) {
        return {
            ...state,
            threadList: action.payload,
        };
    }
    if (action.type === GET_THREADS_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Thread list not found',
        };
    }
    if (action.type === GET_STRATEGY_IMPORTED_ACTION) {
        return {
            ...state,
            strategyImported: action.payload
        };
    }
    if (action.type === ALL_THREADS_WITH_CHAT) {
        return {
            ...state,
            allThreadsWithChatMessages: action.payload
        }

    }
    if (action.type === UPDATE_ALL_THREADS_WITH_CHAT) {
        return {
            ...state,
            updateAllThreadsWithChatMessages: action.payload
        }

    }
    if (action.type === LOAD_MORE_THREADS) {
        return {
            ...state,
            loadMore: action.payload
        }

    }
    if (action.type === FETCH_NEXT_THREAD_CHATS) {
        return {
            ...state,
            fetchNextThreadChats: action.payload
        }
    }
    if (action.type === CREATED_NEW_THREAD_WITH_GOTO_CHAT) {
        return {
            ...state,
            createdNewThreadWithGoToChat: action.payload
        }
    }

    return state;
}