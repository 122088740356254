import React, { useEffect, useState, useRef } from 'react';
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { BiLike, BiDislike, BiSolidLike, BiSolidDislike } from "react-icons/bi";
import { RxCopy } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa6";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { MdOutlineCircle } from "react-icons/md";
import { Avatar, Button, Card, Checkbox, Col, Image, Input, Layout, List, Progress, Row, Skeleton, Table, Tooltip, Typography } from 'antd';
import PlansomLogo from "../../images/vector/Frame.png";
import SendLogo from "../../images/Plansom/sendLogo.png";
import BotMessageIcon from "../../images/Plansom/chatPIcon.png";
import chatMessageLoader from "../../images/Plansom/chatMessageLoader.png";
import backgroundStarImage from "../../images/Plansom/backgroundStarImage.png";

const { Header, Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const headerStyle = {
  textAlign: 'center',
  color: 'blue',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
  background: "linear-gradient(to right, #3867FF4D, #ffffff, #3867FF4D)",
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  background: "linear-gradient(to right, #3867FF4D, #ffffff, #3867FF4D)",
};

const layoutStyle = {
  width: '100%',
  height: "100vh",
  // background: "linear-gradient(to right, #3867FF4D, #ffffff, #3867FF4D)",
};

const goalsColumns = [
  {
    key: 'col1',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input active={true} style={{ height: '10px', marginTop: '5px' }} />
      </div>
    ),
    className: 'blue-border',
  },
  {
    key: 'col2',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input active={true} style={{ height: '10px', marginTop: '5px' }} />
      </div>
    ),
  },
  {
    key: 'col3',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input active={true} style={{ height: '10px', marginTop: '5px' }} />
      </div>
    ),
  },
];

const tasksColumns = [
  {
    key: 'col1',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input active={true} style={{ height: '10px', marginTop: '5px' }} />
      </div>
    ),
    className: 'green-border',
  },
  {
    key: 'col2',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input active={true} style={{ height: '10px', marginTop: '5px' }} />
      </div>
    ),
  },
  {
    key: 'col3',
    render: () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton.Input active={true} style={{ height: '10px', marginTop: '5px' }} />
      </div>
    ),
  },
];

const progressTexts = [
  {
    done: false,
    text: "Customizing Goals & Tasks"
  },
  {
    done: false,
    text: "Adapting the Plan to Your Schedule"
  },
  {
    done: false,
    text: "Optimizing Task Flow"
  },
  {
    done: false,
    text: "Identifying Quick Wins with Guided Actions"
  }
]


const ChatModal = ({ setOpenChatModal, preDefinedInput, setPreDefinedInput, existingSocket, existingStateSocket, value }) => {
  const scrollRef = useRef(null);
  const socketRef = useRef(null);
  const loadingSocketRef = useRef(null);
  const [isConnected, setIsConnected] = useState(value);
  const [userInput, setUserInput] = useState(preDefinedInput);
  const [allUserInputs, setAllUserInputs] = useState([]);
  const [allBotResponses, setAllBotResponses] = useState([]);
  const [allLoadingBotResponses, setAllLoadingBotResponses] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [progresslength, setProgressLength] = useState(0);
  const [isMessageSend, setIsMessageSend] = useState(value);
  const [generatedData, setGeneratedData] = useState(null);
  const [finishGeneratingPlan, setFinishGeneratingPlan] = useState(false);
  const [isButtonHovered, setIsButttonHovered] = useState(null);
  const [isMessageLiked, setIsMessageLiked] = useState(false);
  const [isMessageDisLiked, setIsMessageDisLiked] = useState(false);
  const [startOver, setStartOver] = useState(false);
  const [goalsCount, setGoalsCount] = useState(0);
  const [tasksCount, setTasksCount] = useState(0);
  const [percent, setPercent] = useState(50);
  const [showPlansomLogo, setShowPlansomLogo] = useState(true);
  const [showGeneratingGoalsTasks, setShowGeneratingGoalsTasks] = useState(false);
  const [showPlanGeneratingPlanProgress, setShowPlanGeneratingProgress] = useState(false);
  const [showCreatingPlanProgressTexts, setShowCreatingPlanProgressTexts] = useState(progressTexts);
  const [input, setInput] = useState("")

  const goalsDataSource = Array(goalsCount).fill({});

  const tasksDataSource = Array(tasksCount).fill({});
  const currentPath = useRef(window.location.pathname)
  const AI_PLAN_PATH = "/ai-plan";

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.data?.id;
  
 
  const handleGoalsCount = () => {
    setGoalsCount(prevCount => {
      if (prevCount <= 4) {
        return prevCount + 1;
      }
      return prevCount;
    });
  };

  const handleTasksCount = () => {
    setTasksCount(prevCount => {
      if (prevCount <= 3) {
        return prevCount + 1;
      }
      return prevCount;
    });
  };

  console.log("1231234234234",isMessageSend)


  function generateUniqueUID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 10000);
    return `${timestamp}${randomNum}`;
  }

  function getOrCreateUID() {
    let storedUID = localStorage.getItem('plansom_bot_id');
    if (!storedUID) {
      storedUID = generateUniqueUID();
      localStorage.setItem('plansom_bot_id', storedUID);
    }
    return storedUID;
  }

  function formatResponse(response) {
    console.log("1234567890987654321234567",response)
    // Replace headers
    response = response?.replace(/### (.*$)/gim, '<h3>$1</h3>');

    // Replace bold text
    response = response?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Replace inline code
    response = response?.replace(/`([^`]+)`/g, '<code>$1</code>');

    // Replace ordered lists
    response = response?.replace(/(?:\d+\..*\n?)+/g, function (match) {
      let items = match?.trim().split(/\n(?=\d+\.\s)/); // Split based on numbers
      let listItems = items?.map(item => {
        let number = item?.match(/^\d+/)[0]; // Extract number
        let content = item?.replace(/^\d+\.\s*/, ''); // Remove number
        return `<li><b>${number}</b>. ${content}</li>`;
      }).join('');
      return `<ol>${listItems}</ol>`;
    });

    // Replace line breaks with <br>
    response = response?.replace(/\n/g, '<br>');

    return response;
  }

  function formatItemText(text) {
    // Regular expression to match patterns like "1. ", "2. ", etc.
    const regex = /\d+\.\s*/g;

    // Split the text by commas, removing index numbers
    const items = text?.split(', ').map(item => item.replace(regex, '').trim());

    // Render each item in a separate <Text> component
    return (
      <>
        {Array.isArray(items) ? (
          items.map((item, index) => (
            <Paragraph key={index} type="text" style={{ margin: 0 }}>
              {item}
            </Paragraph>
          ))
        ) : (
          <Paragraph type="text" style={{ margin: 0 }}>
            {items}
          </Paragraph>
        )}
      </>
    );
  }

  const handleOptionClick = (option) => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((selected) => selected !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    setInput(e.target.value)
  }

  const chatData = [
    ...allUserInputs.map((message) => ({ ...message, type: 'sent' })),
    ...allBotResponses.map((message) => ({ ...message, type: 'received' })),
    ...(allLoadingBotResponses?.length > 1 ? [{ type: 'loading', data: allLoadingBotResponses }] : [])
  ].sort((a, b) => a.id - b.id);


  const handleStartOver = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
      console.log("WebSocket connection closed manually");
    }
    setAllBotResponses([]);
    setAllUserInputs([]);
    setAllLoadingBotResponses([]);
    setShowPlansomLogo(true);
    setShowGeneratingGoalsTasks(false);
    setShowPlanGeneratingProgress(false);
    setFinishGeneratingPlan(false);
    setPercent(0);
    setProgressLength(0);
  };

  const ProductRoadmap = ({ data }) => {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: data }}
        style={{ padding: '0px', fontSize: '14px' }}
      />
    );
  };

  const MessageDisplay = ({ data, isMessageSend }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    if (!isMessageSend) {
      setAllLoadingBotResponses([]);
      return;
    }

    if (currentIndex < data?.length)
      setCurrentIndex(currentIndex + 1);

    return (
      <Text style={{ color: "#81869C", fontStyle: 'italic' }}>
        {data[data.length - 1]?.message}&nbsp;&nbsp;
        <Image
          width={30}
          src={chatMessageLoader}
          preview={false}
        />
      </Text>
    );
  };

  const sendMessage = () => {
    setIsMessageSend(true);
    setGeneratedData(null);
    console.log("User_input",userInput)
    console.log("User_console",selectedOptions)
    if (userInput) {
      setAllUserInputs((prevMessages) => [
        ...prevMessages,
        {
          text: `${selectedOptions}\n${userInput}`,
          id: prevMessages.length,
        },
      ]);

      const payload = {
        user_input: userInput,
        user_id : userId
      }
      socketRef.current.send(JSON.stringify(payload));
    }
    else if (selectedOptions) {
      const joinedOptions = selectedOptions.join(', ');
      setAllUserInputs((prevMessages) => [
        ...prevMessages,
        {
          text: joinedOptions,
          id: prevMessages.length,
        },
      ]);

      const payload = {
        user_input: userInput,
        user_id : userId
      }
      socketRef.current.send(JSON.stringify(payload));
    }
    setUserInput("");
    setInput("");
    console.log("1111",userInput)
    setSelectedOptions([]);
  };


  useEffect(() => {
    if (existingStateSocket) {
    loadingSocketRef.current = existingStateSocket

    loadingSocketRef.current.onmessage = (event) => {
      console.log("Loading Message received:", event);
      setAllLoadingBotResponses((prevMessages) => [
        ...prevMessages,
        {
          ...JSON.parse(event.data),
          id: prevMessages.length,
        },
      ]);
    };

    loadingSocketRef.current.onopen = () => {
      console.log("Loading WebSocket is connected");
    };

    loadingSocketRef.current.onclose = () => {
      console.log("Loading WebSocket is closed");
    };

    // Handle Loading WebSocket error event
    loadingSocketRef.current.onerror = (error) => {
      console.error("Loading WebSocket error:", error);
    };
  }
    // return () => {
    //   socketRef.current.close();
    // };
  }, []);

  useEffect(() => {
    console.log("000111",allUserInputs)
    if (existingSocket) {
      socketRef.current = existingSocket;

      socketRef.current.onmessage = (event) => {
        console.log("Message received:", event);
        setShowPlansomLogo(false);
        setShowGeneratingGoalsTasks(true);
        setIsMessageSend(false);
        setAllLoadingBotResponses([]);
        
        const botData = JSON.parse(event.data);
        if (botData?.finish === true) {
          setShowGeneratingGoalsTasks(false);
          setShowPlanGeneratingProgress(true);
        }
        setProgressLength(botData?.thread_progress <= 50 ? botData.thread_progress : 50);
        
        if (typeof botData.bot === 'string') {
          if (botData?.bot?.includes("Something went wrong, please try again")) {
            setStartOver(true);
          }
          const responseText = formatResponse(botData.bot);
          setGeneratedData(responseText);
          setAllBotResponses((prevMessages) => [
            ...prevMessages,
            {
              ...botData,
              formatedData: responseText,
              id: prevMessages.length,
            },
          ]);
        } else {
          setAllBotResponses((prevMessages) => [
            ...prevMessages,
            {
              ...botData,
              id: prevMessages.length,
            },
          ]);
          handleGoalsCount();
          handleTasksCount();
        }
      };
    }
  }, [existingSocket]);

  // // const socketRef = useRef(null);
  // const [messages, setMessages] = useState([]);
  // // ... other state variables ...

  // useEffect(() => {
  //   // Use existing socket if provided
  //   if (existingSocket) {
  //     socketRef.current = existingSocket;
      
  //     // Set up message handler
  //     socketRef.current.onmessage = (event) => {
  //       console.log("Message received:", event);
  //       setShowPlansomLogo(false);
  //       setShowGeneratingGoalsTasks(true);
  //       setIsMessageSend(false);
  //       setAllLoadingBotResponses([]);
        
  //       try {
  //         const botData = JSON.parse(event.data);
  //         if (botData?.finish === true) {
  //           setShowGeneratingGoalsTasks(false);
  //           setShowPlanGeneratingProgress(true);
  //         }
  //         setProgressLength(botData?.thread_progress <= 50 ? botData.thread_progress : 50);
          
  //         if (typeof botData.bot === 'string') {
  //           if (botData?.bot?.includes("Something went wrong, please try again")) {
  //             setStartOver(true);
  //           }
  //           const responseText = formatResponse(botData.bot);
  //           setGeneratedData(responseText);
  //           setAllBotResponses((prevMessages) => [
  //             ...prevMessages,
  //             {
  //               ...botData,
  //               formatedData: responseText,
  //               id: prevMessages.length,
  //             },
  //           ]);
  //         } else {
  //           setAllBotResponses((prevMessages) => [
  //             ...prevMessages,
  //             {
  //               ...botData,
  //               id: prevMessages.length,
  //             },
  //           ]);
  //           handleGoalsCount();
  //           handleTasksCount();
  //         }
  //       } catch (error) {
  //         console.error("Error processing message:", error);
  //       }
  //     };

  //     // Send initial message if we have input
  //     if (preDefinedInput && isConnected) {
  //       const message = {
  //         message: preDefinedInput,
  //         user_id: "101010" // or however you generate your user ID
  //       };
  //       socketRef.current.send(JSON.stringify(message));
  //     }
  //   }

  //   return () => {
  //     // Don't close the socket here since it's managed by the parent
  //     socketRef.current = null;
  //   };
  // }, [existingSocket, isConnected]);

  // useEffect(() => {
  //   socketRef.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/plansombot/101010`);

  //   socketRef.current.onopen = () => {
  //     console.log("WebSocket is connected");
  //     setIsConnected(true);
  //   };

  //   socketRef.current.onmessage = (event) => {
  //     console.log("Message received:", event);
  //     setShowPlansomLogo(false);
  //     setShowGeneratingGoalsTasks(true);
  //     setIsMessageSend(false);
  //     setAllLoadingBotResponses([]);
  //     const botData = JSON.parse(event.data);
  //     if (botData?.finish === true) {
  //       // setFinishGeneratingPlan(true);
  //       setShowGeneratingGoalsTasks(false);
  //       setShowPlanGeneratingProgress(true);
  //     }
  //     // setProgressLength(botData?.thread_progress);
  //     setProgressLength(botData?.thread_progress <= 50 ? botData.thread_progress : 50);
  //     if (typeof botData.bot === 'string') {
  //       if (botData?.bot?.includes("Something went wrong, please try again")) {
  //         setStartOver(true);
  //       }
  //       const responseText = formatResponse(botData.bot);
  //       setGeneratedData(responseText);
  //       setAllBotResponses((prevMessages) => [
  //         ...prevMessages,
  //         {
  //           ...JSON.parse(event.data),
  //           formatedData: responseText,
  //           id: prevMessages.length,
  //         },
  //       ]);
  //     } else {
  //       setAllBotResponses((prevMessages) => [
  //         ...prevMessages,
  //         {
  //           ...JSON.parse(event.data),
  //           id: prevMessages.length,
  //         },
  //       ]);
  //       handleGoalsCount();
  //       handleTasksCount();
  //     }
  //   };

  //   socketRef.current.onclose = () => {
  //     console.log("WebSocket is closed");
  //     setIsConnected(false);
  //   };

  //   // Handle WebSocket error event
  //   socketRef.current.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };

  //   // return () => {
  //   //   socketRef.current.close();
  //   // };
  // }, []);

  useEffect(() => {
    console.log("Valuesssssssssssssss",value)
    console.log("Isconnected",isConnected)
    console.log("preDefinedInput",preDefinedInput)
    if (isConnected && preDefinedInput) {
      sendMessage();
      setPreDefinedInput("");
    }
  }, [isConnected])

  useEffect(() => {
    if (preDefinedInput) {
      setUserInput(preDefinedInput);
    }
  }, [preDefinedInput])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatData]);

  useEffect(() => {
    let interval;

    const startingPercent = 50;
    const targetPercent = 100;
    const totalTime = 180 * 1000; // 3 minutes in milliseconds
    const intervalTime = 3000; // Interval in milliseconds (every 2 seconds)

    // Calculate the increment needed for each interval to reach 100% in 3 minutes
    const increment = ((targetPercent - startingPercent) / (totalTime / intervalTime));

    if (showPlanGeneratingPlanProgress) {
      interval = setInterval(() => {
        setPercent((prevPercent) => {
          const newPercent = Math.min(100, Math.round(prevPercent + increment));

          // const newPercent = Math.min(targetPercent, prevPercent + increment);

          if (newPercent >= targetPercent) {
            clearInterval(interval);
            setProgressLength(targetPercent);
            return targetPercent;
          }
          if (newPercent > 50 && newPercent < 52) {
            setFinishGeneratingPlan(true);
            setAllBotResponses((prevMessages) => [
              ...prevMessages,
              {
                finishedGeneratingPlan: true,
                id: prevMessages.length,
              },
            ]);
          }

          if (newPercent > 50) {
            const nextProgressLength = Math.min(100, Math.floor((newPercent / 10)) * 10);
            if (nextProgressLength > progresslength) {
              setProgressLength(nextProgressLength);
            }
          }

          setShowCreatingPlanProgressTexts((prevTexts) => {
            const updatedTexts = [...prevTexts];
            if (newPercent >= 60 && !updatedTexts[0].done) updatedTexts[0].done = true;
            if (newPercent >= 70 && !updatedTexts[1].done) updatedTexts[1].done = true;
            if (newPercent >= 80 && !updatedTexts[2].done) updatedTexts[2].done = true;
            if (newPercent >= 90 && !updatedTexts[3].done) updatedTexts[3].done = true;
            return updatedTexts;
          });

          return newPercent;
        });
      }, intervalTime);
    }

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [showPlanGeneratingPlanProgress]);


  return (
    <>
      <style>
        {`
          /* Center skeleton loader vertically in the cell */
          .centered-row td {
            vertical-align: middle;
          }

          /* Blue left border on the first column */
          .blue-border {
            border-left: 2px solid #1D3BAF; 
          }

          .green-border {
            border-left: 2px solid #389E0D; 
          }
        `}
      </style>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <Row align="middle" justify="space-between" style={{ display: 'flex', alignItems: "center" }}>
            <Col style={{ flex: '0 0 auto', textAlign: 'left' }}>
              <Image
                width={200}
                src={PlansomLogo}
                preview={false}
              />
            </Col>
            <Col style={{ flex: '1', textAlign: 'left', alignItems: "center", justifyContent: "center" }}>
              <Title style={{ fontSize: "28px", fontWeight: 600, fontFamily: "Bricolage Grotesque", color: "#0D1B4F", marginLeft: "20px", marginBottom: "-10px" }}>
                Let’s Create Your Plan and Your Tasks! <Tooltip placement="bottom" title={<span style={{ color: "#000000", fontFamily: "Inter", fontWeight: 400, fontSize: "14px" }}>Start building your personalized roadmap for success! Outline key steps, set goals, and track your progress to reach your business objectives.</span>} arrow={true} color="#ffffff"><HiOutlineInformationCircle size={22} color="#81869C" style={{ marginBottom: "5px" }} /></Tooltip>
              </Title>
            </Col>
            <Col style={{ flex: '0 0 auto', textAlign: 'right' }}>
              <IoMdClose size={24} color="#81869C" cursor="pointer" onClick={() => { setOpenChatModal(false); }} />
            </Col>
          </Row>
        </Header>
        <Layout>
          <Content style={contentStyle} >
            <Row align="top">
              <Progress percent={progresslength} showInfo={false} strokeColor={"orange"} />
            </Row>
            <Row align="bottom" justify="space-between" gutter={[16, 16]} wrap={false} style={{ height: "calc(100vh - 80px)", padding: "1% 1% 2% 1%" }}>
              <Col
                xs={24} sm={12} md={14} xl={14}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <div
                  ref={scrollRef}
                  style={{
                    flexGrow: 1, width: '100%',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                  }}>
                  <List
                    itemLayout="horizontal"
                    dataSource={chatData}
                    renderItem={(item, Index) => {
                      const lastReceivedIndex = chatData.map(msg => msg.type).lastIndexOf('received');
                      return (
                        <List.Item
                          style={{
                            justifyContent: item.type === 'sent' ? 'flex-end' : 'flex-start',
                            textAlign: item.type === 'sent' ? 'left' : 'left',
                            padding: '10px 20px',
                            border: "none",
                          }}
                        >
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            maxWidth: '60%',
                          }}>

                            {(item.type === 'received' || item.type === 'loading') && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  marginRight: 8,
                                }}
                              >
                                <Avatar
                                  size={40}
                                  style={{
                                    backgroundColor: "#F7F8FC",
                                    border: "1px solid #C9D0EC",
                                  }}
                                  icon={
                                    <Image
                                      width={40}
                                      src={BotMessageIcon}
                                      preview={false}
                                    />
                                  }
                                />
                              </div>
                            )}

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: item.type === 'sent' ? 'flex-end' : 'flex-start',
                              }}
                            >
                              <div
                                style={{
                                  background: '#ffffff',
                                  border: "1px solid #C9D0EC",
                                  padding: '10px 15px',
                                  borderRadius: item.type === 'received' ? '0px 15px 15px 15px' : item.type === 'loading' ? '0px 15px 15px 15px' : '15px 0px 15px 15px',
                                  width: '100%',
                                  marginBottom: item.type === 'received' ? '10px' : '0px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <Text style={{ color: "#81869C" }}>
                                    {item.type === 'received' ? "Plansom Assistant" : item.type === 'loading' ? "" : "You"}
                                  </Text>
                                </div>

                                {item.type === 'loading' && isMessageSend ? (
                                  <MessageDisplay data={item?.data} isMessageSend={isMessageSend} />
                                ) : (
                                  item.type === 'received' ?
                                    item?.formatedData ? <ProductRoadmap data={item?.formatedData} /> : item?.bot?.question
                                    : formatItemText(item.text)
                                )}

                                {item.type === 'received' && startOver && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      marginRight: 8,
                                      marginTop: 0,
                                      width: '100%',
                                    }}
                                  >
                                    <Button
                                      shape='round'
                                      style={{
                                        padding: '20px',
                                        lineHeight: '1.2',
                                      }}
                                      href=''
                                      iconPosition='start'
                                      icon={<RiRefreshLine size={20} />}
                                      onClick={() => {
                                        handleStartOver();
                                      }}
                                    >
                                      Start Over
                                    </Button>
                                  </div>
                                )}

                                {item?.type === 'received' && item?.finishedGeneratingPlan && lastReceivedIndex === Index && (
                                  <>
                                    {currentPath.current == AI_PLAN_PATH ? <Text>
                                      Your personalized action plan is being crafted! While we finalize it, take a moment to explore your tasks or try something new. We'll let you know as soon as it's ready.
                                    </Text> :
                                      <Text>
                                        Thank you. Your Personalised Action Plan is building. Please create an account to view your results.
                                      </Text>}

                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start ',
                                        alignItems: 'center',
                                        marginRight: 8,
                                        marginTop: 5,
                                        width: '100%',
                                      }}
                                    >
                                      {
                                        currentPath.current == AI_PLAN_PATH ? <Button
                                          shape='round'
                                          type='primary'
                                          style={{
                                            background: '#152A7C',
                                            padding: '20px',
                                            lineHeight: '1.2',
                                          }}
                                          href='/my-day'
                                        >
                                          Go to MyDay
                                        </Button> : <Button
                                          shape='round'
                                          type='primary'
                                          style={{
                                            background: '#152A7C',
                                            padding: '20px',
                                            lineHeight: '1.2',
                                          }}
                                          href='/register'
                                        >
                                          Get Your Custom Action Plan
                                        </Button>
                                      }
                                      <Button
                                        shape='round'
                                        style={{
                                          padding: '20px',
                                          lineHeight: '1.2',
                                          marginLeft: 10
                                        }}
                                        href=''
                                        iconPosition='start'
                                        icon={<RiRefreshLine size={20} />}
                                        onClick={() => {
                                          handleStartOver();
                                        }}
                                      >
                                        Start Over
                                      </Button>
                                    </div>
                                  </>
                                )}

                                {item.type === 'received' && !item?.finishedGeneratingPlan && !startOver && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      marginRight: 8,
                                      marginTop: 5,
                                      width: '100%',
                                    }}
                                  >
                                    <RxCopy size={20} color="#81869C" style={{ marginRight: 8, cursor: "pointer" }} onClick={() => { }} />
                                    {!isMessageLiked ?
                                      <BiLike size={20} color="#81869C" style={{ marginRight: 8, cursor: "pointer" }} onClick={() => { setIsMessageLiked(!isMessageLiked) }} /> :
                                      <BiSolidLike size={20} color="#237804" style={{ marginRight: 8, cursor: "pointer" }} onClick={() => { setIsMessageLiked(!isMessageLiked) }} />
                                    }
                                    {!isMessageDisLiked ?
                                      <BiDislike size={20} color="#81869C" style={{ cursor: "pointer" }} onClick={() => { setIsMessageDisLiked(!isMessageDisLiked) }} /> :
                                      <BiSolidDislike size={20} color="#CF1322" style={{ cursor: "pointer" }} onClick={() => { setIsMessageDisLiked(!isMessageDisLiked) }} />
                                    }
                                  </div>
                                )}

                              </div>
                              {item.type === 'received' && !item.formatedData && !item?.finishedGeneratingPlan && (
                                <div style={{ width: '100%' }}>
                                  <Paragraph style={{ color: "#81869C", marginTop: "3px", marginBottom: "3px" }}>
                                    Select all options that are relevant to you
                                  </Paragraph>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    {item?.bot?.options?.map((option, index) => (
                                      <Button
                                        key={index}
                                        shape="round"
                                        icon={selectedOptions.includes(option) && lastReceivedIndex === Index &&
                                          <IoMdCheckmark size={20} />
                                        }
                                        style={{
                                          margin: "5px",
                                          background: selectedOptions.includes(option) && lastReceivedIndex === Index
                                            ? isButtonHovered === index ? '#152A7C' : '#1D3BAF'
                                            : isButtonHovered === index && lastReceivedIndex === Index ? '#E8EBF7' : '#ffffff',
                                          color: selectedOptions.includes(option) && lastReceivedIndex === Index ? '#fff' : isButtonHovered === index && lastReceivedIndex === Index ? '#465EBD' : '#000',
                                          whiteSpace: 'normal',
                                          wordBreak: 'break-word',
                                          textAlign: 'center',
                                          padding: '20px',
                                          lineHeight: '1.2',
                                          border: selectedOptions.includes(option) && lastReceivedIndex === Index ? '1px solid #152A7C' : isButtonHovered === index && lastReceivedIndex === Index ? '1px solid #465EBD' : '1px solid #ccc',
                                          cursor: 'pointer',
                                        }}
                                        disabled={lastReceivedIndex !== Index}
                                        onMouseEnter={() => setIsButttonHovered(index)}
                                        onMouseLeave={() => setIsButttonHovered(null)}
                                        onClick={() => handleOptionClick(option)}
                                      >
                                        {option?.replace(/^\d+\.\s*/, '')}
                                      </Button>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>

                            {item.type === 'sent' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  marginLeft: 8,
                                }}
                              >
                                <Avatar
                                  size={40}
                                  style={{
                                    backgroundColor: "#F7F8FC",
                                    border: "1px solid #C9D0EC",
                                  }}
                                  icon={<FaRegUser color='#05060E' />}
                                />
                              </div>
                            )}
                          </div>

                        </List.Item>
                      )
                    }
                    }
                  />
                </div>
                {!finishGeneratingPlan &&
                  <div style={{ position: "relative", width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <TextArea
                      style={{
                        borderRadius: "40px",
                        padding: "10px",
                        margin: "20px 15px 0px",
                        width: "100%",
                        overflowY: 'scroll',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none'
                      }}
                      autoSize={{
                        minRows: 1,
                        maxRows: 5,
                      }}
                      value={input}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          if (userInput) {
                            sendMessage();
                          }
                        }
                      }}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Type your answer"
                    />
                    {(selectedOptions?.length > 0 || userInput)  ?
                      <Image
                        width={45}
                        src={SendLogo}
                        preview={false}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "0px",
                          top: "55%",
                          transform: "translateY(-30%)",
                        }}
                        onClick={() => {
                          sendMessage();
                          // if (selectedOptions?.length !== 0 || userInput !== "" && !preDefinedInput) {
                          //   sendMessage();
                          // }
                        }}
                      /> :
                      <Button
                        shape='round'
                        style={{
                          padding: '20px',
                          lineHeight: '1.2',
                          marginLeft: 10,
                          right: "0px",
                          top: "55%",
                          transform: "translateY(-30%)",
                        }}
                        href=''
                        iconPosition='start'
                        icon={<RiRefreshLine size={20} />}
                        onClick={() => {
                          handleStartOver();
                        }}
                      >
                        Restart
                      </Button>
                    }
                  </div>
                }

              </Col>
              <Col
                xs={24} sm={12} md={10} xl={10}
                style={{ height: "100%" }}
              >
                {showPlansomLogo &&
                  <Card
                    bordered={true}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      borderRadius: "20px",
                      border: "2px solid #080D210A",
                      background: `
                      linear-gradient(
                        115deg, 
                        rgba(56, 103, 255, 0.3) 10%, 
                        #F9F9FB 40%
                      ),
                      linear-gradient(
                        200deg, 
                        rgba(56, 103, 255, 0.3) 10%, 
                        #F9F9FB 40%
                      )
                    `,
                      height: "100%",
                      textAlign: "center"
                    }}
                  >
                    <Image
                      width={250}
                      src={PlansomLogo}
                      preview={false}
                    />
                    <Title
                      style={{
                        fontSize: "24px",
                        fontWeight: 600,
                        fontFamily: "Bricolage Grotesque",
                        color: "#112264",
                        marginTop: "20px"
                      }}
                    >
                      Turn goals into action – build your <br /> success plan today!
                    </Title>
                  </Card>
                }
                {showGeneratingGoalsTasks &&
                  <Card
                    bordered={true}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      border: "2px solid #080D210A",
                      background: '#ffffff',
                      height: "100%",
                      textAlign: "center"
                    }}
                  >
                    <Row align="middle" justify="space-between" wrap={false}>
                      <Col xs={24} style={{ textAlign: 'left' }}>
                        <Paragraph style={{ fontSize: "20px", fontWeight: 600, fontFamily: "Open Sans", color: "#05060E" }}>
                          Crafting Your Action Plan
                        </Paragraph>
                      </Col>
                    </Row>
                    <Row align="middle" justify="space-between" wrap={false}>
                      <Col xs={24} style={{ textAlign: 'left' }}>
                        <Text style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#1D3BAF" }}>
                          Your Goals
                        </Text>
                        <div style={{ overflowX: 'auto', marginTop: "10px" }}>
                          <Table
                            pagination={false}
                            showHeader={false}
                            bordered={true}
                            dataSource={goalsDataSource}
                            columns={goalsColumns}
                            rowClassName="centered-row"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row align="middle" justify="space-between" wrap={false} style={{ marginTop: 30 }}>
                      <Col xs={24} style={{ textAlign: 'left' }}>
                        <Text style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Open Sans", color: "#389E0D" }}>
                          Your Tasks
                        </Text>
                        <div style={{ overflowX: 'auto', marginTop: "10px" }}>
                          <Table
                            pagination={false}
                            showHeader={false}
                            bordered={true}
                            dataSource={tasksDataSource}
                            columns={tasksColumns}
                            rowClassName="centered-row"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                }
                {
                  showPlanGeneratingPlanProgress &&
                  <Card
                    bordered={true}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      borderRadius: "20px",
                      border: "2px solid #080D210A",
                      background: `
                        linear-gradient(
                          45deg,
                          rgba(56, 103, 255, 0.3) 5%,
                          #F9F9FB 40%
                        ),
                        linear-gradient(
                          -135deg,
                          rgba(56, 103, 255, 0.3) 5%,
                          #F9F9FB 50%
                        )
                      `,
                      height: "100%",
                      position: 'relative',
                      textAlign: "center"
                    }}
                  >
                    <div style={{ position: 'absolute', top: "10%", left: "10%" }}>
                      <Image
                        width={150}
                        src={backgroundStarImage}
                        preview={false}
                      />
                    </div>
                    <div style={{ position: 'absolute', top: "20%", right: "10%" }}>
                      <Image
                        width={100}
                        src={backgroundStarImage}
                        preview={false}
                      />
                    </div>
                    <div style={{ position: 'absolute', top: "40%", left: "20%" }}>
                      <Image
                        width={100}
                        src={backgroundStarImage}
                        preview={false}
                      />
                    </div>
                    <div style={{ position: 'absolute', bottom: "10%", right: "20%" }}>
                      <Image
                        width={100}
                        src={backgroundStarImage}
                        preview={false}
                      />
                    </div>
                    <Progress type="dashboard" percent={percent} strokeColor={"#1D3BAF"} strokeWidth={10} />
                    <Title
                      style={{
                        fontSize: "24px",
                        fontWeight: 600,
                        fontFamily: "Bricolage Grotesque",
                        color: "#112264",
                        marginTop: "20px"
                      }}
                    >
                      Creating Your Plan
                    </Title>
                    {showCreatingPlanProgressTexts?.map((item) => (
                      <div style={{ marginTop: 10, display: 'flex', alignItems: 'flex-start' }}>
                        <Text
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            fontFamily: "Bricolage Grotesque",
                            color: "#112264",
                          }}
                        >
                          {item.done ?
                            <IoMdCheckmark size={20} color="#389E0D" />
                            : <MdOutlineCircle size={20} color="#DDDEE4" />
                          }&nbsp;
                          {item.text}
                        </Text>
                      </div>
                    ))
                    }
                  </Card>
                }
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default ChatModal;
