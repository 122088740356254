import { useState, useEffect, useRef } from "react";
import { Row, Col, Accordion, Card } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import profilePicture from "../../../../src/images/profile/profilePic.png";
import Select from "react-select";
import { getAllGoalsByScope } from "../../../services/GoalService";
import { Link, useNavigate } from "react-router-dom";
import {
  editTask,
  getAITaskSearchResults,
  getTaskAttachments,
  sendTaskAttachments,
  taskFeedback,
} from "../../../services/TaskService";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";
import { getUserdetail } from "../../../store/actions/AuthActions";
import {
  getAllTeams,
  getOrgMembers,
  getTeamMemByTeamId,
} from "../../../store/actions/TeamAction";
import { createPersonalRoom } from "../../../services/messageService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "./LoadingSpinner";

function TaskSidebar(props) {
  const stateData = useSelector((state) => state);
  const [taskDetailsinfo, setTaskDetailsinfo] = useState(props?.data?.task);
  const [EditTask, setEditTask] = useState(false);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const orgId = parseInt(localStorage.getItem("organization_id"), 10);
  const teamId = localStorage.getItem("team_id");
  const [tasksAttachments, setTasksAttachments] = useState([]);
  const fileRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [FileErrorMessage, setFileErrorMessage] = useState("");
  const [taskOwner, setTaskOwnerValue] = useState("");
  const [options, setOptions] = useState([]);
  const [taskImpact, setTaskImpactValue] = useState({
    value: "medium",
    label: "Medium",
  });
  const [selectedTeam, setSelectedTeam] = useState(teamId);
  const [taskPulse, setTaskPulseValue] = useState();
  const [taskStatus, setTaskStatusValue] = useState();
  const [taskCreater, setTaskCreatorValue] = useState("");
  const [defaultOptions, setDefaultOptions] = useState();
  const [taskGoalEdit, setTaskGoalEdit] = useState();
  const [taskDetails, setTaskDetails] = useState("");
  const [goToTaskChats, setGoToTaskChats] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tastComp, setTaskComp] = useState(false);
  const [tastSuccess, setTaskSuccess] = useState(false);
  const [goalsOption, setGoalsOption] = useState();
  const [AIHelp, setAIHelp] = useState(false);
  const [searchedAIHelpText, setSearchedAIHelpText] = useState();
  const [Results, setResults] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const scrollRef = useRef(false);
  const scrollRefAttachment = useRef(false);
  const initialTaskState = {
    task_name: "",
    description: "",
    task_effort: "",
  };

  const [createTask, setCreateTaskForm] = useState({
    task_name: "",
    description: "",
    task_effort: "",
  });

  const statusOption = [
    { value: "hit", label: "Hit" },
    { value: "miss", label: "Miss" },
    { value: "beat", label: "Beat" },
  ];
  const [showResults, setShowResults] = useState([])
  const [loadMore, setloadMore] = useState(false)
  
  const [taskStatusOptions, settaskStatusOptions] = useState(statusOption);
  const taskImpactOptions = [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  const taskPulseOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  useEffect(() => {
    setDefaultOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    setTaskDetailsinfo(() => props?.data?.task);
  }, [props?.data?.task]);

  useEffect(() => {
    setResults(() => Results);
  }, [Results]);

  useEffect(() => {
    if(Results.length){
    if(!loadMore){
      setShowResults(() => [Results[0]])
    }
    else{
      setShowResults(() => Results)
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }, 1000);
    }
  }
  }, [Results, loadMore]);

  useEffect(() => {
    if (!taskStatus && taskPulse) {
      setTaskComp(() => false)
      setTaskSuccess(() => true)
    }
    else if (taskStatus && !taskPulse) {
      setTaskComp(() => true)
      setTaskSuccess(() => false)
    }
    else {
      setTaskComp(() => false)
      setTaskSuccess(() => false)
    }
  }, [taskStatus, taskPulse])

  useEffect(() => {
    setIsSearchLoading(isSearchLoading);
  }, [isSearchLoading]);

  useEffect(() => {
    setSearchedAIHelpText(searchedAIHelpText);
  }, [searchedAIHelpText]);

  useEffect(() => {
    setTaskDetailsinfo(() => taskDetailsinfo);
    handleTaskEditClick(taskDetailsinfo);
    getTasksAttachment(taskDetailsinfo);
    getTeamGoal();
    if (taskDetailsinfo?.task_status === "Completed") {
      setEditTask(false);
    }
    setAIHelp(true);
    setResults([]);
    setShowResults(() => [])
    setloadMore(()=>false)
    getAIResults();
  }, [taskDetailsinfo]);

  useEffect(() => {
    setTaskDetailsinfo(() => taskDetailsinfo);
  }, [taskDetailsinfo])
  

  useEffect(() => {
    setShowResults(() =>showResults)
  }, [showResults]);

  useEffect(() => {
    if (stateData?.team) {
      let optionData = stateData?.team?.orgMembers?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        const data = {
          label: option.first_name ? option.first_name + " " + option.last_name : option?.email,
          value: option.first_name ? option.first_name + " " + option.last_name: option?.email,
          userId: option.id,
        };
        optionList.push(data);
      });
      setOptions(optionList);
    }
  }, [stateData?.team]);

  const changeTaskOwner = (userRole) => {
    setTaskOwnerValue(userRole);
  };

  const changeTaskStatus = (taskStatus) => {
    setTaskStatusValue(() => taskStatus);
  };

  const changeGoalOption = (userRole) => {
    setDefaultOptions(userRole);
    setTaskGoalEdit(userRole);
  };

  const handleCreateTaskChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...createTask };
    newFormData[fieldName] = fieldValue;
    setCreateTaskForm(newFormData);
  };

  const changeTaskPulse = (taskPulse) => {
    setTaskPulseValue(() => taskPulse);
    if (taskPulse?.value === "true") {
      settaskStatusOptions(statusOption);
    } else {
      settaskStatusOptions(statusOption);
    }
  };

  const changeTaskImpact = (taskImpact) => {
    setTaskImpactValue(taskImpact);
  };

  const handleTaskEditClick = (task) => {
    setCreateTaskForm(initialTaskState);
    dispatch(
      getUserdetail(task?.task_owner?.id, userInfoDetails?.data?.accessToken)
    );
    const impact = {
      value:
        task?.task_impact?.charAt(0).toLowerCase() +
        task?.task_impact?.slice(1),
      label:
        task?.task_impact?.charAt(0).toUpperCase() +
        task?.task_impact?.slice(1),
    };
    setTaskImpactValue(impact);
    const taskState = {
      task_name: task?.name,
      description: task?.description,
      task_effort: task?.task_effort,
    };
    setTaskCreatorValue({
      label: task?.task_creator?.email,
      value: task?.task_creator?.email,
      userId: task?.task_creator?.id,
    });
    setTaskOwnerValue({
      label: task?.task_owner?.first_name + " " + task?.task_owner?.last_name,
      value: task?.task_owner?.first_name + " " + task?.task_owner?.last_name,
      userId: task?.task_owner?.id,
    });
    if (task?.goal)
      setDefaultOptions({ value: task?.goal?.id, label: task?.goal?.name });
    setTaskGoalEdit({ value: task?.goal?.id, label: task?.goal?.name });
    setTaskDetails(task);
    setCreateTaskForm(taskState);
    if (task?.task_status === "Completed") {
      setEditTask(false);
    } else if (
      task?.task_status === "Scheduled" &&
      (userInfoDetails?.data?.id === taskDetailsinfo?.task_owner?.id ||
        userInfoDetails?.data?.id === taskDetailsinfo?.task_creator?.id)
    ) {
      setEditTask(true);
    } else if (
      userInfoDetails?.data?.id === taskDetailsinfo?.task_creator?.id ||
      userInfoDetails?.data?.id === taskDetailsinfo?.task_owner?.id
    ) {
      setEditTask(true);
    } else {
      setEditTask(false);
    }
    setGoToTaskChats(task?.show_chat ? task?.show_chat : false);
  };

  useEffect(() => {
    if (tasksAttachments.length)
      if (scrollRefAttachment.current) {
        scrollRefAttachment.current.scrollTop =
          scrollRefAttachment.current.scrollHeight;
      }
  }, [tasksAttachments]);

  useEffect(() => {
    let optionList = [];
    const data = {
      label: userInfoDetails?.data?.email,
      value: userInfoDetails?.data?.email,
      userId: userInfoDetails?.data?.id,
    };
    optionList.push(data);
    setOptions(optionList);
    if (orgId !== null) {
      getAlOrgMem();
      getTeamMem();
    }
    getOrgTeams();
  }, [taskDetailsinfo?.id]);

  useEffect(() => {
    setTasksAttachments(() => tasksAttachments);
  }, [tasksAttachments]);

  async function getTasksAttachment(task) {
    const data = getTaskAttachments(task.id, userInfoDetails?.data?.accessToken);
    data?.then(await((res) => {
      setIsLoading(false);
      setTasksAttachments(() => res?.data?.data);
    }))
    data?.catch(await((err) => {
      console.log("err", err);
    }))
  }

  const changedDateFormats = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var today1 = new Date(date);
    return (
      days[today1.getDay()] +
      " " +
      monthNames[today1.getMonth()] +
      " " +
      String(today1.getDate()).padStart(2, "0") +
      " " +
      today1.getFullYear()
    );
  };

  const getNameAndType = (file) => {
    var filename = file?.substring(file?.lastIndexOf("/") + 1);
    var filename1 = filename?.split(".")[0];
    var type = filename?.substring(filename?.lastIndexOf("."));
    return { name: filename1, type: type };
  };

  const sendMultipleAttachments = async(e) => {
    setIsLoading(true);
    const datas = [];
    for (const file of e) {
      datas.push(file);
    }
    if (taskDetailsinfo) {
      const data = sendTaskAttachments(
        taskDetailsinfo?.id,
        datas,
        userInfoDetails?.data?.accessToken
      );
      data?.then(await((res) => {
        getTasksAttachment(taskDetailsinfo);
      }))
      data?.catch(await((err) => {}));
    }
  };

  const getTeamGoal = () => {
    let optionGoalList = [];
    let orgdata;

    orgdata = getAllGoalsByScope(
      orgId,
      // "organization",
      userInfoDetails?.data?.accessToken
    );
    orgdata?.then(async (res) => {
      let optionGoalData = res?.data?.data;
      if (optionGoalData.length != 0) {
        optionGoalData?.forEach((option) => {
          const data = {
            label: option.name,
            value: option.id,
            userId: option.id,
          };
          optionGoalList.push(data);
        });
      }
    });
    orgdata?.catch(async (err) => {});
    setGoalsOption(() => optionGoalList);
  };

  const getAIResults = async (res) => {
    setIsSearchLoading(true);
    if(taskDetailsinfo ){
      const data = getAITaskSearchResults(res,taskDetailsinfo, userInfoDetails?.data?.accessToken);
      data?.then(await((res) => {
        setIsSearchLoading(false);
        res?.data?.data?.forEach((ele) => {
          setResults((res) => [...res, ele]);
        });
        setSearchedAIHelpText("");
      }))
      data?.catch(await((err) => {
        toast(
          <>
            <div className="m-1 d-flex">
            <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
            <div>
              <h2  style={{
                fontSize: "22px" }}>
                Failed
              </h2>
              <h3 style={{
                fontSize: "18px" }}>
              {err?.response?.data?.message}
              </h3>
              </div>
            </div>
          </>,
          { 
            toastId: "task-create",
            className: 'toast-error-container toast-error-container-after ',
          }
        )
      }));
    }
    
  };

  async function getAlOrgMem() {
    dispatch(await getOrgMembers(userInfoDetails?.data?.accessToken));
  }

  async function  getTeamMem() {
    if (teamId)
      dispatch( await getTeamMemByTeamId(teamId, userInfoDetails?.data?.accessToken));
  }

  async function getOrgTeams() {
    if (orgId) dispatch(await getAllTeams(orgId, userInfoDetails?.data?.accessToken));
  }

  async function getfeedbackTask() {
    const data = taskFeedback(
      taskDetails?.id,
      userInfoDetails.data?.accessToken,
      taskPulse?.value,
      taskStatus?.value,
      createTask?.task_effort
    );
    data?.then(await((res) => {
    }))
    data?.catch(await((err) => {
      console.log("error editTask", err);
    }))
  }

  async function submitEditedTask(e) {
    e.preventDefault();
    const data = editTask(
      orgId,
      taskDetails?.id,
      selectedTeam,
      taskGoalEdit?.value,
      taskOwner,
      taskCreater,
      taskImpact.value,
      createTask,
      userInfoDetails?.data?.accessToken,
      taskDetailsinfo?.task_status
    );
    data?.then(await((res) => {
      setCreateTaskForm(initialTaskState);
      setTaskOwnerValue("");
      setTaskGoalEdit();
      getAlOrgMem();
      getTeamMem();
      if (taskStatus && taskPulse) {
        getfeedbackTask();
      }
      setTaskDetailsinfo(() => res?.data?.data);
      handleTaskEditClick(res?.data?.data)
      props.refreshed();
      props.close(false);
    }))
    data?.catch(await((err) => {}));
  }

  const goToTasksChat = async(taskDetail) => {
    if (!taskDetail.thread) {
      const data = createPersonalRoom(
        orgId,
        taskDetail?.goal_owner?.id,
        "personal",
        taskDetail.name,
        taskDetail,
        null,
        userInfoDetails?.data
      );
      data?.then(await((res) => {
        navigate("/messages", { state: { selectedUser: res?.data?.data } });
      }))
      data?.catch((err) => {
        toast(
          <>
            <div className="m-1 d-flex">
            <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
            <div>
              <h2  style={{
                fontSize: "22px" }}>
                Failed
              </h2>
              <h3 style={{
                fontSize: "18px" }}>
              {err?.response?.data?.message}
              </h3>
              </div>
            </div>
          </>,
          { 
            toastId: "task-create",
            className: 'toast-error-container toast-error-container-after ',
          }
        )
      });
    } else {
      navigate("/messages", {
        state: { selectedUser: { thread_id: taskDetail.thread } },
      });
    }
    localStorage.setItem("gotoChatkey", true);
  };

  function messageChangeHandler(e) {
    setSearchedAIHelpText(() => e.target.value);
  }

  function displayStringWithLineBreaks(str, index) {
    var container = document.querySelectorAll("p#chatResponse");
    if(container[index] !== undefined) {
      container[index].innerHTML = str.replace(/\n/g, "<br>");
    }
  }

  return (
    <div
      className="m-0 p-0 "
      style={{ border: "1px solid #cacbcd", borderRadius: "10px" }}
    >
      <aside className="sidebartoggle m-0 p-0">
        <button
        data-testid="close-task"
          type="button"
          className="btn-close float-end align-center m-2 mt-3 p-2 btn-danger "
          aria-label="Close"
          style={{ border: "0px" }}
          onClick={() => props.close(false)}
        ></button>
        <h2 className="text-center py-1">{taskDetailsinfo?.name}</h2>
      </aside>
      <PerfectScrollbar
        id="chartBox"
        className="widget-media dlab-scroll w-100 p-0 m-0 "
        style={{ maxHeight: "52vh", minHeight: "15vh" }}
        containerRef={(el) => {
          scrollRef.current = el;
        }}
      >
        <Row className="mx-1 p-0 my-3" style={{ backgroundColor: "#ffffff" }}>
          <div className="mt-3">
            <div className="form-group mb-3">
              <h5>Align goal</h5>
              {EditTask ? (
                <Select
                  name="goal_owner"
                  className="p"
                  isClearable
                  options={goalsOption}
                  value={defaultOptions ? defaultOptions : ""}
                  onChange={changeGoalOption}
                />
              ) : (
                <span>{taskDetailsinfo?.goal?.name || "-"}</span>
              )}
            </div>
          </div>
          <div className="mt-2">
            <h5>Description</h5>
            {EditTask ? (
              <textarea
                name="description"
                type="text"
                className="figma-input p"
                placeholder="Enter task description"
                rows={5}
                autocomplete="off"
                value={createTask.description}
                onChange={handleCreateTaskChange}
              />
            ) : (
              <span>{taskDetailsinfo?.description || "-"}</span>
            )}
          </div>
          <div class="accordion-item border-1">
            <Accordion
              className="accordion accordion-solid-bg"
              defaultActiveKey="0"
            >
              <h2 class="accordion-header h3" id="headingOne">
                <b>Details</b>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div
                    style={{ height: "60%", width: "100%" }}
                    className="p-3 m-0"
                  >
                    <Row className="mb-2">
                      <Col className="col-sm-5">
                        <span className="h5" data-testid="task-creator">Task creator </span>
                      </Col>
                      <Col>
                        <div className="d-flex">
                          <div className="chat-img1 mt-1">
                            <b>:</b>&nbsp;&nbsp;
                            <img
                              src={
                                taskDetailsinfo?.task_creator
                                  ?.profile_picture === null
                                  ? profilePicture
                                  : taskDetailsinfo?.task_creator
                                      ?.profile_picture
                              }
                              alt="task-creator"
                              title="task-creator"
                            />
                          </div>
                          <div className="d-flex mb-1 align-items-center">
                            <span className="p">
                              {taskDetailsinfo?.task_creator?.first_name +
                                " " +
                                taskDetailsinfo?.task_creator?.last_name}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-sm-5">
                        <span className="h5">Task owner </span>
                      </Col>
                      <Col>
                        {EditTask ? (
                          <Select
                            name="task_owner"
                            className="p"
                            options={options}
                            styles={{
                              padding: "2px",
                              borderRadius: "10px",
                            }}
                            value={taskOwner}
                            onChange={changeTaskOwner}
                          />
                        ) : (
                          <div className="d-flex">
                            <div className="chat-img1 mt-1">
                              <b>:</b>&nbsp;&nbsp;
                              <img
                                src={
                                  taskDetailsinfo?.task_owner
                                    ?.profile_picture === null
                                    ? profilePicture
                                    : taskDetailsinfo?.task_owner
                                        ?.profile_picture
                                }
                                alt="profile-img"
                                title="profile-img"
                              />
                            </div>
                            <div className="d-flex mb-1 align-items-center">
                              <span className="p">
                                {taskDetailsinfo?.task_owner?.first_name +
                                  " " +
                                  taskDetailsinfo?.task_owner?.last_name}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-sm-5">
                        <span className="h5">Task impact</span>
                      </Col>
                      <Col>
                        {EditTask ? (
                          <Select
                            name="task_impact"
                            className="p"
                            options={taskImpactOptions}
                            defaultValue={taskImpact}
                            onChange={changeTaskImpact}
                          />
                        ) : (
                          <Col>
                            <b>:</b>&nbsp;&nbsp;
                            <span className="p">
                              {taskDetailsinfo?.task_impact}
                            </span>
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Row className="form-group mb-3">
                      <Col className="col-sm-5">
                        <h5>Effort in hours</h5>
                      </Col>
                      <Col>
                        {EditTask &&
                        taskDetailsinfo.task_status !== "Scheduled" ? (
                          <input
                            type="number"
                            className="figma-input-num p"
                            name="task_effort"
                            placeholder="Enter hours"
                            required
                            min={1}
                            autocomplete="off"
                            value={createTask.task_effort}
                            onChange={handleCreateTaskChange}
                            style={{paddingLeft:"10px"}}
                          />
                        ) : (
                          <div className="d-flex">
                            <div className="d-flex mb-1 align-items-center">
                              <b>:</b>&nbsp;&nbsp;
                              <span className="p">
                                {taskDetailsinfo?.task_effort}
                              </span>
                            </div>
                          </div>
                        )}
                        {!createTask?.task_effort ? (
                          <span style={{ color: "red" }}>Required*</span>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col className="col-sm-5">
                        <span className="h5">Task pulse </span>
                      </Col>
                      <Col>
                        <div className="d-flex mt-2">
                          <div className="d-flex mb-1 align-items-center">
                            <b>:</b>&nbsp;&nbsp;{" "}
                            <span className="p">
                              {taskDetailsinfo?.task_pulse}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-sm-5">
                        <span className="h5">Task status</span>
                      </Col>
                      <Col>
                        <div className="d-flex">
                          <div className="d-flex mb-1 align-items-center">
                            <b>:</b>&nbsp;&nbsp;
                            <span className="p">
                              {taskDetailsinfo?.task_status}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col className="col-sm-5">
                        <span className="h5">Task type </span>
                      </Col>
                      <Col>
                        <div className="d-flex">
                          <div className="d-flex mb-1 align-items-center">
                            <b>:</b>&nbsp;&nbsp;
                            <span className="p">
                              {taskDetailsinfo?.task_type}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-sm-5">
                        <span className="h5">Task accepted </span>
                      </Col>
                      <Col>
                        <div className="px-1 d-flex">
                          <div className="d-flex mb-1 align-items-center">
                            <b>:</b>&nbsp;&nbsp;
                            <span className="p">
                              {taskDetailsinfo?.task_accepted === true
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {taskDetailsinfo?.task_status !== "Completed" &&
                    (userInfoDetails?.data?.id ===
                      taskDetailsinfo?.task_owner?.id ||
                      userInfoDetails?.data?.id ===
                        taskDetailsinfo?.task_creator?.id) ? (
                      <Row className="mb-2">
                        <Col className="col-sm-5">
                          <span className="h5">Task completed</span>
                        </Col>
                        <Col>
                          <div className="d-flex">
                            <div className="d-flex mb-1 align-items-center">
                              <Select
                                className="p"
                                name="task_impact"
                                isClearable
                                options={taskPulseOptions}
                                defaultValue={taskPulse}
                                onChange={changeTaskPulse}
                              />
                            </div>
                          </div>
                          <span className="text-danger">
                            {tastComp ? "required *" : ""}
                          </span>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mb-2">
                        <Col className="col-sm-5">
                          <span className="h5">Task completed</span>
                        </Col>
                        <Col>
                          <div className="d-flex">
                            <div className="d-flex mb-1 align-items-center">
                              <b>:</b>&nbsp;&nbsp;
                              <span className="p">
                                {taskDetailsinfo?.task_completed === true
                                  ? "Yes"
                                  : taskDetailsinfo?.task_completed === false
                                  ? "No"
                                  : "na"}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {taskDetailsinfo?.task_status !== "Completed" &&
                    (userInfoDetails?.data?.id ===
                      taskDetailsinfo?.task_owner?.id ||
                      userInfoDetails?.data?.id ===
                        taskDetailsinfo?.task_creator?.id) ? (
                      <Row className="mb-2">
                        <Col className="col-sm-5">
                          <span className="h5">Task success </span>
                        </Col>
                        <Col>
                          <div className="d-flex">
                            <div className="d-flex mb-1 align-items-center">
                              <Select
                                className="p"
                                name="task_status"
                                isClearable
                                options={taskStatusOptions}
                                defaultValue={taskStatus}
                                onChange={changeTaskStatus}
                              />
                            </div>
                          </div>
                          <span className="text-danger">
                            {tastSuccess ? "required *" : ""}
                          </span>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mb-2">
                        <Col className="col-sm-5">
                          <span className="h5">Task success</span>
                        </Col>
                        <Col>
                          <div className="d-flex">
                            <div className="d-flex mb-1 align-items-center">
                              <b>:</b>&nbsp;&nbsp;
                              <span className="p">
                                {taskDetailsinfo?.task_status
                                  ? taskDetailsinfo?.task_success || "No"
                                  : "na"}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="mt-1">
                      <Col className="col-sm-5 mt-2">
                        <span className="h5">Created</span>
                      </Col>
                      <Col>
                        <div className="d-flex">
                          <div className="d-flex mb-1 align-items-center">
                            <b>:</b>&nbsp;&nbsp;
                            <span className="p">
                              {changedDateFormats(taskDetailsinfo?.created_at)}{" "}
                              at{" "}
                              {taskDetailsinfo?.created_at
                                ?.split("T")[1]
                                .slice(0, 5)}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col className="col-sm-5 mt-2">
                        <span className="h5">Updated</span>
                      </Col>
                      <Col>
                        <div className="d-flex">
                          <div className="d-flex mb-1 align-items-center">
                            <b>:</b>&nbsp;&nbsp;
                            <span className="p">
                              {changedDateFormats(taskDetailsinfo?.updated_at)}{" "}
                              at{" "}
                              {taskDetailsinfo?.updated_at
                                ?.split("T")[1]
                                .slice(0, 5)}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
          <div className="mt-3">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <h4 class="float-start">Attachments</h4>
                <button
                  class="figma-link border-0 bg-white float-end pt-2 me-2"
                  title="Upload attachments"
                  style={{ position: "relative" }}
                  onClick={() => fileRef.current.click()}
                >
                  <i
                    class="bi bi-paperclip"
                    type="file"
                    id="img"
                    name="img"
                    accept="image/*"
                    style={{ fontSize: "15px", borderRadius: "30px" }}
                  ></i>
                  &nbsp;&nbsp;Upload
                </button>
              </div>
            </div>
            <input
              id="img"
              ref={fileRef}
              onChange={(e) => {
                var size = "";
                let selectedFile = [];
                e.preventDefault();
                if (e.target.files.length === 1) {
                  size = parseFloat(
                    e.target.files[0].size / (1024 * 1024)
                  ).toFixed(2);
                  // size <= 1 && setDatas([...datas, e.target.files[0]]);
                  size <= 30 && selectedFile.push(e.target.files[0]);
                } else {
                  for (const file of e.target.files) {
                    size = parseFloat(file.size / (1024 * 1024)).toFixed(2);
                    // size <= 1 && setDatas([...datas, file]);
                    size <= 30 && selectedFile.push(file);
                  }
                }
                size > 30 &&
                  setFileErrorMessage(
                    "Uploaded file too Big, please select a file less than 30mb"
                  );
                size < 30 && sendMultipleAttachments(selectedFile);
              }}
              multiple={true}
              type="file"
              accept="image/*,.doc,.csv,text/csv,.xlsx,.xls,.docx,.ppt, .pptx,.txt,.pdf,video/mp4,video/x-m4v,video/*"
              hidden
            />
            <Dropzone
              onDrop={(acceptedFiles) => {
                let file = [];
                acceptedFiles.forEach((files) => {
                  var size = parseFloat(files.size / (1024 * 1024)).toFixed(2);
                  size > 30 &&
                    setFileErrorMessage(
                      "Uploaded file too Big, please select a file less than 30mb"
                    );
                  size < 30 && file.push(files);
                });
                if (file?.length) sendMultipleAttachments(file);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    border: "1px solid #e6eae8",
                    width: "100%",
                    maxHeight: "15rem",
                    minHeight: "15rem",
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    marginRight: "1rem",
                  }}
                  {...getRootProps({
                    onClick: (event) => event.stopPropagation(),
                  })}
                >
                  <input {...getInputProps()} />

                  <PerfectScrollbar
                    className="dlab-scroll p-0 m-0 "
                    style={{ maxHeight: "14rem", minHeight: "14rem" }}
                    containerRef={(el) => {
                      scrollRefAttachment.current = el;
                    }}
                  >
                    <div className="p-1 m-1 row">
                      {tasksAttachments?.map((element, index) => (
                        <>
                          <Col className="col-sm-6" key={index}>
                            <Card className="m-1 p-0">
                              <div className="mb-3 m-0">
                                {getNameAndType(element?.attachments)?.type ==
                                  ".png" ||
                                getNameAndType(element?.attachments)?.type ==
                                  ".jpg" ||
                                getNameAndType(element?.attachments)?.type ==
                                  ".jpeg" ||
                                getNameAndType(element?.attachments)?.type ==
                                  ".svg" ? (
                                  <a
                                    className="text-center py-1"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <img
                                      src={element?.attachments}
                                      className="img-fluid img-thumbnail p-1 m-0"
                                      alt="attachment"
                                      title="attachment"
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    ></img>{" "}
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".pdf" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="150"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-file-pdf"
                                        viewBox="1 0 16 16"
                                      >
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                        <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".doc" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="blue"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-doc"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".txt" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="gray"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-txt"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2h-2v-1h2a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.928 15.849v-3.337h1.136v-.662H0v.662h1.134v3.337h.794Zm4.689-3.999h-.894L4.9 13.289h-.035l-.832-1.439h-.932l1.228 1.983-1.24 2.016h.862l.853-1.415h.035l.85 1.415h.907l-1.253-1.992 1.274-2.007Zm1.93.662v3.337h-.794v-3.337H6.619v-.662h3.064v.662H8.546Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".xlsx" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="green"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-xlsx"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".xls" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="#00cc00"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-xls"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".docx" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="#ffcc00"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-docx"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-6.839 9.688v-.522a1.54 1.54 0 0 0-.117-.641.861.861 0 0 0-.322-.387.862.862 0 0 0-.469-.129.868.868 0 0 0-.471.13.868.868 0 0 0-.32.386 1.54 1.54 0 0 0-.117.641v.522c0 .256.04.47.117.641a.868.868 0 0 0 .32.387.883.883 0 0 0 .471.126.877.877 0 0 0 .469-.126.861.861 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642Zm.803-.516v.513c0 .375-.068.7-.205.973a1.47 1.47 0 0 1-.589.627c-.254.144-.56.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.463 1.463 0 0 1-.589-.627 2.151 2.151 0 0 1-.205-.973v-.513c0-.379.069-.704.205-.975.137-.274.333-.483.59-.627.257-.147.564-.22.92-.22.357 0 .662.073.916.22.256.146.452.356.59.63.136.271.204.595.204.972ZM1 15.925v-3.999h1.459c.406 0 .741.078 1.005.235.264.156.46.382.589.68.13.296.196.655.196 1.074 0 .422-.065.784-.196 1.084-.131.301-.33.53-.595.689-.264.158-.597.237-.999.237H1Zm1.354-3.354H1.79v2.707h.563c.185 0 .346-.028.483-.082a.8.8 0 0 0 .334-.252c.088-.114.153-.254.196-.422a2.3 2.3 0 0 0 .068-.592c0-.3-.04-.552-.118-.753a.89.89 0 0 0-.354-.454c-.158-.102-.361-.152-.61-.152Zm6.756 1.116c0-.248.034-.46.103-.633a.868.868 0 0 1 .301-.398.814.814 0 0 1 .475-.138c.15 0 .283.032.398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.44 1.44 0 0 0-.49-.272 1.836 1.836 0 0 0-.606-.097c-.355 0-.66.074-.911.223-.25.148-.44.359-.571.633-.131.273-.197.6-.197.978v.498c0 .379.065.704.194.976.13.271.321.48.571.627.25.144.555.216.914.216.293 0 .555-.054.785-.164.23-.11.414-.26.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.699.699 0 0 1-.273.248.874.874 0 0 1-.401.088.845.845 0 0 1-.478-.131.834.834 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627v-.495Zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035l.823-1.439Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".csv" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="#6699ff"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-csv"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".ppt" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="#33ccff"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-filetype-ppt"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.817-1.333h-1.6v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474.108-.201.161-.427.161-.677 0-.25-.052-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.793.793 0 0 1-.375.082H4.15V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.767-.67v3.336H7.48v-3.337H6.346v-.662h3.065v.662H8.274Z"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                ) : getNameAndType(element?.attachments)
                                    ?.type == ".mp4" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".webm" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".mov" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".avi" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".m4v" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".mp3" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".mkv" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".m4a" ||
                                  getNameAndType(element?.attachments)?.type ==
                                    ".mpg" ? (
                                  <a
                                    className="text-center py-1 b-0"
                                    href={element?.attachments}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span style={{ color: "red" }}>
                                      <svg
                                        color="#a97dbd"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-file-play"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 6.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V6.884z" />
                                      </svg>
                                    </span>
                                  </a>
                                ) : (
                                  <Col className="col-sm-12">
                                    <a
                                      className="text-center"
                                      href={element?.attachments}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download
                                    >
                                      <svg
                                        color="#43752e"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="110"
                                        height="110"
                                        fill="currentColor"
                                        class="bi bi-file-earmark"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                      </svg>
                                    </a>
                                    <span className="text-wrap">
                                      &nbsp;&nbsp;&nbsp;{" "}
                                      {
                                        getNameAndType(element?.attachments)
                                          ?.name
                                      }
                                    </span>
                                  </Col>
                                )}
                                <Row className="p-1 mb-3">
                                  {getNameAndType(element?.attachments)
                                    ?.type === ".png" ? (
                                    <Col className="">
                                      <a
                                        className=""
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="green"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="25"
                                          fill="currentColor"
                                          class="bi bi-filetype-png"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-3.76 8.132c.076.153.123.317.14.492h-.776a.797.797 0 0 0-.097-.249.689.689 0 0 0-.17-.19.707.707 0 0 0-.237-.126.96.96 0 0 0-.299-.044c-.285 0-.506.1-.665.302-.156.201-.234.484-.234.85v.498c0 .234.032.439.097.615a.881.881 0 0 0 .304.413.87.87 0 0 0 .519.146.967.967 0 0 0 .457-.096.67.67 0 0 0 .272-.264c.06-.11.091-.23.091-.363v-.255H8.82v-.59h1.576v.798c0 .193-.032.377-.097.55a1.29 1.29 0 0 1-.293.458 1.37 1.37 0 0 1-.495.313c-.197.074-.43.111-.697.111a1.98 1.98 0 0 1-.753-.132 1.447 1.447 0 0 1-.533-.377 1.58 1.58 0 0 1-.32-.58 2.482 2.482 0 0 1-.105-.745v-.506c0-.362.067-.678.2-.95.134-.271.328-.482.582-.633.256-.152.565-.228.926-.228.238 0 .45.033.636.1.187.066.348.158.48.275.133.117.238.253.314.407Zm-8.64-.706H0v4h.791v-1.343h.803c.287 0 .531-.057.732-.172.203-.118.358-.276.463-.475a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.475-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.381.574.574 0 0 1-.238.24.794.794 0 0 1-.375.082H.788v-1.406h.66c.218 0 .389.06.512.182.123.12.185.295.185.521Zm1.964 2.666V13.25h.032l1.761 2.675h.656v-3.999h-.75v2.66h-.032l-1.752-2.66h-.662v4h.747Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }{" "}
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".jpg" ||
                                    getNameAndType(element?.attachments)
                                      ?.type === ".jpeg" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="gray"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-jpg"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-4.34 8.132c.076.153.123.317.14.492h-.776a.797.797 0 0 0-.097-.249.689.689 0 0 0-.17-.19.707.707 0 0 0-.237-.126.96.96 0 0 0-.299-.044c-.285 0-.507.1-.665.302-.156.201-.234.484-.234.85v.498c0 .234.032.439.097.615a.881.881 0 0 0 .304.413.87.87 0 0 0 .519.146.967.967 0 0 0 .457-.096.67.67 0 0 0 .272-.264c.06-.11.091-.23.091-.363v-.255H8.24v-.59h1.576v.798c0 .193-.032.377-.097.55a1.29 1.29 0 0 1-.293.458 1.37 1.37 0 0 1-.495.313c-.197.074-.43.111-.697.111a1.98 1.98 0 0 1-.753-.132 1.447 1.447 0 0 1-.533-.377 1.58 1.58 0 0 1-.32-.58 2.482 2.482 0 0 1-.105-.745v-.506c0-.362.066-.678.2-.95.134-.271.328-.482.582-.633.256-.152.565-.228.926-.228.238 0 .45.033.636.1.187.066.347.158.48.275.133.117.238.253.314.407ZM0 14.786c0 .164.027.319.082.465.055.147.136.277.243.39.11.113.245.202.407.267.164.062.354.093.569.093.42 0 .748-.115.984-.345.238-.23.358-.566.358-1.005v-2.725h-.791v2.745c0 .202-.046.357-.138.466-.092.11-.233.164-.422.164a.499.499 0 0 1-.454-.246.577.577 0 0 1-.073-.27H0Zm4.92-2.86H3.322v4h.791v-1.343h.803c.287 0 .531-.057.732-.172.203-.118.358-.276.463-.475.108-.201.161-.427.161-.677 0-.25-.052-.475-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.546 1.333a.795.795 0 0 1-.085.381.574.574 0 0 1-.238.24.794.794 0 0 1-.375.082H4.11v-1.406h.66c.218 0 .389.06.512.182.123.12.185.295.185.521Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".doc" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="blue"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-doc"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".pdf" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="red"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-pdf"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".txt" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="gray"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-txt"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2h-2v-1h2a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.928 15.849v-3.337h1.136v-.662H0v.662h1.134v3.337h.794Zm4.689-3.999h-.894L4.9 13.289h-.035l-.832-1.439h-.932l1.228 1.983-1.24 2.016h.862l.853-1.415h.035l.85 1.415h.907l-1.253-1.992 1.274-2.007Zm1.93.662v3.337h-.794v-3.337H6.619v-.662h3.064v.662H8.546Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".xlsx" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="green"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-xlsx"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".xls" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="#00cc00"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-xls"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".docx" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="#ffcc00"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-docx"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-6.839 9.688v-.522a1.54 1.54 0 0 0-.117-.641.861.861 0 0 0-.322-.387.862.862 0 0 0-.469-.129.868.868 0 0 0-.471.13.868.868 0 0 0-.32.386 1.54 1.54 0 0 0-.117.641v.522c0 .256.04.47.117.641a.868.868 0 0 0 .32.387.883.883 0 0 0 .471.126.877.877 0 0 0 .469-.126.861.861 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642Zm.803-.516v.513c0 .375-.068.7-.205.973a1.47 1.47 0 0 1-.589.627c-.254.144-.56.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.463 1.463 0 0 1-.589-.627 2.151 2.151 0 0 1-.205-.973v-.513c0-.379.069-.704.205-.975.137-.274.333-.483.59-.627.257-.147.564-.22.92-.22.357 0 .662.073.916.22.256.146.452.356.59.63.136.271.204.595.204.972ZM1 15.925v-3.999h1.459c.406 0 .741.078 1.005.235.264.156.46.382.589.68.13.296.196.655.196 1.074 0 .422-.065.784-.196 1.084-.131.301-.33.53-.595.689-.264.158-.597.237-.999.237H1Zm1.354-3.354H1.79v2.707h.563c.185 0 .346-.028.483-.082a.8.8 0 0 0 .334-.252c.088-.114.153-.254.196-.422a2.3 2.3 0 0 0 .068-.592c0-.3-.04-.552-.118-.753a.89.89 0 0 0-.354-.454c-.158-.102-.361-.152-.61-.152Zm6.756 1.116c0-.248.034-.46.103-.633a.868.868 0 0 1 .301-.398.814.814 0 0 1 .475-.138c.15 0 .283.032.398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.44 1.44 0 0 0-.49-.272 1.836 1.836 0 0 0-.606-.097c-.355 0-.66.074-.911.223-.25.148-.44.359-.571.633-.131.273-.197.6-.197.978v.498c0 .379.065.704.194.976.13.271.321.48.571.627.25.144.555.216.914.216.293 0 .555-.054.785-.164.23-.11.414-.26.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.699.699 0 0 1-.273.248.874.874 0 0 1-.401.088.845.845 0 0 1-.478-.131.834.834 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627v-.495Zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035l.823-1.439Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".csv" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="#6699ff"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-csv"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type === ".ppt" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="#33ccff"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-filetype-ppt"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.817-1.333h-1.6v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474.108-.201.161-.427.161-.677 0-.25-.052-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.793.793 0 0 1-.375.082H4.15V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.767-.67v3.336H7.48v-3.337H6.346v-.662h3.065v.662H8.274Z"
                                          />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : getNameAndType(element?.attachments)
                                      ?.type == ".mp4" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".webm" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".mov" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".avi" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".m4v" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".mp3" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".mkv" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".m4a" ||
                                    getNameAndType(element?.attachments)
                                      ?.type == ".mpg" ? (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="#a97dbd"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-file-play"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 6.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V6.884z" />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  ) : (
                                    <Col className="col-sm-12">
                                      <a
                                        className="text-center"
                                        href={element?.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                      >
                                        <svg
                                          color="#43752e"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="110"
                                          height="110"
                                          fill="currentColor"
                                          class="bi bi-file-earmark"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                        </svg>
                                      </a>
                                      <span className="text-wrap p">
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        {
                                          getNameAndType(element?.attachments)
                                            ?.name
                                        }
                                      </span>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </Card>
                          </Col>
                        </>
                      ))}

                      {isLoading && (
                        <Col>
                          <LoadingSpinner data={{padding: "5px",margin: "25px"  }}/>
                        </Col>
                      )}
                      {FileErrorMessage && (
                        <Col>
                          <center>
                            <div
                              style={{ padding: "5px", margin: "25px" }}
                            ></div>
                            <div className="text-danger">
                              {FileErrorMessage}
                            </div>
                          </center>
                        </Col>
                      )}
                      <Col>
                        <center
                          className="m-3 p"
                          style={{ padding: "20px", margin: "300px" }}
                        >
                          Drag and drop files to upload{" "}
                        </center>
                      </Col>
                    </div>
                  </PerfectScrollbar>
                </div>
              )}
            </Dropzone>
          </div>
        </Row>
        <div
          className="row d-flex m-0 p-0 my-3"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="col my-1">
            {EditTask && taskDetailsinfo?.task_status !== "Completed" && (
              <div className="text-center">
                <button
                disabled={!taskDetailsinfo?.task_accepted}
                  type="submit"
                  className="figma-btn"
                  onClick={(e) => {
                    submitEditedTask(e);
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          {props?.noti ? <div className="col my-1">
            {goToTaskChats ||
            userInfoDetails?.data?.id === taskDetailsinfo?.task_owner?.id ||
            userInfoDetails?.data?.id === taskDetailsinfo?.task_creator?.id ? (
              <Row>
                <div className="text-center">
                  <button
                    className="figma-btn text-white"
                    style={{ backgroundColor: "#424C70" }}
                    to=""
                    onClick={() => goToTasksChat(taskDetailsinfo)}
                  >
                    <i class="bi bi-chat-dots me-2"></i>
                    Go to chat
                  </button>
                </div>
              </Row>
            ) : (
              ""
            )}
          </div> : ""}
        </div>
        <Row className="m-0 p-0 m-3">
          {Results.length >= 1 && AIHelp && (
            <Row className="m-0 p-0">
              <h3>AI tips: </h3>
              <div className="m-0 p-0">
                {showResults.length &&
                  showResults.map((ele, index) => (
                    <>
                      <div
                        className="row p-0 m-0 mb-2"
                        style={{
                          backgroundColor: "#cfd1d3",
                          zIndex: 50,
                          width: "100%",
                        }}
                      >
                        <h4 className="p-2 h4" style={{wordBreak:"break-all"}}>Prompt : {ele?.prompt}</h4>
                        <div className="p-0 bg-white">
                          <p
                            className="px-2 py-3 fs-16"
                            id="chatResponse"
                            style={{ zIndex: 5, backgroundColor: "#f3f3f3" }}
                          >
                            {displayStringWithLineBreaks(ele?.response, index)}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              {showResults?.length === 1 && Results?.length >= 2 && !loadMore ?   
                <div className="fs-18" style={{ textAlign: "center" }}>
                  <Link
                    to=""
                    className="figma-link col-sm-3 mx-5 fs-18"
                    data-bs-toggle="tooltip"
                    title="Load more results"
                    onClick={(e) => {
                      e.preventDefault();
                      setloadMore(true);
                      setShowResults(Results);
                    }}
                  >
                    Load more...
                  </Link>
                </div>:""}
            </Row>
            
          )}
          {isSearchLoading && (
              <LoadingSpinner/>
          )}

          {AIHelp && (
            <Row className=" m-0  p-0 my-2">
              <div className="my-2 mx-0">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getAIResults(searchedAIHelpText);
                  }}
                >
                  <div className="d-flex m-0">
                    <input
                      className="figma-input fs-14 p"
                      rows={2}
                      type="text"
                      name="name"
                      placeholder="Ask AI for help"
                      autocomplete="off"
                      style={{ border: "1px solid #8a8686" }}
                      value={searchedAIHelpText}
                      onChange={messageChangeHandler}
                      required
                      setIsLoading={isSearchLoading}
                    />
                    <button
                      title="search prompt result"
                      type="submit"
                      className="figma-btn float-end m-0 p-0 ms-3"
                    >
                      <span>Search</span>
                    </button>
                  </div>
                </form>
              </div>
            </Row>
          )}
        </Row>
      </PerfectScrollbar>
    </div>
  );
}

export default TaskSidebar;
