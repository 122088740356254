import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import {
  getPartnerStrategy,
  addReviews,
  askMeLater,
  strategyReviewNoThanks,
} from "../../../services/StoreService";
import { getOrgById } from "../../../services/OrganizationService";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Typography, Link } from '@mui/material';

function RatingsIcon(props) {
  const { isChecked, handleStarClick, num } = props;
  let fontWeightValue = isChecked ? "1000" : "normal";

  return (
    <span
      className={isChecked ? "fa fa-star checked starsColor" : "fa fa-star"}
      style={{ fontWeight: fontWeightValue }}
      onClick={() => handleStarClick(num)}
    ></span>
  );
}

const RatingAndComment = (props) => {
  const [show, setShow] = useState(false);
  const [strategiesData, setStrategiesData] = useState({});
  const orgId = localStorage.getItem("organization_id");
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [strategyDataAsk, setStrategyDataAsk] = useState([]);
  const [strategyDetail, setStrategyDetail] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [errors, setErrors] = useState("");
  

  const handleStarClick = (strategyId, clickedStar) => {
    setStrategiesData((prevStrategiesData) => ({
      ...prevStrategiesData,
      [strategyId]: {
        ...prevStrategiesData[strategyId],
        rating: clickedStar,
      },
    }));
    setErrors("");
  };

  const handleCommentChange = (strategyId, event) => {
    setErrors("");
    setStrategiesData((prevStrategiesData) => ({
      ...prevStrategiesData,
      [strategyId]: {
        ...prevStrategiesData[strategyId],
        comment: event.target.value,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const reviews = Object.keys(strategiesData).map((strategyId) => ({
      strategy: parseInt(strategyId),
      comment: strategiesData[strategyId].comment || "",
      rating: strategiesData[strategyId].rating || 0,
    }));

    setReviewData(reviews);

    // const strategyIds = Object.keys(strategiesData).map((strategyId) =>
    //   parseInt(strategyId)
    // );

    // Update strategyDataAsk with strategy IDs
    // setStrategyDataAsk(strategyIds);

    const data = addReviews(reviews, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      setShow(false);
    });
    data?.catch((err) => {
      console.log("errorReviews", err?.response?.data?.message);
      if (errors === "") {
        setErrors(err?.response?.data?.message);
      } else {
        // setShow(false);
      }
    });
    window.location.reload()
  };

  const askMeLaterHandler = () => {
    setShow(false);

    const data = askMeLater(
      strategyDataAsk,
      userInfoDetails?.data?.accessToken
    );
    data?.then((res) => {
      console.log(res, "data");
    });
    data?.catch((err) => {
      console.log("errorReviews", err);
    });
    window.location.reload()
  };

  const StrategyReviewNoThanks = () =>{
    setShow(false);

    const data = strategyReviewNoThanks(
      strategyDataAsk,
      userInfoDetails?.data?.accessToken
    );
    data?.then((res) => {
      console.log(res, "data");
    });
    data?.catch((err) => {
      console.log("errorReviews", err);
    });
    window.location.reload()

  }

  async function getOrgByOrgId(orgId, token) {
    if (orgId !== null) {
      const data = getOrgById(orgId, token);
      data?.then((res) => {
        console.log(res, "data");
      });
      data?.catch((err) => {
        console.log("error", err?.response?.data?.data);

        let datts = [];
        const data = [];
        err?.response?.data?.data?.strategy?.forEach((element) => {
          datts.push({
            strategy: element.id,
            rating: strategiesData[element.id]?.rating || 0,
            comment: strategiesData[element.id]?.comment || "",
          });

          data.push({ element });
          setStrategyDataAsk((prevStrategyDataAsk) => [
            ...prevStrategyDataAsk,
            element.id,
          ]);
        });
        // setStrategyData(datts);
        setStrategyDetail(data);
        if (
          err?.response?.status === 403 &&
          err?.response?.data?.message === "Please add reviews and ratings"
        ) {
          setShow(true);
        }
      });
    }
  }

  useEffect(() => {
    getOrgByOrgId(orgId, userInfoDetails.data.accessToken);
  }, []);

  return (
    <>
      {strategyDetail?.length === 1 && (
        <Modal
          className="fade bd-example-modal-md pt-6"
          show={show}
          size="md"
        >
          <Modal.Header style={{ display:"flex", alignItems:"center" , justifyContent:"center"}}>
            <div >
              <h3 className="">Your opinion matters to us!</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="row mb-4" style={{ textAlign: "center", display:"flex", alignItems:"center", justifyContent:"center"}}>
           
                <div>
                  <h4 className="mb-4">How was your experience?</h4>
                </div>
                {strategyDetail?.map((item) => (
                  <>
                    <div className=" mb-2"  style={{wordWrap:"break-word"}} >
                      <h5> {item?.element?.name}</h5>
                    </div>
                   
                     <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '200px', // Set your desired width
        height: '100px', // Set your desired height
        overflow: 'hidden',
       
      
      }}
    >
        <Link href={`/product/${item?.element?.name}`} underline="none" target="_self" rel="noopener noreferrer">
      <img
        src={item?.element?.image}
        alt="description"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          cursor: 'pointer'
        }}
      />
      </Link>
                   </Box>
   
                    <div className="mb-2" style={{wordWrap:"break-word"}}>
                      {/* <h5>  {item?.element?.description?.split('. ')[0] + '.'}</h5> */}
                      {/* <p className="" dangerouslySetInnerHTML={{ __html:item?.element?.description?.split('. ')[0] + '.' }}></p>  */}
                    </div>
                 

                    <div>
                      {Array.from({ length: 5 }, (_, index) => index + 1).map(
                        (ele) => (
                          <RatingsIcon
                            key={ele}
                            num={ele}
                            handleStarClick={() =>
                              handleStarClick(item?.element?.id, ele)
                            }
                            isChecked={
                              ele <= strategiesData[item?.element?.id]?.rating
                            }
                          />
                        )
                      )}
                    </div>
                    <div
                      className="p-4"
                      style={{ width: "100%", alignItems: "center" }}
                    >
                      <textarea
                        name="comment"
                        className="p"
                        style={{
                          height: "100%",
                          background: "#ffffff",
                          border: "1px solid #e7e9ed",
                          padding: "8px 8px",
                        }}
                        placeholder="Please add comment"
                        rows={4}
                        value={strategiesData[item?.element?.id]?.comment || ""}
                        onChange={(e) =>
                          handleCommentChange(item?.element?.id, e)
                        }
                      />
                    </div>
                  </>
                ))}
              </div>
              {errors !== "" && (
                <div
                  className="alert alert-danger ms-4 me-4"
                  role="alert"
                  style={{ textAlign: "center" }}
                >
                  {errors}
                </div>
              )}

              <div
                className=" d-flex justify-content-center"
                style={{ textAlign: "center" }}
              >
                <button type="submit" className="btn rate-btn p ms-2">
                  Rate now
                </button>
              </div>
            </form>
            <div style={{display:"flex", justifyContent:"space-between"}} >
            <button
              className="btn me-2 mt-4"
              onClick={askMeLaterHandler}
              // style={{ marginLeft: "180px" }}
            >
              May be later
            </button>
            <button
              className="btn me-2 mt-4"
              onClick={StrategyReviewNoThanks}
              // style={{ marginLeft: "180px" }}
            >
              No thanks
            </button>
            
            </div>
          </Modal.Body>
        </Modal>
      )}

      {strategyDetail?.length !== 1 && (
        <Modal
          className="fade bd-example-modal-md pt-6 mt-4"
          show={show}
          size="lg"
        >
          <Modal.Header style={{ display:"flex", alignItems:"center" , justifyContent:"center"}}>
            <div >
              <h3 className="">Your opinion matters to us!</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
            <PerfectScrollbar
                  id="chartBox"
                  className="widget-media dlab-scroll w-100"
                  options={{ suppressScrollX: true }}
                  style={{ maxHeight: "45vh", minHeight: "7vh" }}
                >  
              {strategyDetail?.map((item) => (
                 
                <Row key={item?.id} className="mb-4">
                  <Col className=""  xs={12} sm={12} md={12} lg={3}>
                    <div>
                    <Box
                 sx={{
                  // display: 'flex',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                  width: '100%', // Set your desired width
                  height: '100%', // Set your desired height
                  overflow: 'hidden'
             }}
             >
        <Link href={`/product/${item?.element?.name}`} underline="none" target="_self" rel="noopener noreferrer">
      <img
        src={item?.element?.image}
        alt="description"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          cursor: 'pointer'
        }}
      />
    
      </Link>
                   </Box>
                 {/* <div className="mb-4 mt-2"  style={{overflowWrap:"anywhere"}}>{item?.element?.name}</div> */}
                </div>
                  </Col>
                  {/* <div className="col" style={{ overflowWrap:"anywhere"}}>{item?.element?.description}</div> */}
                  <Col className="p-3" xs={4} sm={4} md={4} lg={3} style={{overflowWrap:"anywhere" }}>
                  {/* {item?.element?.description?.split('. ')[0] + '.'} */}
                  <div className="mb-4"  style={{overflowWrap:"anywhere"}}>{item?.element?.name}</div>
                  {/* <p className="" dangerouslySetInnerHTML={{ __html:item?.element?.description?.split('. ')[0] + '.' }}></p>  */}
                  </Col>

                  <Col className="p-3" xs={4} sm={4} md={4} lg={3} style={{display:"flex", justifyContent:"center"}}>
                    {Array.from({ length: 5 }, (_, index) => index + 1).map(
                      (ele) => (
                        <RatingsIcon
                          key={ele}
                          num={ele}
                          handleStarClick={() =>
                            handleStarClick(item?.element?.id, ele)
                          }
                          isChecked={
                            ele <= strategiesData[item?.element?.id]?.rating
                          }
                        />
                      )
                    )}
                  </Col>
                  <Col className="p-3" xs={4} sm={4} md={4} lg={3} style={{display:"flex", justifyContent:"center"}}>
                    {" "}
                    <textarea
                      name="comment"
                      className="figma-input p"
                      placeholder="Please add comment"
                      style={{
                        height: "100%",
                        background: "#ffffff",
                        border: "1px solid #e7e9ed",
                        padding: "8px 8px",
                      }}
                      rows={3}
                      value={strategiesData[item?.element?.id]?.comment || ""}
                      onChange={(e) =>
                        handleCommentChange(item?.element?.id, e)
                      }
                    />
                  </Col>
                </Row>
              
              ))}

</PerfectScrollbar>

              {errors !== "" && (
                <div
                  className="alert alert-danger mt-5"
                  role="alert"
                  style={{ textAlign: "center" }}
                >
                  {errors}
                </div>
              )}

              <div
                className="mt-4 d-flex justify-content-center"
                style={{ textAlign: "center" }}
              >
                <button type="submit" className="btn rate-btn p ms-2">
                  Rate now
                </button>
              </div>
            </form>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <button
              className="btn me-2 mt-4"
              onClick={askMeLaterHandler}
              // style={{ marginLeft: "180px" }}
            >
              Maybelater
            </button>
            <button
              className="btn me-2 mt-4"
              onClick={StrategyReviewNoThanks}
              // style={{ marginLeft: "180px" }}
            >
              No thanks
            </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default RatingAndComment;
