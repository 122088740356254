import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import { partnerSignUp, verifyPartnerSignupOTP } from "../../services/AuthService";

// import { resendOTPSignup, partnerSignUp, verifyPartnerSignupOTP } from "../../services/AuthService";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import PlansomLogo from "../../images/PlansomLogo2.png";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { number } from "prop-types";

export const LocationDisplayReg = () => {
    const location = useLocation()
    return <div data-testid="location-display-reg">{location?.pathname}</div>
}

const PartnerRegistration = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [first_name, setFirstname] = useState('');
    const [last_name, setLastname] = useState('');
    const [Weburl, setWeburl] = useState("");
    const [BuisnessType, setBusinessType] = useState("");
    const [ClientsCount, setClientsCount] = useState(null);
    const [comment, setComment] = useState("");
    console.log("ClientsCount", ClientsCount)
    let errorsObj = { email: '', first_name:'', last_name:'', Weburl:'', BuisnessType:'', comment:'', ClientsCount:'', company_name:'' };
    const [errors, setErrors] = useState(errorsObj);
    const [disableEmail, setDisableEmail] = useState(false);
    const [msg, setMsg] = useState('');
    const [openOtp, setOtp] = useState(false);
    console.log("check value", openOtp)
    const [btn, setBtn] = useState('Verify email');
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    console.log("value", value)
    const stateData = useSelector(state => state);
    const [isAccepted, setIsAccepted] = useState(false);
    const [image, setImage] = useState(null);
    const [spinner, setSpinner] = useState(false)
    const [spinnerSign, setSpinnerSign] = useState(false)

    console.log(stateData.auth.auth.message,"pejuioojuiooiwieieiiieiqeiqi")

    const client_choice = [
        {
            name: "choice_one",
            value: "Between 0 to 50",
        },
        {
            name: "choice_two",
            value: "Between 50 to 100",
        },
        {
            name: "choice_three",
            value: "Between 100 to 500",
        },
        {
            name: "choice_four",
            value: "Between 500 to 1000",
        },
        {
            name: "choice_five",
            value: "Between 1000 to 10000",
        },
        {
            name: "choice_six",
            value: "10000 and above",
        }
    ];

    const [SelectCount, setSelectCount] = useState([])

    useEffect(() => {
        if (isAccepted) {
            handle()
        }
    }, [isAccepted, value])

    const handle = () => {
        setIsAccepted(true)
    };

    useEffect(() => {
        setOtp(false);
        if (location?.state?.email !== null
            && location?.state?.email !== undefined
            && location?.state?.email !== '') {
            setEmail(location?.state?.email)
            setBtn('Create account')
            setDisableEmail(true);
            console.log('UseEffect register')
        }
        let optionList = [];
        client_choice?.map(option => {
            const data = {
                label: option.value,
                value: option.name
            }
            optionList.push(data)
        });
        setSelectCount(optionList);
    }, []);

    useEffect(() => {
        setClientsCount(ClientsCount)
    }, [ClientsCount])

    useEffect(() => {
        if (email !== '') {
          setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }
        if(first_name !== ''){
          setErrors(prevErrors => ({ ...prevErrors, first_name: '' }));
        }
        if(last_name !== ''){
          setErrors(prevErrors => ({ ...prevErrors, last_name: '' }));
        }
        if(Weburl !== ''){
          setErrors(prevErrors => ({ ...prevErrors, Weburl: '' }));
        }
        if(BuisnessType !== ''){
          setErrors(prevErrors => ({ ...prevErrors, BuisnessType: '' }));
        }
        if(comment !== ''){
          setErrors(prevErrors => ({ ...prevErrors, comment: '' }));
        }
        if(ClientsCount !== null){
          setErrors(prevErrors => ({ ...prevErrors, ClientsCount: '' }));
        }
        if(company_name !== ''){
          setErrors(prevErrors => ({ ...prevErrors, company_name: '' }));
        }
    }, [email,first_name,last_name,Weburl,BuisnessType,comment,ClientsCount, company_name])



    const otpSubmit = () => {
      setSpinnerSign(true)
        const data = verifyPartnerSignupOTP(email, value);
        data.then((res) => {
          setSpinnerSign(false)
            console.log("data", res)
            navigate("/");
            swal("Good job!", "Partner application has been successfully submitted!", "success");   
           
        })
        data.catch((err) => {
            console.log("error");
           
            const errorMessage = err?.response?.data?.message
            swal("Oops", errorMessage, "error");
            setSpinnerSign(false)

        });
    };

    const setotp = (value) => {
        setValue(value)
    }

    function onSignUp(e) {
        console.log("signup")
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required*';
            error = true;
        }
        if(first_name === ''){
          errorObj.first_name = 'First name is Required*';
          error = true;
        }
        if(last_name === ''){
          errorObj.last_name = 'Last name is Required*';
          error = true;
        }
        if(Weburl === ''){
          errorObj.Weburl = 'Website URL is Required*';
          error = true;
        }
        if(BuisnessType === ''){
          errorObj.BuisnessType = 'Business type is Required*';
          error = true;
        }
        if(comment === ''){
          errorObj.comment = 'Comment is Required*';
          error = true;
        }
        if(ClientsCount === null){
          errorObj.ClientsCount = 'Client choice is required*'
          error = true;
        }
        if(company_name === ''){
          errorObj.company_name = 'Company name is required*'
          error = true;
        }
        setErrors(errorObj);
        if (error) return;
        else {
          setSpinner(true)
            const data = partnerSignUp(
              email,
                first_name,
                last_name,
                Weburl,
                BuisnessType,
                ClientsCount?.value,
                comment,
                company_name,
                image
                );
            data.then((res) => {
                setOtp(true)
                setSpinner(false)
                
              
            })
            data.catch((err) => {
                console.log("error");
             
                const errorMessage = err?.response?.data?.message
                swal("Oops", errorMessage, "error");
                setSpinner(false)
  
            });
        }
    }

    const resendOTP = () => {
        // const data = resendOTPSignup(email);
        // data.then(async (res) => {
        //     // console.log('GoalDetailsssssss', res);
        // })
        // data.catch(async (err) => {
        //     // console.log('error deleteTask',err)
        // })
    };

    const changeCount = Count => {
        setClientsCount(Count)
    }

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setImage(file);
      
    };

    return (
        <>

            <div className="authincation h-100 float-center">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center mt-5">
                        <div className="col-12 mt-5">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form" style={{ padding: "2.125rem 3.125rem" }}>
                                            <div className="text-center mb-3">
                                                <Link to="/login">
                                                    <div data-testid="image" className="text-center mb-4">
                                                        <img src={PlansomLogo} style={{ height: '80px', width: '80px', padding: "1px", margin: "1px 5px 2px 40px" }} alt="" />
                                                    </div>
                                                </Link>
                                            </div>

                                            {!openOtp &&
                                                <>
                                                    <h1 className="text-center mb-2">Create account</h1>
                                                    <p className="" style={{ color: "#717994" }}>Enter your information below to create a new partner account.</p>
                                                    <form onSubmit={onSignUp}>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group mb-3">
                                                                    <h5>First name</h5>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={first_name}
                                                                        onChange={(e) => setFirstname(e.target.value)}
                                                                        className="figma-input p"
                                                                        placeholder="First name"
                                                                        // required
                                                                    />
                                                                     {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <h5>Last name</h5>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={last_name}
                                                                        onChange={(e) => setLastname(e.target.value)}
                                                                        className="figma-input p"
                                                                        placeholder="Last name"
                                                                        // required
                                                                    />
                                                                     {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                                                                </div>

                                                                <div className="form-group mb-3" hidden={disableEmail == true}>
                                                                    <h5>Email</h5>
                                                                    <input
                                                                       type="email"
                                                                        defaultValue={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        className="figma-input p"
                                                                        placeholder="Email"
                                                                    // required
                                                                    />
                                                                    {errors.email && <div className="text-danger">{errors.email}</div>}
                                                                </div>
                                                                <div className="form-group mb-3" hidden={disableEmail == true}>
                                                                    <h5>Company name</h5>
                                                                    <input
          
                                                                        defaultValue={company_name}
                                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                                        className="figma-input p"
                                                                        placeholder="Company name"
                                                                    // required
                                                                    />
                                                                    {errors.company_name && <div className="text-danger">{errors.company_name}</div>}
                                                                </div>

                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group mb-3">
                                                                    <h5>Website URL</h5>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={Weburl}
                                                                        onChange={(e) => setWeburl(e.target.value)}
                                                                        className="figma-input p"
                                                                        placeholder="Website url"
                                                                    />
                                                                     {errors.Weburl && <div className="text-danger">{errors.Weburl}</div>}
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <h5>Business type</h5>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={BuisnessType}
                                                                        onChange={(e) => setBusinessType(e.target.value)}
                                                                        className="figma-input p"
                                                                        placeholder="Business type"
                                                                        // required
                                                                    />
                                                                     {errors.BuisnessType && <div className="text-danger">{errors.BuisnessType}</div>}
                                                                </div>

                                                                <div className="form-group mb-3">
                                                                    <h5>No. of clients
                                                                        {/* <Dropdown
                                                                            className="float-end"
                                                                            title="Filter"
                                                                        >
                                                                            <Dropdown.Toggle
                                                                                style={{
                                                                                    background: "none",
                                                                                    border: "none",
                                                                                }}
                                                                                className=""
                                                                            >Select
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {client_choice?.map((scope, i) => (
                                                                                    <Dropdown.Item
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setClientsCount(scope);
                                                                                        }}
                                                                                        key={i}
                                                                                        className="p"
                                                                                    >
                                                                                        {scope?.value}
                                                                                    </Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown> */}
                                                                    </h5>
                                                                    <Select
                                                                        name="client_choice"
                                                                        className='p'
                                                                        options={SelectCount}
                                                                        value={ClientsCount}
                                                                        onChange={changeCount}
                                                                    />
                                                                     {errors.ClientsCount && <div className="text-danger">{errors.ClientsCount}</div>}
                                                                </div>

                                                                <div className="form-group mb-3">
        <h5>Company logo</h5>
        <input
          type="file"
          onChange={handleImageChange}
          className="figma-input p"
        />
        {errors.image && <div className="text-danger">{errors.image}</div>}
      </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <h5>Comment</h5>
                                                            <textarea
                                                                name="description"
                                                                className="figma-input fs-14 p"
                                                                placeholder="Comment"
                                                                rows={2}
                                                                autocomplete="off"
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}
                                                            />
                                                            {errors.comment && <div className="text-danger">{errors.comment}</div>}
                                                        </div>
                                                        <div className="text-center">
                                                            <button
                                                                type="submit"
                                                                className="figma-btn text-white mb-2 w-100"
                                                                onClick={(e) => { console.log("qwertyuioplkjhgfdsazxcvbnm") }}
                                                            >
                                                                {btn} {spinner && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                                                            </button>
                                                        </div>
                                                    </form>
                                                    <div className="text-center new-account mt-1 mb-1">
                                                        <p className="" style={{ color: "#717994" }} >
                                                            Already have an account?{" "}
                                                            <Link className="figma-link" to="/login">
                                                                Sign in
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </>
                                            }


                                            {openOtp && <>
                                                <div className='align-items-center justify-content-center'>
                                                    <div className="text-center">
                                                        <h3 className='text-center fs-30'>Enter code</h3>
                                                        <p style={{ color: "#717994" }}>We sent a code to <span style={{ color: "#424C70" }}>{email}</span></p>
                                                    </div>

                                                    <div className='inputs d-flex flex-row justify-content-center' style={{ height: 'auto', width: '100%' }}>
                                                        <center>
                                                            <OtpInput
                                                                value={value}
                                                                className="figma-otp-box"
                                                                inputStyle={{ height: 60, width: 60 }}
                                                                onChange={(e) => {
                                                                    setotp(e);
                                                                }}
                                                                numInputs={6}
                                                                renderInput={(props) => (
                                                                  <input
                                                                    {...props}
                                                                    type="number"
                                                                  />
                                                                )}
                                                            /></center>
                                                    </div>
                                                    <div className="justify-content-center d-grid mt-4">
                                                        <button className="figma-btn text-white w-100 btn-blue" onClick={otpSubmit}>
                                                            {t('Sign up')}  {spinnerSign && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                                                        </button>
                                                    </div>
                                                    <div className="text-center new-account mt-3">
                                                        {/* <p className="p-0 m-0">
                                                            <span style={{ color: "#717994" }}>Didn't receive the mail?</span>
                                                            <Link className="figma-link" to="" onClick={resendOTP}>
                                                                &nbsp;Click to respond
                                                            </Link>
                                                        </p> */}
                                                        <p className="m-0 p-0" >
                                                            <span style={{ color: "#717994" }}>Already have an account?{" "}</span>
                                                            <Link className="figma-link" to="/login">
                                                                Sign in
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CookieConsent
                location="bottom"
                buttonStyle={{ backgroundColor: "green", color: 'white' }}
                buttonText="Accept and close"
                cookieName="Plansom.com"
                style={{ background: "#393E41" }}
                enableDeclineButton
                declineButtonStyle={{ backgroundColor: '#343a40' }}
                onAccept={() => { setIsAccepted(true); }}
            >
                We make use of first and third party cookies to improve our user experience. By using this website, you agree with our cookies policy.
            </CookieConsent>
        </>
    )
}

export default PartnerRegistration