import React, { useState, useEffect, memo, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import {
  createGroupRoom,
  createPersonalRoom,
} from "../../../services/messageService";
import { getOrgMem } from "../../../services/TeamService";
import { Link } from "react-router-dom";
import { gettingAllThreadByWebsocket } from "../../../store/actions/TaskActions";
import { useDispatch } from "react-redux";
import profilePicture from "../../../images/profile/profilePic.png";

const CustomClearText = () => "clear all";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

function CreateThread(props) {
  const fileRef = useRef();
  const [files, setFiles] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const orgId = localStorage.getItem("organization_id");
  const [orgUserMember, setOrgUserMember] = useState([]);
  const [threadName, setThreadName] = useState("");
  const [model, setModel] = useState(props?.model);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectUsers, setSelectUsers] = useState(false);
  const [personalModel, setPersonalModel] = useState(false);
  const [groupModel, setGroupModel] = useState(true);
  let threaderrorsObj = { thread_name: "", thread_users: null };
  const [threaderrors, setthreadErrors] = useState(threaderrorsObj);
  const dispatch = useDispatch()
  const userRole = userInfoDetails.data.user_role
  const RoomOptions = [
    {
      label: "Personal",
      value: "personal",
    },
    {
      label: "Group",
      value: "group",
    },
  ];

  useEffect(() => {
    orgMemberList();
  }, []);

  useEffect(() => {
    props.setModal(model);
  }, [model]);

  const changeHandler = (userSelected) => {
    setSelectedUserId(userSelected);
  };

  const handleTab = (e) => {
    if (e.value === "personal") {
      setPersonalModel(true);
      setGroupModel(false);
    }
    if (e.value === "group") {
      setGroupModel(true);
      setPersonalModel(false);
    }
  };

  const storePersonalUser = (user) => {
    setSelectedUserId(user?.userId);
  };

  const thread_errors = { ...threaderrorsObj };

  const createThreadRoom = (e) => {
    e.preventDefault();
    let room_type = null;
    if (threadName?.length === 0 || (selectedUserId === null && personalModel === false)) {
      console.log("set err")
      thread_errors.thread_name = "Thread name is required*";
      thread_errors.thread_users = "Please select the user*";
      setthreadErrors(thread_errors);
    }
    else {
      if (personalModel === true) {
        room_type = "personal";
        const data = createPersonalRoom(
          orgId,
          selectedUserId,
          room_type,
          threadName,
          null,
          null,
          userInfoDetails.data,
          selectedFile
        );
        data?.then(async (res) => {
          setModel(false);
          setthreadErrors("");
          props.setModal(false);
        });
        data?.catch(async (err) => {
          console.log("error user org list");
        });
      }
      if (groupModel === true) {
        room_type = "group";
        let selectedUsersId = [];
        if (selectedUserId) {
          selectedUserId.map((element) => {
            selectedUsersId.push(element?.userId);
          });
          const data = createGroupRoom(
            orgId,
            selectedUsersId,
            threadName,
            room_type,
            "",
            "",
            userInfoDetails.data,
            selectedFile
          );
          data?.then(async (res) => {
            setModel(false);
            setthreadErrors("")
            props.setModal(false);
            dispatch(gettingAllThreadByWebsocket(true))
          });
          data?.catch(async (err) => {
            console.log("error user org list");
          });
        }
      }
    }
  };

  const orgMemberList = () => {
    const data = getOrgMem(userInfoDetails.data.accessToken);
    data?.then(async (res) => {
      if (res.data.message === "Organization Members") {
        let optionData = res?.data?.data;
        let optionList = [];
        optionData?.forEach((option) => {
          if (option.id !== userInfoDetails?.data?.id) {
            const data = {
              label: option.first_name ? option.first_name + " " + option.last_name : option?.email,
              value: option.first_name ? option.first_name + " " + option.last_name : option?.email,
              userId: option.id,
            };
            optionList.push(data);
          }
        });
        setOrgUserMember(optionList);
      }
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFiles(imageUrl);
      setSelectedFile(file);
    }
  };

  return (
    <Modal className="fade bd-example-modal-lg" show={model} size="lg">
      <Modal.Body>
        <div className="text-center mb-2" style={{ width: "20rem" }} >
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-2 p-2"
            onClick={() => {
              setModel(false);
              setthreadErrors("");
              setPersonalModel(false);
              setGroupModel(false);
            }}
          />
          <h2 className="text-center" data-testid="Create-new-thread">Create new thread</h2>
        </div>

        <form onSubmit={(e) => createThreadRoom(e)}>
          {!selectUsers &&
            <Row style={{ backgroundColor: "#356fd4", height: "5rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Col className="d-flex justify-content-center align-items-center">
                <div className="chat-img d-flex align-items-start" style={{ marginTop: "30px" }}>
                  <img
                    src={files ? files : profilePicture}
                    alt="notification-img"
                    title="notification-img"
                    style={{ height: "70px", width: "70px", cursor: "pointer", border: "1px solid black" }}
                    className="plansom-notification-img"
                    onClick={() => fileRef.current.click()}
                  />
                </div>
                <input
                  ref={fileRef}
                  onChange={handleImageChange}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  hidden
                />
              </Col>
            </Row>
          }
          {!selectUsers &&
            <Row style={{ marginTop: "3rem" }}>
              <Col>
                <label className="h5">Thread topic</label>
                <input
                  type="text"
                  className="figma-input p py-4"
                  placeholder="Enter group name"
                  value={threadName}
                  onChange={(e) => setThreadName(e.target.value)}
                />
                {
                  <>
                    {threaderrors?.thread_name && !threadName?.length && (
                      <span style={{ color: "red" }}>
                        {threaderrors?.thread_name}
                      </span>
                    )}
                  </>
                }
              </Col>
            </Row>
          }
          {/* <Row>
            <Col className="m-1">
              <label className="h5" >Select thread type</label>
              <Select
              data-testId="room-type-select"
                className="p"
                name="room_type"
                options={RoomOptions}
                onChange={(e) => {
                  handleTab(e);
                }}
                isClearable
              />
            </Col>
          </Row> */}
          {selectUsers &&
            <Row style={{ marginTop: "1rem" }}>
              {personalModel ? (
                <Col className="m-1">
                  {/* <label className="h5">Select user</label>
                <Select
                  name="goal_owner"
                  className="p"
                  options={orgUserMember}
                  onChange={(e) => {
                    storePersonalUser(e);
                  }}
                /> */}
                  {userRole === "owner" && (
                  <h5 className="float-end mt-1">
                    Create users &nbsp;
                    <Link className="figma-link" to={"/invite-users"}>
                      <u>here ...</u>
                    </Link>
                  </h5>
                  )}
                </Col>
              ) : (
                <Col className="mt-1">
                  <label className="text-black font-w500">Select user</label>
                  <Select
                    closeMenuOnSelect={false}
                    components={{ ClearIndicator }}
                    styles={{ clearIndicator: ClearIndicatorStyles }}
                    defaultValue={""}
                    value={selectedUserId}
                    onChange={changeHandler}
                    data-testid="team-users"
                    isMulti
                    options={orgUserMember}
                  />
                  {
                    <>
                      {threaderrors?.thread_users && !selectedUserId && (
                        <span style={{ color: "red" }}>
                          {threaderrors?.thread_users}
                        </span>
                      )}
                    </>
                  }


                  {userRole === "owner" && (
                  <h6 className="float-end mt-1">
                    Create users
                    <Link data-testId="create-users-here" className="figma-link" to={"/invite-users"} >
                      <u> here ...</u>
                    </Link>
                  </h6>
                  )}
                </Col>
              )}
            </Row>
          }
          <div className="text-end mt-2">
            <button
              type="button"
              className="figma-btn float-right p-2 me-3"
              onClick={() => {
                if (threadName !== "") {
                  setSelectUsers(!selectUsers);
                }else{
                  if (threadName.length === 0) {
                    const updatedErrors = { ...threaderrors };
                    updatedErrors.thread_name = "Thread name is required*"; 
                    setthreadErrors(updatedErrors); 
                  } 
                }
              }}
            >
              {selectUsers ? "Back" : "Next"}
            </button>
            {selectUsers &&
              <button type="submit" className="figma-btn float-right p-2">
                Create
              </button>
            }
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateThread;
