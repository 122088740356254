import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  getCurrentSubscription,
  getInvoice,
  getSubscriptionPlan,
  getTransactionHistory,
  makeSubscriptionPlanPayment,
} from "../../../services/StripePayment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Location } from "react-router-dom";

function SubscriptionPlans() {
  const navigate = useNavigate();
  const org_id = parseInt(localStorage.getItem("organization_id"));
  const time_scope = localStorage.getItem("timescope");
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [allPlan, setAllPlan] = useState(true);
  const [viewPlan, setViewPlan] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(false);
  console.log("5555", currentSubscription)
  const [selectedUsers, setSelectedUser] = useState("1");
  console.log(selectedUsers, "selectedUsers...");
  const [selectedPlan, setSelectedPlan] = useState();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [currentSubscriptionPlans, setCurrentSubscriptionPlans] = useState();
  console.log(currentSubscriptionPlans,"currentSubscriptionPlans")
  const [start, setStart] = useState(0);
  const [stop, setStop] = useState(10);
  const [loadMore, setLoadMore] = useState(false);
  const [inputField, setinputField] = useState(false);
  const [transactionHistoryDetails, setTransactionHistoryDetails] = useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState();
  const [Show, setShow] = useState(false);
  const [ShowMessage, setShowMessage] = useState(false);
  const [error, setError] = useState();
  const [viewPlanDisable, setViewPlanDisable] = useState(false);
  const location = useLocation()
  const [showRouteTo, setShowRouteTo] = useState(true)

  useEffect(()=>{
    console.log(location?.state?.routeTo, selectedPlan?.total_amount,"7777777777999999999")
    if(showRouteTo && location?.state?.routeTo === "true" ){
      setCurrentSubscription(true);
      
      setBackButton(true);
      setAllPlan(false);
      setViewPlan(false);
      // setSelectedUser();
      setinputField(false);
      setSelectedPlan("View current subscription");
console.log("5555555555555", currentSubscription)
    }
    console.log(location?.state?.routeTo, "BBBBBBBBBBBBBBBBBBB")
  },[location?.state?.routeTo])
  
  console.log(currentSubscription,"5555555555555")
 

  useEffect(() => {
    if (allPlan) {
      
     
      setTransactionHistory(false);
      if(showRouteTo && location?.state?.routeTo==="true"){
        setCurrentSubscription(true);
        setAllPlan(false);
        // setViewPlan(true);
        setBackButton(true);
      }else{
      setCurrentSubscription(false);
      setAllPlan(true);
      setViewPlan(false);
      setBackButton(false);
    }
      
    }
  }, [allPlan]);

  const onValueChange = (event) => {
    setSelectedUser(event.target.value);
    setError(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      setShowMessage();
    }, 6000);
  }, [Show]);

  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get("status");
  useEffect(() => {
    if (paramValue === "success") {
      navigate("/payment-success");
    } else if (paramValue === "fail") {
      navigate("/payment-fail", { state: { Subscription: true } });
    }
  }, [paramValue]);

  useEffect(() => {
    getSubscriptionPlanDetails();
    // setSelectedUser(()=> selectedUsers)
  }, []);

  useEffect(() => {
    setSelectedUser(() => selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    let data = transactionHistoryDetails?.sort((a, b) => a?.id - b?.id);
    setTransactionHistoryDetails(data);
  }, [transactionHistoryDetails]);

  useEffect(() => {
    if (transactionHistory) {
      getTransactionHistoryDetails(start, stop);
    }
  }, [transactionHistory, start, stop]);

  useEffect(() => {
    getCurrentSubscriptionPlanDetails();
  }, [currentSubscription, allPlan]);

  const getSubscriptionPlanDetails = async () => {
    const data = getSubscriptionPlan(userInfoDetails?.data?.accessToken);
    data?.then(
      await ((res) => {
        setSubscriptionPlans(res?.data?.data);
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error deleteTask");
      })
    );
  };

  const getTransactionHistoryDetails = async (start, stop) => {
    const data = getTransactionHistory(
      start,
      stop,
      time_scope,
      userInfoDetails?.data?.accessToken
    );
    data?.then(
      await ((res) => {
        if (res?.data?.data?.length >= 10) {
          setLoadMore(true);
        } else if (res?.data?.data?.length <= 10) {
          setLoadMore(false);
        }
        let datas = [...transactionHistoryDetails];

        datas.push(...res?.data?.data);
        setTransactionHistoryDetails(datas);
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error deleteTask");
      })
    );
  };

  const makeSelectedSubscriptionPlanPayment = async (
    plan_type,
    user_quantity
  ) => {
    const data = makeSubscriptionPlanPayment(
      plan_type,
      user_quantity,
      org_id,
      userInfoDetails?.data?.accessToken
    );
    data?.then(
      await ((res) => {
        // localStorage.setItem(
        //   "SubscriptionDetails",
        //   JSON.stringify({ plan_type: plan_type, user_quantity: user_quantity , plan:selectedPlan})
        // );
  //       const urlParams = new URLSearchParams(window.location.search);
  // const paramValue = urlParams.get("status");
  //   if (paramValue === "success") {
  //     navigate("/payment-success");
  //   } else if (paramValue === "fail") {
  //     navigate("/payment-fail", { state: { Subscription: true } });
  //   }
        localStorage.setItem("session_id", res?.data?.data?.session_id);
        if (res?.data?.message === "New licenses added successfully.") {
          setShow(true);
          setShowMessage("New licenses added successfully !");
          setErrorMessage();
        } else if (res?.data?.data?.url) {
          window.location.replace(res?.data?.data?.url);
          setErrorMessage();
        }
      })
    );
    data?.catch(
      await ((err) => {
        if (
          err?.response?.data?.message ===
          "Only the organization owner can make payment"
        ) {
          setErrorMessage(err?.response?.data?.message);
        }
      })
    );
  };

  useEffect(() => {
    setCurrentSubscriptionPlans(currentSubscriptionPlans);
    // localStorage.setItem(
    //   "SubscriptionDetails",
    //   JSON.stringify({ plan: currentSubscriptionPlans })
    // );
  }, [currentSubscriptionPlans]);

  const getCurrentSubscriptionPlanDetails = async () => {
    const data = getCurrentSubscription(userInfoDetails?.data?.accessToken);
    data?.then(
      await ((res) => {
        if (res?.data?.data) {
          setViewPlanDisable(true);
        }
        setCurrentSubscriptionPlans(res?.data?.data);
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error deleteTask");
      })
    );
  };

  const changedDateFormats = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var today1 = new Date(date);
    return (
      days[today1.getDay()] +
      " " +
      monthNames[today1.getMonth()] +
      " " +
      String(today1.getDate()).padStart(2, "0") +
      " " +
      today1.getFullYear()
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage();
    }, 6000);
  }, [errorMessage]);

  useEffect(() => {
    if (selectedUsers) {
      setError(false);
    }
  }, [error, selectedUsers]);

  const getInvoiceReceipt = (id) => {
    const data = getInvoice(id, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      res?.data?.data?.url && window.open(res?.data?.data?.url);
    });
    data?.catch((error) => {
      console.log("getInvoiceReceipt", error);
    });
  };

  const errorToast = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                // color: "#131F4C",
                fontSize: "22px",
              }}
            >
              Failed
            </h2>
            <h3
              style={{
                // color: "#131F4C",
                fontSize: "18px",
              }}
            >
              {errorMessage}
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "schedule-task",
        className: "toast-error-container toast-error-container-after ",
      }
    );
  };

  const successToast = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                // color: "#131F4C",
                fontSize: "22px",
              }}
            >
              Success
            </h2>
            <h3
              style={{
                // color: "#131F4C",
                fontSize: "18px",
              }}
            >
              {ShowMessage}
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "schedule-task",
        className: "toast-success-container toast-success-container-after ",
      }
    );
  };
  console.log("subscriptionPlans-----",subscriptionPlans)
  return (
    <>
      {errorMessage?.length && errorToast()}

      {Show && ShowMessage && successToast()}

      <Row className="m-0 p-0">
        <div className="row m-0 p-0">
          <div className="col m-0 p-0">
            {backButton && (
              <span className="float-start mt-2">
                <i
                  className="fa fa-reply fs-20"
                  title={`Navigate back to subscription plan`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewPlan(false);
                    setAllPlan(true);
                    setSelectedUser("1");
                    setinputField(false);
                    setError(false);
                    setShowRouteTo(false)
                  }}
                />

                <span className="h3 ms-3">
                  {selectedPlan?.subscription_plan_type === "month"
                    ? "Monthly plan"
                    : transactionHistory
                    ? "Transaction history"
                    : currentSubscription
                    ? "Current subscription"
                    : "Yearly plan"}
                </span>
              </span>
            )}
          </div>
          <div className="col d-flex justify-content-end">
            <button
              style={{ float: "right", display: "inline-block", width: "auto" }}
              className="figma-btn m-1 mx-2 transaction-history"
              disabled={transactionHistory}
              onClick={() => {
                setTransactionHistory(true);
                setCurrentSubscription(false);
                setBackButton(true);
                setAllPlan(false);
                setViewPlan(false);
                setinputField(false);
                setSelectedUser();
                setTransactionHistoryDetails([]);
                setSelectedPlan("Transaction history");
              }}
            >
              <span>Transaction history</span>
            </button>

            <button
              to=""
              className="figma-btn m-1 mx-2 current_subscription"
              style={{
                float: "right",
                display: "inline-block",
                width: "auto",
              }}
              onClick={(event) => {
                setTransactionHistory(false);
                setCurrentSubscription(true);
                setBackButton(true);
                setAllPlan(false);
                setViewPlan(false);
                setSelectedUser();
                setinputField(false);
                setSelectedPlan("View current subscription");
              }}
            >
              <span className="text-white">View current subscription</span>
            </button>
          </div>
        </div>

        {allPlan && (
          <div className="row m-0 p-0 subscription-plan">
            {subscriptionPlans?.map((element) => (
              <Col
                className="mb-5"
                md={6}
                sm={12}
                style={{ height: "fit-content" }}
              >
                <div className="subscription-plan-card">
                  <div className="subscription-plan-card-body">
                    <h2 className="text-center text-title font-weight-bold">
                      {element?.subscription_plan_type === "month"
                        ? "Monthly plan"
                        : "Yearly plan"}
                    </h2>
                    <h3 className="font-weight-bold">
                      ${element?.total_amount?.toLocaleString('en-US')}
                    </h3>

                    <div className="mx-2 bg-primary"></div>
                    <ul className="list-unstyled my-3 text-small text-left mx-5">
                      <li className="mb-3 text-dark fs-18 p text-start">
                        <i className="fa fa-check mr-2 text-primary "></i>{" "}
                        {element?.discription}
                      </li>
                      <li className="mb-3 text-dark fs-18 text-start">
                        <i className="fa fa-check mr-2 text-primary"></i>{" "}
                        Discount - {element?.discount_percent} %
                      </li>
                      <li className="mb-3 text-dark fs-18 text-start">
                        <i className="fa fa-check mr-2 text-primary"></i> Free
                        trial period - {element?.free_trial_period} &nbsp; day's
                      </li>
                    </ul>
                  </div>
                  <div className="subscription-plan-card-footer text-center">
                    <button
                      style={{
                        float: "center",
                        display: "inline-block",
                        width: "auto",
                      }}
                      className="figma-btn m-1 mx-2 pt-1 choose"
                      onClick={() => {
                        setAllPlan(false);
                        setViewPlan(true);
                        setTransactionHistory(false);
                        setCurrentSubscription(false);
                        setBackButton(true);
                        setSelectedPlan(element);
                        // setSelectedUser();
                        setinputField(false);
                      }}
                    >
                      <span>Choose</span>
                    </button>
                  </div>
                </div>
              </Col>
            ))}
            {subscriptionPlans?.length === 0 && (
              <h4 className="text-center">
                Subscription plans not available for now !
              </h4>
            )}
          </div>
        )}

        {viewPlan && (
          <div className="row m-0 p-0 mb-5 subscription-plan">
            <div
              className="row m-0 p-3 px-5 subscription-plan-card"
              style={{ width: "100%" }}
            >
              <h2 className="text-center text-title font-weight-bold">
                {selectedPlan?.subscription_plan_type === "month"
                  ? "Monthly plan"
                  : "Yearly plan"}
              </h2>
              <h3 className="text-center font-weight-bold">
                {selectedPlan?.per_user_price
                  ? "$ " + selectedPlan?.per_user_price?.toLocaleString('en-US')
                  : "$0"}
              </h3>

              <Col className="m-0" md={7} sm={12}>
                <div className="subscription-plan-card-body">
                  <div className="mx-2 bg-primary"></div>
                  <ul className="list-unstyled my-3 text-small text-left">
                    <li className="mb-3 text-dark fs-18 p text-start">
                      <i className="fa fa-check mr-2 text-primary "></i>{" "}
                      {selectedPlan?.discription}{" "}
                    </li>
                    <li className="mb-3 text-dark fs-18 text-start">
                      <i className="fa fa-check mr-2 text-primary "></i>{" "}
                      Discount -{" "}
                      {selectedPlan?.discount_percent
                        ? selectedPlan?.discount_percent + " %"
                        : selectedPlan?.subscription_plan_type === "month" ? "Choose yearly plan for discount" : ""}
                    </li>
                    <li className="mb-3 text-dark fs-18 text-start">
                      <i className="fa fa-check mr-2 text-primary"></i> Free
                      trial period -{" "}
                      {selectedPlan?.free_trial_period
                        ? selectedPlan?.free_trial_period + " day's"
                        : "NA"}
                    </li>
                  </ul>
                </div>
              </Col>
              <Col className="m-0 p-0" md={5} sm={12}>
                <div className="m-0 p-0 ps-5 mt-5">
                  <h3 className="mb-3">Additional license - {selectedUsers || ""}</h3>

                  <div className="radio ">
                    <label className="p fs-18" style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="1"
                        checked={selectedUsers === "1"}
                        onChange={onValueChange}
                      />
                      1 User
                      ($ {(selectedPlan?.per_user_price * 1)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>

                  <div className="radio ">
                    <label className="p fs-18 " style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="10"
                        checked={selectedUsers === "10"}
                        onChange={onValueChange}
                      />
                      10 Users (${(selectedPlan?.per_user_price * 10)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>

                  <div className="radio">
                    <label className="p fs-18" style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="25"
                        checked={selectedUsers === "25"}
                        onChange={onValueChange}
                      />
                      25 Users (${(selectedPlan?.per_user_price * 25)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>

                  <div className="radio">
                    <label className="p fs-18" style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="50"
                        checked={selectedUsers === "50"}
                        onChange={onValueChange}
                      />
                      50 Users (${(selectedPlan?.per_user_price * 50)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>

                  <div className="mx-1">
                    <Link
                      className="fs-16 text-blue mb-3"
                      onClick={() => setinputField(!inputField)}
                    >
                      Number of users
                    </Link>

                    {inputField && (
                      <div className="radio">
                        <input
                          type="number"
                          className="figma-input-num  p "
                          style={{ width: "200px" }}
                          name="user"
                          placeholder="Enter no of user"
                          autocomplete="off"
                          min={0}
                          value={selectedUsers}
                          onChange={(e) => setSelectedUser(e?.target?.value)}
                        />
                      </div>
                    )}
                  </div>

                  
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={
                      viewPlanDisable
                        ? "Already have plan for organization? You can update plan in 'current plan subscription' section! Need to cancel or remove subscribers? Email support@plansom.com"
                        : ""
                    }
                    style={
                      viewPlanDisable
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  >
                    <button
                      className="figma-btn m-1 mx-2 pt-2 mt-3"
                      onClick={() => {
                        setAllPlan(false);
                        setViewPlan(true);
                        setTransactionHistory(false);
                        setCurrentSubscription(false);
                        setBackButton(true);
                        setinputField(false);
                        makeSelectedSubscriptionPlanPayment(
                          selectedPlan?.subscription_plan_type,
                          selectedUsers || 0
                        );
                        localStorage.setItem(
                          "SubscriptionDetails",
                          JSON.stringify({
                            plan_type: selectedPlan?.subscription_plan_type,
                            user_quantity: selectedUsers || 0,
                            plan: selectedPlan,
                          })
                        );
                        localStorage.removeItem("store");
                        // localStorage.setItem('store',  JSON.stringify({
                        // SubscriptionPlan:selectedPlan}))
                        // navigate("/stripe")
                      }}
                      // title={
                      //   viewPlanDisable
                      //     ? "Already have plan for organization.You can update plan in 'current plan subscription' section."
                      //     : ""
                      // }
                      style={
                        viewPlanDisable
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                      disabled={viewPlanDisable}
                    >
                      <span>Pay now</span>
                    </button>
                  </a>
                  {/* {viewPlanDisable === true && <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={
                      viewPlanDisable
                        ? "Need to cancel or remove subscribers? Email support@plansom.com"
                        : ""
                    }
                    // style={
                    //   viewPlanDisable
                    //     ? { cursor: "not-allowed" }
                    //     : { cursor: "pointer" }
                    // }
                  >
                    <button
                      className="figma-btn m-1 mx-2 pt-2 mt-3"
                      onClick={() => {
                        setAllPlan(false);
                        setViewPlan(true);
                        setTransactionHistory(false);
                        setCurrentSubscription(false);
                        setBackButton(true);
                        setinputField(false);
                        makeSelectedSubscriptionPlanPayment(
                          selectedPlan?.subscription_plan_type,
                          selectedUsers || 0
                        );
                        localStorage.setItem(
                          "SubscriptionDetails",
                          JSON.stringify({
                            plan_type: selectedPlan?.subscription_plan_type,
                            user_quantity: selectedUsers || 0,
                            plan: selectedPlan,
                          })
                        );
                        localStorage.removeItem("store");
                        // localStorage.setItem('store',  JSON.stringify({
                        // SubscriptionPlan:selectedPlan}))
                        // navigate("/stripe")
                      }}
                      // title={
                      //   viewPlanDisable
                      //     ? "Already have plan for organization.You can update plan in 'current plan subscription' section."
                      //     : ""
                      // }
                      // style={
                      //   viewPlanDisable
                      //     ? { cursor: "not-allowed" }
                      //     : { cursor: "pointer" }
                      // }
                    >
                      <span>Contact support</span>
                    </button>
                  </a>} */}
                  
                  <ReactTooltip effect="solid" id="my-tooltip" />
                </div>
              </Col>
            </div>
          </div>
        )}

        {currentSubscription  &&
          (currentSubscriptionPlans ? (
            <div className="row m-0 p-0 mb-5 subscription-plan">
              <div
                className="row m-0 p-3 px-5 subscription-plan-card"
                style={{ alignContent: "center" }}
              >
                <h2 className="text-center text-title font-weight-bold ">
                  {currentSubscriptionPlans?.subscription_plan_type === "month"
                    ? "Monthly plan"
                    : "Yearly plan"}
                </h2>
                <h3 className="text-center font-weight-bold">
                  ${currentSubscriptionPlans?.per_user_price}
                </h3>
                <Col className="m-0 p-0" md={7} sm={12}>
                  <div className="subscription-plan-card-body">
                    <ul className="list-unstyled my-3 text-small text-left">
                      <li className="mb-3 text-dark fs-18 p text-start">
                        <i className="fa fa-check mr-2 text-primary me-1"></i>
                        {currentSubscriptionPlans?.discription}
                      </li>
                      <li className="mb-3 text-dark fs-18 text-start">
                        <i className="fa fa-check mr-2 text-primary "></i>{" "}
                        Discount -{" "}
                        {currentSubscriptionPlans?.discount_percent
                          ? currentSubscriptionPlans?.discount_percent + " %"
                          : "NA"}
                      </li>
                      <li className="mb-3 text-dark fs-18 p  text-start text-capitalize">
                        <i className="fa fa-check mr-2 text-primary "></i>{" "}
                        Subscription status :-
                        {currentSubscriptionPlans?.subscription_status}
                      </li>
                      <li className="mb-3 text-dark fs-18 text-start">
                        <i className="fa fa-check mr-2 text-primary"></i>{" "}
                        Subscription / license count :-{" "}
                        {currentSubscriptionPlans?.members_count
                          ? currentSubscriptionPlans?.members_count
                          : "NA"}
                        .
                      </li>
                      <li className="mb-3 text-dark  fs-18 text-start">
                        <i className="fa fa-check mr-2 text-primary"></i> Start
                        date :-{" "}
                        {changedDateFormats(
                          currentSubscriptionPlans?.subscription_start_date
                        )}{" "}
                        .
                      </li>
                      <li className="mb-3 text-dark fs-18 text-start ">
                        <i className="fa fa-check mr-2 text-primary"></i> End
                        date :-{" "}
                        {changedDateFormats(
                          currentSubscriptionPlans?.subscription_end_date
                        )}{" "}
                        .
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col className="m-0 p-0 ps-5 mt-4" md={5} sm={12}>
                  <h3 className="mb-3">Additional license</h3>

                  <div className="radio ">
                    <label className="p  fs-18 " style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="10"
                        checked={selectedUsers === "10"}
                        onChange={onValueChange}
                      />
                      10 Users ($
                      {(currentSubscriptionPlans?.per_user_price * 10)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>

                  <div className="radio">
                    <label className="p fs-18" style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="25"
                        checked={selectedUsers === "25"}
                        onChange={onValueChange}
                      />
                      25 Users ($
                      {(currentSubscriptionPlans?.per_user_price * 25)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>

                  <div className="radio">
                    <label className="p fs-18" style={{ cursor: "pointer" }}>
                      <input
                        className="mx-3 p"
                        type="radio"
                        value="50"
                        checked={selectedUsers === "50"}
                        onChange={onValueChange}
                      />
                      50 Users ($
                      {(currentSubscriptionPlans?.per_user_price * 50)?.toFixed(2)?.toLocaleString('en-US') || 0})
                    </label>
                  </div>
                  <div className="mx-1">
                    <Link
                      className="fs-16 text-blue mb-3"
                      onClick={() => setinputField(!inputField)}
                    >
                      Number of users
                    </Link>

                    {inputField && (
                      <div className="radio">
                        {/* <label className="p fs-18" style={{ cursor: "pointer" }}> Enter user :  */}
                        {/* </label> */}

                        <input
                          type="number"
                          className="figma-input-num  p "
                          style={{ width: "200px" }}
                          name="user"
                          placeholder="Enter no of user"
                          autocomplete="off"
                          min={0}
                          value={selectedUsers}
                          onChange={(e) => setSelectedUser(e?.target?.value)}
                        />
                      </div>
                    )}
                  </div>
                 
                 {selectedUsers  &&  ( <h4 className="mt-3 "> On basis of remaining days in current subscription the total payable amount for 1 liscense is {currentSubscriptionPlans?.total_amount}  </h4>)}
                 

                  <h4 className="mt-3">
                   
                    Total amount : ${" "}
                    {
 (!isNaN(currentSubscriptionPlans?.total_amount * selectedUsers)
    ? (currentSubscriptionPlans?.total_amount * selectedUsers)
        .toFixed(2)
        .toLocaleString('en-US')
    : 0)
}

                  </h4>
                  {!selectedUsers && error && (
                    <p className="fs-14 text-danger p-0 m-0">
                      "Please select the number of users!"
                    </p>
                  )}

                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={
                      !selectedUsers
                        ? "Update subscription plan by adding additional license!"
                        : ""
                    }
                    style={
                      !selectedUsers
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  >
                    <button
                      className="figma-btn m-1 mx-2 pt-2 mt-3"
                      onClick={() => {
                        setAllPlan(false);
                        setViewPlan(false);
                        setTransactionHistory(false);
                        setCurrentSubscription(true);
                        setBackButton(true);
                        setinputField(false);
                        setError(true);
                        if (selectedUsers) {
                          const results = makeSelectedSubscriptionPlanPayment(
                            currentSubscriptionPlans?.subscription_plan_type,
                            selectedUsers
                          );
                          results.then((res) => {
                            setTimeout(() => {
                              getCurrentSubscriptionPlanDetails();
                            }, 2000);
                          });
                          results.catch((err) => {});
                        }
                      }}
                      // title={
                      //   !selectedUsers
                      //     ? "Update subscription plan by adding one or more user"
                      //     : ""
                      // }
                      disabled={!selectedUsers ? true : false}
                      style={
                        !selectedUsers
                          ? { width: "auto", cursor: "not-allowed" }
                          : { width: "auto", cursor: "pointer" }
                      }
                    >
                      <span>Pay now</span>
                    </button>
                  </a>
                  <ReactTooltip effect="solid" id="my-tooltip" />
                </Col>
              </div>
            </div>
          ) : (
            <div className="subscription-plan-card mt-5 p-2 ">
              <p className="text-center h4">Subscription not available!</p>
            </div>
          ))}

        {transactionHistory && (
          <div className="row m-0 p-0">
            <Col className="m-0 p-0 m-2 mt-2 w-100">
              {loadMore && (
                <Row>
                  <Link
                    className="text-center h4 text-blue"
                    onClick={() => {
                      setStart(stop + 1);
                      setStop(transactionHistoryDetails?.length + 10);
                    }}
                  >
                    load more ...
                  </Link>
                </Row>
              )}
              {transactionHistoryDetails?.length !== 0 ? (
                <PerfectScrollbar
                  className="dlab-scroll"
                  id="chartBox"
                  style={{ height: "70vh" }}
                >
                  {transactionHistoryDetails?.map((element, index) => (
                    <div
                      className="subscription-plan-card m-3"
                      key={element?.id}
                    >
                      <div className="d-flex p-2">
                        <div
                          className="text-center me-2"
                          style={{
                            position: "relative",
                            alignSelf: "center",
                            width: "4rem",
                          }}
                        >
                          {element.payment_status === "paid" ? (
                            <i class="bi bi-check-circle fs-26 text-green m-2 pt-2"></i>
                          ) : (
                            <i class="bi bi-x-circle fs-26 text-red m-2 pt-2"></i>
                          )}
                        </div>
                        <div
                          className="text-center"
                          style={{
                            position: "relative",
                            alignSelf: "center",
                            width: "100%",
                          }}
                        >
                          {element.payment_status === "paid" ? (
                            <div className="row m-0 p-0">
                            <div className="col-md-8 col-12 p-0">
                              <p className="text-start fs-16 py-2">
                                {element?.message}
                              </p>
                            </div>
                            <div className="col-md-4 col-12 text-end d-flex justify-content-end align-items-center">
                              {element?.payment_status === "paid" && (
                                <Link
                                  title="Click to view invoice"
                                  className="bi bi-eye-fill fs-30 py-1"
                                  style={{
                                    width: "50px",
                                    borderRadius: "5px",
                                    color: "#6e6d6d",
                                  }}
                                  onClick={(e) => {
                                    getInvoiceReceipt(element?.id);
                                  }}
                                ></Link>
                              )}
                            </div>
                          </div>
                          
                          ) : (
                            <div className="d-flex">
                              <p
                                className="p-0 m-0 text-start fs-16"
                                style={{
                                  position: "relative",
                                  alignSelf: "center",
                                  width: "95%",
                                }}
                              >
                                {element?.message}
                              </p>
                              <span className="ms-5 text-end d-flex float-end">
                                {element?.payment_link && (
                                  <button
                                    className="btn figma-btn float-end"
                                    style={{
                                      width: "80px",
                                      borderRadius: "5px",
                                    }}
                                    onClick={(e) => {
                                      window.location.replace(
                                        element?.payment_link
                                      );
                                    }}
                                  >
                                    Retry
                                  </button>
                                )}
                                {/* {element?.payment_status === "paid" && (
                                  <a
                                    href={element?.payment_link}
                                    target="_blank"
                                    rel="noReferrer"
                                    title="Click to view invoice"
                                    style={{
                                      width: "50px",
                                      borderRadius: "5px",
                                      color: "#6e6d6d",
                                    }}
                                  >
                                    <i class="bi bi-eye-fill float-end fs-30 px-2 py-1"></i>
                                  </a>
                                )} */}
                                {element?.payment_status === "paid" && (
                                  <Link
                                    title="Click to download invoice"
                                    className=" bi bi-file-earmark-arrow-down-fill float-end fs-30 px-2 py-1"
                                    style={{
                                      width: "50px",
                                      borderRadius: "5px",
                                      color: "#6e6d6d",
                                    }}
                                    onClick={(e) => {
                                      getInvoiceReceipt(element?.id);
                                    }}
                                  ></Link>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </PerfectScrollbar>
              ) : (
                <div className="subscription-plan-card mt-5 p-2 ">
                  <p className="text-center h4">
                    Transaction history not present!
                  </p>
                </div>
              )}
            </Col>
          </div>
        )}
      </Row>
    </>
  );
}

export default SubscriptionPlans;
