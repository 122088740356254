import React, { useEffect, useState } from "react";
import PlansomLogo from "../../../images/vector/Frame.png";
import SearchBox from "./SearchBox";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import store from "../../../images/Vector.svg";
import { useNavigate, useLocation } from "react-router-dom";

function StoreHeaders() {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetails);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth <= 540 ? true : false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 540) {
        setWindowWidth(true);
      }
    };

    window.addEventListener("resize30vh", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="store-headers p-0 m-0">
      <div className="header-Container" data-testid="store-header">
        <div className="header-containtss" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {/* <div className="headers"> */}
          <div className="header1" style={{ display: "flex" }}>
            <div className="plansom-logo float-center iphoneHeader">
              <a href="/">
              <img className="plansom-logo " alt="Plansom logo" title="Plansom logo"  style={{cursor:"pointer"}} src={PlansomLogo}  />
              </a>
            </div>
            <div className="headerContains" data-testid="headerContains">
              {windowWidth ? <>
                <Dropdown>
                  <Dropdown.Toggle className="storeHeaderDropDown">
                    ☰
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                      {(location.pathname === "/") ? (
                        <a
                          href="/store-front"
                          className="p fs-18 mobilesizeHeaderTabs"
                          data-testid="about-us"
                          // onClick={() => navigate("/store-front")}
                          style={{
                            textDecoration: "none", // To remove default underline
                            color: "inherit", // To inherit the text color
                            cursor: "pointer",
                          }}
                        >
                          Store
                        </a>
                      ) : (
                        <a
                          href="/"
                          className="p fs-18 mobilesizeHeaderTabs"
                          data-testid="about-us"
                          // onClick={() => navigate("/")}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            cursor: "pointer",
                          }}
                        >
                          Home
                        </a>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-7">
                        <a
                          href="/solutions"
                          className="p fs-18 mobilesizeHeaderTabs"
                          data-testid="about-us"
                          // onClick={() => navigate("/solutions")}
                          style={{
                            textDecoration: "none", // To remove default underline
                            color: "inherit", // To inherit the text color
                            cursor: "pointer",
                          }}
                        >
                         Solutions
                        </a>
                      </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                        <a
                          href="/about-us"
                          className="p fs-18 mobilesizeHeaderTabs"
                          data-testid="about-us"
                          // onClick={() => navigate("/about-us")}
                          style={{
                            textDecoration: "none", // To remove default underline
                            color: "inherit", // To inherit the text color
                            cursor: "pointer",
                          }}
                        >
                          About us
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <a
                          href="/pricing"
                          className="p fs-18 mobilesizeHeaderTabs"
                          data-testid="about-us"
                          // onClick={() => navigate("/pricing")}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            cursor: "pointer",
                          }}
                          target="_self"
                        >
                          Pricing
                        </a>
                      </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </> :
                <>
                  {(location.pathname === "/") ?
                    (<a
                      href="/store-front"
                      className="p fs-18 mobilesizeHeaderTabs"
                      data-testid="about-us"
                      // onClick={(e) => {
                      //   e.preventDefault(); // Prevent the default behavior of anchor tag
                      //   // navigate("/store-front");
                      // }}
                      target="_self"
                    >
                      Store
                    </a>)
                    :
                      (<a
                        href="/"
                        className="p fs-18 mobilesizeHeaderTabs"
                        data-testid="about-us"
                        // onClick={(e) => {
                        //   e.preventDefault(); // Prevent the default behavior of anchor tag
                        //   // navigate("/");
                        // }}
                        target="_self"
                      >
                        Home
                      </a>)
                  }
                   <a
                      href="/solutions"
                      className="p fs-18 mobilesizeHeaderTabs"
                      data-testid="about-us"
                      // onClick={(e) => {
                      //   e.preventDefault(); // Prevent the default behavior of anchor tag
                      //   // navigate("/solutions");
                      // }}
                      target="_self"
                    >
                     Solutions
                    </a>
                  <a
                      href="/about-us"
                      className="p fs-18 mobilesizeHeaderTabs"
                      data-testid="about-us"
                      // onClick={(e) => {
                      //   e.preventDefault(); // Prevent the default behavior of anchor tag
                      //   // navigate("/about-us");
                      // }}
                      target="_self"
                    >
                      About Us
                    </a>

                    <a
                      href="/pricing"
                      className="p fs-18 mobilesizeHeaderTabs"
                      data-testid="about-us" // Note: data-testid should be unique; consider changing for the second link
                      // onClick={(e) => {
                      //   e.preventDefault(); // Prevent the default behavior of anchor tag
                      //   // navigate("/pricing");
                      // }}
                      target="_self"
                    >
                      Pricing
                    </a>
                </>
              }
              <div
                className="header-searchbox"
                // style={{ width: "45%", height: "40px", padding: "0 3vw" }}
                style={{alignItems:"center", display:"flex"}}
                data-testid="search-box"
              >
                <SearchBox />
              </div>
              <div className="header-buttons header-buttonforSmall">
              {(localStorage.getItem("loggedIn") ==="true" && userInfoDetails?.data?.accessToken !== undefined && userInfoDetails?.data?.accessToken !== "" )? 

                (<Link
                  className="btn try-button btn-small"
                  to="/home"
                  style={{
                    border: "1px solid #356FD4",
                    // backgroundColor: "#ffffff",
                    // color: "#131f4c",
                  }}
                >
                 Log in
                </Link>) : ( <Link
                  className="btn try-button btn-small"
                  to="/login"
                  style={{
                    border: "1px solid #356FD4",
                    // backgroundColor: "#ffffff",
                    // color: "#131f4c",
                  }}
                >
                  Log in
                </Link>)}
                <Link  style={{
                    border: "1px solid #356FD4",
                    // backgroundColor: "#ffffff",
                    // color: "#131f4c",
                  }} 
                  className="btn try-button btn-small" to={(location.pathname === "/") ? "/store-front" : "/page-register"}>
                  Try Now
                </Link>
              </div>
              {/* <div className="store-icon" onClick={() => navigate("/#")}>
              <img src={store} style={{ height: "35px" }} alt="Store Icon" title="Store Icon" />
            </div> */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div >
  );
}

export default StoreHeaders;
