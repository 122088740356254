import axios from "axios";
import config from "./config";
const baseURL = config.BASE_URL;

export function createGoal(
  org_id,
  user_id,
  goal_owner_id,
  teamId,
  goal_name,
  goal_description,
  goalAlignId,
  attachment,
  token,
  isSuperParent,
  priorityNo,
  status
) {
  const priorityValue = priorityNo === '' ? 0 : parseInt(priorityNo);

  const postData = {
    organization_id: org_id,
    goal_creator: user_id,
    goal_owner: goal_owner_id,
    team_id: teamId,
    name: goal_name,
    // goal_type: scope,
    description: goal_description,
    goal_parent: goalAlignId,
    attachments: attachment,
    is_superparent: isSuperParent,
    order: priorityValue,
    status: status
  };
  return axios.post(baseURL + `goals`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function editGoal(
  orgId,
  goalOwner_id,
  teamId,
  goalDetails,
  goalIdDetails,
  // scope,
  goalAlignId,
  token,
  isSuperParent,
  priorityNo,
  status
) {
  const postData = {
    organization_id: orgId,
    goal_owner: goalOwner_id,
    team_id: teamId,
    // goal_type: scope,
    name: goalDetails?.name,
    description: goalDetails?.description,
    goal_parent: goalAlignId,
    is_superparent: isSuperParent,
    order: parseInt(priorityNo),
    status: status
  };
  return axios.put(
    baseURL + `goals/goal/` + goalIdDetails?.id + `/update`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}


export function editGoalssss(
  goalIdDetails,
  orgId,
  goalOwner_id,
  teamId,
  goaltype,
  is_superparent,
  order,
  status,

  token

) {
  const postData = {
    organization_id: orgId,
    goal_owner: goalOwner_id,
    team_id: teamId,
    goal_type: goaltype,
    is_superparent: is_superparent,
    order: order,
    status: status
  };
  return axios.put(
    baseURL + `goals/goal/` + goalIdDetails?.id + `/update`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getGoals(scope, org_id, teamId, token) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const postData = {
    organization_id: org_id,
    team_id: teamId,
    time_scope: timeScope,
    start_date: timeScope === "custom" ? startDate : "",
    end_date: timeScope === "custom" ? endDate : "",
  };
  return axios.post(baseURL + `goals/` + scope, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteGoal(goal_id, token) {
  return axios.delete(baseURL + `goals/goal/` + goal_id + "/delete", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getTasksByGoal(
  goal_id,
  token,
  goToTaskPage = 0,
  sortOrder,
  sortBy,
  FilterName,
  typeItem,
  impactItem,
  taskStatus,
  taskEffort,
  taskOwner,
  taskControl,
  taskRisk,
  taskActiveInactiveStatus,
  scheduledTaskDueInFilter
) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const postData = {
    time_scope: timeScope,
    start_date: timeScope === "custom" ? startDate : "",
    end_date: timeScope === "custom" ? endDate : "",
    sort_by: sortBy,
    sort_type: sortOrder,
  };
  return axios.post(
    baseURL +
    `goals/${goal_id}/tasks?page=${goToTaskPage ? goToTaskPage + 1 : 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        task_type: typeItem?.name,
        name: FilterName,
        task_impact: impactItem?.name,
        task_success: taskStatus, 
        task_effort: taskEffort,
        task_owner: taskOwner,
        task_control: taskControl?.name,
        risk_status: taskRisk?.name,
        status: taskActiveInactiveStatus,
        due_in: scheduledTaskDueInFilter
      },
    }
  );
}

export function getHomeGraph(defaultTime, token, goToPage = 0, goal_id, trackScope, teamId) {
  const timeScope =
    defaultTime != "" ? defaultTime : localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = localStorage.getItem("scope");
  const team_id = teamId ? teamId : localStorage.getItem("team_id");


  const postData = {
    organization_id: organization_id,
    scope: trackScope ? trackScope : "",
    time_scope: trackScope ? timeScope : "1 month",
    team_id: team_id,
    goal_id: goal_id,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
  };
  let url =
    timeScope === "custom"
      ? baseURL + `goals/home/graph?page=${goToPage + 1}`
      : baseURL + `goals/home/graph`;
  return axios.post(url, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getGoalByOrgId(scope, org_id, teamId, token) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const postData = {
    organization_id: org_id,
    team_id: teamId,
    time_scope: timeScope,
    start_date: timeScope === "custom" ? startDate : "",
    end_date: timeScope === "custom" ? endDate : "",
  };
  return axios.post(baseURL + `goals/team-goals/all`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getGoalTimelineComplete(org_id, scope, token) {
  const postData = {
    organization_id: org_id,
    scope: scope,
  };
  return axios.post(baseURL + `goals/timeline/complete`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteAttchment(attchmentId, token) {
  const postData = {
    attachment_ids: attchmentId,
  };
  return axios.post(baseURL + `goals/goal/attachments/delete`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllGoalsByScope(org_id, token, all = true, display_all = false) {

  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const postData = {
    organization_id: org_id,
    all: all,
    display_all: display_all,
    time_scope: timeScope,
    start_date: timeScope === "custom" ? startDate : "",
    end_date: timeScope === "custom" ? endDate : "",

  };
  return axios.post(baseURL + `goals/goal-list`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllGoalsByScopeInTime(goal_id, token) {
  const scope = localStorage.getItem("scope");
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const org_id = localStorage.getItem("organization_id");
  const team_id = localStorage.getItem("team_id");
  const postData = {
    organization_id: org_id,
    goal_id: goal_id,
    team_id: team_id,
    time_scope: timeScope,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
  };
  return axios.post(baseURL + `goals/dropdown-list/${scope}`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getSubGoals(
  goalId,
  token,
  goToSubGoalPage = 0,
  sortOrder,
  sortBy,
  nameFilter,
  subStatusItem,
  subCompleteStartRange,
  subCompleteEndRange,
  subWinsStartRange,
  subWinsEndRange,
  subOntimeStartRange,
  subOntimeEndRange,
  subRiskFilter,
  subStatusFilter,
  subGoalOwner

) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const postData = {
    time_scope: timeScope,
    start_date: timeScope === "custom" ? startDate : "",
    end_date: timeScope === "custom" ? endDate : "",

  };
  let win = "";
  let complete = "";
  let ontime = "";
  if (subWinsStartRange || subWinsEndRange) {
    win = String(`${subWinsStartRange || 0}-${subWinsEndRange || 100}`);
  }
  if (subCompleteStartRange || subCompleteEndRange) {
    complete = String(
      `${subCompleteStartRange || 0}-${subCompleteEndRange || 100}`
    );
  }
  if (subOntimeStartRange || subOntimeEndRange) {
    ontime = String(`${subOntimeStartRange || 0}-${subOntimeEndRange || 100}`);
  }
  return axios.post(
    baseURL +
    `goals/${goalId}/sub-goals?page=${goToSubGoalPage ? goToSubGoalPage + 1 : 1
    }`, postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        sort_by: sortBy,
        sort_type: sortOrder,
        goal_status: subStatusItem?.name,
        name: nameFilter,
        completed_percent: complete,
        win_percent: win,
        on_time_percent: ontime,
        risk_average: subRiskFilter,
        status: subStatusFilter,
        goal_owner: subGoalOwner
      },
    }
  );
}

// export function getGoalMessageById(task_id, token) {
//   return axios.get(baseURL + "comments/goal/" + task_id + "/retrieve", {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   });
// }

export function getGoalAttachments(task_id, token) {
  return axios.get(baseURL + "goals/goal/" + task_id + "/get-attachment", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getGoalById(goal_id, token) {
  if (!goal_id) {
    console.warn("Goal ID is undefined, skipping API call.");
    return Promise.reject("Goal ID is undefined");
  }
  return axios.get(baseURL + "goals/goal/" + goal_id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function sendGoalAttachments(task_id, file, userInfo) {
  const postData = {
    goal: task_id,
    attachments: file,
  };
  return axios.post(baseURL + `goals/goal/create-attachment`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}

export function getAllGoalsByOrgID(
  token,
  goToPage = 0,
  sortOrder,
  sortBy,
  selectedStatusItem,
  NameFilter,
  display,
  CompleteStartRange,
  CompleteEndRange,
  WinsStartRange,
  WinsEndRange,
  OntimeStartRange,
  OntimeEndRange,
  RiskStartRange,
  RiskEndRange,
  pagesize,
  riskFilter,
  statusFilter,
  goalOwner
) {
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const organization_id = localStorage.getItem("organization_id");
  // const timeScope = localStorage.getItem("timescope");
  // const startDate = localStorage.getItem("startDate");
  // const endDate = localStorage.getItem("endDate");

  const postData = {
    organization_id: organization_id || userInfoDetails?.data?.default_organization,
    sort_by: sortBy,
    sort_type: sortOrder,
    display_all: display,
    // time_scope: timeScope,
    // start_date: timeScope === "custom" ? startDate : "",
    // end_date: timeScope === "custom" ? endDate : "",
    page_size: pagesize
  };
  let win = "";
  let complete = "";
  let ontime = "";
  let risk = "";
  if (WinsStartRange || WinsEndRange) {
    win = String(`${WinsStartRange || 0}-${WinsEndRange || 100}`);
  }
  if (CompleteStartRange || CompleteEndRange) {
    complete = String(`${CompleteStartRange || 0}-${CompleteEndRange || 100}`);
  }
  if (OntimeStartRange || OntimeEndRange) {
    ontime = String(`${OntimeStartRange || 0}-${OntimeEndRange || 100}`);
  }
  if (RiskStartRange || RiskEndRange) {
    risk = String(`${RiskStartRange || 0}-${RiskEndRange || 100}`);
  }

  return axios.post(
    baseURL +
    `goals/goal-list?page=${goToPage + 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        goal_status: selectedStatusItem?.name,
        name: NameFilter,
        completed_percent: complete,
        win_percent: win,
        on_time_percent: ontime,
        risk_average: riskFilter,
        status: statusFilter,
        goal_owner: goalOwner,

      },
    }
  );
}

export function getSearchResults(
  type,
  start,
  end,
  search,
  attachmentType,
  token
) {
  const organization_id = localStorage.getItem("organization_id");
  const postData = {
    start_index: start,
    end_index: end,
    search_text: search,
    organization_id: organization_id,
    type: attachmentType,
  };
  return axios.post(baseURL + `users/search/` + type, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function postGoalCache(post_data, token) {
  return axios.post(baseURL + `chat/chat-bot`, post_data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getAISearchResults(res, taskDetailsinfo, token) {
  const goalId = taskDetailsinfo?.goal_parent === null ? taskDetailsinfo?.id : "";
  const subGoalId = taskDetailsinfo?.goal_parent ? taskDetailsinfo?.id : "";
  const postData = {
    prompt: res,
    goal_id: goalId,
    sub_goal_id: subGoalId,
  };
  return axios.post(baseURL + `chat/chat-bot/response`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getPerformanceDataByGoal(
  userId,
  goToPage,
  token,
  sortAsc,
  sortBy,
  userName,
  CompleteStartRange,
  CompleteEndRange,
  WinsStartRange,
  WinsEndRange,
  OntimeStartRange,
  OntimeEndRange,
  selectedTaskType
) {
  const scope = localStorage.getItem("scope");
  const teamId = localStorage.getItem("team_id");
  const orgId = localStorage.getItem("organization_id");

  let win = "";
  let complete = "";
  let ontime = "";
  if (WinsStartRange || WinsEndRange) {
    win = String(`${WinsStartRange || 0}-${WinsEndRange || 100}`);
  }
  if (CompleteStartRange || CompleteEndRange) {
    complete = String(`${CompleteStartRange || 0}-${CompleteEndRange || 100}`);
  }
  if (OntimeStartRange || OntimeEndRange) {
    ontime = String(`${OntimeStartRange || 0}-${OntimeEndRange || 100}`);
  }

  const postData = {
    scope: scope,
    organization_id: orgId,
    team_id: teamId || null,
    user_id: "",
    sort_by: sortBy,
    sort_type: sortAsc,
    task_type: selectedTaskType,
  };

  return axios.post(
    baseURL + `users/performance?page=${goToPage ? goToPage + 1 : 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        completed_percent: complete,
        win_percent: win,
        on_time_percent: ontime,
        first_name: userName,
      },
    }
  );
}



export function archieveGoal(goal_id, token) {
  const postData = {
    goal_ids: [goal_id],
  };
  return axios.post(baseURL + `goals/goal/archive`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getAllArchieveGoal(org_id, goToPage = 0, token, pagesize, selectedStatusItem, NameFilter, CompleteStartRange,
  CompleteEndRange, WinsStartRange, WinsEndRange, OntimeStartRange, OntimeEndRange, sortOrder,
  sortBy,) {
  const postData = {
    page_size: pagesize,
    sort_by: sortBy,
    sort_type: sortOrder,

  }
  let complete = "";
  let win = "";
  let ontime = "";
  if (CompleteStartRange || CompleteEndRange) {
    complete = String(`${CompleteStartRange || 0}-${CompleteEndRange || 100}`);
  }
  if (WinsStartRange || WinsEndRange) {
    win = String(`${WinsStartRange || 0}-${WinsEndRange || 100}`);
  }
  if (OntimeStartRange || OntimeEndRange) {
    ontime = String(`${OntimeStartRange || 0}-${OntimeEndRange || 100}`);
  }

  return axios.post(baseURL + "goals/goal/archive_list/" + org_id + `?page=${goToPage + 1}`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      goal_status: selectedStatusItem?.name,
      name: NameFilter,
      completed_percent: complete,
      win_percent: win,
      on_time_percent: ontime,
    }
  });
}

export function unArchieveGoal(goal_id, token) {
  const postData = {
    goal_ids: [goal_id],
  };
  return axios.post(baseURL + `goals/goal/unarchive`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function makeTemplates(org_id, goal_id, token) {
  const postData = {
    organization_id: org_id,
    goal_id: goal_id
  };
  return axios.post(baseURL + `goals/goal/make_template`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getAllTrackObjectives(goal_id, token, scope) {
  const org_id = localStorage.getItem("organization_id");
  const postData = {
    organization_id: org_id,
    goal_id: goal_id,
    team_id: localStorage.getItem("team_id")
  };
  return axios.post(baseURL + `goals/track/dropdown-list/${scope}`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getGoalHomeGraph(defaultTime, token, goToPage = 0, teamId) {
  const timeScope =
    defaultTime != "" ? defaultTime : localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = localStorage.getItem("scope");
  const team_id = teamId ? teamId : localStorage.getItem("team_id");
  const postData = {
    organization_id: organization_id,
    team_id: team_id,
    time_scope: timeScope,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : ""
  };
  let url = baseURL + `goals/complete-hours/${scope}`
  return axios.post(url, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}