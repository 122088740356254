import {
    CREATE_TASK_CONFIRMED_ACTION,
    CREATE_TASK_FAILED_ACTION,
    DELETE_TASK_CONFIRMED_ACTION,
    DELETE_TASK_FAILED_ACTION,
    EDIT_TASK_CONFIRMED_ACTION,
    EDIT_TASK_FAILED_ACTION,
    USER_SPECIFIC_TASK_CONFIRMED_ACTION,
    USER_SPECIFIC_TASK_FAILED_ACTION

} from '../actions/TaskActions';
import {
    GET_TASKS_BY_GOAL_CONFIRMED_ACTION,
    GET_TASKS_BY_GOAL_FAILED_ACTION,
} from '../actions/GoalsActions';

import { TASK_LATE_COUNT_UPDATE } from '../actions/TaskActions';
import { GETTING_ALL_THREAD_FROM_WEBSOCKET } from '../actions/TaskActions';
import { TASK_SHOWING_FILTER } from '../actions/TaskActions';
import { MAKE_DEFAULT_TEMPLATE } from '../actions/TaskActions';
import { GOAL_NAVIGATION_MANAGE } from '../actions/TaskActions';
import { REFRESH_TASK_LIST } from '../actions/TaskActions';
import { GOAL_EDIT_MANAGE } from '../actions/TaskActions';
import { PLANNED_GOAL_MY_DAY } from '../actions/TaskActions';
import { UPDATE_STREAK } from '../actions/TaskActions';
import { UPDATE_PROGRESS_BAR } from '../actions/TaskActions';

const initialState = {
    errorMessage: '',
    successMessage: '',
    tasks: '',
    taskLateCountStatus: false,
    gettingAllThreadStatus: false,
    taskShowingFilter: false,
    makeDefaultTemplate: false,
    goalNavigationManage: false,
    refreshTaskList: false,
    goalEditManage: false,
    plannedGoalMyDay : false,
    updateStreak : false,
    updateProgressBar: false
};

export function taskReducer(state = initialState, action) {
    if (action.type === CREATE_TASK_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message,
            tasks: action.payload.data
        };
    }

    if (action.type === CREATE_TASK_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Task not created',
            tasks: action.payload.data
        };
    }
    if (action.type === DELETE_TASK_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message,
            tasks: action.payload.data
        };
    }

    if (action.type === DELETE_TASK_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Task not Deleted',
            tasks: action.payload.data
        };
    }
    if (action.type === EDIT_TASK_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message,
            tasks: action.payload.data
        };
    }

    if (action.type === EDIT_TASK_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Failed to edit Task',
            goals: action.payload.data
        };
    }
    if (action.type === GET_TASKS_BY_GOAL_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: "Tasks found Successfully",
            tasks: action.payload.data
        };
    }

    if (action.type === GET_TASKS_BY_GOAL_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Tasks not found',
            tasks: action.payload.data
        };
    }
    if (action.type === USER_SPECIFIC_TASK_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: "User Specific tasks found Successfully",
            tasks: action.payload.data
        };
    }

    if (action.type === USER_SPECIFIC_TASK_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'No tasks assigned to user',
            tasks: action.payload.data
        };
    }

    if (action.type === TASK_LATE_COUNT_UPDATE) {
        console.log(action.payload, "7777action.payload.data")
        return {
            ...state,
            taskLateCountStatus: action.payload
        }
    }

    if (action.type === GETTING_ALL_THREAD_FROM_WEBSOCKET) {
        return {
            ...state,
            gettingAllThreadStatus: action.payload
        }
    }

    if (action.type === TASK_SHOWING_FILTER) {
        console.log(action.payload, "7777action.payload.data")
        return {
            ...state,
            taskShowingFilter: action.payload
        }
    }

    if (action.type === MAKE_DEFAULT_TEMPLATE) {
        return {
            ...state,
            makeDefaultTemplate: action.payload
        }
    }

    if (action.type === GOAL_NAVIGATION_MANAGE) {
        return {
            ...state,
            goalNavigationManage: action.payload
        }

    }

    if (action.type === REFRESH_TASK_LIST) {
        return {
            ...state,
            refreshTaskList: action.payload
        }

    }
    if (action.type === GOAL_EDIT_MANAGE) {
        return {
            ...state,
            goalEditManage: action.payload
        }

    }
    if (action.type === PLANNED_GOAL_MY_DAY) {
        return {
            ...state,
            plannedGoalMyDay: action.payload
        }
    }

    if (action.type === UPDATE_STREAK) {
        return {
            ...state,
            updateStreak: action.payload
        }
    }

    if (action.type === UPDATE_PROGRESS_BAR) {
        return {
            ...state,
            updateProgressBar: action.payload
        }
    }
    
    return state;
}