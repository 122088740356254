import axios from "axios";
import config from "./config";
const baseURL = config.BASE_URL;

export function createTaskService(
  orgId,
  user_id,
  taskOwner,
  createTask,
  taskImpact,
  taskControl,
  taskStatus,
  goalDetails,
  attachment,
  token
) {
  let postData;
  if (
    (goalDetails?.id !== undefined && goalDetails?.id !== "") ||
    goalDetails?.id !== null
  ) {
    if (!taskOwner) {
      taskOwner = user_id;
    }
    postData = {
      organization_id: orgId,
      task_creator: user_id,
      task_owner: taskOwner,
      name: createTask?.task_name,
      description: createTask?.description,
      task_impact: taskImpact,
      task_control: taskControl,
      task_effort: createTask?.task_effort,
      status: taskStatus,
      goal: goalDetails?.id,
      attachments: attachment,
    };
  } else {
    postData = {
      organization_id: orgId,
      task_creator: user_id,
      task_owner: taskOwner,
      name: createTask?.task_name,
      description: createTask?.description,
      task_impact: taskImpact,
      task_effort: createTask?.task_effort,
      status: taskStatus,
      goal: null,
      attachments: attachment,
    };
  }

  return axios.post(baseURL + `tasks/create`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteTask(task_id, token) {
  return axios.delete(baseURL + `tasks/task/` + task_id + "/delete", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function editTask(
  orgId,
  task_id,
  teamId,
  goal_id,
  taskOwner,
  taskCreater,
  taskImpact,
  taskControl,
  taskDetail,
  token,
  status,
  startDate,
  newStatus,
  order
) {
  console.log(teamId, "teammmmmmmm")
  const postData = {
    organization_id: orgId,
    task_owner: taskOwner?.userId,
    task_creator: taskCreater?.userId,
    task_impact: taskImpact,
    task_control: taskControl,
    task_effort: taskDetail?.task_effort,
    name: taskDetail?.task_name,
    description: taskDetail?.description,
    goal: goal_id || "",
    team: teamId || localStorage.getItem('team_id') || "",
    date_time: startDate,
    status: newStatus,
    order: order
  };
  return axios.put(baseURL + `tasks/task/` + task_id + "/update", postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function editTaskForActiveInactive(
  orgId,
  task_id,
  teamId,
  goal_id,
  taskOwner,
  taskCreater,
  taskImpact,
  taskControl,
  taskDetail,
  token,
  status,
  startDate,
  newStatus,
) {
  const postData = {
    organization_id: orgId,
    task_owner: taskOwner?.userId,
    task_creator: taskCreater?.userId,
    task_impact: taskImpact,
    task_control: taskControl,
    task_effort: taskDetail?.task_effort,
    name: taskDetail?.task_name,
    description: taskDetail?.description,
    goal: goal_id || "",
    team: teamId || localStorage.getItem('team_id') || "",
    date_time: startDate,
    status: newStatus,
  };
  return axios.put(baseURL + `tasks/task/` + task_id + "/update", postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function taskFeedback(
  task_id,
  token,
  taskPulse,
  taskStatus,
  taskImpactHours
) {
  const postData = {
    task_success: taskStatus,
    task_completed: taskPulse,
    task_time_logged: taskImpactHours,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return axios.post(baseURL + `tasks/` + task_id + "/complete", postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function userSpecificTaskHistory(
  org_id,
  token,
  goToHistoryPage = 0,
  sortOrder,
  sortBy,
  HistoryTaskNameFilter,
  seletcedHistoryTaskType,
  selectedHistoryTaskImpact,
  selectedHistoryTaskControl,
  selectedHistoryTaskRiskStatus,
  HistoryTaskEffortFilter,
  HistoryTaskOwnerFilter,
  HistoryTasksGoalNameFilter,
  selectedHistoryTaskStatus,
  selectedHistoryTaskScheduleStatus,
  page_size
) {
  const timeScope = localStorage.getItem("timescope");
  const teamId = localStorage.getItem("team_id");
  const scope = localStorage.getItem("scope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const org_ids = localStorage.getItem("organization_id");
  const postData = {
    organization_id: org_ids,
    // time_scope: timeScope,
    // team_id: teamId,
    // scope: scope,
    // start_date: timeScope === "custom" ? startDate : "",
    // end_date: timeScope === "custom" ? endDate : "",
    sort_by: sortBy,
    sort_type: sortOrder,
    page_size: page_size
  };
  return axios.post(
    baseURL + `tasks/history?page=${goToHistoryPage ? goToHistoryPage + 1 : 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        task_type: seletcedHistoryTaskType,
        name: HistoryTaskNameFilter,
        task_impact: selectedHistoryTaskImpact,
        task_control: selectedHistoryTaskControl,
        risk_status: selectedHistoryTaskRiskStatus,
        task_effort: HistoryTaskEffortFilter,
        task_owner: HistoryTaskOwnerFilter,
        goal: HistoryTasksGoalNameFilter,
        task_success: selectedHistoryTaskStatus,
        on_time_status: selectedHistoryTaskScheduleStatus,
      },
    }
  );
}

export function userSpecificTask(
  org_id,
  token,
  pageUpcomingCount,
  sortOrder,
  sortBy,
  UpcomingTaskNameFilter,
  seletcedUpcomingTaskType,
  selectedUpcomingTaskImpact,
  selectedUpcomingTaskControl,
  selectedUpcomingTaskRiskStatus,
  selectedTaskStatusItem,
  UpcomingTaskEffortFilter,
  UpcomingTaskOwnerFilter,
  UpcomingTasksGoalNameFilter,
  page_size
) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const scope = localStorage.getItem("scope");
  const teamId = localStorage.getItem("team_id");
  const org_ids = localStorage.getItem("organization_id");

  console.log(selectedUpcomingTaskRiskStatus, "selectedUpcomingTaskRiskStatus")
  const postData = {
    organization_id: org_ids,
    // time_scope: timeScope,
    // scope: scope,
    // team_id: teamId,
    // start_date: timeScope === "custom" ? startDate : "",
    // end_date: timeScope === "custom" ? endDate : "",
    sort_by: sortBy,
    sort_type: sortOrder,
    page_size: page_size
  };
  return axios.post(
    baseURL +
    `tasks/user?page=${pageUpcomingCount ? pageUpcomingCount + 1 : 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        task_type: seletcedUpcomingTaskType,
        name: UpcomingTaskNameFilter,
        task_impact: selectedUpcomingTaskImpact,
        task_control: selectedUpcomingTaskControl,
        risk_status: selectedUpcomingTaskRiskStatus,
        task_status: selectedTaskStatusItem,
        task_effort: UpcomingTaskEffortFilter,
        task_owner: UpcomingTaskOwnerFilter,
        goal: UpcomingTasksGoalNameFilter,
      },
    }
  );
}

export function taskSelector(orgId, token) {
  console.log(
    "&&&&&&&&&",
    Intl.DateTimeFormat().resolvedOptions().timeZone.replace("_", " ")
  );
  const postData = {
    organization_id: orgId,
    time_zone: Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.replace("_", " "),
  };
  return axios.post(baseURL + `tasks/task-selector`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteAttchment(attchmentId, token) {
  const postData = {
    attachment_ids: attchmentId,
  };
  return axios.post(baseURL + `tasks/task/attachments/delete`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function taskScheduleAlgo(orgId, token) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const postData = {
    organization_id: orgId,
    time_zone: userTimezone, //Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return axios.post(baseURL + `tasks/task-scheduler`, postData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
}

export function getScheduledTasks(
  orgId,
  teamId,
  timescope,
  token,
  page,
  sortOrder,
  sortBy,
  ScheduledTaskNameFilter,
  seletcedScheduledTaskType,
  selectedScheduledTaskImpact,
  selectedScheduledTaskControl,
  selectedScheduledTaskRiskStatus,
  ScheduledTaskEffortFilter,
  ScheduledTaskOwnerFilter,
  ScheduledTasksGoalNameFilter,
  selectedScheduleTaskStatus,
  scheduledTaskDueInFilter,
  page_size,
  lateCount,
  statusFilter,
  seletcedScheduledTaskStatus
) {
  console.log(lateCount, "uuuuuuuuuuuuuuuuuuuuuuuuuu");

  const scope = localStorage.getItem("scope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const postData = {
    organization_id: orgId,
    // time_scope: timescope,
    // team_id: teamId,
    // scope: scope,
    // start_date: startDate,
    // end_date: endDate,
    sort_by: sortBy,
    sort_type: sortOrder,
    page_size: page_size,
    late_count_filter: lateCount
  };
  return axios.post(
    baseURL + `tasks/scheduled?page=${page ? page + 1 : 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        task_type: seletcedScheduledTaskType,
        name: ScheduledTaskNameFilter,
        task_impact: selectedScheduledTaskImpact,
        task_control: selectedScheduledTaskControl,
        risk_status: selectedScheduledTaskRiskStatus,
        task_effort: ScheduledTaskEffortFilter,
        task_owner: ScheduledTaskOwnerFilter,
        goal: ScheduledTasksGoalNameFilter,
        task_current_status: selectedScheduleTaskStatus,
        due_in: scheduledTaskDueInFilter,
        status: statusFilter,
        task_success: seletcedScheduledTaskStatus
      },
    }
  );
}

export function getTimeAlignmentGraph(goal_id, goToPage = 0, token, trackScope) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = trackScope ? trackScope : localStorage.getItem("scope");
  const teamId = localStorage.getItem("team_id");
  const postData = {
    organization_id: organization_id,
    scope: trackScope ? trackScope : scope,
    goal_id: goal_id,
    team_id: teamId,
    time_scope: timeScope,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
  };
  // let url =
  //   timeScope === "custom"
  //     ? baseURL + `tasks/alignment/ ${scope}?page=${goToPage + 1} `
  //     : baseURL + `tasks/alignment/` + scope;
  return axios.post(
    baseURL + `tasks/alignment/${scope}?page=${goToPage + 1} `,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getTimeByGoalAlignmentGraph(goal_id, token) {
  console.log("goal_id in taskService", goal_id);
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const teamId = localStorage.getItem("team_id");
  const scope = localStorage.getItem("scope");

  const postData = {
    organization_id: organization_id,
    scope: scope,
    goal_id: goal_id,
    team_id: teamId,
    time_scope: timeScope,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
  };
  return axios.post(baseURL + `goals/effort/` + scope, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getTimeByTaskAlignmentGraph(token, goal_id, trackScope, team_id) {
  const timeScope = localStorage.getItem("timescope");
  const start_date = localStorage.getItem("startDate");
  const end_date = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = localStorage.getItem("scope");
  const scopes = trackScope ? trackScope : scope;
  const teamId = team_id ? team_id : localStorage.getItem("team_id");
  const postData = {
    organization_id: organization_id,
    scope: scopes,
    goal_id: goal_id,
    team_id: teamId,
    time_scope: timeScope,
    start_date:
      localStorage.getItem("timescope") === "custom" ? start_date : "",
    end_date: localStorage.getItem("timescope") === "custom" ? end_date : "",
  };
  return axios.post(baseURL + `tasks/task-type/` + scopes, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getTimeByGoalGraph(goal_id, page, token) {
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = localStorage.getItem("scope");
  const postData = {
    organization_id: organization_id,
    scope: scope,
    time_scope: timeScope,
    start_date: startDate,
    end_date: endDate,
  };
  return axios.post(
    baseURL + `goals/goal_performance/${goal_id}?page=${page + 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getTaskMessageById(task_id, token) {
  return axios.get(baseURL + "comments/task/" + task_id + "/retrieve", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getTaskAttachments(task_id, token) {
  console.log("task_id", task_id);
  return axios.get(baseURL + "tasks/task/" + task_id + "/get-attachment", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function sendTaskAttachments(task_id, file, userInfo) {
  const postData = {
    task: task_id,
    attachments: file,
  };
  return axios.post(baseURL + `tasks/task/create-attachment`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}

export function uploadTaskAndGoalFile(file, scope, team, teamUser, userInfo) {
  console.log(team, "rrrr")
  const postData = {
    excel_sheet: file,
    scope: scope,
    team: team,
    team_user: teamUser,
    organization_id: localStorage.getItem("organization_id"),
  };

  console.log("datas#############", postData, teamUser);
  return axios.post(baseURL + `users/import-excel`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}

export function postTaskCache(post_data, token) {
  return axios.post(baseURL + `chat/chat-bot`, post_data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getAITaskSearchResults(res, taskDetailinfo, token) {
  const postData = {
    prompt: res,
    task_id: taskDetailinfo?.id,
  };
  return axios.post(baseURL + `chat/chat-bot/response`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getScheduledTimeSlots(task_id, token) {
  console.log("task_id", task_id);
  return axios.get(baseURL + "tasks/schedule_slot/" + task_id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}



export function rescheduledTask(task_id, token, date_time) {
  console.log(date_time, "date_timeeeeeeeeeee")
  const postData = {
    date_time: date_time.toISOString(),
  };
  return axios.post(baseURL + "tasks/task_reschedule/" + task_id, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function markTaskAsIncomplete(
  task_id,
  token

) {
  const postData = {
  };
  return axios.put(baseURL + `tasks/` + task_id + "/mark-task-incomplete", postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function toBeScheduledTask(
  task_id,
  token,

) {
  const postData = {

  };
  return axios.post(baseURL + `tasks/to-be-schedule/` + task_id, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllStreaks(token) {
  return axios.get(baseURL + "users/streak", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}