import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, Row } from "react-bootstrap";
import { createQuestionSet } from "../../../services/OrganizationService";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getAllQuestionSet } from "../../../services/OrganizationService";
import LoadingSpinner from "../Component/LoadingSpinner";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import Vector3 from "../../../images/vector/Vector3.png"
import { deleteQuestionSets } from "../../../services/OrganizationService";
import { makeChatbotQuestionSetdefault } from "../../../services/OrganizationService";
import Select from "react-select";
import { getOrgMem } from "../../../services/TeamService";
import { shareQuestionSets } from "../../../services/OrganizationService";
import { editQuestionsSets } from "../../../services/OrganizationService";
import { red } from "@material-ui/core/colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { makeDefaultTemplate } from "../../../store/actions/TaskActions";
import { useDispatch } from "react-redux";





const ChatbotQuestionSet = ()=>{
    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState(['']);
    const [additionalQuestions, setAdditionalQuestions] = useState([]);
    console.log(additionalQuestions, "75757questions")
    
    const navigate = useNavigate()
    const [showModal, setModal] = useState(false);
    const userDetail = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetail);
    const [loader, setLoader] = useState(false)
    const [storeQuestion, setStoreQuestion] = useState("")
    const [questionSets, setQuestionSets] = useState([]);
    console.log(questions,"questionsllll")

    const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
    const [deleteQuestionSet, setDeleteQuestionSet] = useState()

    // const [defaultQuestionSet, setDefaultQuestionSet] = useState()
    const [shareModel, setShareModel] = useState(false)
    const [shareQuestionSetId, setShareQuestionSetId] = useState()

    const [usersOption, setUsersOption] = useState();
    console.log(usersOption, "userOption")
    const [defaultOptions, setDefaultOptions] = useState();
    const orgId = parseInt(localStorage.getItem("organization_id"), 10);
    const [userId, setUserId] = useState()
    const [error, setError] = useState('')
    
    const [editModelShow, setEditModelShow] = useState(false)
    const [questioneditid, setQuestioneditid] = useState("")

    const [deleteErrorMessage, setDeleteErrorMessage] = useState()

    const dispatch = useDispatch()
    

   


    const handleDeleteQuestion = (index, isAdditional) => {
      console.log(index, "pppppppppppppppppppppppppppppp")
      if (isAdditional) {
        setQuestions(questions.filter((_, i) => i !== index));
      } else {
        setAdditionalQuestions(additionalQuestions.filter((_, i) => i !== index));
      }
    };

  
    const handleQuestionChange = (index, value) => {
        console.log(value, "value")
      const newQuestions = [...questions];
      newQuestions[index] = value;
      console.log(newQuestions,"newQuestions")
      setQuestions(newQuestions);
    };

    const handleAdditionalQuestionChange = (index, value) => {
      console.log(value,index, "valueadadadadda")
    const newQuestions = [...additionalQuestions];
    newQuestions[index] = value;
    console.log(newQuestions,"newQuestions")
    setAdditionalQuestions(newQuestions);
  };

    const handleEditChange = (index, value) => {
        setQuestions(prevQuestions => {
          const newQuestions = [...prevQuestions];
          newQuestions[index] = { ...newQuestions[index], question: value };
          return newQuestions;
        });
      };
  
    const handleAddQuestion = () => {
      setQuestions([...questions, '']);
      console.log(questions, "qwerquestionssss")
    };

    const handleAddAdditionalQuestion = () =>{
      setAdditionalQuestions([...additionalQuestions,''])
    }
  
    const handleSubmitQuestion = (event) => {
      event.preventDefault();
      console.log("hhhhhhhhhhh")
      const data = createQuestionSet(title, questions,  userInfoDetails?.data?.accessToken)
      data.then(async(res)=>{

        setModal(false)
        dispatch(makeDefaultTemplate(true))
        setTitle('')
        setQuestions([''])
        gettingAllQuestionSet()
        // window.location.reload()
        toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      // color: "#131F4C",
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      // color: "#131F4C",
                      fontSize: "18px",
                    }}
                  >
                    Your question set has been created successfully!
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-schedule",
              className: "toast-success-container toast-success-container-after ",
            }
          );
            console.log(res,"responseASSSSSS")
      })
      data?.catch(async (err) => {
        console.log(err, "responseASSSSSS");
      });  
    };
    const ShowQuesttionModel = ()=>{
        setModal(true)
    }

    const gettingAllQuestionSet = () =>{
        setLoader(true)
        const data = getAllQuestionSet(userInfoDetails?.data?.accessToken)
        data?.then(async (res) => {
            setQuestionSets(res?.data?.data);
            setLoader(false)
            console.log(res, "response121212ASSSSSS");
          }); 
        data?.catch(async (err) => {
            console.log(err, "response121212ASSSSSS");
          }); 
    }

    useEffect(()=>{
       gettingAllQuestionSet()
       getAllUser()
    },[])

    const DeletegetQuestionSetId = (event, set) =>{
        event.preventDefault()
        setDeleteQuestionSet(set)
        SetOpenDeleteModal(true)

        console.log("454545wwwwwwwwww",set)
    }

    const selectedFolderForDelete = () => {
        // e.preventDefault()
        const data = deleteQuestionSets(deleteQuestionSet?.id, userInfoDetails?.data?.accessToken);
        data?.then((res) => {
          console.log(res, "affffffffffffffff")
          SetOpenDeleteModal(false)
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      // color: "#131F4C",
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      // color: "#131F4C",
                      fontSize: "18px",
                    }}
                  >
                    Your question set has been deleted successfully!
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-schedule",
              className: "toast-success-container toast-success-container-after ",
            }
          );
          gettingAllQuestionSet()
        });
        data?.catch((err) => { 
          setDeleteErrorMessage(err?.response?.data?.message)
          console.log(err?.response?.data?.message, "affffffffffffffff")
    
        });
      }

     const  makeDefaultQuestionSet = (event, set) =>{
        event.preventDefault()
        // setDefaultQuestionSet(set)
        console.log("responseData")
        const data = makeChatbotQuestionSetdefault(set?.id, userInfoDetails?.data?.accessToken)
        data.then(async(res)=>{
            console.log(res,"responseData")
           dispatch(makeDefaultTemplate(true))
            gettingAllQuestionSet()
            
            toast(
                <>
                  <div className="m-1 d-flex">
                    <i
                      class="bi bi-check-circle fs-26 me-3"
                      style={{ color: "#131F4C" }}
                    ></i>
                    <div>
                      <h2
                        style={{
                          // color: "#131F4C",
                          fontSize: "22px",
                        }}
                      >
                        Completed
                      </h2>
                      <h3
                        style={{
                          // color: "#131F4C",
                          fontSize: "18px",
                        }}
                      >
                        Your question set has been set dafault successfully!
                      </h3>
                    </div>
                  </div>
                </>,
                {
                  toastId: "task-schedule",
                  className: "toast-success-container toast-success-container-after ",
                }
              );
           

        })
        data.catch(async(err)=>{
            console.log(err, "responseData")

        })
     }

    const getShareQuestionSet = (event, set) =>{
        event.preventDefault()
        setShareQuestionSetId(set)

     }

     const handleShareQuestionSet = (event) => {
        event.preventDefault()
        const data = shareQuestionSets(shareQuestionSetId?.id, userId, userInfoDetails?.data?.accessToken)
        data.then(async(res)=>{
            setShareModel(false)
            setUserId()
            setDefaultOptions("")
            toast(
                <>
                  <div className="m-1 d-flex">
                    <i
                      class="bi bi-check-circle fs-26 me-3"
                      style={{ color: "#131F4C" }}
                    ></i>
                    <div>
                      <h2
                        style={{
                          // color: "#131F4C",
                          fontSize: "22px",
                        }}
                      >
                        Completed
                      </h2>
                      <h3
                        style={{
                          // color: "#131F4C",
                          fontSize: "18px",
                        }}
                      >
                        Your question set has been shared successfully!
                      </h3>
                    </div>
                  </div>
                </>,
                {
                  toastId: "task-schedule",
                  className: "toast-success-container toast-success-container-after ",
                }
              );
            console.log(res,"7777response")
        })
        data.catch(async(err)=>{
            console.log(err,"7777response")
            setError(err?.response?.data?.message)
        })

     }

     const changeUserOption = (userRole) => {
        console.log(userRole,"userRole")
        setDefaultOptions(userRole);
        setUserId(userRole?.userId);
        setError('')
      };


      const getAllUser = () => {
        let optionGoalLists = [];
        let orgdata;
        orgdata = getOrgMem(
          userInfoDetails?.data?.accessToken, 
        );
        orgdata?.then(async (res) => {
          let optionGoalData = res?.data?.data;
          if (optionGoalData.length !== 0) {
            optionGoalData?.forEach((option) => {
              const data = {
                label: option.first_name + " " + option.last_name,
                value: option.first_name + " " + option.last_name,
                userId: option.id,
              };
              optionGoalLists.push(data);
            });
          }
        });
        orgdata?.catch(async (err) => { });
        setUsersOption(optionGoalLists);
      };


    const  handleEditModalName = (event,questionSet ) =>{
        event.preventDefault()
        // const newQuestions = [...questions];
        // newQuestions[index] = value;
        // setQuestions(newQuestions);
        // console.log(newQuestions, "454545542424242422632065605651562")
        setQuestioneditid(questionSet?.id)
        setTitle(questionSet?.title);
  setQuestions(questionSet?.questions);
  setEditModelShow(true);

    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
      };

      const handleSubmitEdit = (e)=>{
        e.preventDefault()

        const data = editQuestionsSets(questioneditid,title, questions, userInfoDetails?.data?.accessToken, additionalQuestions)
        data.then(async(res)=>{
            console.log(res, "222121212121221212")
            setEditModelShow(false)
            setQuestions([''])
            setAdditionalQuestions([])
            setTitle()
            gettingAllQuestionSet()

        })
        data.catch(async(err)=>{
            console.log(err, "222121212121221212")

        })

      }


    return(
        <>
        <div className="p-0">
       <Modal className="fade bd-example-modal-lg" show={showModal} size="lg">
        <Modal.Body>
          <form onSubmit={handleSubmitQuestion}>
            <div className="d-flex">
              <div className="col">
                <h2 className="text-center">Create Question Set</h2>
              </div>
              <button
                type="button"
                className="btn-close float-end text-center mt-3"
                onClick={() => (setModal(false), setTitle(""),setQuestions([''])
                    )}
              ></button>
            </div>
            <div className="modal-body p-0">
            <div className="form-group mb-3">
                    <h5 className="">Label</h5>
                    <div className="contact-name">
                      <input
                       
                        className="figma-input p"
                        autoComplete="off"
                        
                        required="required"
                        placeholder="Label"
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ height: "36px" }}
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <PerfectScrollbar
              id="chartBox"
              className="widget-media dlab-scroll w-100 "
              options={{ suppressScrollX: true }}
              style={{ maxHeight: "47vh", minHeight: "7vh" }}
            >
                 { questions.map((question, index) => (   <div className="form-group mb-3">
                    <div key={index}>
                 <label htmlFor={`question${index + 1}`}>Question {index + 1}:</label>
                    <div className="contact-name d-flex justify-content-center align-items-center">
                      <input
                       
                        className="figma-input p"
                        autoComplete="off"
                        
                        required="required"
                        placeholder="Add question"
                        type="text"
                        id={`question${index + 1}`}
                        name={`question${index + 1}`}
                        value={question}
                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                        style={{ height: "36px" }}
                      />
                      <span className="validation-text"></span>
                      <button type="button" className="outline-primary btn btn-primary  ms-2 " onClick={() => handleDeleteQuestion(index, true)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                    </div>
                    </div>
                  </div>))}
                  </PerfectScrollbar>
            
            </div>
            <div className=" mb-4">
                <button className="figma-btn text-white" type="button" onClick={handleAddQuestion}> Add Question</button>
            </div>
            
            <input type="submit" value="Submit"  className="float-end figma-btn text-white"/>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="delete-modal" title="delete-modal" src={Vector3} />
          <h4 className="fs-20 p-2">
            Are you sure you want to delete{" "} folder
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
         
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this - {deleteQuestionSet?.title} 
          </p>
          <p className="p-2" style={{color:"red"}}>
           {deleteErrorMessage}
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            // to="/chatbots"
            onClick={(e) => {
              SetOpenDeleteModal(false);
              setDeleteErrorMessage()
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              selectedFolderForDelete(deleteQuestionSet?.id);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>
      <Modal className="fade bd-example-modal-lg" show={shareModel} size="lg">
        <Modal.Body>
          <form onSubmit={handleShareQuestionSet}>
            <div className="d-flex">
              <div className="col">
                <h2 className="text-center mb-2">Share Question Set</h2>
                <h2 className="">{shareQuestionSetId?.title}</h2>
              </div>
              <button
                type="button"
                className="btn-close float-end text-center mt-3"
                onClick={() => (
                    setShareModel(false),
                    setDefaultOptions(),
                    setUserId(),
                    setError('')
                    )
                    }
              ></button>
            </div>
            <div className="modal-body p-0">

                <div className="form-group mb-3">
                   
                    <h5>Select users</h5>
                        <Select
                         required
                          name="goal_owner"
                          className="p"
                          isClearable
                          options={usersOption}
                          value={defaultOptions}
                          onChange={changeUserOption}
                        />
                  </div>
                  {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )}
               
            </div>
          
            
            <input type="submit" value="Share"  className="float-end figma-btn text-white"/>
          </form>
        </Modal.Body>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={editModelShow} size="lg">
  <Modal.Body>
    <form onSubmit={handleSubmitEdit}>
      <div className="d-flex">
        <div className="col">
          <h2 className="text-center">Edit Question Set</h2>
        </div>
        <button
          type="button"
          className="btn-close float-end text-center mt-3"
          onClick={() => {
           setEditModelShow(false)
            setTitle("");
            setQuestions(['']);
            setAdditionalQuestions([])
          }}
        ></button>
      </div>
      <div className="modal-body p-0">
        <div className="form-group mb-3">
          <h5 className="">Label</h5>
          <div className="contact-name">
            <input
              className="figma-input p"
              autoComplete="off"
              required="required"
              placeholder="Label"
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={handleTitleChange}
              style={{ height: "36px" }}
            />
            <span className="validation-text"></span>
          </div>
        </div>
        <PerfectScrollbar
          id="chartBox"
          className="widget-media dlab-scroll w-100"
          options={{ suppressScrollX: true }}
          style={{ maxHeight: "47vh", minHeight: "7vh", paddingRight:"2vh"}}
        >
          {questions?.map((question, index) => (
            <div className="form-group mb-3 " key={index}>
              <label htmlFor={`question${index + 1}`}>Question {index + 1}:</label>
              <div className="contact-name d-flex justify-content-center align-items-center">
                <input
                  className="figma-input p"
                  autoComplete="off"
                  required="required"
                  placeholder="Add question"
                  type="text"
                  id={`question${index + 1}`}
                  name={`question${index + 1}`}
                  value={question?.question}
                  onChange={(e) => handleEditChange(index, e.target.value)}
                  style={{ height: "36px" }}
                />
                <span className="validation-text"></span>
                <button type="button" className="outline-primary btn btn-primary ms-2 " onClick={() => handleDeleteQuestion(index, true)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
              </div>
            
            </div>
          ))}

{ additionalQuestions.map((question, index) => (   <div className="form-group mb-3">
                    <div key={index}>
                 <label htmlFor={`question${index + 1}`}>Question {questions.length + index + 1}:</label>
                    <div className="contact-name d-flex justify-content-center align-items-center">
                      <input
                       
                        className="figma-input p"
                        autoComplete="off"
                        
                        required="required"
                        placeholder="Add question"
                        type="text"
                        id={`question${index + 1}`}
                        name={`question${index + 1}`}
                        value={question}
                        onChange={(e) => handleAdditionalQuestionChange(index, e.target.value)}
                        style={{ height: "36px" }}
                      />
                      <span className="validation-text"></span>
                      <button type="button" className="outline-primary btn btn-primary ms-2 " onClick={() => handleDeleteQuestion(index, false)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                    </div>

                    
                    </div>
                  </div>))}   

                  <div className=" mb-4">
                <button className="figma-btn text-white" type="button" onClick={handleAddAdditionalQuestion}> Add Question</button>
            </div>


        </PerfectScrollbar>
      </div>
      
      <input type="submit" value="Save" className="float-end figma-btn text-white" />
    </form>
  </Modal.Body>
</Modal>




       <div className="d-flex col p-0 m-0 ">
        <div className="col">
          {/* {localStorage.getItem("previousLocation") !==
            localStorage.getItem("currentLocation") && (
            <span className="float-start me-3 mt-1">
              <i
                className="fa fa-reply fs-20"
                title={`Navigate back to ${
                  localStorage.getItem("previousLocation").split("/")[1]
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(localStorage.getItem("previousLocation"));
                }}
              />
            </span>
          )}
          <span className="h2">Question set</span> */}
        </div>
        <button
                  className="figma-btnnn m-1">
        <span className="float-end">
          <Link
            to=""
            onClick={ShowQuesttionModel}
           
            // style={{ float: "right" }}
            // className="btn btnUser"
          >
        
            <span style={{color:"white"}}>Create Question</span>
          </Link>
        </span>
        </button>
      </div>
     
      {loader ? (
        <center>
          <div style={{ padding: "20px", margin: "300px" }}>
            <LoadingSpinner/>
          </div>
        </center>
      ) : (
        <>
        
      <div className="row mt-4">
  {questionSets.map((set, index) => (
    <div className="col-md-12 col-lg-12 mb-3" key={set.id}>
      <div className="card contact-bx item-content">
        <div className="card-body user-profile pb-0">
          <div className="d-flex justify-content-between">
            <h3>
              {set.title} 
              {set?.default === true ? <span className="badge badge-pill badge-warning ms-2">Default</span> : ""}
            </h3>
            <span className="float-end">
              <Dropdown className="ms-auto">
                <Dropdown.Toggle
                  variant=""
                  as="div"
                  className="btn-link i-false"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12.4999"
                      cy="3.5"
                      r="2.5"
                      fill="#A5A5A5"
                    />
                    <circle
                      cx="12.4999"
                      cy="11.5"
                      r="2.5"
                      fill="#A5A5A5"
                    />
                    <circle
                      cx="12.4999"
                      cy="19.5"
                      r="2.5"
                      fill="#A5A5A5"
                    />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight={true}
                  data-testid="org-menu"
                  className="dropdown-menu-right"
                >
                {!set?.default_superuser && <Dropdown.Item
                    data-testid="org-edit"
                    onClick={(event) => {
                      setEditModelShow(true);
                      handleEditModalName(event, set, index);
                    }}
                  >
                    Edit
                  </Dropdown.Item>}
                  <Dropdown.Item
                    data-testid="org-delete"
                    onClick={(event) => {
                      makeDefaultQuestionSet(event, set);
                    }}
                  >
                    Default
                  </Dropdown.Item>
                  {!set?.default_superuser &&   <Dropdown.Item
                    data-testid="org-delete"
                    onClick={(event) => {
                      setShareModel(true);
                      getShareQuestionSet(event, set);
                    }}
                  >
                    Share
                  </Dropdown.Item>}
                  {!set?.default_superuser &&  <Dropdown.Item
                    className="text-danger"
                    data-testid="org-delete"
                    onClick={(event) => {
                      SetOpenDeleteModal(true);
                      DeletegetQuestionSetId(event, set);
                    }}
                  >
                    Delete
                  </Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
          {set.questions.map((question) => (
            <div key={question.id}>
              <Row>
                <h4 style={{ wordWrap: "break-word" }}>Question {question.order}</h4>
              </Row>
              <Row>
                <p style={{ wordWrap: "break-word" }}>{question.question}</p>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </div>
  ))}
</div>

         </>
              )}
       </div>
        </>
    )
}
export default ChatbotQuestionSet