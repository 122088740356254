import { formatError } from "../../services/AuthService";
import {
  createOrg,
  getOrgs,
  deleteOrganization,
  editOrg,
  userSpecificOrg,
  defaultOrg,
} from "../../services/OrganizationService";

export const CREATE_ORG_CONFIRMED_ACTION =
  "[createOrg action] confirmed createOrg";
export const CREATE_ORG_FAILED_ACTION = "[createOrg action] failed createOrg";
export const GET_ORG_LIST_CONFIRMED_ACTION =
  "[getOrgs action] confirmed getOrgs";
export const GET_ORG_LIST_FAILED_ACTION = "[getOrgs action] failed getOrgs";
export const DELETE_ORG_CONFIRMED_ACTION =
  "[deleteOrganization action] confirmed deleteOrganization";
export const DELETE_ORG_FAILED_ACTION =
  "[deleteOrganization action] failed deleteOrganization";
export const EDIT_ORG_CONFIRMED_ACTION = "[editOrg action] confirmed editOrg";
export const EDIT_ORG_FAILED_ACTION = "[editOrg action] failed editOrg";
export const GET_USER_SPECIFIC_ORG_LIST_CONFIRMED_ACTION =
  "[userSpecificOrg action] confirmed userSpecificOrg";
export const GET_USER_SPECIFIC_ORG_LIST_FAILED_ACTION =
  "[userSpecificOrg action] failed userSpecificOrg";
export const DEFAULT_ORG_CONFIRMED_ACTION =
  "[defaultOrg action] confirmed defaultOrg";
export const DEFAULT_ORG_FAILED_ACTION =
  "[defaultOrg action] failed defaultOrg";

export function createOrgAction(
  org_name,
  address_one,
  address_two,
  city,
  state,
  country,
  zip_code,
  email,
  contact,
  org_description,
  token
) {
  return (dispatch) => {
    createOrg(
      org_name,
      address_one,
      address_two,
      city,
      state,
      country,
      zip_code,
      email,
      contact,
      org_description,
      token
    )
      .then((response) => {
        dispatch(createOrgConfirmAction(response.data));
      })
      .catch((error) => {
        // const errorMessage = formatError(error.response.data);
        dispatch(createOrgFailedAction(error.response.data));
      });
  };
}
export function createOrgConfirmAction(message) {
  return {
    type: CREATE_ORG_CONFIRMED_ACTION,
    payload: message,
  };
}
export function createOrgFailedAction(message) {
  return {
    type: CREATE_ORG_FAILED_ACTION,
    payload: message,
  };
}

export function getUserSpecificOrgList(userId, token) {
  return (dispatch) => {
    userSpecificOrg(userId, token)
      .then((response) => {
        dispatch(getUserSpecificOrgListConfirmAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(getUserSpecificOrgListFailedAction(errorMessage));
      });
  };
}
export function getUserSpecificOrgListConfirmAction(message) {
  return {
    type: GET_USER_SPECIFIC_ORG_LIST_CONFIRMED_ACTION,
    payload: message,
  };
}

export function getUserSpecificOrgListFailedAction(message) {
  return {
    type: GET_USER_SPECIFIC_ORG_LIST_FAILED_ACTION,
    payload: message,
  };
}
export function getOrgList(userId, token) {
  return (dispatch) => {
    getOrgs(userId, token)
      .then((response) => {
        dispatch(getOrgListConfirmAction(response.data));
      })
      .catch((error) => {
        console.log("error", error);
        const errorMessage = formatError(error.response.data);
        dispatch(getOrgListFailedAction(errorMessage));
      });
  };
}
export function getOrgListConfirmAction(message) {
  return {
    type: GET_ORG_LIST_CONFIRMED_ACTION,
    payload: message,
  };
}

export function getOrgListFailedAction(message) {
  return {
    type: GET_ORG_LIST_FAILED_ACTION,
    payload: message,
  };
}
export function deleteOrg(orgId, token) {
  return (dispatch) => {
    deleteOrganization(orgId, token)
      .then((response) => {
        dispatch(deleteOrgConfirmAction(response.data.message));
      })
      .catch((error) => {
        console.log("error", error);
        const errorMessage = formatError(error.response.data);
        dispatch(deleteOrgFailedAction(errorMessage));
      });
  };
}
export function deleteOrgConfirmAction(message) {
  return {
    type: DELETE_ORG_CONFIRMED_ACTION,
    payload: message,
  };
}

export function deleteOrgFailedAction(message) {
  return {
    type: DELETE_ORG_FAILED_ACTION,
    payload: message,
  };
}
export function editOrgAction(orgId, updatedOrgDetails, token) {
  return (dispatch) => {
    editOrg(orgId, updatedOrgDetails, token)
      .then((response) => {
        dispatch(editOrgConfirmAction(response.data));
      })
      .catch((error) => {
        console.log("error", error);
        const errorMessage = formatError(error.response.data);
        dispatch(editOrgFailedAction(errorMessage));
      });
  };
}
export function editOrgConfirmAction(data) {
  return {
    type: EDIT_ORG_CONFIRMED_ACTION,
    payload: data,
  };
}

export function editOrgFailedAction(message) {
  return {
    type: EDIT_ORG_FAILED_ACTION,
    payload: message,
  };
}
export function setDefaultOrgAction(orgId, token) {
  return (dispatch) => {
    defaultOrg(orgId, token)
      .then((response) => {
        
        dispatch(defaultOrgConfirmAction(response.data));
      })
      .catch((error) => {
        console.log("error", error);
        const errorMessage = formatError(error.response.data);
        dispatch(defaultOrgFailedAction(errorMessage));
      });
  };
}
export function defaultOrgConfirmAction(data) {
  return {
    type: DEFAULT_ORG_CONFIRMED_ACTION,
    payload: data,
  };
}

export function defaultOrgFailedAction(message) {
  return {
    type: DEFAULT_ORG_FAILED_ACTION,
    payload: message,
  };
}
