import axios from "axios";
import config from "./config";
const baseURL = config.BASE_URL;

export function getCategories() {
  return axios.get(baseURL + "strategy/category", {
    headers: {},
  });
}

export function getSubCategories(id) {
  return axios.get(baseURL + "strategy/category/" + id, {
    headers: {},
  });
}

export function getPartnerStrategy() {
  return axios.get(baseURL + "strategy/store", {
    headers: {},
  });
}

export function getTrustedPartner() {
  return axios.get(baseURL + "users/trusted-by", {
    headers: {},
  });
}

export function getSubStrategy() {
  return axios.get(baseURL + "strategy/store/sub_category", {
    headers: {},
  });
}

export function getSearchResults(value, scope, filter = null, page = 0) {
  const modifiedName = scope?.replace(/\//g, '-');
  console.log(modifiedName, scope, "scopessssssfjjkldnklkf")
  let postData;
  if (filter) {
    let price = "";
    if (filter?.price?.length) {
      price = String(
        `${filter?.price[0]?.min || 0}-${filter?.price[0]?.max || 0}`
      );
    }
    postData = {
      ...filter,
      price: price || "0-0",
      search_text: value,
    };
  } else {
    postData = { search_text: value };
  }
  return axios.post(
    baseURL +
    `strategy/store/search/${String(modifiedName)}?page=${page ? page + 1 : 1}`,
    postData,
    {
      headers: {
        // Authorization: 'Bearer ' + token
      },
    }
  );
}

export function getFilterData() {
  return axios.get(baseURL + `strategy/store/filter-list`, {
    headers: {
      // Authorization: 'Bearer ' + token
    },
  });
}

export function makeStripePayment(
  plan_type,
  currancy,
  selectedUsers,
  strategy,
  orgId,
  token
) {
  let selectedUser = "";
  if (localStorage.getItem("strategyStatus") === "true") {
    selectedUser = null;
    plan_type = "";
  } else {
    selectedUser = parseInt(selectedUsers);
    plan_type = plan_type;
  }
  const postData = {
    organization_id: orgId,
    plan_type: plan_type,
    currency: currancy,
    new_user_quantity: selectedUser,
    strategies: [strategy],
  };
  return axios.post(baseURL + `billing/make-payment/strategy`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getInvoiceDetails(session_id, token) {
  return axios.get(baseURL + `billing/payment/success`, {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      session_id: session_id,
    },
  });
}

export function createQuestion(strategy, title, token) {
  const postData = {
    strategy: strategy,
    title: title,
  };
  return axios.post(baseURL + `strategy/question/create`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getQuestionList(strategyId, token) {
  return axios.get(baseURL + `strategy/question/${strategyId}/list`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function createAnswer(question, content, token) {
  const postData = {
    question: question,
    content: content,
  };
  return axios.post(baseURL + `strategy/answer/create`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getAnswerList(questionId, token) {
  return axios.get(baseURL + `strategy/answer/${questionId}/list`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function addReviews(strategy, token) {
  const postData = {
    // strategy: strategy,
    // rating: rating,
    // comment: comment,
    rating_data: strategy,
  };
  return axios.post(baseURL + `strategy/reviews`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function askMeLater(strategy, token) {
  const postData = {
    strategy_id: strategy,
  };
  return axios.post(baseURL + `strategy/review/ask-me-later`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function strategyReviewNoThanks(strategy, token) {
  const postData = {
    strategy_id: strategy,
  };
  return axios.post(baseURL + `strategy/review/no-thanks`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getReviewsList(strategyId, token) {
  return axios.get(baseURL + `strategy/${strategyId}/reviews/list/all`);
}


export function getStoreImages() {
  return axios.get(baseURL + "strategy/store/slider-image", {
    headers: {
     
    },
  });
}


export function getStrategyById(stategyId, token) {
  return axios.get(baseURL + `strategy/details/${stategyId}`, {
    headers: {
      // Authorization: "Bearer " + token,
    },
  });
}

export function getCategoryById(categoryId) {
  return axios.get(baseURL + `strategy/category/retrieve/${categoryId}`, {
    headers: {
      // Authorization: "Bearer " + token,
    },
  });
}