import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getCategories,
  getCategoryById,
  getSubCategories,
} from "../../../services/StoreService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [store, setStore] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    JSON.parse(localStorage.getItem("store"))?.selectedCategory
  );
  const [Index, setIndex] = useState(null);
  const [data, setData] = useState()

  

  useEffect(()=>{
    const url = window.location.href
    const regex = /\/category\/(\d+)\//;
    const match = url.match(regex);
    
    if (match) {
      var categoryId = match[1];
      console.log("Category ID:", categoryId);
      const data = getCategoryById(categoryId);
      data?.then(((res) => {
       console.log(res,"LLLLLLLLLLLLLLLLLLLLLLLLLL")
    
      let localStorageValue = {};
      localStorageValue['SelectedStrategy'] = {}
      if(location?.pathname.includes("category")){
        setData(res?.data?.data)

localStorageValue['navSelectedCategory'] = res?.data?.data;
localStorage.setItem('store', JSON.stringify(localStorageValue));}
else if(location?.pathname.includes("subcategory")){
  setData(res?.data?.data)
  localStorageValue['selectedSubCategories'] = res?.data?.data;
localStorage.setItem('store', JSON.stringify(localStorageValue));
}
        })
      );
      data?.catch(((err) => { 
       console.log(err)
      }));
    } else {
      console.log("No category ID found in the URL");
    } 

  },[window.location.href])

  useEffect(() => {
    if (location.pathname.includes("/category")){
      getSubStoreCategories(
        JSON.parse(localStorage.getItem("store"))?.navSelectedCategory?.id
      );
    } else if (location.pathname === "/store-front") {
      getStoreCategories();
    }
  }, [
    location,
    JSON.parse(localStorage.getItem("store"))?.navSelectedCategory?.id,
  ]);

  useEffect(() => {
    setSelectedCategory(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    if (store.length > 0) {
      initializeSlider();
    }
  }, [store]);

  const initializeSlider = () => {
    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 7,
      slidesToScroll: 7,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 3841,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          },
        },
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Slider className="slider-styles" {...sliderSettings}>
        {store?.map((element, index) => (
          // <div
          //   key={element.id}
          //   className="slider-styles"
          //   href="#"
          //   onClick={(e) => {
          //     console.log(element?.id,"llllllllllllllllll")
          //     e.preventDefault();
          //     if (element?.subcategories) {
          //       let stores = JSON.parse(localStorage.getItem("store"));
          //       stores["navSelectedCategory"] = element;
          //       localStorage.setItem("store", JSON.stringify(stores));
          //       setSelectedCategory(element);
          //       navigate("/category-page",{
          //         state:{element}
          //       });
          //     } else {
          //       setIndex(() => element.id);
          //       let stores = JSON.parse(localStorage.getItem("store"));
          //       stores["selectedSubCategories"] = element;
          //       localStorage.setItem("store", JSON.stringify(stores));
          //       setSelectedCategory(element);
          //       navigate(`/category-page/${element?.id}`, {
          //         state: { element },
          //       });
          //     }
          //   }}
          // >
          //   <span
          //   className="category-slider-font"
          //     style={
          //       Index === element.id
          //         ? {
          //           color: "#356fd4",
          //           fontFamily: "Open Sans",
          //           fontWeight: 400,
          //           alignItems: "center",
          //           backgroundColor: "#c3c1c1 !important",
          //           border: "1px solid #b0afaf !important",
          //           // borderBottom: "1px solid #8ea3f1",
          //         }
          //         : {
          //           color: "#353437",
          //           fontWeight: 400,
          //           fontFamily: "Open Sans",
          //           alignItems: "center",
          //           backgroundColor: "#c3c1c1 !important",
          //           border: "1px solid #b0afaf !important",
          //         }
          //     }
          //   >
          //     {element.name}
          //   </span>
          // </div>
          <Link
          key={element.id}
          className="slider-styles"
          to={`/category/${element?.id}/${(element?.name).replace(/ /g, '-').toLowerCase()}`}
          onClick={(e) => {
            console.log(element, "llllllllllllllllll");
            e.preventDefault();
            if (element?.subcategories) {
              let stores = JSON.parse(localStorage.getItem("store"));
              stores["navSelectedCategory"] = element;
              localStorage.setItem("store", JSON.stringify(stores));
              setSelectedCategory(element);
              navigate(`/category/${element?.id}/${(element?.name).replace(/ /g, '-')}`, {
                state: { element },
              });
            } else {
              setIndex(() => element.id);
              let stores = JSON.parse(localStorage.getItem("store"));
              stores["selectedSubCategories"] = element;
              localStorage.setItem("store", JSON.stringify(stores));
              setSelectedCategory(element);
              // navigate(`/subcategory/${element?.id}/${(element?.name).replace(/ /g, '-')}`, {
              //   state: { element },
              // });

              // navigate(`/subcategory/${element?.id}/${(element?.name.replace(/\s+/g, '-').replace(/\//g, '-'))}`, {
              //   state: { element },
              // })

              navigate(`/subcategory/${element?.id}/${(element?.name.replace(/\s+/g, '-').replace(/ *\/ */g, '-'))}`, {
                state: { element },
            });
            

            }
          }}
          style={
            Index === element.id
              ? {
                  color: "#356fd4",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  alignItems: "center",
                  backgroundColor: "#c3c1c1 !important",
                  border: "1px solid #b0afaf !important",
                  // borderBottom: "1px solid #8ea3f1",
                }
              : {
                  color: "#353437",
                  fontWeight: 400,
                  fontFamily: "Open Sans",
                  alignItems: "center",
                  backgroundColor: "#c3c1c1 !important",
                  border: "1px solid #b0afaf !important",
                }
          }
        >
          <span className="category-slider-font">{element.name}</span>
        </Link>
        ))}
      </Slider>
    );
  };

  const getStoreCategories = async () => {
    const data = getCategories();
    data?.then(
      await ((res) => {
        setStore(res.data.data);
      })
    );
    data?.catch(await ((err) => { }));
  };

  const getSubStoreCategories = async (id) => {
    const data = getSubCategories(id);
    data?.then(
      await ((res) => {
        setStore(res.data.data);
      })
    );
    data?.catch(await ((err) => { }));
  };

  return (
    <>
      <div className="nav-bars">
        <div className="nav-bar-items" data-testid="nav-bar-items">
          <div className="selected-Tab text-center">
            <Link
              className=" text-center text-wrap"
              href="#"
              style={{}}
              onClick={(e) => {
                e.preventDefault();
                let stores = JSON.parse(localStorage.getItem("store")) || {};
                stores["navSelectedCategory"] = { name: "all" };
                stores["selectedSubCategories"] = null;
                localStorage.setItem("store", JSON.stringify(stores));
                navigate("/store-front", {
                  state: location?.state?.element,
                });
              }}
            >
              <span className="selected-nav-item-text">
                {"All categories"}
              </span>
            </Link>
          </div>
          {JSON.parse(localStorage.getItem("store"))?.navSelectedCategory
            ?.name !== "all" &&
            <>
              <span className="right-arrow">&#9654;</span>
              <div className="selected-Tab text-center me-1">
                <Link
                  className=" text-center"
                  href="#"
                >
                  <span className="selected-nav-item-text">
                    {JSON.parse(localStorage.getItem("store"))
                      ?.navSelectedCategory?.name} 
                  </span>
                </Link>
              </div></>}
          <div
            className="ms-2 col text-center slider-container"
            style={{
           
              fontSize: "16px",
            }}
          >
            {store.length > 0 && initializeSlider()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
