import axios from 'axios';
import config from './config';
const baseURL = config.BASE_URL;
const userDetails = localStorage.getItem('userDetails');

export function getOrgMem(token) {
    const orgId = localStorage.getItem('organization_id')
    return axios.get(
        baseURL + `organizations/members/` + orgId, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getUsersForShare(token, query) {
    const orgId = localStorage.getItem('organization_id')
    return axios.post(
        baseURL + `goals/search/users/teams`, query, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function sharingTask(token, payload) {
    return axios.post(
        baseURL + `goals/task/share`, payload, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getOrgInvitedMem(token) {
    const orgId = localStorage.getItem('organization_id')
    const putData = {
        "organization_id": orgId
    }
    return axios.post(
        baseURL + `organizations/invited-users`, putData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}
export function createTeam(teamName, orgId, userId, userList, teamId, token) {
    const postData = {
        name: teamName,
        organization_id: orgId,
        team_manager: userId,
        team_members: userList,
        team_parent: teamId
    };
    return axios.post(
        baseURL + `teams/create`,
        postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }

    );
}

export function editTeam(teamName, orgId, userId, image, userList, teamId, token) {
    const putData = {
        name: teamName,
        organization_id: orgId,
        team_members: userList,
        team_image: image || null
    };
    if (putData.team_image) {
        delete putData['team_members']
        return axios.put(
            baseURL + `teams/team/` + teamId + `/update`,
            putData, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': "multipart/form-data"
            }
        }
        );
    }
    else {
        return axios.put(
            baseURL + `teams/team/` + teamId + `/update`,
            putData, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
        );
    }
}

export function getAllOrgTeams(orgId, token) {

    return axios.get(
        baseURL + `teams/org/` + orgId, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getAllSubTeam(teamId, token) {
    return axios.get(
        baseURL + `teams/` + teamId + `/sub-teams`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function deleteTeam(teamId, orgId, token) {
    return axios.delete(
        baseURL + `teams/team/` + teamId + `/delete`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getTeamMemById(teamId, token) {
    return axios.get(
        baseURL + `teams/` + teamId + `/members`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}