import React from "react";

function LoadingSpinner(props) {
  return (
    <div
      style={{
        position: "relative",
        top: props?.data?.top,
        left: props?.data?.left,
      }}
      className="m-0 p-0 centered"
      data-testid="loading-spinner"
    >
      <center>
        <div
          style={{
            padding: props?.data?.padding,
            margin: props?.data?.margin,
            marginY: props?.data?.marginY,
          }}
          className="loader"
        >
          <div className="loading-dots--dot mx-1" data-testid="loading-dot-1"></div>
          <div className="loading-dots--dot " data-testid="loading-dot-2"></div>
          <div className="loading-dots--dot mx-1" data-testid="loading-dot-3"></div>
        </div>
      </center>
    </div>
  );
}

export default LoadingSpinner;
