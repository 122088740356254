import React from "react";
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
  Skeleton,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useState, useEffect } from "react";
import { getAllGoalsByOrgID } from "../../../services/GoalService";
import completedGoal from "../../../images/goalCompleted.png";
import Vector_disable from '../../../images/Plansom/Vector_disable.png'
import RocketFilled from '../../../images/Plansom/RocketFilled.png'
import InfoCircleFilled from '../../../images/Plansom/InfoCircleFilled.png'

const PlannedObjectives = () => {
  const [goals, setGoals] = useState([]);
  const [fetchingGoals, setFetchingGoals] = useState(false);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);

  console.log(goals, "goalffffffhijokljgcxdfhyyiopyhh")

  const getAllTheGoal = () => {
    setFetchingGoals(true);
    const data = getAllGoalsByOrgID(
      userInfoDetails?.data?.accessToken,
      null,
      "",
      "",
      "",
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      3,
      "",
      "",
      ""
    );
    data?.then(async (res) => {
      setGoals(res?.data?.data);
      setFetchingGoals(false);
    });
    data?.catch(async (err) => {
      console.log("error getAllGoalsByOrgID", err);
    });
  };
  useEffect(() => {
    getAllTheGoal();
  }, []);

  const getGoalIcon = (goal) => {
    if(goal?.status === 'Inactive'){
      return <img src={Vector_disable} alt="complete" />;
    }
   else if (goal?.goal_status === "On track")
      return <img src={completedGoal} alt="about to complete" />;
    else if (goal?.goal_status === "At risk")
      return <WarningAmberIcon sx={{ color: "#FFA726" }} />;
    else if (goal?.goal_status === "Failed"){
    return <RemoveCircleOutlineOutlinedIcon sx={{ color: "#FF4D4F" }} />;
    }else if (goal?.goal_status === "Failing") {
      return <img src={RocketFilled} alt="Failing" />;
    }
  };

  return (
   <>
  {goals?.length == 0 ? "":
   <Card
      sx={{
        borderRadius: 4,
        padding: 2,
        minHeight: "46vh",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: fetchingGoals ? 5 : 3 }}>
          Goals Progress
        </Typography>
        {
          fetchingGoals ? <>
            <Skeleton style={{ marginBottom: "7%" }} />
            <Skeleton style={{ marginBottom: "7%" }} />
            <Skeleton style={{ marginBottom: "7%" }} />
            <Skeleton style={{ marginBottom: "7%" }} />
            <Skeleton style={{ marginBottom: "7%" }} />
          </> :
            <>
              {goals?.map((goal, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    {getGoalIcon(goal)}


                    <Typography sx={{ ml: 1, flexGrow: 1 }}>{goal?.name}</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                  {goal?.goal_status === "Failing" ? (`"Failing :" ${goal?.goal_completed_percent} % <img src={RocketFilled} alt="Failing" />`) : `${goal?.goal_completed_percent}%`}    
                    </Typography>
                  </Box>
                  <LinearProgress
                    sx={{
                      height: 8,
                      borderRadius: 5,
                      backgroundColor: "#DDDEE4",
                      "& .MuiLinearProgress-bar": {
                        bgcolor: 
                          goal?.status === "Inactive" 
                            ? "#DDDEE4" 
                            : goal?.goal_status === "On track"
                              ? "#0B9060"
                              : goal?.goal_status === "At risk"
                                ? "#FAAD14"
                                : "#FF4D4F",
                      },
                    }}
                    variant="determinate"
                    value={goal?.goal_completed_percent}
                  />
                </Box>
              ))}
            </>
        }
      </CardContent>
    </Card> } 
    </>   
  );

};

export default PlannedObjectives;
