import React, { useState, useEffect, lazy } from "react";
import { RingLoader } from "react-spinners";
import loadable from "@loadable/component";
// import LineChart from "./../Chartjs/line1";
// import LineCharts from "./../Chartjs/LineChart";
// import PieChart from "../Chartjs/PieChart";
// import BarChart from "../Chartjs/taskBarChart";
import { Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import WinBarChart from "../Chartjs/WinBarChart";
import {
  getAllGoalsByScope,
  getAllGoalsByScopeInTime,
} from "../../../services/GoalService";
import Select from "react-select";
import BarChartLowMediumHigh from "../Chartjs/BarChartLowMediumHigh";

const LineChart = lazy(()=>import("./../Chartjs/line1"))
const LineCharts = lazy(()=>import("./../Chartjs/LineChart"))
const PieChart = lazy(()=>import("../Chartjs/PieChart"))
const BarChart = lazy(()=>import("../Chartjs/taskBarChart"))
const WinBarChart = lazy(()=>import("../Chartjs/WinBarChart"))

const Time = () => {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const scope = localStorage.getItem("scope");
  const timeScope = localStorage.getItem("timescope");
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const org_id = localStorage.getItem("organization_id");
  const team_id = localStorage.getItem("team_id");
  const location = useLocation();
  const navigate = useNavigate();
  const [allParentGoals, setAllParentGoals] = useState();
  const [allSubGoals, setAllSubGoals] = useState();
  const [selectedGoalType, setSelectedGoalType] = useState(null);
  const [selectedSubGoalType, setSelectedSubGoalType] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
  }, []);

  useEffect(() => {
    getGoals(org_id, scope, userInfoDetails?.data?.accessToken, "");
  }, []);

  useEffect(() => {
    setSelectedGoalType(selectedGoalType);
    setSelectedSubGoalType(selectedSubGoalType);
  }, [selectedSubGoalType, selectedGoalType]);

  useEffect(() => {
    // if (location && location.state && location?.state?.refresh === true) {
    setRefreshGraph(true);
    // }
    let goal_ID = selectedSubGoalType?.value
      ? selectedSubGoalType?.value
      : selectedGoalType?.value
        ? selectedGoalType?.value
        : "";

    getGoals(org_id, scope, userInfoDetails?.data?.accessToken, goal_ID);
  }, [location?.state, scope, timeScope, team_id]);

  const getGoals = (org_id, scope, token, goal_id) => {
    const data = getAllGoalsByScopeInTime(goal_id, token);
    data?.then((res) => {
      console.log("res?.data?.data", res?.data?.data?.goals);

      if (!goal_id) {
        let parentgoalData = [];
        res?.data?.data?.goals.forEach((ele) => {
          parentgoalData.push({ value: ele?.id, label: ele?.name });
        });
        setAllParentGoals(parentgoalData);
      } else {
        if (goal_id) {
          let subgoalData = [];
          res?.data?.data?.sub_goal.forEach((ele) => {
            subgoalData.push({ value: ele?.id, label: ele?.name });
          });
          setAllSubGoals(subgoalData);
        }
      }
    });
    data?.catch((err) => { });
  };

  const changeGoalTypeHandler = (selectedGoal) => {
    console.log("selectedGoal", selectedGoal);
    getGoals(
      org_id,
      scope,
      userInfoDetails?.data?.accessToken,
      selectedGoal?.value
    );
    setSelectedGoalType(selectedGoal);
    setRefreshGraph(true);
  };

  const changeSubGoalTypeHandler = (selectedGoal) => {
    // getGoals(
    //   org_id,
    //   scope,
    //   userInfoDetails?.data?.accessToken,
    //   selectedGoal?.value
    // );
    setSelectedSubGoalType(selectedGoal);
    setRefreshGraph(true);
  };

  useEffect(() => {
    setSelectedGoalType(null);
    setSelectedSubGoalType(null);

    setAllParentGoals(null);
  }, [scope, team_id]);

  return (
    <>
      <Row className="my-4">
        <div className="col-md-6">
          {localStorage.getItem("previousLocation") !== localStorage.getItem("currentLocation") && (
            <span className="">
              <i
                className="fa fa-reply fs-20"
                title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]}`}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  console.log("2435687394053");
                  navigate(localStorage.getItem("previousLocation"));
                }}
              />
            </span>
          )}
        </div>

        <div className="col-md-6 float-end" data-testid="parent-goal-dropdown">
          <div className="row">
            <div className="col-md-6 mr-5">
              <div className="mb-2">
                <Select
                  style={{ zIndex: "22222 !important" }}
                  className="p fs-14 w-100"
                  name="goalType"
                  placeholder="Please select goal type..."
                  options={allParentGoals}
                  styles={{ padding: "2px", borderRadius: "10px" }}
                  value={selectedGoalType}
                  onChange={changeGoalTypeHandler}
                  isClearable
                  required
                />
              </div>
            </div>

            {selectedGoalType?.value && (
              <div className="col-md-6">
                <div className="mb-2">
                  <Select
                    style={{ zIndex: "22222 !important" }}
                    className="p fs-14 w-100"
                    name="goalType"
                    placeholder="Please select sub-goal type..."
                    options={allSubGoals}
                    styles={{ padding: "2px", borderRadius: "10px" }}
                    value={selectedSubGoalType}
                    onChange={changeSubGoalTypeHandler}
                    isClearable
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Row>




      <Row>
        <BarChart
          refresh={refreshGraph}
          goal_id={
            !selectedSubGoalType?.value
              ? selectedGoalType?.value
              : selectedSubGoalType?.value
          }
        />
        <PieChart
          refresh={refreshGraph}
          goal_id={
            !selectedSubGoalType?.value
              ? selectedGoalType?.value
              : selectedSubGoalType?.value
          }
        />
      </Row>
      <Row>
        <LineCharts
          refresh={refreshGraph}
          goal_id={
            !selectedSubGoalType?.value
              ? selectedGoalType?.value
              : selectedSubGoalType?.value
          }
          setRefreshGraph={setRefreshGraph}
        />
        <LineChart
          refresh={refreshGraph}
          goal_id={
            !selectedSubGoalType?.value
              ? selectedGoalType?.value
              : selectedSubGoalType?.value
          }
          setRefreshGraph={setRefreshGraph}
        />
      </Row>
      <Row>
        <WinBarChart
          refresh={refreshGraph}
          goal_id={
            !selectedSubGoalType?.value
              ? selectedGoalType?.value
              : selectedSubGoalType?.value
          }
          setRefreshGraph={setRefreshGraph}
        />
          <BarChartLowMediumHigh
          refresh={refreshGraph}
          goal_id={
            !selectedSubGoalType?.value
              ? selectedGoalType?.value
              : selectedSubGoalType?.value
          }
          setRefreshGraph={setRefreshGraph}
        />
      </Row>
     
    </>
  );
};

export default Time;
