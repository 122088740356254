import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import star from "../../../images/vector/Vector.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

function RatingImage() {
  return (
    <div className="rating-stars">
      {/* <img
        src={star}
        alt="rating-stars"
        title="rating-stars"
        style={{ width: "10px", marginTop:"5vw",marginLeft:"2.2vw", position:"relative" }}
      /> */}
      <LazyLoadImage
       src={star}
       alt="rating-stars"
       title="rating-stars"
       style={{ width: "10px", marginTop:"5vw",marginLeft:"2.2vw", position:"relative" }}
        />
    </div>
  );
}

function CircularRatings(props) {
  console.log("propppss", props)
  return (
    <div className="circular-ratings-container">
      <CircularProgressbar
        // value={props?.ratings}
        value={props?.ratings} maxValue={5} text={props?.ratings}
        styles={{ color: "#b7a42a" }}
      >
        {/* <RatingImage/> */}
        {/* <div
          className="ratingsValueInStrategyDetailsPage"
          style={{ fontSize: 12, marginTop: "0.4vw", marginLeft:"2.6vw" }}
        >
          <strong >{props?.ratings}%</strong>
        </div> */}
      </CircularProgressbar>
    </div>
  );
}

export default CircularRatings;
