import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Card, Row } from "react-bootstrap";
import { CustomErrorComponent } from "custom-error";
import { getSampleTemplateFile } from "../../../services/OrganizationService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Modal } from "react-bootstrap";
import { createFolder } from "../../../services/OrganizationService";
import { getfolderList } from "../../../services/OrganizationService";
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ViewFolder from "./ViewFolder";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { deleteFolder } from "../../../services/OrganizationService";
import Vector3 from "../../../images/vector/Vector3.png"
import { editFolder } from "../../../services/OrganizationService";
import LoadingSpinner from "../Component/LoadingSpinner";
import { uploadFileInFolder } from "../../../services/OrganizationService";
import { toUseTemplate } from "../../../services/OrganizationService";
import { moveFileInFolder } from "../../../services/OrganizationService";
import ShareTemplate from "./ShareTemplate";
import ShareTemplateFolder from "./ShareTemplateFolder";

const onError = (e) => {
  return (
    <>
      <div data-testid="error">Error in loading excel sheet</div>
    </>
  );
};

function Template() {
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [url, setUrl] = useState([]);
  console.log("OOOOOOOOOOOOOOO", url)
  const type = "xlsx";

  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [deleteFolderName, setDeleteFolderName] = useState("")

  const [selectedTemplate, setSelectedTemplate] = useState();


  const [showModal, setShowModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [largeModal, setLargeModal] = useState(false)
  const [path, setpath] = useState("")
  const [folders, setFolders] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [editModal, setEditModel] = useState(false)
  const [prevEditFolderName, setPrevEditFolderName] = useState(false)
  const [editFolderName, setEditFolderName] = useState("")

  const [editName, setEditName] = useState("")

  const [loader, setLoader] = useState(false)


  const [showFileUplod, setShowFileUpload] = useState(false)

  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');

  const [folderNameToDrop, setFolderNameToDrop] = useState()
  const [manageHeight, setManageHeight] = useState(false)

  const [shareOpen, setShareOpen] = useState(false)
  const [shareTemplateFileName, setShareTemplateFileName] = useState()

  const [shareOpenFolder, setShareOpenFolder] = useState(false)
  const [shareTemplateFolderName, setShareTemplateFolderName] = useState()

  const [showTemplateOrFolder, setShowTemplateFolder] = useState()
  const [ShowEditTemplateOrFolder, setShowEditTemplateOrFolder] = useState()


  console.log(manageHeight, "manageHeight")


  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }

    // getFile();
    // getFolder()
  }, []);

  useEffect(() => {
    setUrl(url);
  }, [url]);

  // const getFile = () => {
  // const data = getSampleTemplateFile(userInfoDetails?.data?.accessToken);
  // data?.then((res) => {
  // setUrl([...url, { url: res?.data?.data?.url }]);
  // });
  // data?.catch((err) => { });
  // };


  // const folders = ['Folder 1', 'Folder 2', 'Folder 3'];


  const openCreateTeamModal = () => {
    setLargeModal(true)
  }

  function handleCreateFolder(e) {
    e.preventDefault()

    const data = createFolder(folderName, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      console.log("createdfolderResponse", res)
      getFolder()
      setLargeModal(false)
      setFolderName("")

    });
    data?.catch((err) => {
      setError(err?.response?.data?.message)
      console.log("createdfolderResponse", err)
    });
  }

  const getFolder = () => {
    setLoader(true)
    const data = getfolderList(path, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      setFolders(res?.data?.folders)
      setLoader(false)
      console.log(res?.data?.folders, "foldersssssASSaaaassddfffsfsfsffffffeererrr")
      // const urls = res?.data?.folders.map(item => item.url).filter(url => url);
      // console.log("yyyyyyy",)
      // setUrl([...url, ...urls.map(url => ({ url }))]);

      const fileUrls = res?.data?.folders.filter(item => item.is_file)
        .map(item => ({ url: item.url, name: item.name }));
      setUrl([...fileUrls]);
    });
    data?.catch((err) => {
      console.log("BBBBBBBBBBBBBBBBB", err)
    });
  }

  const DeleteFolderGetName = (event, folder) => {
    event.preventDefault()
    setDeleteFolderName(folder)
  }


  const selectedFolderForDelete = () => {
    // e.preventDefault()
    const data = deleteFolder(deleteFolderName, path, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      SetOpenDeleteModal(false)
      getFolder()
    });
    data?.catch((err) => {

    });
  }



  const handleEditModalName = (event, folder) => {
    event.preventDefault()
    setPrevEditFolderName(folder)
    setEditName(folder)


  }

  const handleShareFolderName = (event, folder) => {
    event.preventDefault()
    setShareTemplateFolderName(folder)

  }

  const handleEditFolder = (e) => {
    e.preventDefault()
    const data = editFolder(prevEditFolderName, editFolderName, path, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      setEditModel(false)
      console.log("55555555555ooooooooooooooooo", res)
      getFolder()
    });
    data?.catch((err) => {
      setError(err?.response?.data?.message)
    });
  }




  useState(() => {
    getFolder()
  }, [])

  const handleFolderItemClick = (folderName) => {
    navigate("/viewFolder", { state: { folderName: folderName } });
    setIsOpen(true);
  };

  const uploadFileModal = () => {
    setShowFileUpload(true)
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const selectedFile = event.target.files[0];

    if (selectedFile && (selectedFile.name.endsWith('.csv') || selectedFile.name.endsWith('.xlsx'))) {
      setFile(selectedFile);
      setSelectedFileName(selectedFile.name);
      setError('');
    } else {
      setFile(null);
      setSelectedFileName('');
      setError('Please select a .csv or .xlsx file');
    }
  };

  const handleUploadfile = (event) => {
    event.preventDefault()
    console.log(path, file, "858588285285")
    if (error === '') {
      const data = uploadFileInFolder(path, file, userInfoDetails?.data?.accessToken);
      data?.then((res) => {
        setShowFileUpload(false)
        getFolder()
      });
      data?.catch((err) => {
        setError(err?.response?.data?.message)
      });
    }
  }

  const handleUseTempateModalName = (event, element) => {
    event.preventDefault()
    const data = toUseTemplate(path, element, userInfoDetails?.data?.accessToken)
    data.then((res) => {
      console.log(res, "LLLLLLLLLLLLLLLL")
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your template is uploaded successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    })
    data.catch((err) => {
      console.log(err?.response?.data?.message, "LLLLLLLLLLLLLLLL")
      toast(
        <>
          <div className="mx-1 d-flex">
            <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
            <div>
              <h2 style={{
                // color: "#ffffff",
                fontSize: "22px"
              }}>
                Failed
              </h2>
              <h3 style={{
                // color: "#ffffff",
                fontSize: "18px"
              }}>
                {err?.response?.data?.message}!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "thread-data",
          className: 'toast-error-container toast-error-container-after ',
        }
      )
    })
  }

  const handleShareTempate = (event, element) => {
    event.preventDefault()
    setShareTemplateFileName(element)
    console.log(element, "qqeeieooeorojrkmrjjfkkfmdjk")

  }

  const handleDragStart = (event, id) => {
    console.log("eventtt 1", id)
    setFolderNameToDrop(id.name)
    setManageHeight(true)
    event.dataTransfer.setData('text/plain', id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();

  };

  const handleDrop = (event, id) => {
    event.preventDefault();
    console.log("eventtt 2", id)
    const cardId = event.dataTransfer.getData('text/plain');
    onDrop(cardId, id);
  };

  const onDrop = (cardId, id) => {
    console.log("cardId, url", cardId, url)
    const updatedCards = url.filter(card => card.url !== cardId);
    setUrl(updatedCards);
    console.log(`Dropped card ${cardId} into folder `);
    const data = moveFileInFolder(id?.name, folderNameToDrop, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      setShowFileUpload(false)
      getFolder()
    });
    data?.catch((err) => {
      setError(err?.response?.data?.message)
    });
  };





  console.log("urlurlurlurlurl", url)

  return (
    <>
      {
        shareOpen && <ShareTemplate
          path={path}
          shareModel={shareOpen}
          file={shareTemplateFileName}
          share={"template"}
          setShareModelParent={setShareOpen}

        />
      }
      {
        shareOpenFolder && <ShareTemplateFolder
          path={path}
          shareModel={shareOpenFolder}
          file={shareTemplateFolderName}
          share={"folder"}
          setShareModelParent={setShareOpenFolder}

        />
      }

      <Modal
        className="fade bd-example-modal-lg"
        show={showFileUplod}
        size="lg"
        data-testid="modal3"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setShowFileUpload(false)
              setError("")
            }}
          ></button>
          <h2 className="text-center mb-4">Uplod File </h2>

          <form onSubmit={handleUploadfile}>
            <div>
              {/* <h2> File Upload</h2> */}
              <input type="file" required accept=".csv, .xlsx" onChange={handleFileChange} />
              {error && <div className="text-danger fs-12 ms-2">{error}</div>}
            </div>
            <div className="text-center">
              <button type="submit" className="figma-btn text-white mt-3" style={{ alignSelf: "flex-end" }}>
                Upload
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={largeModal}
        size="lg"
        data-testid="modal3"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setLargeModal(false);
              setError("")
            }}
          ></button>
          <h2 className="text-center mb-4">Create folder </h2>
          <form onSubmit={(e) => handleCreateFolder(e)}>
            <Row>

              <input
                type="text"
                required
                className="form-control"
                style={{ height: "37px" }}
                placeholder="Folder name"
                data-testid="folder-name"
                onChange={(e) => (setFolderName(e.target.value), setError(""))}
              />
              {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )}
            </Row>

            <div>
              <div className="text-center">
                <button
                  type="submit"
                  className="figma-btn text-white mt-3"
                  style={{ alignSelf: "flex-end" }}
                >
                  Create folder
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={editModal}
        size="lg"
        data-testid="modal4"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setEditModel(false);
              setError("")
            }}
          ></button>
          <h2 className="text-center mb-4">Edit {ShowEditTemplateOrFolder == "folders" ? "folder" : "template"} </h2>
          <form onSubmit={(e) => handleEditFolder(e)}>
            <Row>

              <input
                type="text"
                required
                className="form-control"
                style={{ height: "37px" }}
                placeholder="Folder name"
                data-testid="folder-name"
                value={editName}
                onChange={(e) => (setEditFolderName(e.target.value), setEditName(e.target.value))}
              />
              {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )}
            </Row>

            <div>
              <div className="text-center">
                <button
                  type="submit"
                  className="figma-btn text-white mt-3"
                  style={{ alignSelf: "flex-end" }}
                >
                  Edit {ShowEditTemplateOrFolder == "folders" ? "folder" : "template"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="delete-modal" title="delete-modal" src={Vector3} />
          <h4 className="fs-20 p-2">
            Are you sure you want to delete{" "}{showTemplateOrFolder == "folders" ? "folder" : "template"}
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">

          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this - {deleteFolderName}
          </p>
        </Modal.Body>
        <div className="d-flex justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/templates"
            onClick={(e) => {
              SetOpenDeleteModal(false);
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              selectedFolderForDelete(folderName);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <div>
        <div className="row d-flex" >
          {/* <div className="float-start" >

            <h3 className=""> Templates </h3>
          </div> */}
          <div className="float-end">
            <span>
              <button className="figma-btn float-end mb-2 mt-2" onClick={(e) => uploadFileModal()} style={{ marginLeft: "10px" }} > Upload file</button>
            </span>
            <span>
              <button className="figma-btn float-end mb-2 mt-2" onClick={(e) => openCreateTeamModal()}> Create folder</button>
            </span>

          </div>

        </div>
        <div className="row mt-4 p-2" >


          {folders.map((folder, index) => (

            (!folder.is_file && <div style={{ width: "150px" }} onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, folder)} className="folder-item" key={index} >

              <div className="float-end">
                <Dropdown className="ms-auto">
                  <Dropdown.Toggle
                    variant=""
                    as="div"
                    className="btn-link i-false"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12.4999"
                        cy="3.5"
                        r="2.5"
                        fill="#A5A5A5"
                      />
                      <circle
                        cx="12.4999"
                        cy="11.5"
                        r="2.5"
                        fill="#A5A5A5"
                      />
                      <circle
                        cx="12.4999"
                        cy="19.5"
                        r="2.5"
                        fill="#A5A5A5"
                      />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    alignRight={true}
                    data-testid="org-menu"
                    className="dropdown-menu-right"
                  >
                    <Dropdown.Item
                      data-testid="org-edit"
                      onClick={(event) => {
                        setEditModel(true)
                        handleEditModalName(event, folder.name)
                        setShowEditTemplateOrFolder("folders")
                      }}
                    >
                      Edit
                    </Dropdown.Item>

                    <Dropdown.Item
                      data-testid="org-edit"
                      onClick={(event) => {
                        setShareOpenFolder(true)
                        handleShareFolderName(event, folder.name)
                      }}
                    >
                      Share folder
                    </Dropdown.Item>


                    <Dropdown.Item
                      className="text-danger"
                      data-testid="org-delete"
                      onClick={(event) => {
                        SetOpenDeleteModal(true);
                        DeleteFolderGetName(event, folder.name)
                        setShowTemplateFolder("folders")

                      }}
                    >
                      Delete
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="folder-icon" >

                <FontAwesomeIcon icon={faFolder} className="mr-1" style={{ fontSize: "3rem", color: "gray" }} onClick={() => handleFolderItemClick(folder.name)} />
              </div>

              <h6 className="folder-name mt-2" style={{ wordBreak: "break-word" }}>{folder.name}</h6>
            </div>

            )))}
        </div>
      </div>

      {loader ? (
        <center>
          <div style={{ padding: "20px", margin: "300px" }}>
            <LoadingSpinner />
          </div>
        </center>) : (<> {!selectedTemplate ? (
          <Row>
            {url?.map((element, index) => (
              <div className="col-sm-4" key={index}>
                <div className="my-2 p-2">

                  <Card key={url.url} id={url.url} draggable={true} onDragStart={(e) => handleDragStart(e, element)} className="m-0 p-2 bg-white">


                    <div style={{ display: 'flex', justifyContent: 'space-between', wordBreak: "break-word" }}>
                      <h5>{element.name}</h5>
                      <Dropdown className="ms-auto">
                        <Dropdown.Toggle
                          variant=""
                          as="div"
                          className="btn-link i-false"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="12.4999"
                              cy="3.5"
                              r="2.5"
                              fill="#A5A5A5"
                            />
                            <circle
                              cx="12.4999"
                              cy="11.5"
                              r="2.5"
                              fill="#A5A5A5"
                            />
                            <circle
                              cx="12.4999"
                              cy="19.5"
                              r="2.5"
                              fill="#A5A5A5"
                            />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          alignRight={true}
                          data-testid="org-menu"
                          className="dropdown-menu-right"
                        >
                          <Dropdown.Item
                            data-testid="org-edit"
                            onClick={(event) => {
                              setEditModel(true)
                              handleEditModalName(event, element.name)
                              setShowEditTemplateOrFolder("template")
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            data-testid="org-edit"
                            onClick={(event) => {
                              // setEditModel(true)
                              handleUseTempateModalName(event, element.name)
                            }}
                          >
                            Use template
                          </Dropdown.Item>
                          <Dropdown.Item
                            data-testid="org-edit"
                            onClick={(event) => {
                              setShareOpen(true)
                              handleShareTempate(event, element.name)
                            }}
                          >
                            Share template
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-danger"
                            data-testid="org-delete"
                            onClick={(event) => {
                              SetOpenDeleteModal(true);
                              DeleteFolderGetName(event, element?.name)
                              setShowTemplateFolder("template")

                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="template-card p-1">
                      <Card.Body className="mb-0 p-1" style={{ height: "38vh" }} onClick={(e) => e.stopPropagation()}>
                        <FileViewer
                          fileType={type}
                          filePath={element?.url}
                          errorComponent={CustomErrorComponent}
                          onError={onError}
                          onGridSort={() => { }} // Add a no-op function to handle this if required
                        />
                      </Card.Body>
                      <Card.Footer className="p-0 m-0 text-center">
                        <OverlayTrigger
                          delay={{ hide: 100, show: 10 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              <h5 className="p text-white" data-testid="view-template" >View template!</h5>
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                          <Button
                            data-testid="View"
                            className="m-1 px-3"
                            style={{ width: "auto", borderRadius: "3px" }}
                            onClick={(e) => {
                              setSelectedTemplate(element);
                            }}
                          >
                            View
                          </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          delay={{ hide: 1000, show: 10 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              <h5 className="p text-white">
                                {" "}
                                Download template!
                              </h5>
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                          <a
                            href={element?.url}
                            download="File upload template"
                            target="_blank"
                          >
                            <Button className="mx-3 " data-testid="download-button">
                              <i class="bi bi-download p-1"></i>
                            </Button>
                          </a>
                        </OverlayTrigger>
                      </Card.Footer>
                    </div>
                  </Card>
                </div>
              </div>
            ))}
          </Row>
        ) : (
          ""
        )}</>)}

      {folders?.length === 0 && !loader && <center>
        <span className="th text-center " style={{ top: "50px", position: "relative" }}>
          No records found.
        </span>
      </center>}

      {selectedTemplate?.url ? (
        <Card className="my-2 p-2">

          <Card.Header className="p-1">
            <div className="text-start">
              <OverlayTrigger
                delay={{ hide: 1000, show: 10 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <h5 className="p text-white">
                      Navigate back on template list!
                    </h5>
                  </Tooltip>
                )}
                placement="bottom"
              >
                <Link
                  to=""
                  onClick={() => {
                    setSelectedTemplate();
                  }}
                  style={{ float: "left" }}
                  className="float-start text-start"
                >
                  <i className="fa fa-reply fs-20" aria-hidden="true"></i>
                </Link>
              </OverlayTrigger>
            </div>
            <div className="text-end m-1">
              <OverlayTrigger
                delay={{ hide: 1000, show: 10 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <h5 className="p text-white" data-testid="sample-template"> Download sample template!</h5>
                  </Tooltip>
                )}
                placement="bottom"
              >
                <a href={selectedTemplate?.url} download="File upload template" target="_blank">
                  <Button className="mx-2">
                    <i class="bi bi-download"></i>
                  </Button>
                </a>
              </OverlayTrigger>
            </div>
          </Card.Header>
          <Card.Body className="p-1">
            {selectedTemplate?.url && (
              <div
                style={{ position: "relative", height: "61vh", width: "100%" }}
              >
                <FileViewer
                  fileType={type}
                  filePath={selectedTemplate?.url}
                  errorComponent={CustomErrorComponent}
                  onError={onError}
                />
              </div>
            )}
          </Card.Body>
        </Card>

      ) : (
        ""
      )}
    </>
  );
}

export default Template;