import config from '../config';


class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
    this.newData = null;
    this.UserData = JSON.parse(localStorage?.getItem('userDetails'))
  }
  connect(room_id, userDetails) {
    localStorage.setItem("websocketIds", room_id);

    const path = config.API_PATH;

    this.socketRef = new WebSocket(path + room_id + '/sender/' + userDetails?.id);

    this.socketRef.onopen = () => {
      console.log('WebSocket connected successfully:', this.socketRef?.url);
      this.newData = null;
    };
    this.socketRef.onmessage = e => {
      this.newData = JSON.parse(e?.data)

      let url = this?.socketRef?.url.split("/")

      if (url[url?.length - 3] === this.newData?.thread) {
        this.socketNewMessage(e?.data);
        this.callbacks['newSocketData'] = this.newData;
      }
    };
    this.socketRef.onerror = e => {
      console.log("Websocket error", e.message);
      this.newData = null;
    };
    // this.socketRef.onclose = () => { 
    //   this.newData = null;
    //   // this.connect(room_id, this.UserData.data);
    // };
  }

  logout() {
    // Logic to close WebSocket connection
    if (this.socketRef) {
      this.socketRef.close();
      console.log('WebSocket connection closed');
    }
  }

  close() {
    if (this.socketRef) {
      console.log('WebSocket disconnected:');
      this.socketRef.close();
    }
  }

  checkAndCloseWebSocket() {
    const currentUrl = window.location.href;  // Get the full URL
    if (!currentUrl.includes("message")) {  // Check if URL contains 'message'
      this.close();  // Close WebSocket
    }
  }



  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    this.messagesAll = parsedData.text;
    // this.fetchMessagesChat(parsedData)
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === 'message') {
      this.callbacks[command](parsedData.messages);
    }
    else if (command === 'new_message') {
      this.callbacks[command](parsedData.message);
    }
    else if (command === 'add_attachment') {
      this.callbacks[command](parsedData.message);
    }
    else if (command === 'refresh') {
      this.callbacks[command](parsedData.command);
    }
  }

  // initChatUser(room_id) {
  //   WebSocketInstance.connect(room_id);
  //   // this.sendMessage({ command: 'init_chat', username: username });
  // }

  fetchMessages(username) {
    this.sendMessage({ command: 'fetch_messages', sender_id: this.UserData?.data?.id });
  }

  newChatMessage(message) {

    if (message.command === "add_attachment") {
      this.sendMessage(message)
    }
    else if (message.command === "refresh") {
      this.sendMessage({
        "sender_id": JSON.parse(localStorage?.getItem('userDetails'))?.data?.id,
        "command": "refresh"
      })
    }
    else {
      this.sendMessage({
        "message": message?.text,
        "is_sender": "True",
        "sender_id": JSON.parse(localStorage?.getItem('userDetails'))?.data?.id,
        "command": "new_message",
        "thread": message?.threadId,
      });
    }
    // this.sendMessage({ "attachment": message.attachment,"is_sender": "True","sender_id":this.UserData?.data?.id,"command":"new_message"}); 

  }

  addCallbacks(messagesCallback, newMessageCallback) {
    this.callbacks['messages'] = messagesCallback;
    this.callbacks['new_message'] = newMessageCallback;
    this.callbacks['add_attachment'] = newMessageCallback;
    this.callbacks['refresh'] = newMessageCallback;
  }

  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    }
    catch (err) {
      console.log(err.message);
    }
  }

  state() {
    return this.socketRef.readyState;
  }

  waitForSocketConnection(callback, retries = 10, interval = 1000) {
    const socket = this.socketRef;

    // Check if maximum retries have been reached
    if (retries <= 0) {
      return;
    }

    setTimeout(() => {
      if (socket.readyState === 1) {
        // Connection is open, execute the callback
        if (callback != null) {
          callback();
        }
      } else {
        // Retry with reduced attempts
        this.waitForSocketConnection(callback, retries - 1, interval);
      }
    }, interval);
  }


}

const WebSocketInstance = WebSocketService.getInstance();
export default WebSocketInstance;


