import axios from 'axios';
import config from './config';
const baseURL = config.BASE_URL;
const userDetails = localStorage.getItem('userDetails');
const userInfoDetails = JSON.parse(userDetails)

export function createOrg(name, address_line_one, address_line_two, city, state, country, zipcode, email, contact, description, token) {
  const postData = {
    name,
    address_line_one,
    address_line_two,
    city,
    state,
    country,
    zipcode,
    email,
    description,
  };
  return axios.post(
    baseURL + `organizations/create`,
    postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }

  );
}

export function getOrgs(userId, token) {
  return axios.get(
    baseURL + `organizations/user/` + userId, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}
export function userSpecificOrg(userId, token) {
  return axios.get(
    baseURL + `user-privileges/` + userId, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}
export function deleteOrganization(orgId, token) {
  return axios.delete(
    baseURL + `organizations/organization/` + orgId + `/delete`,
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
}

export function editOrg(orgId, updatedOrgDetails, token) {
  const postData = {
    name: updatedOrgDetails.name,
    address_line_one: updatedOrgDetails.address_line_one,
    address_line_two: updatedOrgDetails.address_line_two,
    city: updatedOrgDetails.city,
    state: updatedOrgDetails.state,
    country: updatedOrgDetails.country,
    zipcode: updatedOrgDetails.zipcode,
    email: updatedOrgDetails.email,
    contact: updatedOrgDetails.contact,
    description: updatedOrgDetails.description,
    org_image: updatedOrgDetails.org_image
  };

  return axios.put(
    baseURL + `organizations/organization/` + orgId + `/update`,
    postData, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': "multipart/form-data"
    }
  }
  );
}
export function defaultOrg(orgId, token) {
  const postData = {
    organization_id: orgId
  };

  return axios.post(
    baseURL + `organizations/default`,
    postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}
export function getOrgById(orgId, token) {
  return axios.get(
    baseURL + `organizations/organization/` + orgId,
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
}

export function RemoveUserByOrg(userId, token) {
  const postData = {
    organization_id: localStorage.getItem('organization_id'),
    user_id: userId
  };
  return axios.post(
    baseURL + `organizations/remove-user`,
    postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}
export function cretaeStripePayment(paymentMethod, token) {
  const postData = {
    paymentMethodId: paymentMethod.id,

  }
  return axios.post(
    baseURL + `create-checkout-session`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}


export function getSampleTemplateFile(userInfo) {
  return axios.get(baseURL + `users/get_sample_file`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}


export function createFolder(folder_name, token) {
  const postData = {
    "folder_name": folder_name,
    "action": "create"

  }
  return axios.post(
    baseURL + `users/file_operations`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}


export function getfolderList(folder_name, token) {
  const postData = {
    "folder_name": folder_name,
    "action": "read",

  }
  return axios.post(baseURL + `users/file_operations`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
}

export function deleteFolder(folderName, path, token) {
  const postData = {
    folder_name: folderName,
    path: path,
    "action": "delete"

  };
  return axios.post(baseURL + `users/file_operations`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function editFolder(prevFolderName, newFolderName, path, token) {
  const postData = {
    old_name: prevFolderName,
    new_name: newFolderName,
    path: path,
    "action": "update"
  };
  return axios.post(
    baseURL + `users/file_operations`,
    postData, {
    headers: {
      Authorization: 'Bearer ' + token,

    }
  }
  );
}


export function uploadFileInFolder(path, file, userInfo) {
  console.log("hiiii")
  const postData = {
    path: path,
    file: file,
    "action": "upload"
  };
  return axios.post(baseURL + `users/file_operations`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}


export function moveFileInFolder(path, file, userInfo) {
  console.log("hiiii")
  const postData = {
    destination_path: path,
    source_path: file,
    "source": "templates",
    "action": "move"
  };
  return axios.post(baseURL + `users/file_operations`, postData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}


export function shareTemplateFile(file, path, userId, token, share) {
  const postData = {
    "action": "share",
    "path": path,
    "source": "templates",
    "file_name": file,
    "user_id": userId,
    "share": share

  }
  return axios.post(
    baseURL + `users/file_operations`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}


export function createQuestionSet(title, question, token) {
  const postData = {
    title: title,
    question_set: question

  }
  return axios.post(
    baseURL + `chat/chat-bot/question_set/create`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}

export function getAllQuestionSet(userInfo) {
  return axios.get(baseURL + `chat/chat-bot/question_set/list`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userInfo,
    },
  });
}


export function deleteQuestionSets(questionSetId, token) {
  return axios.delete(
    baseURL + `chat/chat-bot/question_set/` + questionSetId + `/delete`,
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
}

export function makeChatbotQuestionSetdefault(questionSetId, token) {
  const postData = {
    question_set: questionSetId
  }
  return axios.post(
    baseURL + `chat/chat-bot/question_set/default`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}

export function shareQuestionSets(questionSetId, userId, token) {
  const postData = {
    question_set: questionSetId,
    share_user: userId
  }
  return axios.post(
    baseURL + `chat/chat-bot/question_set/share`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}

export function toUseTemplate(paths, file, token) {
  const postData = {
    path: `${paths}/${file}`
  }
  return axios.post(
    baseURL + `users/use_template`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}


export function editQuestionsSets(questionSetId, title, questions, token, additionalQuestions) {
  const postData = {
    title: title,
    questions: questions,
    additional_questions: additionalQuestions
  };
  return axios.put(
    baseURL + `chat/chat-bot/question_set/` + questionSetId + `/update`,
    postData, {
    headers: {
      Authorization: 'Bearer ' + token,
      // 'Content-Type': "multipart/form-data"
    }
  }
  );

}


export function getTaskWorkTable(
  defaultTime, token, goal_id, trackScope, goToPage, sortAsc,
  sortBy,
  userName,
  CompleteStartRange,
  CompleteEndRange,
  teamId
) {
  console.log(trackScope, "trackScopeeeee")
  const timeScope = defaultTime != "" ? defaultTime : localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = localStorage.getItem("scope");
  const team_id = teamId ? teamId : localStorage.getItem("team_id");
  const scopes = trackScope ? trackScope : scope;
  let complete = "";
  if (CompleteStartRange || CompleteEndRange) {
    complete = String(`${CompleteStartRange || 0}-${CompleteEndRange || 100}`);
  }

  const postData = {
    organization_id: organization_id,
    sort_by: sortBy,
    sort_type: sortAsc,
    time_scope: timeScope,
    team_id: team_id,
    goal_id: goal_id,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
    page_size: 5
  };
  return axios.post(
    baseURL + `tasks/work-table/${scopes}?page=${goToPage ? goToPage + 1 : 1}`,
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        complete_hours: complete,
        first_name: userName,
      },
    }
  );
}

export function getAllBarChartData(token) {
  return axios.get(
    baseURL + `tasks/myday/complete-percentage`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}





export function getCompleteHoursBarGraph(defaultTime, token, goToPage = 0, goal_id, trackScope, teamId) {
  console.log(trackScope, "trackScope")
  const timeScope =
    defaultTime != "" ? defaultTime : localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const scope = localStorage.getItem("scope");
  const team_id = teamId ? teamId : localStorage.getItem("team_id");


  const postData = {
    organization_id: organization_id,
    // scope: trackScope ? trackScope : scope,
    time_scope: timeScope,
    // team_id: team_id,
    goal_id: goal_id,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
  };
  let url =
    timeScope === "custom"
      ? baseURL + `goals/complete-hours/graph?page=${goToPage + 1}`
      : baseURL + `goals/complete-hours/graph`;
  return axios.post(url, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getGoalAlignmentGraph(goal_id, token, trackScope, team_id) {
  console.log("goal_id in taskService", goal_id);
  const timeScope = localStorage.getItem("timescope");
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const organization_id = localStorage.getItem("organization_id");
  const teamId = team_id ? team_id : localStorage.getItem("team_id");
  const scope = trackScope ? trackScope : localStorage.getItem("scope");

  const postData = {
    organization_id: organization_id,
    scope: scope,
    goal_id: goal_id,
    team_id: teamId,
    time_scope: timeScope,
    start_date: localStorage.getItem("timescope") === "custom" ? startDate : "",
    end_date: localStorage.getItem("timescope") === "custom" ? endDate : "",
  };
  return axios.post(baseURL + `goals/effort/` + scope, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function shareGoal(goalId, userId, token) {
  const organization_id = localStorage.getItem("organization_id");
  const postData = {
    organization_id: organization_id,
    goal_id: goalId,
    user_id: userId
  }
  return axios.post(
    baseURL + `goals/share`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}


export function shareTask(taskId, userId, token) {
  const organization_id = localStorage.getItem("organization_id");
  const postData = {
    organization_id: organization_id,
    task_id: taskId,
    user_id: userId
  }
  return axios.post(
    baseURL + `tasks/share`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }
  );
}


export function getMyDay(token, taskNameFilter, TaskEffortFilter, ScheduledTasksGoalNameFilter, selectedScheduledTaskImpact, selectedScheduledTaskType, seletcedScheduledTaskStatus) {
  const postData = {
    // scope:route,
    // team_id: teamId
  }
  return axios.post(
    baseURL + `users/list/my-day`, postData, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: {
    
      name: taskNameFilter,
      task_effort: TaskEffortFilter,
      goal : ScheduledTasksGoalNameFilter,
      task_impact : selectedScheduledTaskImpact,
      task_type : selectedScheduledTaskType,
      task_success : seletcedScheduledTaskStatus  
    },
  }
  );
}



export function getQuickWinsData(token) {
  return axios.get(baseURL + `users/list/quick-wins`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

// export function getMyDay(token) {
//   return axios.get(
//       baseURL + `users/my-day/list`, {
//           headers: {
//               Authorization: 'Bearer ' + token
//           }
//   }
//   );
// }










