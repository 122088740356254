import React, { useState, useEffect, Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { getHomeGraph } from "../../../services/GoalService";
import Chart from "react-apexcharts";
import { Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import { data } from "jquery";
import LoadingSpinner from "../Component/LoadingSpinner";
import { saveAs } from 'file-saver';
import { BarChart } from "@material-ui/icons";
import html2canvas from "html2canvas";
import { CircularProgress } from "@material-ui/core";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function BarChartLowMediumHigh(props) {
  const [state, setState] = useState({
    labels: [],
    winPercentageData: [],
    hitPercentageData: [],
    missPercentageData: [],
    beatPercentageData: [],
  });
  const [pagination, setPagination] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [goToPage, setGoToPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);

  const addSymbols = (e) => {
    const suffixes = ["", "K", "M", "B"];
    const order = Math.max(
      Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
      0
    );
    const suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  };

  useEffect(() => {
    setGoToPage(0);
  }, [localStorage.getItem("timescope")]);

  useEffect(() => {
    if (props.refresh === true || goToPage !== 0 || props.goal_id) {
      getGraph(goToPage, props.goal_id);
    }
  }, [goToPage, props.goal_id, props.refresh]);

  const getGraph = (page, goal_id = null) => {
    setLoading(true);
    const response = getHomeGraph(
      "",
      userInfoDetails?.data?.accessToken,
      page,
      goal_id,
      props?.trackScope
    );

    response
      ?.then(async (res) => {
        console.log(res,"responsewwwwww")
        setGoToPage(page);
        setPageCount(Math.ceil(res?.data?.count / 10));
        setPagination(res?.data?.next || res?.data?.previous ? true : false);
        props.setRefreshGraph(false);
        const graphData = res?.data?.data;
        const labels = graphData.map((element) => "Week " + element.week);
        // let winPercentageData = [];
        let hitPercentageData = [];
        let missPercentageData = [];
        let beatPercentageData = [];

        graphData.forEach((element) => {
          // winPercentageData.push({
          //   label: "Week " + element.week,
          //   y: element.win_percent,
          // });
          hitPercentageData.push({
            label: "Week " + element.week,
            y: element.high_percent
            ,
          });
          missPercentageData.push({
            label: "Week " + element.week,
            y: element.medium_percent,
          });
          beatPercentageData.push({
            label: "Week " + element.week,
            y: element.low_percent,
          });
        });

        setState({
          labels,
          // winPercentageData,
          hitPercentageData,
          missPercentageData,
          beatPercentageData,
        });
        setLoading(false);
      })
      .catch(async (err) => {
        console.log("error getHomeGraph");
      });
  };

  useEffect(()=>{
    getGraph()
  },[])

  const ChangePage = (PageNo) => {
    setGoToPage(PageNo);
    getGraph(PageNo, this.props.goal_id);
  };

  const options = {
    animationEnabled: true,
    colorSet: "colorSet2",
    axisX: {
      gridColor: "rgba(52, 58, 64, 0.1)",
      labelFontColor: "rgba(52, 58, 64, 0.4)",
    },
    axisY: {
      gridColor: "rgba(52, 58, 64, 0.1)",
      labelFontColor: "rgba(52, 58, 64, 0.6)",
      title: "Percentage (%)",
      prefix: "",
      labelFormatter: addSymbols,
    },
    toolTip: {
      shared: true,
    },
    legend: {
      cursor: "pointer",
      verticalAlign: "top",
    },
    data: [
      // {
      //   type: "column",
      //   name: "Win percentage",
      //   showInLegend: true,
      //   dataPoints: state.winPercentageData,
      //   color: "rgba(68, 48, 69, 0.7)",
      // },
      {
        type: "line",
        name: "High percentage",
        showInLegend: true,
        dataPoints: state.hitPercentageData,
      },
      {
        type: "line",
        name: "Medium percentage",
        showInLegend: true,
        dataPoints: state.missPercentageData,
      },
      {
        type: "line",
        name: "Low percentage",
        showInLegend: true,
        dataPoints: state.beatPercentageData,
      },
    ],
  };

  const downloadCSV = () => {
    let csvData = "Week,High Percentage,Medium Percentage,Low Percentage\n";
    const graphData = state.labels;
    let weekData = graphData.map((element) => {
      console.log("element csvv", element)
      const week = element;
      // const win_percent = element.y;
      console.log(state.hitPercentageData.find((data)=>data?.label) ,"363652528585")
      const high_percent = state.hitPercentageData.find((data) => data?.label === element)?.y || 0;
      const medium_percent = state.missPercentageData.find((data) => data?.label === element)?.y || 0;
      const low_percent = state.beatPercentageData.find((data) => data?.label === element)?.y || 0;
      return [week,  high_percent, medium_percent, low_percent];
    });

    for (const row of weekData) {
      csvData += row.join(",") + "\n";
    }

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "High_medium_low_percentage_over_time.csv");
  };

  // const downloadPNG = () => {
  //   const canvasElement = document.querySelector(".canvasjs-chart-canvas");
  //   console.log("canvas",canvasElement )
  //   canvasElement.toBlob((blob) => {
  //     saveAs(blob, "High_medium_low_percentage_over_time.png");
  //   });
  // };


  const downloadPNG = async () => {
    try {
      setLoading(true);
      const canvasElement = document.querySelector(".high-graph");

      const canvas = await html2canvas(canvasElement, {
        scrollY: -window.scrollY,
        useCORS: true,
      });

      // Use file-saver to save the canvas as a PNG file
      canvas.toBlob((blob) => {
        saveAs(blob, "High_medium_low_percentage_over_time.png");
        setLoading(false);
      });
    } catch (error) {
      console.error("Error downloading PNG:", error);
      setLoading(false);
    }
  };
 
  

  const downloadSVG = () => {
    const svgContainer = document.querySelector(".canvasjs-chart-canvas");
    const svgData = new XMLSerializer().serializeToString(svgContainer);
    const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8"});
    saveAs(blob, "High_medium_low_percentage_over_time.svg");
  };

  return (
    <Col xl={6} lg={6}>
      <Card data-testid="pie-chart" style={{minHeight:"300px"}}>
      {loading ? <>
          <div className="p-0 m-0 text-center" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", minHeight:"50%"}}>
        <CircularProgress/>
        </div> </>:<>
        <Card.Header>
          <h4 className="card-title">
           Task impact as per high, low, medium percentage over time
          </h4>
          <div className="text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="transparent" id="dropdown-split-basic">
                <i className="bi bi-list fs-20" style={{ width: "20px", height: "20px", color: "#6e8192" }}></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={downloadCSV}>
                  Download CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={downloadPNG}>
                  Download PNG
                </Dropdown.Item>
                <Dropdown.Item onClick={downloadSVG}>
                  Download SVG
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
        <Card.Body>
          {/* {loading && (
            <div
              style={{ position: "relative", top: "50%" }}
              className="m-0 p-0 text-center"
            >
              <LoadingSpinner />
            </div>
          )} */}

          {state.labels.length != 0 ? (
            <div>
              <CanvasJSChart options={options}/>
            </div>
          ) : (
            !loading && "No data available"
          )}

          <div className=" border-0">
            {pagination && (
              <Pagination
                pageCount={pageCount}
                pageIndex={goToPage}
                gotoPage={ChangePage}
              />
            )}
          </div>
        </Card.Body>
        </>}
      </Card>
    </Col>
  );
}

export default BarChartLowMediumHigh;
