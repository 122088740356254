// import { act } from 'react-dom/test-utils';
import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    FORGOT_CONFIRMED_ACTION,
    FORGOT_FAILED_ACTION,
    VERIFY_OTP_CONFIRMED_ACTION,
    VERIFY_OTP_FAILED_ACTION,
    CHANGE_PASSWORD_CONFIRMED_ACTION,
    CHANGE_PASSWORD_FAILED_ACTION,
    VERIFY_OTP_SIGNUP_CONFIRMED_ACTION,
    VERIFY_OTP_SIGNUP_FAILED_ACTION,
    RESEND_OTP_CONFIRMED_ACTION,
    RESEND_OTP_FAILED_ACTION,
    USER_DETAILS_CONFIRM_ACTION,
    USER_DETAILS_FAILED_ACTION,
    INVITE_USER_CONFIRMED_ACTION,
    INVITE_USER_FAILED_ACTION,
    INVITE_PARTNER_CONFIRMED_ACTION,
    INVITE_PARTNER_FAILED_ACTION
} from '../actions/AuthActions';

const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    userDetails : ''
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'OTP sent to email '+ action.payload.data.email ,
            showLoading: false,
        };
    }
    if (action.type === RESEND_OTP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Please enter the code we emailed to '+ action.payload.data.email ,
            showLoading: false,
        };
    }
    if (action.type === FORGOT_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'OTP sent successfully',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === FORGOT_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: '',
            successMessage: 'OTP sent on email successfully',
            showLoading: false,
        };
    }
    if (action.type === CHANGE_PASSWORD_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: '',
            successMessage: 'Password changed successfully',
            showLoading: false,
        };
    }
    if (action.type === USER_DETAILS_CONFIRM_ACTION) {
        return {
            ...state,
            userDetails :action.payload,
            errorMessage: '',
            successMessage: 'Got UserDetails',
            showLoading: false,
        };
    }
    if (action.type === VERIFY_OTP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: '',
            successMessage: 'OTP verified successfully',
            showLoading: false,
        };
    }
    if(action.type === VERIFY_OTP_SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: '',
            successMessage: 'OTP verified successfully',
            showLoading: false,
        };  
    }
    if(action.type === INVITE_PARTNER_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: '',
            successMessage: 'login successfully',
            showLoading: false,
        };  
    }
    if(action.type === INVITE_USER_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: 'User Invited Successfully',
            showLoading: false,
        };  
    }
    

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION ||
        action.type === FORGOT_FAILED_ACTION ||
        action.type === CHANGE_PASSWORD_FAILED_ACTION ||
        action.type === VERIFY_OTP_FAILED_ACTION ||
        action.type === RESEND_OTP_FAILED_ACTION || 
        action.type === USER_DETAILS_FAILED_ACTION ||
        action.type === INVITE_USER_FAILED_ACTION ||
        action.type === INVITE_PARTNER_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    
    if(action.type === USER_DETAILS_FAILED_ACTION) {
        return {
            ...state,
            auth: action.payload.data,
            errorMessage: 'User details not found',
            successMessage: '',
            showLoading: false,
        };  
    }
    if ( action.type === VERIFY_OTP_SIGNUP_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

    
