import React, { useState, useEffect, useRef } from "react";
import { RingLoader } from "react-spinners";
import Select from "react-select";
import {
  Row,
  Modal,
  Dropdown,
  Button,
  Table,
  Col,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import orgImg from "./../../../images/org1.png";
import {
  createTeamAction,
  editTeamAction,
  deleteTeamAction,
  getSubTeamByTeamId,
} from "../../../store/actions/TeamAction";
import Vector3 from "../../../images/vector/Vector3.png";
import LoadingSpinner from "../Component/LoadingSpinner";

const CustomClearText = () => "clear all";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

const SubTeams = (state) => {
  const fileRef = useRef();
  const location = useLocation();
  const filterUserListData = [];
  const [userIds, setUserIds] = useState("");
  const selectedUserListData = [];
  let team = location?.state?.parent
    ? { id: location?.state?.parent }
    : location?.state?.team;
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subteamList, getSubTeamDetails] = useState("");
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [teamId, setTeamId] = useState("");
  const [editTeamModal, setTeamEditModal] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [userSelected, setSelectedUsers] = useState("");
  const [goalsdelete, setGoalsdelete] = useState(false);
  const [goalsupdate, setGoalsupdate] = useState(false);
  const [goalscreate, setGoalscreate] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [selectedSubTeam, setSelectedSubTeam] = useState();
  const [largeModal, setLargeModal] = useState(false);
  let errorsObj = { teamName: "", userList: "" };
  const [errors, setErrors] = useState(errorsObj);
  const orgId = localStorage.getItem("organization_id");
  const [spinner, setSpinner] = useState(false);
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [SelectedTeamDelete, setSelectedTeamDelete] = useState();
  const [highlightSubTeam, sethighlightSubTeam] = useState(
    location?.state?.key
  );

  const changeHandler = (userSelected) => {
    setSelectedUsers(userSelected);
  };

  const handleCreateSubTeamClick = (event, team) => {
    setTeamId(team.id);
    team.team_members.forEach((member) => {
      const userDetailsValue = {
        name: member.first_name + " " + member.last_name,
        userID: member.id,
        label: member.first_name + " " + member.last_name,
        value: member.id,
      };
      selectedUserListData.push(userDetailsValue);
    });
    setUserDetails(selectedUserListData);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    setLargeModal(true);
  };

  const tabData = [
    {
      name: "Sub-Team members",
      icon: "user",
    },
    {
      name: "Sub-teams",
      icon: "user",
    },
  ];

  useEffect(() => {
    sethighlightSubTeam(location?.state?.key);
    if (location?.state?.Team) getSubTeamDetails([location?.state?.Team]);
  }, [location?.state?.Team]);

  useEffect(() => {
    // setSpinner(false);
    getSubteams();
    getTeamMembers();
  }, []);

  useEffect(() => {
    if (stateData.team?.team?.message === "Team Data updated successfully") {
      setTeamEditModal(false);
      getSubteams();
      setGoalsdelete(false);
      setGoalsupdate(true);
      setGoalscreate(false);
    }

    if (stateData.team?.team?.message === "Team deleted successfully") {
      getSubteams();
      getTeamMembers();
      setGoalsdelete(true);
      setGoalsupdate(false);
      setGoalscreate(false);
    }

    if (stateData.team?.errorMessage === "Failed to get Sub team") {
      navigate("/teams");
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(false);
    }

    if (stateData.team?.team?.message === "Sub-Team found successfully") {
      getSubTeamDetails(stateData.team?.team?.data);
      setSpinner(false);
    }

    if (stateData.team?.team?.message === "Team created successfully") {
      setLargeModal(false);
      setTeamId("");
      getSubteams();
      getTeamMembers();
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(true);
    }
  }, [stateData]);

  useEffect(() => {
    let userIds = [];
    if (userSelected.length !== 0) {
      userSelected.forEach((element) => {
        userIds.push(element.userID);
      });
    }
    setUserIds(userIds);
  }, [userSelected]);

  const selectedSubTeamForDelete = (event, team) => {
    event.preventDefault();
    setSelectedTeamDelete(team);
  };

  const handleDeleteTeamClick = () => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);

    dispatch(
      deleteTeamAction(
        SelectedTeamDelete?.id,
        orgId,
        userInfoDetails.data.accessToken
      )
    );
  };

  const handleTeamEditClick = (event, team) => {
    event.preventDefault();
    setTeamId(team.id);
    setTeamName(team.name);
    team.team_members.forEach((member) => {
      const userDetailsValue = {
        name: member.first_name + " " + member.last_name,
        userID: member.id,
        label: member.first_name + " " + member.last_name,
        value: member.id,
      };
      selectedUserListData.push(userDetailsValue);
    });

    setSelectedUsers(selectedUserListData);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    setTeamEditModal(true);
  };

  function changeTeam(event, subTeam) {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    team = subTeam;
    getSubteams();
    getTeamMembers();
  }

  function submitEditTeam(e) {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (teamName === "") {
      errorObj.teamName = "Team name is Required*";
      error = true;
    }
    if (userIds.length === 0) {
      errorObj.userList = "Please select atleast one user*";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      editTeamAction(
        teamName,
        orgId,
        userInfoDetails.data.id,
        "",
        userIds,
        teamId,
        userInfoDetails.data.accessToken
      )
    );
  }

  function submitTeam(e) {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (teamName === "") {
      errorObj.teamName = "Team name is Required*";
      error = true;
    }
    if (userIds.length === 0) {
      errorObj.userList = "Please select atleast one user*";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      createTeamAction(
        teamName,
        orgId,
        userInfoDetails.data.id,
        userIds,
        teamId,
        userInfoDetails.data.accessToken
      )
    );
  }

  function getSubteams() {
    setSpinner(true);
    dispatch(getSubTeamByTeamId(team?.id, userInfoDetails.data.accessToken));
  }

  function getTeamMembers() {
    selectedSubTeam?.team_members.forEach((element) => {
      const userDetailsValue = {
        name: element.first_name + " " + element.last_name,
        userID: element.id,
        label: element.first_name + " " + element.last_name,
        value: element.id,
      };
      filterUserListData.push(userDetailsValue);
    });
    setUserDetails(filterUserListData);
  }

  const handleTeamAttachmentSubmit = (event, team) => {
    event.preventDefault();
    selectedSubTeam?.team_members.forEach((member) => {
      const userDetailsValue = {
        name: member.first_name + " " + member.last_name,
        userID: member.id,
        label: member.first_name + " " + member.last_name,
        value: member.id,
      };
      selectedUserListData.push(userDetailsValue);
    });
    setSelectedUsers(selectedUserListData);

    setTimeout(() => {
      dispatch(
        editTeamAction(
          selectedSubTeam?.name,
          orgId,
          "",
          event.target.files[0],
          userIds,
          selectedSubTeam?.id,
          userInfoDetails.data.accessToken
        )
      );
      getSubteams();
    }, 1000);
  };

  const toastSuccess = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i
            class="bi bi-check-circle fs-26 me-3"
            style={{ color: "#131F4C" }}
          ></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Completed
            </h2>
            {goalsdelete ? (
              <h4>Your sub team has been deleted successfully!</h4>
            ) : goalsupdate ? (
              <h4>Your sub team has been updated successfully!</h4>
            ) : goalscreate ? (
              <h4>Your sub team has been created successfully!</h4>
            ) : (
              ""
            )}
          </div>
        </div>
      </>,
      {
        toastId: "teams",
        className: "toast-success-container toast-success-container-after ",
      }
    );
  };

  return (
    <>
      
          {goalscreate && toastSuccess()}
          {goalsdelete && toastSuccess()}
          {goalsupdate && toastSuccess()}

          <Modal
            show={OpenDeleteModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ borderRadius: "0rem" }}
          >
            <Modal.Title
              className="my-3 mx-4"
              id="contained-modal-title-vcenter"
            >
              <img className="float-start me-1 mt-1 p-2" alt="sub-team-delete" title="sub-team-delete" src={Vector3} />
              <h4 className="fs-20 p-2">
                Are you sure you want to delete Sub Team?{" "}
              </h4>
            </Modal.Title>
            <Modal.Body className="modal-body mx-4 p-0">
              <p className="p-2 pt-0" style={{ color: "#717994" }}>
                You will not be able to recover this Sub Team!
              </p>
            </Modal.Body>
            <div className="d-flex  justify-content-end mx-4 mb-3">
              <Link
                className="figma-link mt-2 me-3"
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  SetOpenDeleteModal(false);
                }}
              >
                No, cancel it!
              </Link>
              <button
                className="figma-btn text-white"
                onClick={() => {
                  {
                    handleDeleteTeamClick();
                    SetOpenDeleteModal(false);
                  }
                }}
              >
                Yes, I am Sure!
              </button>
            </div>
          </Modal>

          <Modal
            className="fade bd-example-modal-lg"
            show={largeModal}
            size="lg"
          >
            <Modal.Body>
              <button
                variant=""
                className="btn-close btn-danger float-end align-center m-1 p-2"
                onClick={() => {
                  setLargeModal(false);
                  setErrors("");
                }}
              ></button>
              <h2 className="text-center mb-4" data-testid="Sub-Team">Create Sub Team</h2>

              <form onSubmit={(e) => submitTeam(e)}>
                <Row>
                  <Col md={6} className="mb-3">
                    <input
                      type="text"
                      className="figma-input p"
                      placeholder="Enter Team name"
                      // defaultValue={org_name}
                      onChange={(e) => setTeamName(e.target.value)}
                      style={{ height: "36px" }}
                    />
                    {errors.teamName && (
                      <div className="text-danger fs-12 ms-2">
                        {errors.teamName}
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <Select
                      closeMenuOnSelect={false}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={""}
                      onChange={changeHandler}
                      isMulti
                      options={userDetails}
                    />
                    {errors.userList && (
                      <div className="text-danger fs-12">{errors.userList}</div>
                    )}
                  </Col>
                  </Row>

                <div className="d-flexflex-wrap align-items-right mt-4">
                  <div className="text-center">
                    <button
                      type="submit"
                      className="figma-btn text-white"
                      style={{ alignSelf: "flex-end" }}
                    >
                      Create Team
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <Modal
            className="fade bd-example-modal-lg"
            show={editTeamModal}
            size="lg"
          >
            <Modal.Body>
              <button
                variant=""
                className="btn-close btn-danger float-end align-center m-1 p-2"
                onClick={() => setTeamEditModal(false)}
              ></button>
              <h2 className="text-center mb-4">Edit Team</h2>

              <form onSubmit={(e) => submitEditTeam(e)}>
                <div className=" row">
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      className="figma-input p"
                      placeholder="Enter Team name"
                      defaultValue={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                      style={{ height: "40px" }}
                    />
                    {errors.teamName && (
                      <div className="text-danger fs-12 ms-2">
                        {errors.teamName}
                      </div>
                    )}
                  </div>
                  <div className="col-6 form-group">
                    <Select
                      closeMenuOnSelect={false}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={userSelected}
                      onChange={changeHandler}
                      isMulti
                      options={userDetails}
                    />
                  </div>
                </div>

                <div className="text-center mt-4">
                  <button
                    type="submit"
                    className="figma-btn text-white float-right"
                  >
                    Edit Team
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <Link className="fa fa-reply" to="/teams" />
          {spinner ? (
          <center>
            <div style={{ padding: "20px", margin: "300px" }} data-tetsid="loader">
              <LoadingSpinner/>
            </div>
          </center>
        ) : (
        <>
          <Row>
            {subteamList ? (
              subteamList.map((subteam, i) => (
                <Col xl={6} sm={12}>
                  <Card
                    className="contact-bx"
                    style={
                      subteam?.id === highlightSubTeam
                        ? { border: "1px solid red" }
                        : {}
                    }
                  >
                    <Card.Header
                      className="header-bg"
                      style={{ position: "unset", borderRadius: "0rem" }}
                      data-testid="Sub-Team"
                    >
                      <div
                        className="profile-photo me-2"
                        style={{ display: "inline-flex" }}
                      >
                        <img
                          src={subteam?.team_image || orgImg}
                          alt="Organization Image"
                          title="Organization Image"
                          className="rounded-circle"
                          style={{ height: "50px", width: "50px" }}
                          onClick={() => {
                            setSelectedSubTeam(subteam);
                            fileRef.current.click();
                          }}
                        />
                        <p className="my-1">
                          <Row>
                            <span
                            data-testid="upload-img"
                              class="fas fa-pen"
                              onClick={(e) => {
                                setSelectedSubTeam(subteam);
                                fileRef.current.click();
                              }}
                              style={{
                                float: "left",
                                width: "15px",
                                cursor: "pointer",
                                borderRadius: "20px",
                                fontSize: "7px",
                                padding: "3px",
                                color: "white",
                                backgroundColor: "#356FD4",
                              }}
                              type="file"
                              id="edit"
                              name="img"
                              accept="image/*"
                            ></span>
                          </Row>
                        </p>
                        <input
                          ref={fileRef}
                          onChange={(e) => {
                            handleTeamAttachmentSubmit(e);
                          }}
                          multiple={false}
                          type="file"
                          accept="image/*"
                          hidden
                        />
                      </div>
                      <Card.Title>
                      <h3>{subteam.name.length > 15 && window.screen.width<=768 ? `${subteam.name.substring(0, 15)}...` : subteam.name}</h3>
                      </Card.Title>
                      <Dropdown className="ms-auto">
                        <Dropdown.Toggle
                          variant=""
                          as="div"
                          className="btn-link i-false"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="12.4999"
                              cy="3.5"
                              r="2.5"
                              fill="#131F4C"
                            />
                            <circle
                              cx="12.4999"
                              cy="11.5"
                              r="2.5"
                              fill="#131F4C"
                            />
                            <circle
                              cx="12.4999"
                              cy="19.5"
                              r="2.5"
                              fill="#131F4C"
                            />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          alignRight={true}
                          data-testid="org-menu"
                          className="dropdown-menu-right"
                        >
                          <Dropdown.Item
                            data-testid="team-edit"
                            onClick={(event) =>
                              handleTeamEditClick(event, subteam)
                            }
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            data-testid="team-edit"
                            onClick={(event) =>
                              handleCreateSubTeamClick(event, subteam)
                            }
                          >
                            Create sub-team
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-danger"
                            data-testid="org-delete"
                            onClick={(event) => {
                              selectedSubTeamForDelete(event, subteam);
                              SetOpenDeleteModal(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Card.Header>
                    <Card.Body>
                      <div className="default-tab">
                        <Tab.Container
                          defaultActiveKey={tabData[0].name.toLowerCase()}
                        >
                          <Nav as="ul" className="nav-tabs">
                            {tabData.map((data, i) => (
                              <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()}>
                                  <i className={`la la-${data.icon} me-2`} />
                                  <span className="fs-16">{data.name}</span>
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                          <Tab.Content className="pt-4">
                            {tabData.map((data, i) => (
                              <Tab.Pane
                                eventKey={data.name.toLowerCase()}
                                key={i}
                              >
                                {data.name === "Sub Team" ? (
                                  <div>
                                    <h4>This is Teams title</h4>
                                  </div>
                                ) : (
                                  <>
                                    {data.name === "Sub-Team members" ? (
                                      <div>
                                        <Col lg={12}>
                                          <Table
                                            responsive
                                            className="display w-100 dataTable "
                                            id="example5"
                                            role="grid"
                                            aria-describedby="example5_info"
                                          >
                                            <thead>
                                              <tr>
                                                {/* <th>#</th> */}
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Timezone</th>
                                                <th>Daily working hours</th>
                                                <th>Weekly working hours</th>
                                                <th>Working Days</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {subteam.team_members.map(
                                                (member, i) => (
                                                  <tr>
                                                    <th>
                                                      {member.first_name +
                                                        " " +
                                                        member.last_name}
                                                    </th>
                                                    <td>{member.email}</td>
                                                    <td>{member.timezone}</td>
                                                    <td>
                                                      {
                                                        member.daily_working_hours
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        member.weekly_working_hours
                                                      }
                                                    </td>
                                                    <td>
                                                      {member.working_days}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                      </div>
                                    ) : (
                                      <>
                                        <Button
                                          data-testid="create-sub-team"
                                          onClick={(event) =>
                                            handleCreateSubTeamClick(
                                              event,
                                              subteam
                                            )
                                          }
                                          className="figma-btn text-white w-50"
                                        >
                                          Create sub-team
                                        </Button>
                                        {subteam.sub_team_present ? (
                                          <Button
                                            onClick={(event) =>
                                              changeTeam(event, subteam)
                                            }
                                            className="figma-btn text-white float-end w-50"
                                          >
                                            Show sub-Teams
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Tab.Pane>
                            ))}
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p>Loading....</p>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default SubTeams;
