import React, { useState, useEffect, lazy } from "react";
import { Card, Row, Col } from "react-bootstrap";
// import CompleteChart from "../Chartjs/CompleteChart";
// import BarChartLowMediumHigh from "../Chartjs/BarChartLowMediumHigh";
import { getHomeGraph } from "../../../services/GoalService";
// import OnTimeChart from "../Chartjs/OnTimeChart";
// import WinsChart from "../Chartjs/WinsChart";
// import TaskWorkTable from "./TaskWorkTable";
// import TimeByTaskTypeTable from "./TimeByTaskTypeTable";
// import PieNewChart from "../Chartjs/PieNewChart";
const LineChart = lazy(() => import("./../Chartjs/line1"))
const LineCharts = lazy(() => import("./../Chartjs/LineChart"))
// const WinBarChart = lazy(() => import("../Chartjs/WinBarChart"))
// import CompleteChart from "../Chartjs/CompleteChart";

const CompleteChart = lazy(()=>import("../Chartjs/CompleteChart"))
const BarChartLowMediumHigh = lazy(()=>import("../Chartjs/BarChartLowMediumHigh"))
const OnTimeChart = lazy(()=> import("../Chartjs/OnTimeChart"))
const WinsChart = lazy(()=> import("../Chartjs/WinsChart"))
const WinBarChart = lazy(()=>import("../Chartjs/WinBarChart"))
const TaskWorkTable = lazy(()=>import("./TaskWorkTable"))
const TimeByTaskTypeTable = lazy(()=>import("./TimeByTaskTypeTable"))
const PieNewChart = lazy(()=>import("../Chartjs/PieNewChart"))


const TrackOraganization = React.memo(({ scopes }) => {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const timeScope = localStorage.getItem("timescope");
  const [graphData, setGraphData] = useState([])
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [trackScope, setTrackScope] = useState("oragnization")
  const org_id = localStorage.getItem("organization_id");
  const [defaultTime, setDefaultTime] = useState("1 month");


  // useEffect(() => {
  //     // if (location && location.state && location?.state?.refresh === true) {
  //     setRefreshGraph(true);

  //   }, [ timeScope]);

  // const getGraph = () => {

  //   const data = getHomeGraph("",
  //     userInfoDetails?.data?.accessToken,
  //     "",
  //     null,
  //     trackScope);
  //   data?.then(async (res) => {
  //     if (res?.data?.data[0]?.completion_percent === 0) {
  //       res?.data?.data?.shift();
  //     }
  //     setGraphData(res?.data?.data);
  //   });
  //   data?.catch(async (err) => {
  //     console.log("error getHomeGraph", err);
  //   });
  // };

  useEffect(() => {
    // getGraph();

    setDefaultTime("");
    setRefreshGraph(!refreshGraph);
  }, [
    org_id,
    localStorage.getItem("scope"),
    localStorage.getItem("timescope"),
    localStorage.getItem("startDate"),
    localStorage.getItem("endDate"),
    // width,
  ]);
  // useEffect(() => {
  //   getGraph()
  // }, [])



  return (
    <>
      <div>
        <Row className="mt-4">
          <CompleteChart
            //  refresh={refreshGraph}
            trackScope={"organization"}
            scope={scopes}

            refresh={refreshGraph}
            // week={weeks}
            graphData={graphData}
          // scope={localStorage.getItem("timescope")}
          // trackScope = {"objective"}
          // goal_id = {goal_id}

          />
          <OnTimeChart
            trackScope={"organization"}
            scope={scopes}

            refresh={refreshGraph}
            // week={weeks}
            graphData={graphData}
          />

          <WinsChart
            trackScope={"organization"}
            scope={scopes}

            refresh={refreshGraph}
            // week={weeks}
            graphData={graphData}
          />
        </Row>
        <Row>
          
          <PieNewChart  trackScope={"organization"}
            scope={scopes}

            refresh={refreshGraph}
            // week={weeks}
            graphData={graphData}>

          </PieNewChart>
         
          <TimeByTaskTypeTable trackScope={"organization"}></TimeByTaskTypeTable>
        
         
        </Row>
        
        <TaskWorkTable trackScope={"organization"}></TaskWorkTable>
        


        <Row >
          <LineCharts
            refresh={refreshGraph}

            setRefreshGraph={setRefreshGraph}
            trackScope={"organization"}
          />
          <LineChart
            refresh={refreshGraph}

            setRefreshGraph={setRefreshGraph}
            trackScope={"organization"}
          />
        </Row>
        <Row>
          <WinBarChart
            refresh={refreshGraph}
            setRefreshGraph={setRefreshGraph}
            trackScope={"organization"}
          />
          <BarChartLowMediumHigh
            refresh={refreshGraph}

            setRefreshGraph={setRefreshGraph}
            trackScope={"organization"}
          />
        </Row>
      </div>
    </>
  );
});

export default TrackOraganization;
