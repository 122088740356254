import axios from "axios";
import config from "./config";
const baseURL = config.BASE_URL;

export function getDataForCalender(month, year, selectedUsersId,selectedTaskStatus,token) {
  console.log(month, year, token, "88888888888888888888");
  const postData = {
    date: "",
    month: month,
    week: "",
    task_status:selectedTaskStatus,
    organization:localStorage.getItem('organization_id'),
    year: year,
    user_id:selectedUsersId,
  };
  return axios.post(baseURL + `tasks/calendar/month`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
