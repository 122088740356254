//login
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row } from "react-bootstrap";

import { Link } from 'react-router-dom';
import '../../vendor/bootstrap/dist/css/bootstrap.min.css';
import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';
import CookieConsent from "react-cookie-consent";
// image
import CryptoJS from 'crypto-js';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useNavigate, useLocation } from "react-router-dom";
import Enc_Key from '../config/index';
import PlansomLogo from "../../images/PlansomLogo2.png";


export const LocationDisplayLogin = () => {
	const location = useLocation()
	return <div data-testid="location-display-login">{location?.pathname}</div>
}
function Login(props) {
	const salt = Enc_Key.Enc_Key;
	const location = useLocation();
	const navigate = useNavigate();
	const stateData = useSelector(state => state);
	const dispatch = useDispatch();
	// const Email = localStorage.getItem('userEmail') ?
	// 	JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userEmail'), salt)?.toString(CryptoJS.enc.Utf8))
	// 	: ''
	const encryptedEmail = localStorage.getItem('userEmail');

let Email = '';

if (encryptedEmail) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedEmail, salt);
    const decryptedEmail = bytes.toString(CryptoJS.enc.Utf8);
    Email = JSON.parse(decryptedEmail);
  } catch (error) {
    console.error('Error decrypting or parsing email:', error);
    Email = '';
  }
} else {
  Email = '';
}
	const [email, setEmail] = useState(Email);
	const pass = localStorage.getItem('userPassword') ?
		JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userPassword'), salt)?.toString(CryptoJS.enc.Utf8))
		: '';
	const [disableEmail, setDisableEmail] = useState(false);
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [remember, setRemember] = useState(localStorage.getItem('remember') === "true" ? true : false)
	const [values, setValues] = React.useState({
		password: pass,
		showPassword: false,
	});

	useEffect(()=>{
// 	const urlParams = new URLSearchParams(window.location.search);
// const encodedState = urlParams.get('state');
// const state = JSON.parse(decodeURIComponent(encodedState));
// console.log(".....", state )


// Extract the state from the URL query parameter
const urlParams = new URLSearchParams(window.location.search);
const encodedState = urlParams.get('state');

// Decode the state object
const state = JSON.parse(decodeURIComponent(encodedState));
console.log(state,"state")

},[])
useEffect(()=>{

	console.log(location?.state?.email,"emailllllllll")
	if(location?.state?.email==undefined){
		localStorage.removeItem("invite_key")
	}
})
	useEffect(() => {
		setRemember(remember)
		if (remember) {
			localStorage.setItem("userEmail", CryptoJS.AES.encrypt(JSON.stringify(email), salt)?.toString());
			localStorage.setItem("userPassword", CryptoJS.AES.encrypt(JSON.stringify(values?.password), salt)?.toString());
		}
		else {
			localStorage.removeItem("userEmail");
			localStorage.removeItem("userPassword");
		}
		localStorage.setItem("remember", remember);
	}, [remember, email, values?.password])

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handlePasswordChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};
	useEffect(() => {
		if (location?.state?.email !== null && location?.state?.email !== undefined && location?.state?.email !== '') {
			setEmail(location?.state?.email);
			setDisableEmail(true);
		}
	}, [])

	useEffect(() => {
		if (stateData.auth?.errorMessage === 'User with this email does not exists') {
			const errorObj = { ...errorsObj };
			errorObj.email = 'User with this email does not exists';
			setErrors(errorObj);
		}
		else if (stateData.auth?.errorMessage === "Incorrect password") {
			const errorObj = { ...errorsObj };
			errorObj.password = 'Incorrect password';
			setErrors(errorObj);
		}
		else {
			setErrors(errorsObj)
		}
	}, [stateData]);

	function onLogin(e) {
		e.preventDefault();
		let error = false;
		var verifyPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])*(?=.*?[#?!@$%^&*-])*.{8,}$/;
		var testt = true;
		testt = verifyPassword.test(values.password);
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (email === '') {
			errorObj.password = 'Password is Required';
			error = true;
		} else if (testt === false) {
			errorObj.password = 'Password should have atleast one number, one special character and one captial letter. passwords must be at least 8 characters long';
			error = true;
		}
		setErrors(errorObj);
		if (error)
			return;
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(email, values.password, navigate));
	}

	return (
		<>
			<div data-testid="authincation" className="authincation remove-bg d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden mx-auto" data-testid="login-aside">
					<div className="d-flex justify-content-center h-100 align-items-center">
						<div className="authincation-content style-2">
							<div className="row no-gutters">
								<div className="col-xl-12 tab-content">
									<div data-testid="flex" className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
										<div data-testid="image" className="text-center mb-4">
											<img src={PlansomLogo} data-testid="login-logo" style={{ height: '80px', width: '80px', padding: "1px", margin: "1px 5px 2px 40px" }} alt="Plansom-logo" title="Plansom-logo" />
										</div>
										<h3 className="mb-2 fs-30 ">Welcome back!</h3>
										<p style={{ color: "#717994" }}>The project management tool that helps you win.</p>
									</div>
									<div id="sign-in" className="auth-form form-validation m-0 p-0">

										<form onSubmit={onLogin} className="form-validate">
											<div className="form-group mb-3" hidden={disableEmail == true}>
												<h5>Email</h5>
												<div >
													<input data-testid="login-email" type="email" className="figma-input p"
														value={email}
														onChange={(e) => setEmail(e.target.value)}
														placeholder="Enter your email"
													/>
												</div>
												{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
											</div>
											<div className="form-group mb-3">
												<h5>Password</h5>
												<Input disableUnderline
													type={values.showPassword ? "text" : "password"}
													onChange={handlePasswordChange("password")}
													value={values.password}
													className="figma-input p"
													style={{ padding: "5px 10px" }}
													placeholder="Enter your password"
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
															>
																{values.showPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													}
												/>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											</div>
											
											{/* <div className="new-account mt-3">
												<Link className="text-primary" to="/page-forgot-password">Reset password</Link>
											</div> */}
											<Row>
												<div className="form-row d-flex justify-content-between col">
													<div className="form-group">
														{remember ? <div className="custom-control custom-checkbox  my-2">
															<input type="checkbox" className="form-check-input me-2" id="basic_checkbox_1" checked={true}  onClick={() => { setRemember(!remember) }} />
															<label className="form-check-label" htmlFor="basic_checkbox_1"><span className='p' style={{ color: "#717994" }}>Remember me</span></label>
														</div> :
															<div className="custom-control custom-checkbox my-2">
																<input type="checkbox" className="form-check-input me-2" id="basic_checkbox_1" onClick={() => { setRemember(!remember) }} />
																<label className="form-check-label" htmlFor="basic_checkbox_1" ><span className='p' style={{ color: "#717994" }}>Remember me</span></label>
															</div>
														}
													</div>
												</div>
												<div className="new-account col  my-2">
													<Link className="figma-link float-end" to="/page-forgot-password">Reset password</Link>
												</div>
											</Row>
											<div className="text-center form-group mb-3">
												<button type="submit" data-testid="login-test" className="figma-btn text white w-100">
													Sign In
												</button>
											</div>
										</form>
										<div className="text-center new-account mt-3">
											<p data-testid="signupLink" ><span style={{ color: "#717994" }}>Not registered yet?</span><Link className="figma-link" to="/page-register">&nbsp;Sign up</Link></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CookieConsent
				location="bottom"
				buttonText="Accept and close"
				cookieName="Plansom.com"
				style={{ background: "#393E41" }}
				buttonStyle={{ backgroundColor: "green", color: 'white' }}
				enableDeclineButton
				declineButtonStyle={{ backgroundColor: '#343a40' }}
			>
				We make use of First and third party cookies to improve our user experience. By using this website, you agree with our Cookies Policy.
			</CookieConsent>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);
