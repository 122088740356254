import axios from "axios";
import swal from "sweetalert";
import {
  loginAction,
  loginConfirmedAction,
  logout,
} from "../store/actions/AuthActions";
import config from "./config";
import { useLocation } from "react-router-dom";
const baseURL = config.BASE_URL;





export function signUp(first_name, last_name, email, password, discount_code, timezone) {
  let invite_key = localStorage.getItem("invite_key");
  if (invite_key != null) {
    const postData = {
      first_name,
      last_name,
      email,
      password,
      timezone,
      discount_code: discount_code
    };
    return axios.post(baseURL + `users/signup/` + invite_key, postData);
  } else {
    const postData = {
      first_name,
      last_name,
      email,
      password,
      timezone,
      discount_code: discount_code,
      returnSecureToken: true,
    };
    return axios.post(baseURL + `users/signup`, postData);
  }
}

export function resendOTPSignup(email) {
  //axios call
  const postData = {
    email,
  };
  return axios.post(baseURL + `users/resend-otp`, postData);
}

export function forgotpass(email) {
  //axios call
  const postData = {
    email,
  };
  return axios.post(baseURL + `users/forgot-password`, postData);
}
export function getUserdetails(user_id, token) {
  return axios.get(baseURL + `users/user/` + user_id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
export function resetPassword(email, password) {
  //axios call
  const postData = {
    email,
    password,
  };
  return axios.post(baseURL + `users/reset-password`, postData);
}
export function verifyOTP(otp, email) {
  //axios call
  const postData = {
    otp,
    email,
  };
  return axios.post(baseURL + `users/verify-otp`, postData);
}

export function checkSocialAuthUser(email) {
  //axios call
  const postData = {
    email,
  };
  return axios.post(baseURL + `users/social-auth-user`, postData);
}

export function verifySignupOTP(otp, email, thread_id) {
  //axios call
  const postData = {
    otp,
    email,
    thread_id

  };
  return axios.post(baseURL + `users/verify-email`, postData);
}

export function inviteUser(email, role, organization_id, token) {
  //axios call
  const postData = {
    email,
    role,
    organization_id,
  };
  return axios.post(baseURL + `users/invite-user`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
export async function login(email, password, thread_id) {
  let invite_key = localStorage.getItem("invite_key");
  //axios call
  console.log("thread_id", thread_id, localStorage.getItem("plansom_bot_id"))

  if (invite_key != null) {
    const postData = {
      email,
      password,

    };
    return await axios.post(baseURL + `users/login/` + invite_key, postData);
  } else {
    const postData = {
      email,
      password,
      thread_id
    };
    return await axios.post(baseURL + `users/login`, postData);
  }
}

export function resendOTPChangeEmail(email, token) {
  //axios call
  const postData = {
    email,
  };
  return axios.post(baseURL + `users/change-email/resend-otp`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function formatError(errorResponse) {
  console.log("eryttyttetetttette", errorResponse.message)
  switch (errorResponse.message) {
    case "{'email': [ErrorDetail(string='Auth Users with this Email Address already exists.', code='unique')]}":
      swal("Oops", "User email already exists", "error");
      break;
    case "User email already exists":
      swal("Oops", "User email already exists", "error");
      break;
    case "User with this email does not exists":
      swal("User with this email address does not exist", "", "error");
      break;
    case "Password does not match validation criteria":
      swal("Oops", "Password does not match validation criteria", "error");
      break;
    case "Auth Users with this Email Address already exists.":
      swal("Oops", "User email already exists", "error");
    case "Email does not match validation criteria":
      swal("Oops", "Email does not match validation criteria", "error");
      break;
    case "Password cannot be same as previously entered password":
      swal("Oops", "Password cannot be same as previously entered password", "error");
      break;
    case "User with this email address does not exist":
      swal("Oops", "User with this email address does not exist", "error");
      break;
    case "Organization with this Organization Name already exists":
      swal(
        "Oops",
        "Organization with this Organization name already exists",
        "error"
      );
      break;
    case "Incorrect password":
      swal("Oops", "You have entered incorrect password", "error");
      break;
    case "User already belongs to an organization":
      swal("Oops", "User already belongs to an organization", "error");
      break;
    case "Organization goal cannot have a team or personal goal as parent goal":
      swal("Oops", "Organization goal cannot have a team or personal goal as parent goal", "error")
      break;
    case "Subgoal has not permission to change scope":
      swal("Oops", "Subgoal has not permission to change scope", "error")
      break;
  }
  if (errorResponse?.messages[0]?.message === "Token is invalid or expired") {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("organization_id");
    localStorage.removeItem("org_access");
    localStorage.removeItem("scope");
    localStorage.removeItem("team_id");
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("timescope");
    localStorage.removeItem("ActiveChat");
    localStorage.removeItem("noOfDays");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    console.log('inside logout')
    dispatch(logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);

  dispatch(loginConfirmedAction(tokenDetails));
}
export function sendOTPForEmail(email, token) {
  const postData = {
    email: email,
  };
  return axios.post(baseURL + `users/change-email`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
export function verifyEmailAction(otp, token) {
  const postData = {
    otp: otp,
  };
  return axios.post(baseURL + `users/verify-new-email`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
export function changePasswordAction(changePass, token) {
  const postData = {
    old_password: changePass.old_pass,
    new_password: changePass.new_pass,
    confirm_password: changePass.confirm_new_pass,
  };
  return axios.post(baseURL + `users/change-password`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updateProfileAction(
  updateProfile,
  startDay,
  endDay,
  startTime,
  endTime,
  lunchStartTime,
  lunchEndTime,
  token
) {
  const postData = {
    first_name: updateProfile.first_name,
    last_name: updateProfile.last_name,
    start_day: startDay,
    end_day: endDay,
    lunch_start_time: lunchStartTime,
    lunch_end_time: lunchEndTime,
    start_time: startTime,
    end_time: endTime,
    lunch_breaks: updateProfile.lunch_breaks,
    working_days: updateProfile.working_days,
    daily_working_hours: updateProfile.daily_working_hours,
    weekly_working_hours: updateProfile.weekly_working_hours,
    holidays: updateProfile.holidays,
    email: updateProfile.email,
    id: updateProfile.id,
  };
  return axios.put(baseURL + `users/user/` + updateProfile.id, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function uploadProfilePic(file, user_id, token) {
  const postData = {
    profile_picture: file,
  };
  return axios.post(
    baseURL + `users/` + user_id + "/profile_picture",
    postData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function sendAccessToken(userData) {
  const postData = {
    refresh: userData.data.refreshToken,
  };
  return axios.post(baseURL + `users/token/refresh`, postData, {
    headers: {
      Authorization: "Bearer " + userData.data.accessToken,
    },
  });
}

export function logoutUser(token) {
  const postData = {
    // otp: otp
  };
  return axios.post(baseURL + `users/logout`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function sendUserSuggestion(user, description, token) {
  const postData = {
    user: user,
    suggestion: description
  };
  return axios.post(baseURL + `users/suggestion`, postData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}




export function partnerSignUp(email, first_name, last_name, Weburl,
  BuisnessType,
  ClientsCount,
  comment, company_name, image) {
  const postData = {
    email: email,
    first_name: first_name,
    last_name: last_name,
    website_url: Weburl,
    business_type: BuisnessType,
    approx_clients_range: ClientsCount,
    comments: comment,
    company_name: company_name,
    company_logo: image

  };
  return axios.post(
    baseURL + `users/partner/apply`,
    postData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }
  );
}


export function verifyPartnerSignupOTP(email, otp) {
  const postData = {
    email: email,
    otp: otp
  };
  return axios.post(
    baseURL + `users/partner/verify-otp`,
    postData, {
    headers: {
      // Authorization: 'Bearer ' + token
    }
  }
  );
}

export function PartenerSignIn(email, password) {
  let invite_key = localStorage.getItem("invite_keys");
  const postData = {
    email: email,
    password: password
  };
  return axios.post(
    baseURL + `users/partner/signup/${invite_key}`,
    postData, {
    headers: {
      // Authorization: 'Bearer ' + token
    }
  }
  );
}


export function getRevenueDetails(token) {
  return axios.get(baseURL + "users/partner/revenue-share", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}




