import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Row, Col, Card } from "react-bootstrap";
import RedialApex from "./RedialApex";
import vector from "../../../images/svg/Vector.svg";
import vectorrs from "../../../images/svg/vectorrs.svg"
import Donut from "./Donut";
import Pagination from "../../components/Pagination/Pagination";
import { getCompleteHoursBarGraph } from "../../../services/OrganizationService";
import LoadingSpinner from "../Component/LoadingSpinner";
import { CircularProgress } from "@material-ui/core";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class BarChartObjective extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      startDate: "",
      endDate: "",
      seriesData: [],
      lableee: [],
      pagination: false,
      pageCount: 1,
      goToPage: null,
      loading : false,
      circularLoader: false,
    };
    this.userDetail = localStorage.getItem("userDetails");
    this.userInfoDetails = JSON.parse(this.userDetail);
  }
  componentDidMount() {
    this.getGraph();
  }

  getGraph = (goPage = 0) => {
    this.setState({ circularLoader : true });
    if(this.props?.goal_id || this.props?.trackScope != "objective"){
    const data = getCompleteHoursBarGraph("", this.userInfoDetails?.data?.accessToken, goPage, this.props?.goal_id);
    data?.then(async (res) => {
        this.setState({ circularLoader : false });
    //   if (res?.data?.data[0]?.completion_percent === 0) {
    //     res?.data?.data?.shift();
    //   }
      this.setState(() => ({
        goToPage: goPage,
        pageCount: Math.ceil(res?.data?.count / 10),
        pagination: res?.data?.next || res?.data?.previous ? true : false,
        labels: res?.data?.data,
     
      }));

    console.log(res, "HEEEEErrrrrr")

    });
    data?.catch(async (err) => {
      console.log(err, "HEEEEErrrrrr" );
    });
  }
  }

  componentWillReceiveProps(newProps) {
    if (newProps?.scope === "custom") {
      setTimeout(() => {
        this.getGraph(this.state?.goToPage);
      }, 1000);
    }
    else {
      setTimeout(() => {
        // if (this.state?.goToPage === 0) {
          this.getGraph(this.state?.goToPage);
        // }
      }, 2000);

    }
    // this.setState({ labels: newProps?.graphData });
    // this.setState({
    //   ...this.state,
    //   seriesData: [
    //     newProps?.graphData[newProps?.graphData?.length - 1]?.hit_percent,
    //     newProps.graphData[newProps?.graphData?.length - 1]?.beat_percent,
    //     newProps?.graphData[newProps?.graphData?.length - 1]?.miss_percent,
    //   ],
    //   lableee: [
    //     "Hit-" +
    //     String(
    //       newProps?.graphData[newProps?.graphData?.length - 1]?.hit_percent
    //     ),
    //     "Beat-" +
    //     String(
    //       newProps?.graphData[newProps?.graphData?.length - 1]?.beat_percent
    //     ),
    //     "Miss-" +
    //     String(
    //       newProps?.graphData[newProps?.graphData?.length - 1]?.miss_percent
    //     ),
    //   ],
    // });
  }

  ChangePage = (pageNo) => {
    this.setState(() => ({ ...this.state, goToPage: pageNo }));
    this.getGraph(pageNo);
  }

  render() {
    const graphData = this.state?.labels;
   
    const labels = [];
    const values = [];
    const prediction = []
    graphData?.forEach((element) => {
        console.log(element, "element")
      labels.push("Week " + element.week);
      values.push(element.complete_hour);
      prediction.push(element?.prediction?.weeks)
      console.log(prediction[prediction.length-1], "99999999999oooodadaaaaaaaaaa")
    });

    console.log(prediction, "99999999999")
    console.log(values, "99999999999oooo")

    const screenWidth = window.innerWidth;
    const barThickness = screenWidth >= 1020 ? 70 : 30;  
    const data = {
      defaultFontFamily: "Open Sans",
      labels: labels,
      
      datasets: [
        {
          label: "Completion hour",
          data: values,
          borderColor: "#4DBFDB",
          borderWidth: "0",
          backgroundColor: "#4DBFDB",
          hoverBackgroundColor: "#4DBFDB",
          barThickness: barThickness,
        },
      ],
    };
    const options = {
      plugins: {
        legend: false,
      },
      scales: {
        y: {
          min: 0,
          // max: 100,
        },

        x: {
          // Change here
          barPercentage: 1,
        },
      },
      responsive: true,
    };
    return (
      <>
       <Col xl={6} lg={6}>
   <Card style={{minHeight:"300px"}}>
    {this.state.circularLoader ? <>
      <div className="p-0 m-0 text-center" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", minHeight:"50%"}}>
        <CircularProgress/>
        </div>
    </> :<>
        <Card.Header>
        <h4>
                      You are{" "}
                      <span className="figma-link ">
                        {values[values?.length - 1]} hour
                        complete 
                      </span>
                      .
                      {prediction ? (
                        <>
                          {" "}
                          At this pace you will be done{" "}
                          <span className="figma-link ">
                            in {prediction[prediction?.length-1]} weeks
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </h4>
          <div className="text-end">
                {/* <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle variant="transparent" id="dropdown-split-basic">
                    <i className="bi bi-list fs-20" style={{ width: "20px", height: "20px", color: "#6e8192" }}></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleDownloadCSV}>
                      Download CSV
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDownloadPNG}>
                      Download PNG
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDownloadSVG}>
                      Download SVG
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
        </Card.Header>
        <Card.Body>
        {/* {this.state.loading && (
            <LoadingSpinner data={{top: "50%"}}/>
          )} */}
          <div className="mb-2">
               {labels.length !== 0 ?(   <Bar
                      data={data}
                      height={150}
                      options={options}
                      className="h-100 w-100 px-1 ps-2"
                    />
                     ) : (
            <span className="text-center"> No data available</span>
          )}
          <div className=" border-0 mt-4">
          {this.state.pagination && (
                      <Pagination
                        pageCount={this.state.pageCount}
                        pageIndex={this.state.goToPage}
                        gotoPage={this.ChangePage}
                      />
                    )}
          </div>
          </div>
        </Card.Body>
        </>}
      </Card>
    </Col>


        {/* <Row>
        <Bar
                      data={data}
                      height={150}
                      options={options}
                      className="h-100 w-100 px-1 ps-2"
                    />
        </Row> */}
      </>
    );
  }
}

export default BarChartObjective;
