import React, { useState, useEffect, lazy } from "react";
import { getAllArchieveGoal } from "../../../services/GoalService";
import { Row, Col, Dropdown, Modal } from "react-bootstrap";
import LoadingSpinner from "../Component/LoadingSpinner";
import { Link } from "react-router-dom";
import { unArchieveGoal } from "../../../services/GoalService";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../Pagination/Pagination";
import { useNavigate } from "react-router-dom";

const ArchieveGoal = () => {
  const [goals, setGoals] = useState([]);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [showTableSpinner, setShowTableSpinner] = useState(true);
  const [filterIcon, setFilterIcon] = useState(true);
  const [selectedStatusItem, setSelectedStatusItem] = useState();
  console.log("selectedStatusItem", selectedStatusItem?.name)
  const [pagination, setPagination] = useState(false);
  const [pageCount, setPageCount] = useState(1)
  const [goToPage, setGoToPage] = useState(0);
  const [inputValue, setInputValue] = useState(localStorage.getItem("archieved_goals_row_per_page") || '10');
  console.log("inputValue", inputValue)
  const numberOptions = [5, 10, 25, 50, 100];

  const [goalNameFilter, setGoalNameFilter] = useState();
  const [CompleteStartRange, setCompleteStartRange] = useState();
  const [CompleteEndRange, setCompleteEndRange] = useState();
  const [WinsStartRange, setWinsStartRange] = useState();
  const [WinsEndRange, setWinsEndRange] = useState();
  const [OntimeStartRange, setOntimeStartRange] = useState();
  const [OntimeEndRange, setOntimeEndRange] = useState();

  const [sortAsc, setSortAsc] = useState(false);
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setsortOrder] = useState();

  const navigate = useNavigate()

  let timeout


  const filterName = [
    {
      name: "On track",
      value: "on_track",
    },
    {
      name: "At risk",
      value: "at_risk",
    },
    {
      name: "Failed",
      value: "failed",
    },
    {
      name: "Failing",
      value: "failing"
    }
  ];

  useEffect(() => {
    getAllArchieveGoals(goToPage)
  }, [goToPage])

  useEffect(() => {
    setGoToPage(() => 0);
    getAllArchieveGoals(0)
  }, [selectedStatusItem, goalNameFilter, CompleteStartRange, CompleteEndRange, WinsStartRange, WinsEndRange, OntimeStartRange, OntimeEndRange])

  useEffect(() => {
    setFilterIcon(filterIcon)
  }, [filterIcon])

  const [GoalInputFieldOpen, setGoalInputFieldOpen] = useState(false);

  useEffect(() => {
    setGoalInputFieldOpen(GoalInputFieldOpen);
  }, [GoalInputFieldOpen]);

  const handleInputChange = (event) => {

    clearTimeout(timeout)
    const newValue = event.target.value;
    setInputValue(newValue);
    localStorage.setItem("archieved_goals_row_per_page", newValue);
    console.log(event.target.value, "qwweeeertrtrttt")

    setTimeout(() => {
      setShowTableSpinner(true);
      const data = getAllArchieveGoal(localStorage.getItem('organization_id'), goToPage, userInfoDetails?.data?.accessToken, event.target.value, selectedStatusItem, goalNameFilter, CompleteStartRange, CompleteEndRange, WinsStartRange, WinsEndRange, OntimeStartRange, OntimeEndRange, sortOrder, sortBy)
      data?.then(async (res) => {
        console.log(res?.data?.count, "77777777777")
        setPageCount(Math.ceil(res?.data?.count / (localStorage.getItem("archieved_goals_row_per_page") || event?.target?.value)));
        setPagination(res?.data?.next || res?.data?.previous ? true : false);
        setGoals(res?.data?.data);
        setShowTableSpinner(false);
        // if ((StatusItem || GoalsName) && res.data.length === 0) {
        //   setFilterIcon(false);
        // }
      });
      data?.catch(async (err) => {
        console.log("error get org goal", err);
      });
    }, 1500)
  };



  const clearAllGoalsFilter = (e) => {


    setSelectedStatusItem(() => "");
    setGoalNameFilter(() => "");
    setCompleteStartRange(() => "");
    setCompleteEndRange(() => "");
    setWinsStartRange(() => "");
    setWinsEndRange(() => "");
    setOntimeStartRange(() => "");
    setOntimeEndRange(() => "");
    getAllArchieveGoals(0, sortOrder, sortBy)

  }

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setInputValue(selectedValue);
  };

  const getAllArchieveGoals = (goToPage, sortAsc = null, sortBy = null) => {
    setShowTableSpinner(true);
    console.log(parseInt(inputValue), "qwweeeertrtrttt")
    const data = getAllArchieveGoal(localStorage.getItem('organization_id'), goToPage, userInfoDetails?.data?.accessToken, inputValue, selectedStatusItem, goalNameFilter, CompleteStartRange, CompleteEndRange, WinsStartRange, WinsEndRange, OntimeStartRange, OntimeEndRange, sortAsc, sortBy)
    data?.then(async (res) => {
      setPageCount(Math.ceil(res?.data?.count / inputValue));
      setPagination(res?.data?.next || res?.data?.previous ? true : false);
      setGoals(res?.data?.data);
      setShowTableSpinner(false);
      console.log(res, "mmmmmmmmmmmmmmmm33333")
    })
    data?.catch(async (err) => {
      console.log(err, "mmmmmmmmmmmmmmmm33333")
    })
  }

  const unarchieveArchiveGoalHandler = (event, goal) => {
    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk", goal)
    const data = unArchieveGoal(goal?.id, userInfoDetails?.data?.accessToken)
    data.then(async (res) => {
      getAllArchieveGoals(0)
      //   getAllOrgGoal(0);
      //   getAllSubGoalData(goal?.id, goToSubGoalPage);
      //   getTasksByGoalId(goToTaskPage);
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your goal has been unArchieved successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
      console.log("55555555Archieve", res)
    })
    data.catch(async (err) => {
      console.log("errorAAAAAAAAAA", err?.response?.data?.message)
      toast(
        <div className="m-1">
          <h3>{err?.response?.data?.message}</h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      console.log("55555555Archieve", err)
    })

  }


  const handleClick11 = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getAllArchieveGoals(goToPage, sortAsc ? "asc" : "desc", sort_by);
    setSortAsc(sortAsc);
  };







  return (
    <>
      <div>
        <i
          className="fa fa-reply fs-20"
          title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/goals")
          }}
        />
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Archieved Goals</h4>

          {(selectedStatusItem ||
            goalNameFilter ||
            CompleteStartRange ||
            CompleteEndRange || WinsStartRange || OntimeStartRange || OntimeEndRange) && (
              <span
                className=" mx-3 pt-1 float-end"
                title="Clear all filter"
                onClick={(e) => {
                  clearAllGoalsFilter(e);
                }}
                style={{
                  cursor: "pointer",
                  color: "#DC5D74",
                }}
              >Clear all filter
                <i
                  className="fas fa-filter fs-18"
                ></i>
                <span>x</span>
              </span>
            )}
        </div>
        <div className="card-body ">

          <div id="job_data" className="dataTables_wrapper">
            {showTableSpinner ? (
              <div
                style={{
                  position: "relative",
                  top:
                    goals.length < 3
                      ? "6rem"
                      : goals.length >= 3
                        ? "9rem"
                        : "1rem",
                }}
                className="m-0 p-0"
              >
                <LoadingSpinner />
              </div>
            ) : (
              ""
            )}
            <div className="table-responsive">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
                defaultPageSize={5}
                style={
                  goals.length < 2
                    ? { minHeight: "110px" }
                    : { minHeight: "200px" }
                }
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc status">
                      <div className="d-flex m-0 p-0">
                        <span className="float-start" style={{ paddingRight: "10px" }}>Status</span>
                        {filterIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                          // style={{flnd"}}
                          // style={{ right: "90%" }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: selectedStatusItem ? "#DC5D74" : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {filterName?.map((scope, i) => (
                                <Dropdown.Item
                                  style={selectedStatusItem?.name === scope?.name ? { backgroundColor: "#B8E5F1" } : { backgroundColor: "#ffffff" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedStatusItem(scope);
                                  }}
                                  key={i}
                                  className="p"
                                >
                                  {scope?.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th className="sorting goalwidth">
                      <div className="d-flex m-0 p-0">
                        <span className="float-start" style={{ paddingRight: "10px" }}>
                          <i
                            class="fas fa-sort fs-16"
                            onClick={() => {
                              handleClick11("goal", !sortAsc);
                            }}
                          ></i>
                          Goal
                        </span>
                        {filterIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "51%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setGoalInputFieldOpen(
                                !GoalInputFieldOpen
                              );
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: goalNameFilter ? "#DC5D74" : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {
                                <>
                                  <input
                                    type="text"
                                    name="name"
                                    className="figma-input p m-0 p-2"
                                    placeholder="Enter goal name"
                                    autocomplete="off"
                                    defaultValue={goalNameFilter}
                                    onKeyUp={(e) => {
                                      setTimeout(() => {
                                        setGoalNameFilter(
                                          e.target.value
                                        );
                                      }, 1000);
                                    }}
                                    style={{
                                      border: "1px solid #9a9b9c",
                                      width: "150px",
                                      borderRadius: "0px",
                                    }}
                                  />
                                </>
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th className="sorting perFields">
                      <div className="d-flex m-0 p-0">
                        <span className="float-start" style={{ paddingRight: "10px" }}> Complete</span>
                        {filterIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "41%" }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: CompleteStartRange || CompleteEndRange ? "#DC5D74" : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-2">
                              {
                                <>
                                  <h5>Enter start & end range</h5>
                                  <Row className="d-flex">
                                    <Col>
                                      <input
                                        type="number"
                                        name="name"
                                        min={0}
                                        max={100}
                                        className="figma-input p m-0 p-2"
                                        placeholder="Start"
                                        autocomplete="off"
                                        defaultValue={
                                          CompleteStartRange
                                        }
                                        onKeyUp={(e) => {
                                          setTimeout(() => {
                                            setCompleteStartRange(
                                              e.target.value
                                            );
                                          }, 1000);
                                        }}
                                        style={{
                                          border: "1px solid #9a9b9c",
                                          height: "30px",
                                        }}
                                      />
                                    </Col>
                                    _
                                    <Col>
                                      <input
                                        type="number"
                                        name="name"
                                        min={0}
                                        max={100}
                                        className="figma-input p m-0 p-2 me-1"
                                        placeholder="End"
                                        autocomplete="off"
                                        defaultValue={CompleteEndRange}
                                        onKeyUp={(e) => {
                                          setTimeout(() => {
                                            setCompleteEndRange(
                                              e.target.value
                                            );
                                          }, 1000);
                                        }}
                                        style={{
                                          border: "1px solid #9a9b9c",
                                          height: "30px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th className="sorting perFields">
                      <div className="d-flex m-0 p-0">
                        <span className="float-start" style={{ paddingRight: "10px" }}>Wins</span>
                        {filterIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "31%" }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: WinsStartRange || WinsEndRange ? "#DC5D74" : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-2">
                              {
                                <>
                                  <h5>Enter start & end range</h5>
                                  <Row className="d-flex">
                                    <Col>
                                      <input
                                        type="number"
                                        name="name"
                                        min={0}
                                        max={100}
                                        className="figma-input p m-0 p-2"
                                        placeholder="Start"
                                        autocomplete="off"
                                        defaultValue={WinsStartRange}
                                        onKeyUp={(e) => {
                                          setTimeout(() => {
                                            setWinsStartRange(
                                              e.target.value
                                            );
                                          }, 1000);
                                        }}
                                        style={{
                                          border: "1px solid #9a9b9c",
                                          height: "30px",
                                        }}
                                      />
                                    </Col>
                                    _
                                    <Col>
                                      <input
                                        type="number"
                                        name="name"
                                        min={0}
                                        max={100}
                                        className="figma-input p m-0 p-2 me-1"
                                        placeholder="End"
                                        autocomplete="off"
                                        defaultValue={WinsEndRange}
                                        onKeyUp={(e) => {
                                          setTimeout(() => {
                                            setWinsEndRange(
                                              e.target.value
                                            );
                                          }, 1000);
                                        }}
                                        style={{
                                          border: "1px solid #9a9b9c",
                                          height: "30px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th className="sorting perFields">
                      <div className="d-flex m-0 p-0">
                        <span className="float-start" style={{ paddingRight: "10px" }}>On-time</span>
                        {filterIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "21%" }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: OntimeStartRange || OntimeEndRange ? "#DC5D74" : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-2">
                              {
                                <>
                                  <h5>Enter start & end range</h5>
                                  <Row className="d-flex">
                                    <Col>
                                      <input
                                        type="number"
                                        name="name"
                                        min={0}
                                        max={100}
                                        className="figma-input p m-0 p-2"
                                        placeholder="Start"
                                        autocomplete="off"
                                        defaultValue={OntimeStartRange}
                                        onKeyUp={(e) => {
                                          setTimeout(() => {
                                            setOntimeStartRange(
                                              e.target.value
                                            );
                                          }, 1000);
                                        }}
                                        style={{
                                          border: "1px solid #9a9b9c",
                                          height: "30px",
                                        }}
                                      />
                                    </Col>
                                    _
                                    <Col>
                                      <input
                                        type="number"
                                        name="name"
                                        min={0}
                                        max={100}
                                        className="figma-input p m-0 p-2 me-1"
                                        placeholder="End"
                                        autocomplete="off"
                                        defaultValue={OntimeEndRange}
                                        onKeyUp={(e) => {
                                          setTimeout(() => {
                                            setOntimeEndRange(
                                              e.target.value
                                            );
                                          }, 1000);
                                        }}
                                        style={{
                                          border: "1px solid #9a9b9c",
                                          height: "30px",
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    {/* <th className="sorting perFields" style={{paddingRight:"10px"}}>Create task</th> */}
                    <th className="sorting perFields">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {goals.map((goal, index) => (
                    <tr
                      className="taskscheduledgoal odd goals"
                      role="row"
                      style={
                        showTableSpinner
                          ? { backgroundColor: "#ebedee", zIndex: "-1" }
                          : {}
                      }
                    >
                      <td
                        // onClick={(event) => {
                        //   setParentGoalId(goal);
                        //   setSelectedGoal([goal]);
                        //   getAllSubGoalData(goal?.id, goToSubGoalPage);
                        //   setGoalDetails(goal);
                        //   setGoalsdelete(false);
                        //   setGoalsupdate(false);
                        //   setGoalscreated(false);
                        //   setTaskcreated(false);
                        //   setTaskupdate(false);
                        //   setTaskdelete(false);
                        // }}
                        className={
                          goal.goal_status === "On track"
                            ? "figbtn-success"
                            : goal.goal_status === "At risk"
                              ? "figbtn-warning"
                              : "figbtn-failed"
                        }
                      >
                        <span
                          className={
                            goal.goal_status === "On track"
                              ? "figstatus"
                              : goal.goal_status === "At risk"
                                ? "figwarning"
                                : "figfailed"
                          }
                        >
                          {goal.goal_status}
                        </span>
                      </td>
                      <td
                      // onClick={(event) => {
                      //   setParentGoalId(goal);
                      //   setSelectedGoal([goal]);
                      //   getAllSubGoalData(goal?.id, goToSubGoalPage);
                      //   setGoalDetails(goal);
                      //   setGoalsdelete(false);
                      //   setGoalsupdate(false);
                      //   setGoalscreated(false);
                      //   setTaskcreated(false);
                      //   setTaskupdate(false);
                      //   setTaskdelete(false);
                      // }}
                      >
                        {goal?.name}
                      </td>
                      <td
                      // onClick={(event) => {
                      //   setParentGoalId(goal);
                      //   setSelectedGoal([goal]);
                      //   getAllSubGoalData(goal?.id, goToSubGoalPage);
                      //   setGoalDetails(goal);
                      //   setGoalsdelete(false);
                      //   setGoalsupdate(false);
                      //   setGoalscreated(false);
                      //   setTaskcreated(false);
                      //   setTaskupdate(false);
                      //   setTaskdelete(false);
                      // }}
                      >
                        {goal?.goal_completed_percent} %
                      </td>
                      <td
                      // onClick={(event) => {
                      //   setParentGoalId(goal);
                      //   setSelectedGoal([goal]);
                      //   getAllSubGoalData(goal?.id, goToSubGoalPage);
                      //   setGoalDetails(goal);
                      //   setGoalsdelete(false);
                      //   setGoalsupdate(false);
                      //   setGoalscreated(false);
                      //   setTaskcreated(false);
                      //   setTaskupdate(false);
                      //   setTaskdelete(false);
                      // }}
                      >
                        {goal?.goal_win_percent} %
                      </td>
                      <td
                      // onClick={(event) => {
                      //   setParentGoalId(goal);
                      //   setSelectedGoal([goal]);
                      //   getAllSubGoalData(goal?.id, goToSubGoalPage);
                      //   setGoalDetails(goal);
                      //   setGoalsdelete(false);
                      //   setGoalsupdate(false);
                      //   setGoalscreated(false);
                      //   setTaskcreated(false);
                      //   setTaskupdate(false);
                      //   setTaskdelete(false);
                      // }}
                      >
                        {goal?.goal_on_time_percent} %
                      </td>
                      <td>
                        <div className="text-start">
                          {goal?.goal_creator?.id ===
                            userInfoDetails?.data?.id ||
                            goal?.goal_owner?.id ===
                            userInfoDetails?.data?.id ? (
                            <Link
                              title="unarchieve goal"
                              to=""
                              className="outline-primary btn btn-primary me-2"


                              onClick={(event) => {
                                unarchieveArchiveGoalHandler(
                                  event,
                                  goal
                                );
                              }}
                            >
                              {/* <i className="fa fa-archive"></i> */}
                              <i className="fas fa-box-open"></i>

                            </Link>
                          ) : (
                            <Link
                              to=""
                              title="unarchieve"
                              className="outline-primary btn btn-primary me-2"


                              onClick={(event) => {
                                unarchieveArchiveGoalHandler(
                                  event,
                                  goal
                                );
                              }}
                            >
                              {/* <i className="fa fa-archive"></i> */}
                              <i className="fas fa-box-open"></i>
                            </Link>
                          )}
                        </div>
                      </td>
                      {/* <td>
                                <div className="d-flex todo-button p-0 m-0">
                                  {goal?.goal_creator?.id ===
                                    userInfoDetails?.data?.id ||
                                    goal?.goal_owner?.id ===
                                    userInfoDetails?.data?.id ||
                                    userInfoDetails?.data?.user_role === "owner" ? (
                                    <button
                                      className="outline-primary btn btn-primary me-2"
                                      onClick={(event) => {
                                        setSelectedGoal([goal]);
                                        setShowGoalModal(true);
                                        setEditTask(true);
                                      }}
                                    >
                                      <i className="fas fa-pen"></i>
                                    </button>
                                  ) : (
                                    <div
                                      title="User does not have permission to perform this action"
                                      className="p-0 m-0"
                                    >
                                      <button
                                        disabled
                                        title="User does not have permission to perform this action"
                                        className="outline-primary btn btn-primary me-1"
                                        onClick={(event) => { }}
                                      >
                                       
                                      </button>
                                    </div>
                                  )}

                                  {goal?.goal_creator?.id ===
                                    userInfoDetails?.data?.id ||
                                    goal?.goal_owner?.id ===
                                    userInfoDetails?.data?.id ||
                                    userInfoDetails?.data?.user_role === "owner" ? (
                                    <button
                                      className="outline-primary btn btn-primary me-2"
                                      onClick={(event) => {
                                        selectedGoalForDelete(event, goal);
                                        SetOpenDeleteModal(true);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  ) : (
                                    <div
                                      title="User does not have permission to perform this action"
                                      className="p-0 m-0"
                                    >
                                      <button
                                        disabled
                                        className="outline-primary btn btn-primary me-0"
                                        onClick={(event) =>
                                          handleDeleteGoalClick(event, goal)
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  )}

                                   <Link
                                          to=""
                                          className="outline-primary btn btn-primary me-2"
                                          

                                          onClick={(event) => {
                                            archiveGoalHandler(event, goal
                                             
                                            );
                                          }}
                                        >
                                        
                                        <i className="fa fa-archive"></i>
                                        </Link>

                                  
                                </div>
                              </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {goals?.length === 0 && !showTableSpinner && (
              <Row className="p-0 m-0">
                <span className="th text-center " style={{ top: "-25px", position: "relative" }}>
                  No records found.
                </span>
              </Row>
            )}
          </div>
          <div>
            <Row className="pt-3 border-0 goal-row">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <div>
                  <label htmlFor="numberSelector">Rows per page  &nbsp;</label>
                  <input
                    type="number"
                    list="numberOptions"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="form-control pagination-row-input"
                    placeholder=""
                    style={{
                      width: "auto", display: "inline-block", maxWidth: "100px", background: "#fff",
                      border: "0.0625rem solid #d7dae3",
                      padding: "0.3125rem 1.25rem",
                      color: "#6e6e6e",
                      borderRadius: "0rem",
                      height: "45px",
                    }}
                  />
                  <datalist id="numberOptions">
                    {numberOptions.map((number) => (
                      <option key={number} value={number} />
                    ))}
                  </datalist>

                </div>

                {pagination && (
                  <>
                    <Pagination
                      pageCount={pageCount}
                      pageIndex={goToPage}
                      gotoPage={setGoToPage}
                    />
                  </>
                )}
              </div>

            </Row>
          </div>
        </div>
      </div>
    </>
  )
}; export default ArchieveGoal