import React, { useState, useEffect } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { createTaskService, postTaskCache, sendTaskAttachments } from "../../../services/TaskService";
import { getAllGoalsByScope, getGoalById } from "../../../services/GoalService";
import Attachment from "./Attachment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { refreshTaskList } from "../../../store/actions/TaskActions";


const TaskModal = (props) => {
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state);
  const userDetail = localStorage.getItem("userDetails");
  const location = useLocation();
  const [spinner, setSpinner] = useState(false);
  const userInfoDetails = JSON.parse(userDetail);
  const orgId = parseInt(localStorage.getItem("organization_id"), 10);
  const [goalDetails] = useState(props.goal);
  const [options, setOptions] = useState();
  const [taskOwner, setTaskOwnerValue] = useState(props.owner);
  const [FileErrorMessage, setFileErrorMessage] = useState("");
  let taskerrorsObj = { taskName: "", taskDescription: "", taskEffort: "", alignGoal: "" };
  const [taskerrors, settaskErrors] = useState(taskerrorsObj);
  const [attachment, setAttachments] = useState();
  const [datas, setDatas] = useState([]);
  const [goalAlignId, setGoalAlign] = useState(props?.goal ? props?.goal?.id : (location?.state?.goalId !== null ? location?.state?.goalId?.id : undefined));
  const [Show, setShow] = useState(props.show);
  const [goalsOption, setGoalsOption] = useState();
  const [defaultOptions, setDefaultOptions] = useState(props?.goal ? {
    label: props?.goal?.name,
    value: props?.goal?.name,
    userId: props?.goal?.id,
  } : (location?.state?.goalId !== null ? {
    label: location?.state?.goalId?.name,
    value: location?.state?.goalId?.name,
    userId: location?.state?.goalId?.id,
  } : undefined));

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" }
  ]

  const [status, setStatus] = useState(goalDetails
    ? {
      label: goalDetails?.status,
      value: goalDetails?.status,
    }
    : null)

  const [warningMessage, setWarningMessage] = useState("");
  const [taskImpact, setTaskImpactValue] = useState({
    value: "medium",
    label: "Medium",
  });

  // Update or add these state variables
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");

  // Modify the handleCreateTaskEffortChange to work with the dropdowns
  const handleHoursChange = (e) => {
    const newHours = e.target.value;
    setHours(newHours);

    // Update taskEffortValue for display consistency
    const formattedTime = `${newHours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    setTaskEffortValue(formattedTime);

    // Convert to decimal for task_effort
    const decimalHours = parseFloat(newHours) + (parseInt(minutes) / 60);
    const newFormData = { ...createTask };
    newFormData.task_effort = decimalHours.toFixed(2);
    setCreateTaskForm(newFormData);
  };

  const handleMinutesChange = (e) => {
    const newMinutes = e.target.value;
    setMinutes(newMinutes);

    // Update taskEffortValue for display consistency
    const formattedTime = `${hours.padStart(2, '0')}:${newMinutes.padStart(2, '0')}`;
    setTaskEffortValue(formattedTime);

    // Convert to decimal for task_effort
    const decimalHours = parseFloat(hours) + (parseInt(newMinutes) / 60);
    const newFormData = { ...createTask };
    newFormData.task_effort = decimalHours.toFixed(2);
    setCreateTaskForm(newFormData);
  };

  const [taskControl, setTaskControlValue] = useState({
    value: "medium",
    label: "Medium"
  })

  const initialTaskState = {
    task_name: "",
    description: "",
    task_effort: "",
  };

  const [taskEffortValue, setTaskEffortValue] = useState("");

  const [createTask, setCreateTaskForm] = useState({
    task_name: "",
    // description: "",
    task_effort: "",

  });

  const taskImpactOptions = [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  const taskControlOptions = [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" }

  ]

  const changeStatusGoal = (status) => {
    setStatus({ label: status.value, value: status.value })
    if (status?.value === "Active" && goalDetails?.status === "Inactive") {
      setWarningMessage("Warning: If you set the task's active state, the parent goal status will also update to active.");
    }
    else {
      setWarningMessage("");
    }
    if (goalAlignId != undefined) {
      const data = getGoalById(goalAlignId, userInfoDetails?.data?.accessToken);
      data?.then(async (res) => {
        if (status?.value === "Active" && res?.data?.data?.status === "Inactive") {
          setWarningMessage("Warning: If you set the task's active state, the parent goal status will also update to active.");
        }
      })
      data?.catch(async (err) => { })
    }

  }

  const handleCreateTaskChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...createTask };
    newFormData[fieldName] = fieldValue;
    setCreateTaskForm(newFormData);
  };

  const handleCreateTaskEffortChange = (event) => {
    event.preventDefault();
    setTaskEffortValue(event.target.value);
    const [hours, minutes] = event.target.value?.split(":").map(Number);
    if (minutes < 0 || minutes > 59) {
      task_errors.taskEffort = "Minutes must be between 00 and 59.";
      settaskErrors(task_errors);
      return;
    }
    const decimalHours = hours + minutes / 60;
    if (decimalHours < 0.1 || decimalHours > 20) {
      task_errors.taskEffort = "Task effort must be at least 00:01 and no more than 20:00";
      settaskErrors(task_errors);
      return;
    }

    if (!/^[0-9]*\:?[0-9]*$/.test(event.target.value)) {
      task_errors.taskEffort = "Use numbers only";
      settaskErrors(task_errors);
    } else if (!/^\d{1,2}:\d{2}$/.test(event.target.value)) {
      task_errors.taskEffort = "Invalid format. Please use hh:mm format.";
      settaskErrors(task_errors);
      return;
    }
    else if (!/^\d{1,2}:\d{2}$/.test(event.target.value)) {
      task_errors.taskEffort = "Invalid format. Please use hh:mm format.";
      settaskErrors(task_errors);
      return;
    }
    else {
      task_errors.taskEffort = "";
      settaskErrors(task_errors);
      const [hours, minutes] = event.target.value.split(":").map(Number);
      const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
      const fieldValue = parseFloat(hours + decimalMinutes);
      const newFormData = { ...createTask };
      const fieldName = event.target.getAttribute("name");
      newFormData[fieldName] = fieldValue;
      setCreateTaskForm(newFormData);
    }
  };


  const changeTaskOwner = (userRole) => {
    setTaskOwnerValue(userRole);
  };

  const changeTaskImpact = (taskImpact) => {
    setTaskImpactValue(taskImpact);
  };

  const changeTaskContol = (taskControl) => {
    setTaskControlValue(taskControl)

  }

  // function convertUrlsToLinks(text) {
  //   const urlRegex = /(https?:\/\/[^\s]+)/g;
  //   if (!text) {
  //     return text; // or return an empty string if that makes more sense for your use case
  //   }
  //   return text.replace(urlRegex, '<a style="color:#81A7E4" href="$&" target="_blank">$&</a>');
  // }

  function convertUrlsToLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (!text) {
      return ''; // return an empty string if text is empty or undefined
    }
    const urls = text.match(urlRegex);
    if (!urls) {
      return ''; // return an empty string if no URLs are found
    }
    return urls.map(url => `<a style="color:#81A7E4;" href="${url}" target="_blank">${url}</a> <br/>`).join(' ');
  }


  useEffect(() => {
    if (!Show) props.handle(Show);
    if (props?.goal === null) {
      getTeamGoal();
    }
  }, [Show]);

  useEffect(() => {
    setAttachments(datas);
  }, [datas]);

  useEffect(() => {
    if (stateData?.team?.orgMembers?.data) {
      let optionData = stateData?.team?.orgMembers?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        const data = {
          label: option.first_name ? option.first_name + " " + option.last_name : option?.email,
          value: option.first_name ? option.first_name + " " + option.last_name : option?.email,
          userId: option.id,
        };
        optionList.push(data);
      });
      setOptions(optionList);
    }
  }, [stateData]);

  useEffect(() => {
    setTimeout(() => {
      setFileErrorMessage("");
    }, 4000);
  }, [FileErrorMessage]);

  const changeGoalOption = (userRole) => {
    setDefaultOptions(userRole);
    setGoalAlign(userRole?.userId);

    const data = getGoalById(userRole?.userId, userInfoDetails?.data?.accessToken);
    data?.then(async (res) => {
      if (status?.value === "Active" && res?.data?.data?.status === "Inactive") {
        setWarningMessage("Warning: If you set the task's active state, the parent goal status will also update to active.");
      }
    })
    data?.catch(async (res) => { })

  };

  const getTeamGoal = () => {
    let optionGoalLists = [];
    let orgdata;
    orgdata = getAllGoalsByScope(
      orgId,
      // "organization",
      userInfoDetails?.data?.accessToken,
      true,
      false
    );
    orgdata?.then(async (res) => {
      let optionGoalData = res?.data?.data;
      if (optionGoalData.length !== 0) {
        optionGoalData?.forEach((option) => {
          const data = {
            label: option.name,
            value: option.name,
            userId: option.id,
          };
          optionGoalLists.push(data);
        });
      }
    });
    orgdata?.catch(async (err) => { });
    setGoalsOption(optionGoalLists);
  };

  const task_errors = { ...taskerrorsObj };

  function submitTask(e) {
    e.preventDefault();
    if (createTask?.task_name.length === 0) {
      task_errors.taskName = "Task name is required*";
      settaskErrors(task_errors);
    } else if (createTask?.task_effort.length === 0) {
      task_errors.taskEffort = "Task efforts are required*";
      settaskErrors(task_errors);
    }
    else if (((+createTask?.task_effort) === 0) || ((+createTask?.task_effort) > 20) || ((+createTask?.task_effort) < 0)) {
      task_errors.taskEffort = "Task effort can only include whole numbers between 1 to 20*";
      settaskErrors(task_errors);
    } else if (status == null) {
      setWarningMessage("Task status is required*");

    }
    else {
      const data = createTaskService(
        orgId,
        userInfoDetails.data.id,
        taskOwner.userId,
        createTask,
        taskImpact.value,
        taskControl.value,
        status.value,
        { id: goalAlignId },
        attachment,
        userInfoDetails.data.accessToken
      );
      setSpinner(true);
      data?.then(async (res) => {
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#ffffff",
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#ffffff",
                    fontSize: "18px",
                  }}
                >
                  Your task has been created successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-create",
            className: "toast-success-container toast-success-container-after ",
          }
        )
        if (res.data.message === "Tasks created successfully") {
          settaskErrors("");
          setCreateTaskForm(initialTaskState);
          setTaskOwnerValue("");
          storeTaskCache(res?.data?.data);
          if (res?.data?.data?.id && attachment.length) {
            const data = sendTaskAttachments(res?.data?.data?.id, attachment, userInfoDetails.data.accessToken);
            data?.then(async (res) => {
            })
            data?.catch(async (err) => {
            })
          }
          setShow(false);
          props.setIsTaskCreated(true);
          dispatch(refreshTaskList(true));
          setSpinner(false);
        }
      });
      data?.catch(async (err) => {
        task_errors.taskEffort = err?.response?.data?.message

        // settaskErrors(task_errors);
        toast(
          <>
            <div className="m-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
              <div>
                <h2 style={{
                  fontSize: "22px"
                }}>
                  Failed
                </h2>
                <h3 style={{
                  fontSize: "18px"
                }}>
                  {err?.response?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-create",
            className: 'toast-error-container toast-error-container-after ',
          }
        )
        setShow(false)
        setSpinner(false)
      });
    }
  }

  function closeModal() {
    setTaskOwnerValue("");
    setCreateTaskForm(initialTaskState);
    setTaskImpactValue({ value: "medium", label: "Medium" });
    setTaskControlValue({ value: "medium", label: "Medium" })
    settaskErrors("");
    setShow(false);
    setDatas([]);
    setGoalAlign("");
    setDefaultOptions("")
  }

  function storeTaskCache(task) {
    const post = {
      task: task?.name,
      task_id: task?.id,
      task_description: task?.description,
    };

    const data = postTaskCache(post, userInfoDetails.data.accessToken);
    data?.then(async (res) => {
    });
    data?.catch(async (err) => {
    });
  }

  return (
    <>
      <Modal className="fade bd-example-modal-xl" show={Show} size="xl">
        <Modal.Body className="create-task-modal">
          <div className="text-center mb-2">
            <button
              data-testId="create-task-close"
              variant=""
              className="btn-close  float-end align-center m-2 p-2 btn-danger"
              onClick={() => {
                closeModal();
                setCreateTaskForm(initialTaskState);
              }}
            ></button>
            <h2 className="text-center">Create task</h2>
          </div>
          {goalDetails === null ? (
            ""
          ) : (
            <h3 className="text-center mb-4">
              Aligned goal -{" "}
              <span style={{ color: "#ef331d" }}>{goalDetails?.name}</span>
            </h3>
          )}
          <div>
            <PerfectScrollbar
              id="chartBox"
              className="widget-media dlab-scroll w-100"
              options={{ suppressScrollX: true }}
              style={{ maxHeight: "75vh", minHeight: "15vh" }}
            >
              <Row className="mt-3">
                <Col xs={12} md={6} className="my-2 create-task-col">
                  <div className="form-group mb-3">
                    <label className="h5">Task</label>
                    <input
                      type="text"
                      name="task_name"
                      className="figma-input p"
                      placeholder="Task"
                      required
                      autocomplete="off"
                      data-TestId="task-name"
                      value={createTask.task_name}
                      onChange={handleCreateTaskChange}
                      style={{ paddingLeft: "15px" }}
                    />
                    {
                      <>
                        {taskerrors?.taskName && !createTask.task_name.length && (
                          <span style={{ color: "red" }}>
                            {taskerrors?.taskName}
                          </span>
                        )}
                      </>
                    }
                  </div>
                  <div className="form-group mb-3">
                    <label className="h5">Status</label>
                    <Select
                      name="task_owner"
                      options={statusOptions}
                      className="p"
                      value={status}
                      defaultValue={status}
                      onChange={changeStatusGoal}
                    />
                    {
                      warningMessage &&
                      <span style={{
                        color: "red",
                        width: "320px",
                        wordWrap: "break-word",
                        display: "inline-block",
                        whiteSpace: "normal"
                      }}>{warningMessage}</span>
                    }
                  </div>
                  <div className="form-group mb-3">
                    <label className="h5">Description</label>
                    <textarea
                      name="description"
                      className="figma-input p "
                      placeholder="Enter task description"
                      rows={2}
                      autocomplete="off"
                      value={createTask?.description}
                      data-testId="task-description"
                      onChange={handleCreateTaskChange}
                    />

                    <div clsssName="m-4 mb-3 p-4" style={{ wordWrap: "break-word", padding: "4px" }} dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(createTask?.description) }} />




                    {/* {
                  <>
                    {taskerrors?.description &&
                      !createTask.description?.length && (
                        <span className="m-1" style={{ color: "red" }}>
                          {taskerrors?.description}
                        </span>
                      )}
                  </>
                } */}
                  </div>
                  {/* {goalDetails !== null ? (
                    ""
                  ) : ( */}
                  <div className="mt-3">
                    <div className="form-group mb-3">
                      <h5>Align goal</h5>
                      <Select
                        name="goal_owner"
                        className="p"
                        isClearable
                        options={goalsOption}
                        value={defaultOptions}
                        onChange={changeGoalOption}
                      />
                      {/* {
                              <>
                                {taskerrors?.alignGoal &&
                                  !createTask.alignGoal?.length && (
                                    <span className="m-1" style={{ color: "red" }}>
                                      {taskerrors?.alignGoal}
                                    </span>
                                  )}
                              </>
                            } */}
                    </div>
                  </div>
                  {/* // )} */}
                  <Attachment setAttachments={setAttachments} attachment={attachment} />
                </Col>
                <Col xs={12} md={6} className="my-2">
                  <div class="accordion-item m-2">
                    <Accordion
                      className="accordion accordion-solid-bg"
                      defaultActiveKey="0"
                    >
                      <h2 class="accordion-header h3" id="headingOne">
                        <b>Details</b>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body" style={{ marginBottom: "105px" }}>
                          <div className=" m-0 p-4" >
                            <Row className="form-group mb-2">
                              <Col className="col-sm-5">
                                <h5>Owner</h5>
                              </Col>
                              <Col>
                                <Select
                                  className="p"
                                  data-testId="task-owner"
                                  name="task_owner"
                                  options={options}
                                  defaultValue={taskOwner}
                                  onChange={changeTaskOwner}
                                />
                              </Col>
                            </Row>

                            {/* <Row className="form-group mb-3">
                          <Col className='col-sm-5'>
                            <h4 className="text-black fs-18">Scope</h4>
                          </Col>
                          <Col>
                            <Select
                              name="task_owner"
                              options={scopeOptions}
                              styles={{ padding: "2px", borderRadius: "10px" }}
                              defaultValue={selectedScopes}
                              onChange={changeGoalScopes}
                            />
                          </Col>
                        </Row> 
                          {scopes === 'team' && <Row className="form-group mb-3">
                            <Col className='col-sm-5'>
                              <h4 className="text-black fs-18">Select team</h4>
                            </Col>
                            <Col>
                              <Select
                                name="task_owner"
                                options={teamList}
                                styles={{ padding: "2px", borderRadius: "10px" }}
                                defaultValue={defaultTeamOptions? defaultTeamOptions:""}
                                onChange={storeTeam}
                              />
                            </Col>
                          </Row>
                          } */}


                            <Row className="form-group mb-2">
                              <Col className="col-sm-5">
                                <h5>Effort</h5>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center">
                                  {/* Hours Dropdown */}
                                  <div className="me-2" style={{ flex: 1 }}>
                                    <Select
                                      placeholder="Hours"
                                      value={{ value: hours, label: `${hours} hours` }}
                                      onChange={(option) => handleHoursChange({ target: { value: option.value } })}
                                      options={[...Array(20)].map((_, i) => ({
                                        value: i.toString().padStart(2, "0"),
                                        label: `${i} hours`,
                                      }))}
                                      styles={{
                                        control: (base) => ({
                                          ...base,
                                          minHeight: "40px",
                                        }),
                                        valueContainer: (base) => ({
                                          ...base,
                                          padding: "2px 8px",
                                        }),
                                        singleValue: (base) => ({
                                          ...base,
                                          color: "#000",
                                        }),
                                      }}
                                    />
                                  </div>

                                  {/* Minutes Dropdown */}
                                  <div className="ms-2" style={{ flex: 1 }}>
                                    <Select
                                      placeholder="Minutes"
                                      value={{ value: minutes, label: `${minutes} mins` }}
                                      onChange={(option) => handleMinutesChange({ target: { value: option.value } })}
                                      options={[...Array(60)].map((_, i) => ({
                                        value: i.toString().padStart(2, "0"),
                                        label: `${i} mins`,
                                      }))}
                                      styles={{
                                        control: (base) => ({
                                          ...base,
                                          minHeight: "40px",
                                        }),
                                        valueContainer: (base) => ({
                                          ...base,
                                          padding: "2px 8px",
                                        }),
                                        singleValue: (base) => ({
                                          ...base,
                                          color: "#000",
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>

                                {taskerrors?.taskEffort && (
                                  <span style={{ color: "red" }}>
                                    {taskerrors?.taskEffort}
                                  </span>
                                )}
                              </Col>
                            </Row>



                            {/* <Row className="form-group mb-2">
                            <Col className="col-sm-5">
                              <h5>Effort in hours</h5>
                            </Col>
                            <Col>
                                <input
                                  data-TestId="efforts-in-hours"
                                  type="string"
                                  className="figma-input-num p"
                                  name="task_effort"
                                  placeholder="Enter hours"
                                  // required
                                  autocomplete="off"
                                  value={taskEffortValue}
                                  onChange={handleCreateTaskEffortChange}
                                  style={{ paddingLeft: "10px" }}
                                  step="any"
                                  min="0"
                                />
                            </Col>
                            </Row>  */}
                            <Row className="">
                              <Col className="col-sm-5"></Col>
                              <Col>
                                {/* {(taskerrors?.taskEffort) && (
                                  <span className="m-0 p-0 " style={{ color: "red" }}>
                                    {taskerrors?.taskEffort}
                                  </span>
                                )} */}
                              </Col>
                            </Row>
                            <Row className="form-group mb-3" >

                              <Col className="col-sm-5">
                                <h5>Impact</h5>
                              </Col>
                              <Col>
                                <Select
                                  data-testId="task-impact"
                                  name="task_impact"
                                  className="p"
                                  options={taskImpactOptions}
                                  defaultValue={taskImpactOptions[1]}
                                  value={createTask.task_impact}
                                  onChange={changeTaskImpact}
                                />
                              </Col>

                            </Row>

                            <Row className="form-group mb-3" >

                              <Col className="col-sm-5">
                                <h5>Control</h5>
                              </Col>
                              <Col>
                                <Select
                                  data-testId="task-impact"
                                  name="task_control"
                                  className="p"
                                  options={taskControlOptions}
                                  defaultValue={taskControlOptions[1]}
                                  // value={createTask.task_impact}
                                  onChange={changeTaskContol}
                                />
                              </Col>

                            </Row>

                            {/* <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task pulse </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task status </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task type </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task completed </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task accepted</h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row> */}
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>

                </Col>
              </Row>
              <div >
                {/* <Accordion className="accordion accordion-solid-bg">
                <div className="accordion-header h3" style={{ display:"flex", justifyContent:"space-between"}}>
                    <h4>Advanced Details</h4>
                    <div >
                  {toggleData ? (
                      <i
                        title="Close"
                        class="bi bi-caret-up-fill fs-24"
                        onClick={() => {
                          setToggleData(!toggleData);
                         
                        }}
                      ></i>
                    ) : (
                      <i
                        title="Open"
                        class="bi bi-caret-down-fill fs-24"
                        onClick={() => {
                          setToggleData(!toggleData);
                          
                        }}
                      ></i>
                    )}
                    </div>
                  </div>
             {toggleData &&    <div class="accordion-body" style={{border:"1px solid hsl(0, 0%, 80%)", padding:"1.5rem", borderTop:"none"}}>
                  <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task pulse </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task status </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task type </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task completed </h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col className="col-sm-5">
                                <h5>Task accepted</h5>
                              </Col>
                              <Col>
                                <b>:</b>&nbsp;&nbsp;
                                <span className="p">{"na"}</span>
                              </Col>
                            </Row>
                  </div>}
                </Accordion> */}
              </div>
              <div className="text-end p-0 mt-4 ">
                <button
                  type="submit"
                  data-testId="submit-task"
                  className="figma-btn text-white float-right create-task-button task-btn mt-4"
                  disabled={spinner}
                  style={{ color: spinner ? "white" : "black" }}
                  onClick={(e) => submitTask(e)}
                >
                  Create task &nbsp;&nbsp;{" "}
                  {spinner && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                </button>
              </div>
            </PerfectScrollbar>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaskModal;
