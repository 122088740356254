import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next"; // Import i18n from i18next
import PortalApp from "./jsx/layouts/Portal/PortalApp";
import { Helmet, HelmetProvider } from 'react-helmet-async'; 

// Initialize i18n
i18n.init({
  resources: {
    // Define your translation resources here
    // For example:
    en: {
      translation: {
        hello: "Hello, World!",
      },
    },
  },
  lng: "en", // Set the default language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});
const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Corporation",
  "name": "Plansom",
  "url": "https://plansom.com",
  "logo": "https://plansom-production-bucket.s3.amazonaws.com/plansom/plansom_logo.png",
  "description": "Plansom specializes in AI-powered project management, offering tools for strategy setting, delegation, prioritization, and team alignment.",
};

const websiteSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://plansom.com",
  "headline": "Double Your Productivity with AI Project Management",
  "description": "Deploy best of breed strategies quickly with AI. Plansom makes delegation easy and helps you prioritize the most important work first.",
};

const productSchema = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "AI Strategy Setting Tool",
  "description": "Leverage generative AI to set best of breed strategies for any business challenge quickly. Features single-click deployment across your organization.",
  "brand": "Plansom",
  "offers": {
    "@type": "Offer",
    "price": "14.99",
    "priceCurrency": "USD",
  },
};

const serviceSchema = {
  "@context": "https://schema.org",
  "@type": "Service",
  "serviceType": "AI Project Management",
  "provider": {
    "@type": "Corporation",
    "name": "Plansom",
  },
  "description": "Plansom's AI Project Management service includes strategy setting, delegation, prioritization, and team alignment with AI support.",
};

const portalRoot = ReactDOM.createRoot(document.getElementById("portal-root"));
// return ReactDOM.createPortal(, portalRoot);
portalRoot.render(<Provider store={store}><Router basename="/"><PortalApp /> </Router></Provider >);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //  <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <CookiesProvider>
      <Provider store={store}>
        <SimpleReactLightbox>
          <Router >
            <ThemeContext>
            <HelmetProvider>
                <Helmet>
                  {/* Add/update meta tags here */}
                  <meta name="description" content="Deploy best of breed strategies quickly with AI. Plansom makes delegation easy and helps you prioritize the most important work first. Free trial." />
                  {/* <Helmet> */}
                <script type="application/ld+json">
                  {JSON.stringify(organizationSchema)}
                </script>
                <script type="application/ld+json">
                  {JSON.stringify(websiteSchema)}
                </script>
                <script type="application/ld+json">
                  {JSON.stringify(productSchema)}
                </script>
                <script type="application/ld+json">
                  {JSON.stringify(serviceSchema)}
                </script>
              {/* </Helmet> */}
                </Helmet>
                <App />
              </HelmetProvider>
           
            </ThemeContext>
          </Router>
        </SimpleReactLightbox>
      </Provider>
    </CookiesProvider>
  </I18nextProvider>
  //  </React.StrictMode>
);
reportWebVitals();
