import React, { useState, useEffect, lazy } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { getAllTrackObjectives } from "../../../services/GoalService";
import { getHomeGraph } from "../../../services/GoalService";
// import CompleteChart from "../Chartjs/CompleteChart";
// import OnTimeChart from "../Chartjs/OnTimeChart";
// import WinsChart from "../Chartjs/WinsChart";
// import TaskWorkTable from "./TaskWorkTable";
// import BarChartObjective from "../Chartjs/BarChartObjective";
// import PieNewChart from "../Chartjs/PieNewChart";

const CompleteChart = lazy(()=>import("../Chartjs/CompleteChart"))
const OnTimeChart = lazy(()=>import("../Chartjs/OnTimeChart"))
const WinsChart = lazy(()=>import("../Chartjs/WinsChart"))
const TaskWorkTable = lazy(()=>import("./TaskWorkTable"))
const BarChartObjective = lazy(()=>import("../Chartjs/BarChartObjective"))
const PieNewChart = lazy(()=>import("../Chartjs/PieNewChart"))


const TrackObjective = React.memo(({ scopes }) => {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const scope = localStorage.getItem("scope");
  const timeScope = localStorage.getItem("timescope");
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const org_id = localStorage.getItem("organization_id");
  const team_id = localStorage.getItem("team_id");
  const location = useLocation();
  const navigate = useNavigate();
  const [allParentGoals, setAllParentGoals] = useState([]);
  console.log(allParentGoals?.length == 0,"allParentsGoal")
  const [allSubGoals, setAllSubGoals] = useState();
  console.log(allSubGoals, "99999999999999999455555555")
  const [selectedGoalType, setSelectedGoalType] = useState('');
  
  console.log(selectedGoalType, "scopesselectedGoalType")
  const [selectedSubGoalType, setSelectedSubGoalType] = useState(null);
  const [graphData, setGraphData] = useState([])
  const [defaultTime, setDefaultTime] = useState("1 month");
  const [trackScope, setTrackScope] = useState("objective")

  console.log(allParentGoals[0], "allParentGoals")

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
  }, []);

  useEffect(() => {
    getGoals(org_id, scope, userInfoDetails?.data?.accessToken, "");
  }, []);

  useEffect(()=> {
    setSelectedGoalType(allParentGoals[0])
  },[allParentGoals])

  // useEffect(() => {
  //   // getGraph()
  //   setSelectedGoalType(selectedGoalType);
  //   setSelectedSubGoalType(selectedSubGoalType);
  // }, [selectedSubGoalType, selectedGoalType]);

  useEffect(() => {
    // if (location && location.state && location?.state?.refresh === true) {
    setRefreshGraph(true);
    // }
    let goal_ID = selectedSubGoalType?.value
      ? selectedSubGoalType?.value
      : selectedGoalType?.value
        ? selectedGoalType?.value
        : "";

    getGoals(org_id, scope, userInfoDetails?.data?.accessToken, goal_ID);
  }, [location?.state, scope, timeScope, team_id]);

  const getGoals = (org_id, scope, token, goal_id) => {
    const data = getAllTrackObjectives(goal_id, token, scope);
    data?.then((res) => {
      console.log("res?.data?.data", res?.data?.data?.goals);

      if (!goal_id) {
        console.log("kkkkkkknnnnnnkkkkknnkkkkkkkkkkkkif")
        let parentgoalData = [];
        res?.data?.data?.goals.forEach((ele) => {
          parentgoalData.push({ value: ele?.id, label: ele?.name });
        });
        setAllParentGoals(parentgoalData);
      } else {
        console.log(res?.data?.data?.sub_goal,"kkkkkkknnnnnnkkkkknnkkkkkkkkkkkkelse")
        if (goal_id) {
          let subgoalData = [];
          res?.data?.data?.sub_goal.forEach((ele) => {
            subgoalData.push({ value: ele?.id, label: ele?.name });
          });
          console.log(subgoalData, "kkkkkkknnnnnnkkkkknnkkkkkkkkkkkk")
          setAllSubGoals(subgoalData);
        }
      }
    });
    data?.catch((err) => { });
  };

  const changeGoalTypeHandler = (selectedGoal) => {
    console.log("selectedGoal", selectedGoal);
    getGoals(
      org_id,
      scope,
      userInfoDetails?.data?.accessToken,
      selectedGoal?.value
    );
    setSelectedGoalType(selectedGoal);
    setSelectedSubGoalType("")
    setRefreshGraph(true);
  };

  const changeSubGoalTypeHandler = (selectedGoal) => {
    // getGoals(
    //   org_id,
    //   scope,
    //   userInfoDetails?.data?.accessToken,
    //   selectedGoal?.value
    // );
    setSelectedSubGoalType(selectedGoal);
    setRefreshGraph(true);
  };

  useEffect(() => {
    // setSelectedGoalType(null);
    setSelectedSubGoalType(null);

    setAllParentGoals([]);
  }, [scope, team_id]);



  let goal_id = selectedSubGoalType?.value
  ? selectedSubGoalType?.value
  : selectedGoalType?.value
    ? selectedGoalType?.value
    : "";


    console.log(goal_id, selectedGoalType?.value,"888888888888888888777777777777777")
  // const getGraph = () => {
    
  //   const data = getHomeGraph( "",
  //   userInfoDetails?.data?.accessToken,
  //   "",
  //   goal_id,
  //   trackScope);
  //   data?.then(async (res) => {
  //     if (res?.data?.data[0]?.completion_percent === 0) {
  //       res?.data?.data?.shift();
  //     }
  //     setGraphData(res?.data?.data);
  //   });
  //   data?.catch(async (err) => {
  //     console.log("error getHomeGraph", err);
  //   });
  // };

  useEffect(() => {
    // getGraph();
  
    setDefaultTime("");
    setRefreshGraph(!refreshGraph);
  }, [
    org_id,
    localStorage.getItem("scope"),
    localStorage.getItem("timescope"),
    localStorage.getItem("startDate"),
    localStorage.getItem("endDate"),
    // width,
  ]);
useEffect(()=>{
// getGraph()
},[])


  return (
    <>
    <div>
      <Row className="" style={{marginLeft:"0.4px", marginRight:"0.4px"}}>
        <Card style={{borderRadius:"0px 0px 0px 0px"}}>
        <div className="col-md-6"  >
        </div>
        <div className="col-md-6 float-end" data-testid="parent-goal-dropdown">
          <div className="row">
            <div className="col-md-6 mr-5 mb-4">
              <div className="mb-2" style={{marginLeft:"10px !important"}}>
                
                {/* <Select
                  style={{ zIndex: "22222 !important" }}
                  className="p fs-14 w-100"
                  name="goalType"
                  // placeholder="Please select goal type..."
                  options={allParentGoals}
                  styles={{ padding: "2px", borderRadius: "10px" }}
                  value={ selectedGoalType }
                  // value={allParentGoals && allParentGoals.length > 0 ? allParentGoals[0] : null}
                  onChange={changeGoalTypeHandler}
                  isClearable
                  required
                /> */}

                   <Select
                    placeholder="Please select goal type..."
                    onChange={changeGoalTypeHandler}
                    defaultValue={allParentGoals ? allParentGoals[0] : null}
                    value={selectedGoalType}
                    className="p add-member-select"
                    options={allParentGoals}
                   />
              </div>
            </div>

            {selectedGoalType?.value && (
              <div className="col-md-6">
                <div className="mb-2 ">
                  <Select
                    style={{ zIndex: "22222 !important" }}
                    className="p fs-14 w-100"
                    name="goalType"
                    placeholder="Please select sub-goal type..."
                    options={allSubGoals}
                    styles={{ padding: "2px", borderRadius: "10px" }}
                    value={selectedSubGoalType}
                    onChange={changeSubGoalTypeHandler}
                    isClearable
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        </Card>
      </Row>
      {allParentGoals?.length == 0&& <div>
        <Card>
          <center>
            <p className="mt-3 p-2">
            Please create goal to see the graph
            </p>
          </center>
        </Card>
        </div> }
{allParentGoals?.length !== 0 && <div>



      <Row>
       
        <CompleteChart
            refresh={refreshGraph}
            // week={weeks}
            graphData={graphData}
            scope={scopes}
            trackScope = {"objective"}
            goal_id = {goal_id}
          />
            <OnTimeChart
           refresh={refreshGraph}
           // week={weeks}
           graphData={graphData}
           scope={scopes}
           trackScope = {"objective"}
           goal_id = {goal_id}
           />
           <WinsChart
           refresh={refreshGraph}
           // week={weeks}
           graphData={graphData}
           scope={scopes}
           trackScope = {"objective"}
           goal_id = {goal_id}
           />
    
          </Row>

          <TaskWorkTable scope={scopes}
           trackScope = {"goal"}
           goal_id = {goal_id}></TaskWorkTable>
             <Row>
<PieNewChart scope={scopes}
           trackScope = {"objective"}
           goal_id = {goal_id}>      </PieNewChart>
           <BarChartObjective scope={scopes}
           trackScope = {"objective"}
           goal_id = {goal_id}></BarChartObjective>
         
           </Row>
           </div>}
      </div>
    </>
  );
});

export default TrackObjective;
