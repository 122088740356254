import React, { useState, useEffect, useRef } from "react";
import { memo } from "react";
import { Dropdown, Modal, Row } from "react-bootstrap";
import Chat from "../../Chat/Chat";
import { Link } from "react-router-dom";
import profilePicture from "../../../images/profile/profilePic.png";
import {
  updateThread,
  deleteRoomWithId,
  uploadImageToThread,
} from "../../../services/messageService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "../../Chat/Notification";
import logo from "../../../images/PlansomLogo2.png";
import Vector3 from "../../../images/vector/Vector3.png";
import Threadmember from "../Component/Threadmember";
import { getThreadList } from "../../../store/actions/NotificationAction";
import { useDispatch } from "react-redux";

const ChatRoom = (props) => {
  const fileRef = useRef();
  const dispatch = useDispatch();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [addMember, setAddMember] = useState(false);
  const [EditRoom, setEditRoom] = useState(false);
  const [selectedUser, setSelectedUser] = useState(props);
  const [RoomName, setRoomName] = useState();
  const [file, setFile] = useState();
  const [files, setFiles] = useState(file);
  const [profileUser, setProfleUser] = useState()
  const [isUpdate, setUpdate] = useState(false);
  const [UpdatedData, setUpdatedData] = useState("");
  const [messages, setMessages] = useState(props.message);
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [threadName, setThreadName] = useState(props?.user?.thread_topic);

  useEffect(() => {
    setThreadName(threadName);
  }, [threadName]);

  useEffect(() => {
    setSelectedUser(props);
    setThreadName(props?.user?.thread_topic);
    setFiles(props?.user?.thread_image);
    setProfleUser(props?.user?.initiator?.profile_picture)
  }, []);

  useEffect(() => {
    setSelectedUser(() => props);
    setMessages(props.message);
    setRoomName(JSON.parse(localStorage.getItem("ActiveChat"))?.thread_topic);
    if (JSON.parse(localStorage.getItem("ActiveChat"))?.thread_topic)
      setThreadName(
        JSON.parse(localStorage.getItem("ActiveChat"))?.thread_topic
      );
    setFiles(JSON.parse(localStorage.getItem("ActiveChat"))?.thread_image);
    setProfleUser(JSON.parse(localStorage.getItem("ActiveChat"))?.initiator?.profile_picture)
  }, [files, threadName]);

  useEffect(() => {
    setSelectedUser(() => props);
    if (JSON.parse(localStorage.getItem("ActiveChat"))?.thread_topic)
      setThreadName(
        JSON.parse(localStorage.getItem("ActiveChat"))?.thread_topic
      );
    setFiles(JSON.parse(localStorage.getItem("ActiveChat"))?.thread_image);
    setProfleUser(JSON.parse(localStorage.getItem("ActiveChat"))?.initiator?.profile_picture)
  }, [selectedUser, props, JSON.parse(localStorage.getItem("ActiveChat"))]);

  useEffect(() => {
    setSelectedUser(() => selectedUser)
  }, [selectedUser])


  const handleDeleteRoomClick = async (event) => {
    event.preventDefault();
    const data = deleteRoomWithId(
      selectedUser.user.thread_id,
      userInfoDetails.data.accessToken
    );
    data?.then(
      await ((res) => {
        toast(
          <>
            <div className="m-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
              <div>
                <h2 style={{
                  fontSize: "22px"
                }}>
                  Completed
                </h2>
                <h3 style={{
                  fontSize: "18px"
                }}>
                  Your thread has been deleted successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "thread-delete",
            className: 'toast-success-container toast-success-container-after ',
          }
        )
        localStorage.removeItem("ActiveChat");
        selectedUser.handler("delete");
        getMessagingList();
      })
    );
    data?.catch(

    );
    SetOpenDeleteModal(false);
  };

  const getMessagingList = () => {
    const start = 0;
    const end = 10;
    dispatch(
      getThreadList(
        localStorage.getItem("organization_id"),
        start,
        end,
        userInfoDetails.data.accessToken
      )
    );
    localStorage.removeItem("ActiveChat");
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const data = updateThread(
      selectedUser.user.thread_id,
      RoomName,
      userInfoDetails.data.accessToken
    );
    data?.then(
      await ((res) => {
        selectedUser.handler("member");
        setThreadName(res?.data?.data?.thread_topic);
        let daata = JSON.parse(localStorage.getItem("ActiveChat"));
        daata.thread_topic = res?.data?.data?.thread_topic;
        localStorage.setItem("ActiveChat", JSON.stringify(daata));
        toast(
          <>
            <div className="m-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
              <div>
                <h2 style={{
                  fontSize: "22px"
                }}>
                  Completed
                </h2>
                <h3 style={{
                  fontSize: "18px"
                }}>
                  Your thread details has been updated successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "thread-update",
            className: 'toast-success-container toast-success-container-after ',
          }
        )
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error user org list");
      })
    );
  };

  useEffect(() => {
    if (isUpdate) {
      selectedUser.handler(UpdatedData);
      setUpdate(false);
    }
  }, [isUpdate]);

  const isChatUpdate = (data) => {
    setUpdate(true);
    setUpdatedData(data);
  };

  return (
    <>
      <Modal
        data-testId="delete-modal"
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="Chat-room" title="Chat-room" src={Vector3} />
          <h4 className="fs-20 p-2">Are you sure you want to delete room?</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this room!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/messages"
            onClick={() => SetOpenDeleteModal(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={(e) => {
              handleDeleteRoomClick(e);
              SetOpenDeleteModal(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal data-testId="edit-modal" className="fade bd-example-modal-lg" show={EditRoom} size="md">
        <Modal.Body>
          <div className="text-center mb-4">
            <button
              variant=""
              className="btn-close float-end align-center m-2 p-2 btn-danger "
              onClick={() => {
                setEditRoom(false);
              }}
            ></button>
            <h2 className="text-center">Edit thread</h2>
          </div>
          <Row className="mx-1">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleChange(e);
                setEditRoom(false);
              }}
            >
              <Row className="d-flex justify-content-between">
                <Row className="mt-3 m-0">
                  <label className="h5">Thread topic</label>
                  <input
                    type="text"
                    name="roomName"
                    className="figma-input py-4"
                    placeholder="Set a thread name"
                    autocomplete="off"
                    value={RoomName}
                    onChange={(event) => setRoomName(event.target.value)}
                  />
                </Row>
                <div className="mt-3 text-center">
                  <button type="submit" className="figma-btn ">
                    Save
                  </button>
                </div>
              </Row>
            </form>
          </Row>
        </Modal.Body>
      </Modal>

      {addMember && (
        <Threadmember
          addMember={addMember}
          user={props}
          setAdd={setAddMember}
          handler={isChatUpdate}
        />
      )}

      {selectedUser?.user?.thread_type !== "notification" ? (
        <>
          <div className="d-flex justify-content-between align-items-center border-bottom px-2 pt-0 flex-wrap">
            {selectedUser?.user?.thread_type !== "group" ? (
              <div className="d-flex align-items-center pb-2">
                <div className="chat-img d-flex align-items-start">
                  <img
                    src={file || files ? files : profileUser ? profileUser : profilePicture}
                    alt="notification-img"
                    title="notification-img"
                    style={{ height: "50px", width: "50px", cursor: "pointer" }}
                    className="plansom-notification-img"
                    onClick={() => fileRef.current.click()}
                  />
                  <p
                    className="m-0 p-0"
                    style={{ left: "0px", bottom: "-50px" }}
                  >
                    <i
                      className="fas fa-pen"
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                      style={{
                        fontSize: "10px",
                        padding: "3px",
                        color: "black",
                        backgroundColor: "#eeeeee",
                      }}
                      onClick={() => fileRef.current.click()}
                    ></i>
                  </p>
                </div>
                <input
                  ref={fileRef}
                  onChange={(e) => {
                    const data = uploadImageToThread(
                      selectedUser?.user?.thread_id,
                      e.target.files[0],
                      userInfoDetails.data.accessToken
                    );
                    data?.then((res) => {
                      setFiles(res?.data?.data?.thread_image);
                      setProfleUser(res?.data?.data?.initiator?.profile_picture)
                      let daata = JSON.parse(
                        localStorage.getItem("ActiveChat")
                      );
                      getMessagingList();
                      daata.thread_image = res?.data?.data?.thread_image;
                      localStorage.setItem("ActiveChat", JSON.stringify(daata));

                      toast(
                        <>
                          <div className="m-1 d-flex">
                            <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
                            <div>
                              <h2 style={{
                                fontSize: "22px"
                              }}>
                                Completed
                              </h2>
                              <h3 style={{
                                fontSize: "18px"
                              }}>
                                Profile image for thread has been updated successfully!
                              </h3>
                            </div>
                          </div>
                        </>,
                        {
                          toastId: "thread-img-update",
                          className: 'toast-success-container toast-success-container-after ',
                        }
                      )
                      selectedUser.handler(true);
                    });
                    data?.catch((err) => { });
                  }}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  hidden
                />
                <div className="ms-2">
                  <h4 className="fs-20 font-w700 thread-name" style={{ overflowWrap: 'break-word', fontSize: '16px', maxWidth: '100%' }}>
                    {threadName?.length > 15 && window.screen.width <= 768 ? `${threadName?.substring(0, 15)}...` : threadName}
                  </h4>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center p-0 m-0 pb-2 ">
                <div className=" m-0 p-0 chat-img d-flex align-items-start">
                  {file || files ? (
                    <img
                      src={file || files ? files : profileUser}
                      alt="profile-img"
                      title="profile-img"
                      style={{
                        height: "50px",
                        width: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => fileRef.current.click()}
                    />
                  ) : profileUser ? (
                    <img
                      src={profileUser}
                      alt="profile-img"
                      title="profile-img"
                      style={{
                        height: "50px",
                        width: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => fileRef.current.click()}
                    />
                    // <i
                    //   className="fa fa-users pt-2"
                    //   aria-hidden="true"
                    //   style={{
                    //     cursor: "pointer",
                    //     fontSize: "35px",
                    //     color: "#878484",
                    //   }}
                    // ></i>
                  ) : (
                    <i
                      className="fa fa-users pt-2"
                      aria-hidden="true"
                      style={{
                        cursor: "pointer",
                        fontSize: "35px",
                        color: "#878484",
                      }}
                    ></i>
                  )}
                  <p className="m-0 p-0" style={{ left: "0px", top: "0px" }}>
                    <i
                      className="fas fa-pen"
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                      style={{
                        fontSize: "10px",
                        padding: "3px",
                        color: "black",
                        backgroundColor: "#eeeeee",
                      }}
                      onClick={() => fileRef.current.click()}
                    ></i>
                  </p>
                </div>
                <input
                  ref={fileRef}
                  onChange={(e) => {
                    const data = uploadImageToThread(
                      selectedUser?.user?.thread_id,
                      e.target.files[0],
                      userInfoDetails.data.accessToken
                    );
                    data?.then((res) => {
                      setFile(res?.data?.data?.thread_image);
                      selectedUser.handler();
                      setFiles(res?.data?.data?.thread_image);
                      setProfleUser(res?.data?.data?.initiator?.profile_picture)
                      let daata = JSON.parse(
                        localStorage.getItem("ActiveChat")
                      );
                      getMessagingList();
                      daata.thread_image = res?.data?.data?.thread_image;
                      localStorage.setItem("ActiveChat", JSON.stringify(daata));
                      toast(
                        <>
                          <div className="m-1 d-flex">
                            <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
                            <div>
                              <h2 style={{
                                fontSize: "22px"
                              }}>
                                Completed
                              </h2>
                              <h3 style={{
                                fontSize: "18px"
                              }}>
                                Profile image for thread has been updated successfully!
                              </h3>
                            </div>
                          </div>
                        </>,
                        {
                          toastId: "thread-img-update",
                          className: 'toast-success-container toast-success-container-after ',
                        }
                      )
                    });
                    data?.catch((err) => { });
                  }}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  hidden
                />
                <div className="ms-2">
                  <h4 className="fs-20 font-w700 thread-name" style={{ overflowWrap: 'break-word', fontSize: '16px', maxWidth: '100%' }}>
                    {threadName?.length > 15 && window.screen.width <= 1024 ? `${threadName?.substring(0, 15)}...` : threadName}
                  </h4>
                </div>
              </div>
            )}
            <div className="activity d-flex align-items-center pb-3">
              <Dropdown className="dropdown ms-2" label="Toggle Dropdown">
                <Dropdown.Toggle as="div" data-testId="toggle-dropdown" className="btn-link i-false" style={{ cursor: "pointer" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#999999" />
                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#999999" />
                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#999999" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right m-0 p-0" data-testId="menu-dropdown">
                  {selectedUser?.user?.initiator?.id ===
                    userInfoDetails?.data?.id && (
                      <>
                        <Dropdown.Item
                          className="px-4"
                          data-testId="click-delete"
                          onClick={(e) => {
                            SetOpenDeleteModal(true);

                          }}
                        >
                          <i className="bi bi-trash text-red me-1 h4"></i>
                          <span>Delete</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          data-testId="click-Edit"
                          className="px-4"
                          onClick={() => {
                            setRoomName(selectedUser.user.thread_topic);
                            setEditRoom(true);
                          }}
                        >
                          <i className="bi bi-pencil text-black me-1 h4"></i>
                          <span>Edit</span>
                        </Dropdown.Item>
                      </>
                    )}
                  <Dropdown.Item
                    className="px-4"
                    data-testId="set-member"
                    onClick={() => {
                      setAddMember(true);
                    }}
                  >
                    <i
                      className="bi bi-people-fill me-2 h4"
                      style={{ color: "#09BD3C" }}
                    ></i>
                    <span>Member</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {selectedUser && (
            <>
              <Chat
                props={selectedUser}
                message={messages}
                ChatUpdate={isChatUpdate}
                orgMember={props.orgMember}
              />
            </>
          )}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center border-bottom px-2 pt-0 flex-wrap">
            <div className="d-flex align-items-center">
              <div className="chat-img d-flex align-items-start">
                <img
                  src={logo}
                  alt="chat-img"
                  title="chat-img"
                  style={{ height: "50px", width: "50px" }}
                  className="mb-2"
                />
              </div>
              <div className="ms-2">
                <h4 className="h3">{selectedUser?.user?.thread_topic}</h4>
              </div>
            </div>
          </div>
          {selectedUser?.user?.thread_id === "0000-0000-0000-0000" && (
            <Notification props={selectedUser} ChatUpdate={isChatUpdate} />
          )}
        </>
      )}
    </>
  );
};

export default memo(ChatRoom);
