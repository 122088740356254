import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { getDataForCalender } from '../../../services/CalendarService';
import { getUserdetails } from '../../../services/AuthService';
import { rescheduledTask } from '../../../services/TaskService';
import { RingLoader } from 'react-spinners'; // Import Spinner component from react-spinners

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const MyCalendar = ({ taskDetailsinfo, startDate, setShowCalendar, userDeatils, taskOwner, setTaskRescheduledAt }) => {
  console.log("abcdefgh 1",taskDetailsinfo)
  console.log("abcdefgh 2",startDate)
  console.log("abcdefgh 3",setShowCalendar)
  console.log("abcdefgh 4",userDeatils)
  console.log("abcdefgh 5",taskOwner)
  console.log("abcdefgh 6",setTaskRescheduledAt)
  const taskScheduleDate = new Date(taskDetailsinfo?.task_schedule); // Convert task_schedule to Date object
  const [currentDate, setCurrentDate] = useState(taskScheduleDate); // State to track current date
  const [userDetails, setUserDetails] = useState({});
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State variable to track loading state
  const userInfoDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    getCalendarData(currentDate);
    getUserdetail();
  }, [currentDate]);

  const getUserdetail = () => {
    const data = getUserdetails(userDeatils?.data?.id, userDeatils?.data?.accessToken);
    data?.then((res) => {
      setUserDetails(res?.data?.data);
    }).catch((err) => { }).finally(() => {
      // setIsLoading(false); // Stop loading spinner when user details are fetched
    });
  };

  const getCalendarData = (date) => {
    const month = date.getMonth() + 1; // Month is zero-based
    const year = date.getFullYear();
    let selectedUsersId = [userInfoDetails?.data?.id];
    let selectedTaskStatus = ["scheduled", "completed"];
    setIsLoading(true);
    const response = getDataForCalender(
      month,
      year,
      [taskOwner?.userId],
      selectedTaskStatus,
      userInfoDetails?.data?.accessToken
    );

    response.then((res) => {
      let appointments = [];
      res?.data?.data.forEach(element => {
        appointments.push({
          id: element?.task?.id,
          title: element?.text,
          start: new Date(element?.startDate?.dateTime),
          end: new Date(element?.endDate?.dateTime),
          color: element?.task?.id === taskDetailsinfo?.id ? 'rgb(248, 177, 177)' : '', // Change 'SPECIFIC_TASK_ID' to the actual ID
          textColor: element?.task?.id === taskDetailsinfo?.id ? 'rgb(216, 9, 9)' : '', // Set text color
          borderColor: element?.task?.id === taskDetailsinfo?.id ? 'rgb(216, 9, 9)' : '', // Set border color
        });
      });
      setIsLoading(false);
      setEvents(appointments);
    }).catch(async (err) => { }).finally(() => {
      setIsLoading(false); // Stop loading spinner when API call is complete
    });
  };

  const isSlotOverlapping = (start, end, eventId) => {
    return events.some(event =>
      event.id !== eventId && (start < event.end && end > event.start)
    );
  };

  const onEventDrop = ({ event, start, end }) => {
    if (isSlotOverlapping(start, end, event.id)) {
      alert('Cannot drop event into a scheduled time slot.');
      return;
    }
    scheduleTask(event?.id, userInfoDetails.data?.accessToken, start);
    const updatedEvent = { ...event, start, end };
    const nextEvents = events.map(existingEvent =>
      existingEvent.id === event.id ? updatedEvent : existingEvent
    );
    setEvents(nextEvents);
  };

  const scheduleTask = (taskId, token, start) => {
    const data = rescheduledTask(taskId, token, start);

    data?.then(async (res) => {
      setTaskRescheduledAt(start);
      console.log(res, "Task rescheduled successfully");
    }).catch(async (err) => {
      console.error(err, "Error rescheduling task");
    });
  };

  const slotPropGetter = (date) => {
    const isScheduled = events.some(event =>
      (date >= event.start && date < event.end) ||
      (moment(date).add(1, 'minute').toDate() > event.start && date < event.end)
    );

    if (isScheduled) {
      return {
        className: 'disabled-slot',
        style: {
          backgroundColor: '#e0e0e0',
          pointerEvents: 'none',
        }
      };
    }

    return {};
  };

  const eventPropGetter = (event) => {
    const backgroundColor = event.color;
    const color = event.textColor;
    const borderColor = event.borderColor;
    return { style: { backgroundColor, color, borderColor, borderStyle: 'solid' } };
  };

  const parseTimeString = (timeString) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    return date;
  };

  const handleNavigate = (date, view, action) => {
    setCurrentDate(date);
  };

  return (
    <div className="" style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '5px', position: 'relative' }}>
      <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5 className="card-title" style={{ margin: 0 }}>Reschedule your task</h5>
        <button
          style={{ backgroundColor: 'white', border: 'none', color: 'black', padding: '5px 5px', cursor: 'pointer' }}
          onClick={() => setShowCalendar(false)}
        >
          &times;
        </button>
      </div>
      <div className="card-body">
        {isLoading && (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <RingLoader color="#ff0000" size={100} loading />
          </div>
        )}
        <DnDCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onEventDrop={onEventDrop}
          min={userDetails?.start_time ? parseTimeString(userDetails.start_time) : new Date()} // 8 AM
          max={userDetails?.end_time ? parseTimeString(userDetails.end_time) : new Date()} // 6 PM
          defaultView="week"
          defaultDate={taskScheduleDate} // Use the converted Date object here
          slotPropGetter={slotPropGetter}
          eventPropGetter={eventPropGetter} // Add this line
          onNavigate={handleNavigate} // Handle navigation
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <button
            className="figma-btn text-white task-update-button"
            onClick={() => setShowCalendar(false)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;
