import React, { useContext, useState, useEffect, useRef } from "react";
import { ChatbotContext } from "../Portal/ChatboatContext";
import {
  getCSVFile,
  getAllBotQuestions,
} from "../../../services/messageService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, json } from "react-router-dom";
import MessageSeconNotification2 from "../../../../src/sound/MessageSeconNotification2.mp3"
import { toast } from "react-toastify";
import { makeDefaultTemplate } from "../../../store/actions/TaskActions";


const Chatbot = (props) => {
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [retryCount, setRetryCount] = useState(0);
  const lastMessageRef = useRef(null);
  const [messages, setMessages] = useState([]);

  console.log(messages,"messages")
  const stateData = useSelector((state) => state);
  const [user, setUser] = useState(false);
  const [botQuestions, setBotQuestions] = useState([]);
  console.log(botQuestions,"44444444")
  const [showFullText, setShowFullText] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [retryingSecond, setRetryingSecond] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [answerQuestion, setAnswerQuestion] = useState([])

  const [token, setToken] = useState("");
  const [goal, setGoal] = useState(null);
  const [csvFileUrl, setCsvFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isloading, setisloading] = useState(false);
  console.log("IS LOADING", isLoading)
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const { chatbotShow, updateState } = useContext(ChatbotContext);
  const [showMoreStrategiesMessage, setShowMoreStrategiesMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, seErrorMessage] = useState("AI processing for the strategy is complete. You'll be notified via email shortly");
  const [loggedInUser, setLoggedIn] = useState(localStorage?.getItem("loggedIn"));
  // let retryCount = 0;
  const [limitMessage, SetLimitMessage] = useState(false);
  const [isLoadingQuestions, setIsLoadinQuestions] = useState(false);
  const [invalidAnswers, setInvalidAnswers] = useState(false);
  const [success, setSuccess] = useState(false)
  const [orgId, setOrgId] = useState(localStorage.getItem('organization_id'));
  console.log(orgId,"orgId")
  // const [gettingQuestionAnswer,SetGettingQuestionAnswer ] = useState(localStorage.getItem('answerQuestion'))
  const storedValue = localStorage.getItem('answerQuestion');
const defaultValue = JSON.stringify( [])

const [questionsLength, SetQuestionsLength] = useState()


const [gettingQuestionAnswer, setGettingQuestionAnswer] = useState(
  storedValue !== null ? storedValue : defaultValue
);

// useEffect(()=>{
//   setGettingQuestionAnswer(gettingQuestionAnswer)
// },[localStorage.getItem('answerQuestion')])

  console.log("gettingQuestionAnswer",gettingQuestionAnswer)


  console.log("loggedInUser",loggedInUser,typeof(loggedInUser))


  console.log("answers.length", answers.length)

  console.log("botQuestions",user, botQuestions)


  console.log(orgId, answers.length,  questionsLength , answers.length ==  questionsLength && !orgId,"orgIddddddddd")

  useEffect(() => {
    if (stateData?.task?.makeDefaultTemplate) {
      getBotQuestions()
      dispatch(makeDefaultTemplate(false))
    }
  }, [
    stateData?.task?.makeDefaultTemplate
  ])
  

  useEffect(()=>{
setOrgId(localStorage.getItem('organization_id'))
  },[localStorage.getItem('organization_id')])

  useEffect(()=>{
   console.log(messages,"messages")
    // setMessages(JSON.parse(gettingQuestionAnswer))
    console.log("sdsdsdsddsd", JSON.parse(gettingQuestionAnswer))
    let botAnswerSet = []
    JSON.parse(gettingQuestionAnswer).forEach(element => {
      console.log(element,"oooooo")
    if (element?.answer){
      console.log("esrdftg",element?.answer)
     
      botAnswerSet.push({text : element?.question, user : false})
      botAnswerSet.push({text : element?.answer, user : true})
    }
    // if(orgId){
    setMessages(botAnswerSet)
    
    // }
      });
    
  },[gettingQuestionAnswer])


  // useEffect(() => {
  //   setTimeout(() => {
  //     updateState(true);
  //   }, 30000);
  // }, []);


  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, showMoreStrategiesMessage]);

  useEffect(() => {
    console.log(botQuestions.length,"botQuestions")
    if ((loggedInUser == 'true' && !botQuestions.length && !(JSON.parse(gettingQuestionAnswer).length)) ) {
      setToken(userInfoDetails?.data?.accessToken);
      // getBotQuestions(userInfoDetails?.data?.accessToken);
      getBotQuestions()
    } else if(loggedInUser == 'true' && !(JSON.parse(gettingQuestionAnswer).length)  ){
      getBotQuestions()
    }
    else if((loggedInUser == "false" || loggedInUser == null )&& !(localStorage.getItem("answerQuestion"))){
      getBotQuestions()
    }
    

    // setMessages(gettingQuestionAnswer)
  }, [loggedInUser]);




  // useEffect(() => {
  //   console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUU 1",loggedInUser == 'true', (JSON.parse(gettingQuestionAnswer).length), !(JSON.parse(gettingQuestionAnswer).length))
  //   console.log(botQuestions.length,"botQuestions")
  //   if ((localStorage.getItem("loggedIn") == 'true' && !botQuestions.length && !(JSON.parse(gettingQuestionAnswer).length)) || (loggedInUser == 'false' && !(JSON.parse(gettingQuestionAnswer).length)  )) {
  //     setToken(userInfoDetails?.data?.accessToken);
  //     console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUU 2")
  //     setTimeout(() => {
  //       getBotQuestions(userInfoDetails?.data?.accessToken);
  //     }, 500);
     
  //     // getBotQuestions()
  //   }else if( ((localStorage.getItem("loggedIn") == "false" )|| loggedInUser == null)){
  //     console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUU 3")
  //     getBotQuestions()
  //   }
  //   // else if(loggedInUser == 'true' && (JSON.parse(gettingQuestionAnswer).length)  ){
  //   //   getBotQuestions()
  //   // }

  //   // setMessages(gettingQuestionAnswer)
  // }, [localStorage.getItem("loggedIn")]);

  useEffect(() => {
    if (stateData?.auth?.userDetails?.data?.logged_in === true) {
      setLoggedIn("true");
    }
  }, [stateData?.auth?.userDetails]);

  useEffect(() => {
    if (currentQuestionIndex < botQuestions.length && !user) {
      setTimeout(
        () => handleBotResponse(botQuestions[currentQuestionIndex]?.question),
        1000
      );
    }

  }, [currentQuestionIndex, user, botQuestions]);

  useEffect(() => {
    if (localStorage.getItem("openChatbot") === "true" && localStorage.getItem("currentLocation") === "/home" && localStorage.getItem("previousLocation") === "/organization-list") {
      updateState(true);
    }
    localStorage.setItem("openChatbot", false)
  }, [localStorage.getItem("currentLocation")])


  

  const handleButtonClick = (response) => {
    localStorage.removeItem('answerQuestion')
    setSuccess(false)
    if (response === "yes") {
      if(JSON.parse(gettingQuestionAnswer).length === questionsLength || JSON.parse(gettingQuestionAnswer).length) {
        console.log("hiiiiiiiiiiiiiiiiiiiiii")
        getBotQuestions()
      }
      // getBotQuestions()
      // window.location.reload();
      setGettingQuestionAnswer(defaultValue)
      localStorage.removeItem('answerQuestion')
      setCurrentQuestionIndex(0);
      setMessages([]);
      setAnswers([]);
      setShowMoreStrategiesMessage(false);
      setUser(false);
      setCsvFileUrl(null);
      setShowError(false);
    } else if (response === "no") {
      if(JSON.parse(gettingQuestionAnswer).length === questionsLength || JSON.parse(gettingQuestionAnswer).length){
        getBotQuestions()
      }
      // window.location.reload();
      setGettingQuestionAnswer(defaultValue)
      localStorage.removeItem('answerQuestion')
      HideChatBot();
      setCurrentQuestionIndex(0);
      setMessages([]);
      setAnswers([]);
      setShowMoreStrategiesMessage(false);
      setUser(false);
      setCsvFileUrl(null);
      setShowError(false);
    } else if (response === "error" && !invalidAnswers) {
      // if (retryCount < 3) {
      //   setRetrying(true);
      //   retryApiCall(botQuestions[0].question, retryCount);
      // } else {
      //   setRetrying(true);
      //   retryApiCall(botQuestions[0].question, retryCount);
      // }
    }
  };

  // useEffect(() => {
  //   setRetrying(retrying);
  // }, [retrying])

  // const retryApiCall = (question, count) => {
  //   if (count === 2) {
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       { text: "Something went wrong. Please try again.", user: false },
  //     ]);

  //     setCurrentQuestionIndex(0);
  //     setAnswers([]);
  //     setShowMoreStrategiesMessage(false);
  //     setUser(false);
  //     setCsvFileUrl(null);
  //     setShowError(false);
  //     setRetryingSecond(false);
  //     retryCount = 0;
  //     setRetrying(false);
  //   } else {
  //     const fetchData = async () => {
  //       setIsLoading(true);
  //       try {
  //         setRetrying(true);
  //         const response = await getCSVFile(answers, token);
  //         const fileUrl = response?.data?.data?.url;
  //         if (fileUrl) {
  //           setGoal(response?.data?.data?.goal);
  //           setCsvFileUrl(fileUrl);
  //           setShowMoreStrategiesMessage(true);
  //           audioRef.current.play();
  //         }
  //       } catch (error) {
  //         if (error?.response?.data?.message === "you have reached daily limit") {
  //           SetLimitMessage(true);
  //         } else if (error?.response?.data?.message?.includes("cannot generate")) {
  //           setInvalidAnswers(true);
  //           setMessages((prevMessages) => [
  //             ...prevMessages,
  //             { text: error?.response?.data?.message, user: false },
  //           ]);

  //           setCurrentQuestionIndex(0);
  //           setAnswers([]);
  //           setShowMoreStrategiesMessage(false);
  //           setUser(false);
  //           setCsvFileUrl(null);
  //           setShowError(false);
  //           setRetrying(false);
  //           setInvalidAnswers(false);
  //         }
  //         else {
  //           if (count === 0 || count === 1) {
  //             setRetryingSecond(true);
  //           }
  //           setRetrying(true);
  //           handleButtonClick("error");
  //           retryCount += 1;
  //           console.error("Error fetching CSV file:", error);
  //         }
  //       } finally {
  //         setIsLoading(false);
  //         setRetrying(false);
  //       }
  //     };

  //     fetchData();
  //   }
  // };
console.log('messagesssssssss', messages)
  const renderMoreStrategiesMessage = () => {
    if (showMoreStrategiesMessage) {
      return (
        <div className="bot-message">
          Do you want to make more strategies?
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5px",
            }}
          >
            <button
              className="figma-btn text-white"
              onClick={() => handleButtonClick("yes")}
              style={{
                backgroundColor: "#7DC2A7",
                marginRight: "10px",
                padding: "2px",
                borderRadius: "2px",
                width: "50px",
              }}
            >
              Yes
            </button>
            <button
              className="figma-btn text-white"
              onClick={() => handleButtonClick("no")}
              style={{
                backgroundColor: "#E58697",
                padding: "3px",
                borderRadius: "2px",
                width: "50px",
              }}
            >
              No
            </button>
          </div>
        </div >
      );
    }
    return null;
  };

  const getBotQuestions = () => {
    // if (userInfoDetails?.data?.accessToken) {
      setIsLoadinQuestions(true);
      setMessages([])
      const data = getAllBotQuestions()
        .then((response) => {
          SetQuestionsLength(response?.data?.data.length)
          console.log(response?.data?.data.length,"resssssss")
          setIsLoadinQuestions(false);
          setBotQuestions(response?.data?.data);
          
        })
        .catch((error) => {
          console.error("Error fetching bot questions:", error);
        });
    // }
  };

  useEffect(() => {
    console.log(JSON.parse(gettingQuestionAnswer), questionsLength ,JSON.parse(gettingQuestionAnswer).length, "888888888888")
    const fetchData = async () => {
      if ((currentQuestionIndex === questionsLength ) && !user) {
        console.log("gggggggggggggggggggg1")
        setAnswers((prevAnswers) => [
          ...prevAnswers,
          {
            question: botQuestions[currentQuestionIndex]?.question,
            answer: messages[messages.length - 1].text,
          },
        ]);

        setAnswerQuestion((prevAnswers) => [
          ...prevAnswers,
          {
            question: botQuestions[currentQuestionIndex]?.question,
            answer: messages[messages.length - 1].text,
          },
        ]);

        localStorage.setItem('answerQuestion', JSON.stringify(answerQuestion));
        setIsLoading(true);
        try {
          console.log("nnnnnnnnsssss")
         console.log(answers,"ooooooooooooooo")
         let setAns
        //  if(JSON.parse(gettingQuestionAnswer).length === 6 && orgId ){
        //   setAns = JSON.parse(gettingQuestionAnswer)
        //  }else{
        //   setAns = answers
        //  }
        if(userInfoDetails?.data?.accessToken) {
            
          
          const response = await getCSVFile(answers, userInfoDetails?.data?.accessToken);
          let retryCount = 0;
          const fileUrl = response?.data?.data?.url;
          console.log("RESPONSE", response?.data);
          localStorage.setItem('chatbotResponseUrl',JSON.stringify(fileUrl))
     
          // localStorage.removeItem('answerQuestion')
          const retrySecondApi = async () => {
            try {
              console.log("retry Count 1", retryCount);
              const response2 = await fetch(fileUrl);
              console.log("retry Count 2", response2);
              console.log("retry Count 2", response2.status)

              if (response2.status == 200) {
                console.log("999",response2)
                clearInterval(retryInterval);
                setisloading(false);
                setGoal(response?.data?.data?.goal);
                setCsvFileUrl(fileUrl);
                setShowMoreStrategiesMessage(true);
                setSuccess(true)
                localStorage.removeItem('answerQuestion')
                audioRef.current.play();
                
              }
            }
            catch (error) {
              console.log("retry Count error", error)
            }
          }
          const maxRetries = 30
          console.log("retry Count", retryCount);
          setisloading(true);
          const retryInterval = setInterval(async () => {
            if (retryCount < maxRetries) {
              retrySecondApi();
              retryCount++;
            } else {
              clearInterval(retryInterval);
              setisloading(false);
              setShowError(true);
              setShowMoreStrategiesMessage(true);
            }
          }, 8000);
          // if (fileUrl) {
          //   setGoal(response?.data?.data?.goal);
          //   setCsvFileUrl(fileUrl);
          //   setShowMoreStrategiesMessage(true);
          //   audioRef.current.play();
          // }

        

          

        }
        } catch (error) {
          toast(
            <>
              <div className="m-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
              <div>
                <h2  style={{
                  fontSize: "22px" }}>
                  Failed
                </h2>
                <h3 style={{
                  fontSize: "18px" }}>
                {error?.response?.data?.message}
                </h3>
                </div>
              </div>
            </>,
            { 
              toastId: "task-create",
              className: 'toast-error-container toast-error-container-after ',
            }
          )
          if (error?.response?.data?.message === "you have reached daily limit") {
            SetLimitMessage(true);
          } else if (error?.response?.data?.message?.includes("cannot generate")) {
            setInvalidAnswers(true);
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: error?.response?.data?.message, user: false },
            ]);

            setCurrentQuestionIndex(0);
            setAnswers([]);
            setShowMoreStrategiesMessage(false);
            setUser(false);
            setCsvFileUrl(null);
            setShowError(false);
            setRetrying(false);
            setInvalidAnswers(false);
          }
          else {
            handleButtonClick("error");
            seErrorMessage(error?.response?.data?.message);
            setShowError(true);
            setShowMoreStrategiesMessage(true);
            // setRetrying(true);
            console.error("Error fetching CSV file:", error);
          }
        } finally {
          setIsLoading(false);
        }
      }else if(JSON.parse(gettingQuestionAnswer).length  && !user && orgId){
        console.log(JSON.parse(gettingQuestionAnswer).length,"gggggggggggggggggggg2")
        try {
          console.log("nnnnnnnnsssss")
         console.log(answers,"ooooooooooooooo")
         let setAns
        //  if(JSON.parse(gettingQuestionAnswer).length === 6 && orgId ){
        //   setAns = JSON.parse(gettingQuestionAnswer)
        //  }else{
        //   setAns = answers
        //  }
          
          const response = await getCSVFile(JSON.parse(gettingQuestionAnswer), userInfoDetails?.data?.accessToken);
         
          let retryCount = 0;
          const fileUrl = response?.data?.data?.url;
          console.log("RESPONSE", fileUrl);
          localStorage.setItem('chatbotResponseUrl',JSON.stringify(fileUrl))
          const retrySecondApi = async () => {
            try {
              console.log("retry Count 1", retryCount);
              const response2 = await fetch(fileUrl);
              console.log("retry Count 2", response2);
              console.log("retry Count 2", response2.status)

              if (response2.status == 200) {
                console.log("999",response2)
                clearInterval(retryInterval);
                setisloading(false);
                setGoal(response?.data?.data?.goal);
                setCsvFileUrl(fileUrl);
                setShowMoreStrategiesMessage(true);
                
                setSuccess(true)
                localStorage.removeItem('answerQuestion')
                audioRef.current.play();
                
              }
            }
            catch (error) {
              console.log("retry Count error", error)
            }
          }
          const maxRetries = 30
          console.log("retry Count", retryCount);
          setisloading(true);
          const retryInterval = setInterval(async () => {
            if (retryCount < maxRetries) {
              retrySecondApi();
              retryCount++;
            } else {
              clearInterval(retryInterval);
              setisloading(false);
              setShowError(true);
              setShowMoreStrategiesMessage(true);
            }
          }, 8000);
          // if (fileUrl) {
          //   setGoal(response?.data?.data?.goal);
          //   setCsvFileUrl(fileUrl);
          //   setShowMoreStrategiesMessage(true);
          //   audioRef.current.play();
          // }
        } catch (error) {
          toast(
            <>
              <div className="m-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{color: "#131F4C"}}></i>
              <div>
                <h2  style={{
                  fontSize: "22px" }}>
                  Failed
                </h2>
                <h3 style={{
                  fontSize: "18px" }}>
                {error?.response?.data?.message}
                </h3>
                </div>
              </div>
            </>,
            { 
              toastId: "task-create",
              className: 'toast-error-container toast-error-container-after ',
            }
          )
          if (error?.response?.data?.message === "you have reached daily limit") {
            SetLimitMessage(true);
          } else if (error?.response?.data?.message?.includes("cannot generate")) {
            setInvalidAnswers(true);
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: error?.response?.data?.message, user: false },
            ]);

            setCurrentQuestionIndex(0);
            setAnswers([]);
            setShowMoreStrategiesMessage(false);
            setUser(false);
            setCsvFileUrl(null);
            setShowError(false);
            setRetrying(false);
            setInvalidAnswers(false);
          }
          else {
            handleButtonClick("error");
            seErrorMessage(error?.response?.data?.message);
            setShowError(true);
            setShowMoreStrategiesMessage(true);
            // setRetrying(true);
            console.error("Error fetching CSV file:", error);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [currentQuestionIndex, user, messages, orgId]);

  

  const handleUserMessage = (message) => {
   
    if ((loggedInUser == "false" || loggedInUser==null) ) {
      console.log("anssss", answers.length)
      // dispatch(addUserAnswer(message));
      // localStorage.setItem("userChatAnswers", message)
      // navigate("/login", {
      //   state: {
      //     answers: JSON.stringify(message),
      //   },
      // });

      // const answersParam = encodeURIComponent(JSON.stringify(message));
      // window.location.href = `/login?answers=${answersParam}`;
      if((answers.length+1) == (questionsLength)){

     // Define your state object
const state = {
  answers: JSON.stringify(answerQuestion),
};

// Encode the state object into a query parameter
const encodedState = encodeURIComponent(JSON.stringify(state));

// Append the encoded state to the URL
// setTimeout(() => {
  window.location.href = `/login?state=${encodedState}`;
// }, 1000);




      // window.location.href = '/login'
      }
    }

    if (!user) {
      if (currentQuestionIndex < botQuestions.length) {

        const question = `Q${currentQuestionIndex + 1}: ${botQuestions[currentQuestionIndex]?.question}`;
        const answer = `Ans: ${message}`;

        setMessages((prevMessages) => [
          ...prevMessages,
          { text: message, user: true },
        ]);

        setAnswers((prevAnswers) => [...prevAnswers, { question, answer }]);

        setAnswerQuestion((prevAnswers) => [...prevAnswers, { question, answer }]);

       
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const handleBotResponse = (response) => {
    const { formattedQuestion } = formatQuestion(
      botQuestions[currentQuestionIndex]?.question,
      currentQuestionIndex,
      botQuestions.length
    );
    const storedAnswers = localStorage.getItem("userChatAnswers");
    // const storedAnswers = localStorage.getItem('answers');
    if (storedAnswers) {
      const storedAnswerMessage = `${storedAnswers}`;
      const question = `Q${currentQuestionIndex + 1}: ${botQuestions[currentQuestionIndex]?.question}`;
      const answer = `Ans: ${storedAnswers}`;

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: formattedQuestion, user: false },
        { text: storedAnswerMessage, user: true },
      ]);
      setAnswers((prevAnswers) => [...prevAnswers, { question, answer }]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      localStorage.removeItem("userChatAnswers")
    }
    else {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: formattedQuestion, user: false },
      ]);
    }
    setUser(false);
  };

  // useEffect(() => {
    
  // }, []);

  const HideChatBot = () => {
    updateState(!chatbotShow);
  };

  const openGoal = () => {
    // navigate("/goals", { state: { goal: goal } });

    // const state = JSON.stringify(goal);
    localStorage.setItem("csvGoal", JSON.stringify(goal));
    window.location.href = `/goals`;
    // window.location.href = `/goals?state=${encodeURIComponent(state)}`;
  };

  const renderAlertText = () => {
    const fullText = `
        Just answer the 6 simple questions in the chatbot and we will generate a strategy for you.
        Your strategy will be uploaded to Plansom with goals and tasks, where you can edit and customize it.
        Once set, Plansom will help you track your strategy. We will help you win by focusing on doing the most important work first.
      `;
    if (showFullText) {
      return <p className="fs-16">{fullText}</p>;
    } else {
      const shortenedText = fullText.slice(0, 100);
      return (
        <>
          <p className="fs-16">{shortenedText}<span
            onClick={() => setShowFullText(true)}
            style={{ cursor: "pointer" }}
          >...</span></p>
        </>
      );
    }
    return null;
  };

  const formatQuestion = (question, currentQuestionIndex, totalQuestions) => {
    const formattedQuestion = (
      <>
        <strong>Q{currentQuestionIndex + 1}/{totalQuestions}:</strong> {question}
      </>
    );
    return { formattedQuestion, originalQuestion: question };
  };

  console.log("messages", messages)

  return (
    <>
      <audio ref={audioRef} src={MessageSeconNotification2} />
      {chatbotShow && (
        <div
          className="card chatbot-container p-2 m-2 mobileSizeChatbot"
          style={{
            width: "34%",
            margin: "auto",
            borderRadius: "10px",
            overflow: "hidden",
            position: "fixed",
            right: "3rem",
            bottom: "5rem",
            zIndex: "2",
            height: "60%",
            boxShadow: "0 0 15px rgba(128, 128, 128, 0.5)"
          }}
        >
          <div className="card-header chatbot-header">AI Plan Generator
            <div className="chatbot-close-icon">
              <i className="fa fa-times" style={{ cursor: "pointer" }} onClick={() => HideChatBot()}></i>
            </div>
          </div>
          <div className="card-body chatbot-messages">
            {/* <div className="chatbotalert-box">
              <h4 className="fs-20 mt-2">
                <strong>Need a plan? Build an AI plan in 1 minute</strong>
              </h4>
              
            { renderAlertText()}
            </div> */}
            {/* {(loggedInUser == "false" || loggedInUser == null || loggedInUser == false) && (<div className="bot-message">
              <strong>Q1/6.</strong> To create a good strategy, I need to understand your business. Please describe your business.
            </div>)} */}
            {/* <div className="line" style={{
              position: "relative",
              width: "100%", // Full width
              height: "1px",
              top: "50%", // Center vertically
              left: 0,
              backgroundColor: "red",
              padding: "1px",
              margin: "2px",
              zIndex: -1, // Behind other content
            }}></div>
            <div className="loader" style={{ color: '#F3F4F6', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
              <div className="position-relative">
                <button className="btn btn-primary rounded-pill">Free trial for 7 days</button>

              </div>
            </div> */}



          { (isLoadingQuestions ?
              (
                <div className="loader" style={{ color: '#F3F4F6', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </div>
              ) : (
                <>
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={message.user ? "user-message" : "bot-message"}
                      ref={index === messages.length - 1 ? lastMessageRef : null}
                    >
                      {message?.text}
                    </div>
                    
                  ))}




         {/* {answers.length ==  (questionsLength+1) && !orgId && <>
        <h3 style={{color:"white"}}> Please login to continue...</h3>
         </>} */}

   {(JSON.parse(gettingQuestionAnswer).length) && ! orgId &&   <h3 style={{color:"white"}}> Please login to continue...</h3>}

  

                  {(isLoading || isloading) && !retrying && (
                    <div className="loader" style={{ color: '#F3F4F6' }}>Your strategy is cooking.This will take a little while as it's quite complicated. You can continue to use the app whilst you wait. Just come back here in a minute.&nbsp;<span className="spinner-grow spinner-grow-sm"></span></div>
                  )
                  }
                  {limitMessage && (
                    <>
                      <div className="bot-message">
                        <i className="fa fa-warning"></i> You have reached daily limit
                      </div>
                    </>
                  )}
                  {success && (
                    <>
                      <div className="bot-message">
                        <i className="fa fa-success"></i> Your strategy created successfully, click <a style={{color:"blue"}} href="/goals">here</a> to see your strategy
                       
                      </div>
                      {renderMoreStrategiesMessage()}
                    </>
                  )}
                  {showError && (
                    <>
                      <div className="bot-message">
                        {errorMessage}
                      </div>
                      <div>
                        {renderMoreStrategiesMessage()}
                      </div>
                    </>
                  )}
                  {/* {(retrying === true || retryingSecond === true) && (
                    <div className="loader" style={{ color: '#F3F4F6' }}>
                      Opps, something went wrong. Please wait, AI will try again
                      &nbsp;<span className="spinner-grow spinner-grow-sm"></span>
                    </div>
                  )} */}
                  {/* {csvFileUrl && (
                    <div className="bot-message">
                      Your strategy is ready, click <Link
                        style={{
                          cursor: "pointer",
                          color: "red",
                          fontSize: "10px",
                        }}
                        onClick={() => { openGoal() }}
                        className="badge badge-light"
                      >
                        <u>here </u>
                      </Link> to see your strategy.
                    </div>
                  )} */}
                  {/* {csvFileUrl && (
                    <>
                      <div className="bot-message">
                        <a href={csvFileUrl} download="filename.csv">
                          <i className="fa fa-download"></i> Download CSV
                        </a>
                      </div>
                      <div>
                        {renderMoreStrategiesMessage()}
                      </div>
                    </>
                  )} */}
                </>
              )
            )}

          </div>
          {/* <div className="card-footer chatbot-input">
            <input
              type="text"
              placeholder="Type a message..."
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== "" && !isLoading) {
                  handleUserMessage(e.target.value);
                  e.target.value = "";
                }
              }}
            />
          </div> */}
         <div className="card-footer chatbot-input" style={{display:"flex", alignItems:"center"}}>
  <textarea
    style={{ height: "50%", width: "100%" }}
    rows={4}
    className="figma-inputss"
    placeholder="Type a message..."
    // onKeyPress={(e) => {
    //   if (e.key === "Enter" && e.target.value.trim() !== "" && !isLoading) {
    //     handleUserMessage(e.target.value.trim());
    //     e.target.value = "";
    //   }
    // }}
    onKeyDown={(e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault(); // Prevent default behavior of Enter key
        if (e.target.value.trim() !== "" && !isLoading) {
          handleUserMessage(e.target.value.trim());
          e.target.value = "";
        }
      }
    }}
  />
  <button
    className="send-btn float-end mx-2 my-1"
    style={{ width: "13%", borderRadius:"100%" }}
    onClick={() => {
      const messageInput = document.querySelector("textarea");
      if (messageInput.value.trim() !== "" && !isLoading) {
        handleUserMessage(messageInput.value.trim());
        messageInput.value = "";
      }
    }}
  >
    <i className="far fa-paper-plane me-1"></i>
   
  </button>
</div>

        </div>)}
    </>
  );
};

export default Chatbot;