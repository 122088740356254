import {
    CREATE_GOAL_CONFIRMED_ACTION,
    CREATE_GOAL_FAILED_ACTION,
    GET_GOALS_CONFIRMED_ACTION,
    GET_GOALS_FAILED_ACTION,
    DELETE_GOAL_CONFIRMED_ACTION,
    DELETE_GOAL_FAILED_ACTION,
    EDIT_GOAL_CONFIRMED_ACTION,
    EDIT_GOAL_FAILED_ACTION,
    // SET_ORG_MODAL_ACTION
   
} from '../actions/GoalsActions';
const initialState = {
    errorMessage: '',
    successMessage: '',
    goals : '',
    // orgModalData: true
};

export function GoalReducer(state = initialState, action) {
    if (action.type === CREATE_GOAL_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message ,
            goals : action.payload.data
        };
    }

    if (action.type === CREATE_GOAL_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Goal not created',
            goals : action.payload.data
        };
    }
    if (action.type === EDIT_GOAL_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message ,
            goals : action.payload.data
        };
    }

    if (action.type === EDIT_GOAL_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Goal not updated',
            goals : action.payload.data
        };
    }
    if (action.type === GET_GOALS_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message ,
            goals : action.payload.data
        };
    }

    if (action.type === GET_GOALS_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Goal not Found',
            goals : action?.payload?.data
        };
    }
    if (action.type === DELETE_GOAL_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message ,
            goals : action.payload.data
        };
    }

    if (action.type === DELETE_GOAL_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Goal not Deleted',
            goals : action.payload.data
        };
    }
    // if(action.type === SET_ORG_MODAL_ACTION) {
    //     return {
    //         ...state,
    //         orgModalData : action.payload
    //     };
    // }
   
    return state;
}