import {
  getOrgMem,
  createTeam,
  getAllOrgTeams,
  editTeam,
  deleteTeam,
  getAllSubTeam,
  getTeamMemById,
} from "../../services/TeamService";

export const GET_ORG_MEM_CONFIRMED_ACTION =
  "[getOrgMem action] confirmed getOrgMem";
export const GET_ORG_MEM_FAILED_ACTION = "[getOrgMem action] failed getOrgMem";
export const CREATE_TEAM_CONFIRMED_ACTION =
  "[createTeam action] confirmed createTeam";
export const CREATE_TEAM_FAILED_ACTION =
  "[createTeam action] failed createTeam";
export const GET_ALL_TEAMS_CONFIRMED_ACTION =
  "[getAllOrgTeams action] confirmed getAllOrgTeams";
export const GET_ALL_TEAMS_FAILED_ACTION =
  "[getAllOrgTeams action] failed getAllOrgTeams";
export const EDIT_TEAM_CONFIRMED_ACTION =
  "[editTeam action] confirmed editTeam";
export const EDIT_TEAM_FAILED_ACTION = "[editTeam action] failed editTeam";
export const DELETE_TEAM_CONFIRMED_ACTION =
  "[deleteTeam action] confirmed deleteTeam";
export const DELETE_TEAM_FAILED_ACTION =
  "[deleteTeam action] failed deleteTeam";
export const GET_ALL_SUB_TEAMS_CONFIRMED_ACTION =
  "[getAllSubTeam action] confirmed getAllSubTeam";
export const GET_ALL_SUB_TEAMS_FAILED_ACTION =
  "[getAllSubTeam action] failed getAllSubTeam";
export const GET_TEAM_MEM_CONFIRMED_ACTION =
  "[getTeamMemById action] confirmed getTeamMemById";
export const GET_TEAM_MEM_FAILED_ACTION =
  "[getTeamMemById action] failed getTeamMemById";

export function getOrgMembers(token) {
  return (dispatch) => {
    getOrgMem(token)
      ?.then((response) => {
        dispatch(getOrgMemConfirmAction(response));
      })
      ?.catch((error) => {
        console.log("error", error);
        dispatch(getOrgMemFailedAction(error));
      });
  };
}

export function getOrgMemConfirmAction(data) {
  return {
    type: GET_ORG_MEM_CONFIRMED_ACTION,
    payload: data,
  };
}

export function getOrgMemFailedAction(message) {
  return {
    type: GET_ORG_MEM_FAILED_ACTION,
    payload: message,
  };
}

export function createTeamAction(
  teamName,
  orgId,
  userId,
  userList,
  teamId,
  token
) {
  return (dispatch) => {
    createTeam(teamName, orgId, userId, userList, teamId, token)
      ?.then((response) => {
        dispatch(createTeamConfirmAction(response));
      })
      ?.catch((error) => {
        console.log("error", error);
        dispatch(createTeamFailedAction(error));
      });
  };
}

export function createTeamConfirmAction(data) {
  return {
    type: CREATE_TEAM_CONFIRMED_ACTION,
    payload: data,
  };
}

export function createTeamFailedAction(message) {
  return {
    type: CREATE_TEAM_FAILED_ACTION,
    payload: message,
  };
}

export function getAllTeams(orgId, token) {
  return (dispatch) => {
    getAllOrgTeams(orgId, token)
      ?.then((response) => {
        dispatch(getAllTeamsConfirmAction(response));
      })
      ?.catch((error) => {
        dispatch(getAllTeamsFailedAction(error));
      });
  };
}

export function getAllTeamsConfirmAction(data) {
  return {
    type: GET_ALL_TEAMS_CONFIRMED_ACTION,
    payload: data,
  };
}

export function getAllTeamsFailedAction(message) {
  return {
    type: GET_ALL_TEAMS_FAILED_ACTION,
    payload: message,
  };
}

export function getSubTeamByTeamId(teamId, token) {
  return (dispatch) => {
    getAllSubTeam(teamId, token)
      ?.then((response) => {
        dispatch(getAllSubTeamsConfirmAction(response));
      })
      ?.catch((error) => {
        dispatch(getAllSubTeamsFailedAction(error));
      });
  };
}

export function getAllSubTeamsConfirmAction(data) {
  return {
    type: GET_ALL_SUB_TEAMS_CONFIRMED_ACTION,
    payload: data,
  };
}

export function getAllSubTeamsFailedAction(message) {
  return {
    type: GET_ALL_SUB_TEAMS_FAILED_ACTION,
    payload: message,
  };
}

export function getTeamMemByTeamId(teamId, token) {
  return (dispatch) => {
    getTeamMemById(teamId, token)
      ?.then((response) => {
        dispatch(getTeamMemConfirmAction(response));
      })
      ?.catch((error) => {
        console.log("error", error);
        dispatch(getTeamMemFailedAction(error));
      });
  };
}

export function getTeamMemConfirmAction(data) {
  return {
    type: GET_TEAM_MEM_CONFIRMED_ACTION,
    payload: data,
  };
}

export function getTeamMemFailedAction(message) {
  return {
    type: GET_TEAM_MEM_FAILED_ACTION,
    payload: message,
  };
}

export function editTeamAction(
  teamName,
  orgId,
  userId,
  image,
  userList,
  teamId,
  token
) {
  return (dispatch) => {
    editTeam(teamName, orgId, userId, image, userList, teamId, token)
      ?.then((response) => {
        dispatch(editTeamConfirmAction(response));
      })
      ?.catch((error) => {
        console.log("error", error);
        dispatch(editTeamFailedAction(error));
      });
  };
}

export function editTeamConfirmAction(data) {
  return {
    type: EDIT_TEAM_CONFIRMED_ACTION,
    payload: data,
  };
}

export function editTeamFailedAction(message) {
  return {
    type: EDIT_TEAM_FAILED_ACTION,
    payload: message,
  };
}

export function deleteTeamAction(teamId, orgId, token) {
  return (dispatch) => {
    deleteTeam(teamId, orgId, token)
      ?.then((response) => {
        dispatch(deleteTeamConfirmAction(response));
      })
      ?.catch((error) => {
        console.log("error", error);
        dispatch(deleteTeamFailedAction(error));
      });
  };
}

export function deleteTeamConfirmAction(data) {
  return {
    type: DELETE_TEAM_CONFIRMED_ACTION,
    payload: data,
  };
}

export function deleteTeamFailedAction(message) {
  return {
    type: DELETE_TEAM_FAILED_ACTION,
    payload: message,
  };
}
