import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Card, Typography, CardContent, Skeleton } from "@mui/material";
import CoachAvtar from '../../../images/Plansom/CoachAvatar.png'
import { getQuickWinsData } from "../../../services/OrganizationService";
import stars from '../../../images/Plansom/stars.png'
import { useNavigate } from "react-router-dom";
import { CustomScrollbars } from "../Component/customScrollbarComponent";

const AiCoach = () => {
  const [quikWinsData, setQuickWinsData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const navigate = useNavigate()
  const getQuickWinsDatas = () => {
    setLoadingData(true);
    const data = getQuickWinsData(userInfoDetails?.data?.accessToken);
    data.then(async (res) => {
      setQuickWinsData(res?.data?.data?.ai_message_data);
      setLoadingData(false);
    });
    data.catch(async (err) => {
    });
  };

  useEffect(() => {
    getQuickWinsDatas();
  }, [])

  return (
    <Card
      sx={{
        borderRadius: 4,
        padding: 2,
        background: "linear-gradient(to bottom right, #9EABDD 0%, #112264 30%, #1D3BAF 100%)",
        color: "#fff",
        minHeight: "46vh"
      }}
    >
      <CardContent>
        {loadingData ?
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start", // Align items at the top
                justifyContent: "flex-start", // Align the entire structure to the left
              }}
            >
              <div style={{ marginRight: "16px" }}>
                <Skeleton
                  variant="circular"
                  width={44}
                  height={44}
                  style={{ backgroundColor: "#FFFFFF" }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Skeleton style={{ backgroundColor: "#FFFFFF", height: "16px", width: "100px" }} />
                <Skeleton style={{ backgroundColor: "#FFFFFF", height: "16px", width: "150px" }} />
              </div>
            </div>
            <div
              style={{
                marginTop: "10%"
              }}
            >
              <Skeleton style={{ backgroundColor: "#FFFFFF", height: "16px" }} />
            </div>
            <div
              style={{
                marginTop: "5%"
              }}
            >
              <Skeleton style={{ backgroundColor: "#FFFFFF", height: "16px", width: "70%" }} />
            </div>
            <div
              style={{
                marginTop: "15%"
              }}
            >
              <Skeleton style={{ backgroundColor: "#FFFFFF", height: "16px", width: "60%" }} />
            </div>
          </>
          :
          <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={CoachAvtar}
                sx={{ width: 50, height: 50, mr: 2 }}
              />
              <Box >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFFFFF" }}>
                  AI Coach
                </Typography>
                <Typography variant="body2" sx={{ color: "#9EABDD" }} >Insights for today</Typography>
              </Box>
            </Box>
            <CustomScrollbars height={"24vh"}>
              <Typography variant="h6" sx={{ mb: 1, color: "#FFFFFF" }}>
                {quikWinsData}
              </Typography>
            </CustomScrollbars>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#fff",
                color: "#05060E",
                fontWeight: "bold",
                borderRadius: "20px",
                textTransform: "none",
                padding: "10px 20px",
                marginTop: "4px",
                mb: 2,
                mt: 2,
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#05060E",
                  boxShadow: "none",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/track")
              }}
            >
              <Avatar
                src={stars}
                sx={{ width: 20, height: 20, mr: 2 }}
              /> <span style={{ color: "#05060E" }}></span>Explore my stats
            </Button>
          </>
        }
      </CardContent>
    </Card>
  );
};

export default AiCoach;
