import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { OrgReducer } from './reducers/OrganizationReducer';
import { TeamReducer } from './reducers/TeamReducer';
import { GoalReducer } from './reducers/GoalReducer';
import { taskReducer } from './reducers/TaskReducer';
import { ReadNotificationReducer, FileUploadReducer, MessagesReducer } from './reducers/NotificationReducer';


const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: AuthReducer,
    org: OrgReducer,
    team: TeamReducer,
    goal: GoalReducer,
    task: taskReducer,
    fileUpload: FileUploadReducer,
    notification: ReadNotificationReducer,
    messages: MessagesReducer,

});

export const store = createStore(reducers, composeEnhancers(middleware));
