import React from "react"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Select from 'react-select'
import { getOrgMem } from "../../../services/TeamService"
import { useLocation } from "react-router-dom"
import { ChartsLegend } from "@mui/x-charts/ChartsLegend"
import { toast } from "react-toastify"
import { shareGoal, shareTemplateFile } from "../../../services/OrganizationService"

const ShareTemplate = (props)=>{
const location = useLocation()
console.log('shareModal llll ', props)

 const [shareModel, setShareModel] = useState(props?.shareModel )
 const [defaultOptions, setDefaultOptions] = useState();
 const [usersOption, setUsersOption] = useState();
 const userDetail = localStorage.getItem("userDetails");
 const userInfoDetails = JSON.parse(userDetail);
 const [userId, setUserId] = useState()
 const [error, setError] = useState()

 console.log(userId, "userIdddss")

 const getAllUser = () => {
    let optionGoalLists = [];
    let orgdata;
    orgdata = getOrgMem(
      userInfoDetails?.data?.accessToken, 
    );
    orgdata?.then(async (res) => {
      let optionGoalData = res?.data?.data;
      if (optionGoalData.length !== 0) {
        optionGoalData?.forEach((option) => {
          const data = {
            label: option.first_name + " " + option.last_name,
            value: option.first_name + " " + option.last_name,
            userId: option.id,
          };
          optionGoalLists.push(data);
        });
      }
    });
    orgdata?.catch(async (err) => { });
    setUsersOption(optionGoalLists);
  };

 


  const handleShareObjective = (event) => {
    event.preventDefault()
    const data = shareTemplateFile(props?.file ,props?.path, userId, userInfoDetails?.data?.accessToken, props?.share)
    data.then(async(res)=>{
        props?.setShareModelParent(false)
        setUserId()
        setDefaultOptions("")
        toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      // color: "#131F4C",
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      // color: "#131F4C",
                      fontSize: "18px",
                    }}
                  >
                    Your template has been shared successfully!


                  
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-schedule",
              className: "toast-success-container toast-success-container-after ",
            }
          );
        console.log(res,"7777response")
    })
    data.catch(async(err)=>{
        console.log(err,"7777response")
        setError(err?.response?.data?.message)
    })

 }


  useEffect(()=>{
    getAllUser()
  },[])


    console.log(shareModel, "shareModel")
    useEffect(()=>{
        console.log( props?.goal, "hhhhhhhyyyyyywwwww")
        setShareModel(props?.shareModel)
    },[props])

   

    const changeUserOption = (userRole) => {
        console.log(userRole,"userRole")
        setDefaultOptions(userRole);
        setUserId(userRole?.userId);
        // setError('')
      };




    return(
        <>
        <Modal className="fade bd-example-modal-lg" show={shareModel} size="lg">
        <Modal.Body>
          <form 
          onSubmit={handleShareObjective}
          >
            <div className="d-flex">
              <div className="col">
                <h2 className="text-center mb-2">Share - {props?.file}</h2>
                {/* <h2 className="">{shareQuestionSetId?.title}</h2> */}
              </div>
              <button
                type="button"
                className="btn-close float-end text-center mt-3"
                onClick={() => (
                  props?.setShareModelParent(false),
                    setDefaultOptions(),
                    setUserId(),
                    setError('')
                    )
                    }
              ></button>
            </div>
            <div className="modal-body p-0">

                <div className="form-group mb-3">
                   
                    <h5>Select users</h5>
                        <Select
                         required
                          name="goal_owner"
                          className="p"
                          isClearable
                          options={usersOption}
                          value={defaultOptions}
                          onChange={changeUserOption}
                        />
                  </div>
                  {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )}
               
            </div>
          
            
            <input type="submit" value="Share"  className="float-end figma-btn text-white"/>
          </form>
        </Modal.Body>
      </Modal>
        </>
    )
}
export default ShareTemplate