import {
  CREATE_ORG_CONFIRMED_ACTION,
  CREATE_ORG_FAILED_ACTION,
  GET_ORG_LIST_CONFIRMED_ACTION,
  GET_ORG_LIST_FAILED_ACTION,
  DELETE_ORG_CONFIRMED_ACTION,
  DELETE_ORG_FAILED_ACTION,
  EDIT_ORG_CONFIRMED_ACTION,
  EDIT_ORG_FAILED_ACTION,
  GET_USER_SPECIFIC_ORG_LIST_CONFIRMED_ACTION,
  GET_USER_SPECIFIC_ORG_LIST_FAILED_ACTION,
  DEFAULT_ORG_CONFIRMED_ACTION,
  DEFAULT_ORG_FAILED_ACTION,
} from "../actions/OrganizationActions";

const initialState = {
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  userDetails: "",
  orgDetails: "",
  baseOrg: "",
};

export function OrgReducer(state = initialState, action) {
  if (action.type === CREATE_ORG_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload.data,
      errorMessage: "",
      successMessage: "OTP sent to email " + action.payload.data.email,
      showLoading: false,
      orgDetails: action.payload,
    };
  }
  if (action.type === GET_ORG_LIST_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload.data,
      errorMessage: "",
      successMessage: "",
      showLoading: false,
      orgDetails: action.payload,
    };
  }
  if (action.type === DELETE_ORG_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload.data,
      errorMessage: "",
      successMessage: "",
      showLoading: false,
      orgDetails: action.payload,
    };
  }
  if (action.type === EDIT_ORG_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload.data,
      errorMessage: "",
      successMessage: action.payload.message,
      showLoading: false,
      orgDetails: action.payload.data,
    };
  }
  if (action.type === GET_USER_SPECIFIC_ORG_LIST_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload.data,
      errorMessage: "",
      successMessage: action.payload.message,
      showLoading: false,
      orgDetails: action.payload.data,
      baseOrg: action.payload.base_org_details,
    };
  }
  if (action.type === DEFAULT_ORG_CONFIRMED_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: action.payload.message,
      showLoading: false,
    };
  }
  if (
    action.type === CREATE_ORG_FAILED_ACTION ||
    action.type === GET_ORG_LIST_FAILED_ACTION ||
    action.type === DELETE_ORG_FAILED_ACTION ||
    action.type === EDIT_ORG_FAILED_ACTION ||
    action.type === GET_USER_SPECIFIC_ORG_LIST_FAILED_ACTION ||
    action.type === DEFAULT_ORG_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }
  if (action.type === GET_ORG_LIST_FAILED_ACTION) {
    return {
      ...state,
      errorMessage: "No organizations found for the user",
      successMessage: "",
      showLoading: false,
      orgDetails: "",
    };
  }
  return state;
}
