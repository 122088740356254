import React, { useState, useEffect } from "react";
import {
  acceptTaskAction,
  acceptGoalAction,
  rejectGoalAction,
  rejectTaskAction,
} from "../../services/messageService";
import { Row, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function ChatAssigned(props) {
  const [toggle, setToggle] = useState(props?.toggle);
  const [SelectedMessage, setSelectedMessage] = useState();
  const [OpenModal, setOpenModal] = useState(false);
  const initialTaskForm = {
    reject_reason: "",
    reject_description: "",
  };
  const [rejectTaskForm, setrejectTaskForm] = useState(initialTaskForm);
  const token = JSON.parse(localStorage?.getItem("userDetails"))?.data
    .accessToken;
  const oneMessage = props.props;
  const [accept, setAccept] = useState(
    oneMessage?.message_type !== "assigned" ? true : false
  );
  const changedDateFormat = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var today1 = new Date(date);
    return (
      days[today1.getDay()] +
      ",  " +
      monthNames[today1.getMonth()] +
      " " +
      String(today1.getDate()).padStart(2, "0") +
      "th, " +
      " " +
      today1.getFullYear()
    );
  };

  useEffect(() => {
    setAccept(accept);
  }, [accept]);

  // const acceptTask = (message) => {
  //   if (message.task) {
  //     const data = acceptTaskAction(message, token);
  //     data?.then(async (res) => {
  //       setAccept(false);
  //     });
  //     data?.catch(async (err) => {});
  //   } else if (message.goal) {
  //     const data = acceptGoalAction(message, token);
  //     data?.then(async (res) => {
  //       setAccept(false);
  //     });
  //     data?.catch(async (err) => {});
  //   }
  // };

  const handleRejectTaskChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...rejectTaskForm };
    newFormData[fieldName] = fieldValue;
    setrejectTaskForm(newFormData);
  };

  const submitRejectReason = (e) => {
    e.preventDefault();
    if (SelectedMessage?.goal) {
      const data = rejectGoalAction(
        SelectedMessage?.goal,
        {
          reject_reason: rejectTaskForm?.reject_reason,
          reject_description: rejectTaskForm?.reject_description,
        },
        SelectedMessage,
        token
      );
      data?.then(async (res) => {
        setOpenModal(false);
        setAccept(false);
      });
      data?.catch(async (err) => {});
    } else if (SelectedMessage?.task) {
      const data = rejectTaskAction(
        SelectedMessage?.task,
        {
          reject_reason: rejectTaskForm?.reject_reason,
          reject_description: rejectTaskForm?.reject_description,
        },
        SelectedMessage,
        token
      );
      data?.then(async (res) => {
        setOpenModal(false);
      });
      data?.catch(async (err) => {});
    }
  };

  const getTwentyFourHourTime = (date) => {
    var d = new Date(date);
    return d.getHours() + ":" + d.getMinutes();
  };

  const getTimeZone = (date) => {
    var today1 = new Date(date);
    let dates = today1.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }); //.split(',')[1]
    return getTwentyFourHourTime(dates);
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={OpenModal} size="md">
        <Modal.Body>
          <div className="text-center m-0">
            <Button
              variant=""
              className="btn-close float-end align-center m-2 p-2"
              onClick={() => {
                setOpenModal(false);
              }}
            ></Button>
            <h2 className="text-center mb-3" style={{ color: "#ef331d" }}>
              Reject reason
            </h2>
          </div>
          <form onSubmit={(e) => submitRejectReason(e)}>
            <div className="row">
              <div className=" form-group mb-3">
                <input
                  type="text"
                  name="reject_reason"
                  className="form-control"
                  placeholder="Reject reason"
                  data-testid="team-name"
                  onChange={handleRejectTaskChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="reject_description"
                  className="form-control"
                  placeholder="Descrition"
                  data-testid="team-name"
                  onChange={handleRejectTaskChange}
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="media justify-content-right align-items-end">
        <>
          <div className="message-sent dropdown1 mt-1 float-end ms-3">
            <span className="fs-14">
              {changedDateFormat(oneMessage?.created_at) +
                " " +
                getTimeZone(oneMessage?.created_at)}
            </span>
            <div className="d-flex justify-content-between mb-0 ">
              <p
                className="mb-1 message"
                key={1}
                style={{ width: "auto", cursor: "auto" }}
              >
                <Row className="d-flex justify-content-between ms-2">
                  {oneMessage?.message_body}
                  <Link
                  data-testId="message-body"
                    to=""
                    className="btn bg-white text-black btn-xs sharp text-center float-end m-0 p-0 ms-2"
                    onClick={(event) => {
                      props.data(true);
                      setToggle(true);
                      setSelectedMessage(oneMessage);
                      event.preventDefault();
                    }}
                  >
                    <i className="fa fa-info fs-16" aria-hidden="true"></i>
                  </Link>
                </Row>
{/* 
                {oneMessage?.message_type === "assigned" &&
                  oneMessage?.receiver?.id ===
                    JSON.parse(localStorage?.getItem("userDetails")).data.id &&
                  oneMessage?.goal && (
                    <div
                      className="text-end mt-4"
                      style={
                        accept ? { cursor: "pointer" } : { cursor: "auto" }
                      }
                    >
                      <button
                        type="submit"
                        className="btn btn-success float-right m-1 p-1 px-2"
                        onClick={() => {
                          acceptTask(oneMessage);
                        }}
                        disabled={
                          oneMessage?.message_type !== "assigned" ? true : false
                        }
                      >
                        Accept
                      </button>

                      <button
                        type="submit"
                        className="btn btn-secondary float-right p-1 px-2"
                        onClick={() => {
                          setOpenModal(true);
                          setSelectedMessage(oneMessage);
                        }}
                        disabled={
                          oneMessage?.message_type != "assigned" && accept
                            ? true
                            : false
                        }
                      >
                        Reject
                      </button>
                    </div>
                  )} */}
              </p>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default ChatAssigned;
