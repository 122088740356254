import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Card, Row } from "react-bootstrap";
import { CustomErrorComponent } from "custom-error";
import { getSampleTemplateFile } from "../../../services/OrganizationService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Modal} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getfolderList } from "../../../services/OrganizationService";
import LoadingSpinner from "../Component/LoadingSpinner";
import { uploadFileInFolder } from "../../../services/OrganizationService";
import { Dropdown } from "react-bootstrap";
import { editFolder } from "../../../services/OrganizationService";
import { deleteFolder } from "../../../services/OrganizationService";
import Vector3 from "../../../images/vector/Vector3.png"
import { toUseTemplate } from "../../../services/OrganizationService";
import ShareTemplate from "./ShareTemplate";

const onError = (e) => {
    return (
      <>
        <div data-testid="error">Error in loading excel sheet</div>
      </>
    );
  };

function ViewFolder() {
    const location = useLocation();
    const navigate = useNavigate()

    const userDetails = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetails);
    const [path, setPath] = useState(location?.state?.folderName);
    const [url, setUrl] = useState([])
    console.log(url,"7777555552222222")
    const type = "xlsx";
  
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [fileName, setFileName] = useState([])
    const [folders, setFolders] = useState([])
    const [loader, setLoder] = useState(false)
    
    const [showFileUplod, setShowFileUpload] = useState(false)

    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');

    const [editModal, setEditModel] = useState(false)
    const [prevEditFolderName, setPrevEditFolderName] = useState(false)
    const [editFolderName, setEditFolderName] = useState("")
    const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
    const [editName, setEditName] = useState("")


    const [deleteFolderName, setDeleteFolderName] = useState("")
    const [folderName, setFolderName] = useState("");

    const [shareOpen, setShareOpen] = useState(false)
    const [shareTemplateFileName, setShareTemplateFileName] = useState()

    console.log("FilePathfilepathFilePath",file?.name, path)
   
    useEffect(()=>{
       getFolder()
    },[])


    const getFolder = () => {
        setLoder(true)
        const data = getfolderList(path, userInfoDetails?.data?.accessToken);
        data?.then((res) => {
        setLoder(false)
        setFolders(res?.data?.folders)

        const fileUrls = res?.data?.folders.filter(item => item.is_file) 
        .map(item => ({ url: item.url, name: item.name }));
        setUrl([...fileUrls]);

        const fileNames = res?.data?.folders.filter(item => item.is_file) 
        .map(item => (item.name ));
        setFileName(fileNames)

    });
    data?.catch((err) => {
      console.log("BBBBBBBBBBBBBBBBB", err)
    });
  }
  
  useEffect(() => {
    setUrl(url);
  }, [url]);

  const uploadFileModal = () =>{
    setShowFileUpload(true)
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const selectedFile = event.target.files[0];
   
    if (selectedFile && (selectedFile.name.endsWith('.csv') || selectedFile.name.endsWith('.xlsx'))) {
      setFile(selectedFile);
      setSelectedFileName(selectedFile.name); 
      setError('');
    } else {
      setFile(null);
      setSelectedFileName('');
      setError('Please select a .csv or .xlsx file');
    }
  };

  const handleUploadfile = (event) =>{
    event.preventDefault()   
    console.log(path, file?.name, "858588285285")
   if(error === ''){
    const data = uploadFileInFolder(path, file, userInfoDetails?.data?.accessToken);
      data?.then((res) => {
        setShowFileUpload(false)
        getFolder()
      });
      data?.catch((err) => { 
        setError(err?.response?.data?.message)
      });
    }
  }


  const handleEditModalName = (event, folder) =>{
    event.preventDefault()
    setPrevEditFolderName(folder)
    setEditName(folder)

  }

  const handleShareTempate = (event, element) =>{
    event.preventDefault()
    setShareTemplateFileName(element)
    console.log(element,"qqeeieooeorojrkmrjjfkkfmdjk")

  }

  const handleEditFolder = (e) =>{
    e.preventDefault()
    const data = editFolder(prevEditFolderName, editFolderName,path, userInfoDetails?.data?.accessToken);
      data?.then((res) => {
        setEditModel(false)
        console.log("55555555555ooooooooooooooooo",res)
        getFolder()
      });
      data?.catch((err) => { 
        setError(err?.response?.data?.message)
      });
  }

  const DeleteFolderGetName = (event, folder) => {
    event.preventDefault()
    setDeleteFolderName(folder)
  }

  const selectedFolderForDelete = () => {
    // e.preventDefault()
    const data = deleteFolder(deleteFolderName,path, userInfoDetails?.data?.accessToken);
    data?.then((res) => {
      SetOpenDeleteModal(false)
      getFolder()
    });
    data?.catch((err) => { 

    });
  }

  const handleUseTempateModalName = (event, element) =>{
    event.preventDefault()
      const data = toUseTemplate(path, element, userInfoDetails?.data?.accessToken )
      data?.then((res)=>{
          console.log(res, "LLLLLLLLLLLLLLLL")
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      // color: "#131F4C",
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      // color: "#131F4C",
                      fontSize: "18px",
                    }}
                  >
                   Your template is uploaded successfully!
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-schedule",
              className: "toast-success-container toast-success-container-after ",
            }
          );
      })
      data?.catch((err)=>{
          console.log(err?.response?.data?.message, "LLLLLLLLLLLLLLLL")
          toast(
            <>
              <div className="mx-1 d-flex">
                <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
                <div>
                  <h2 style={{
                    // color: "#ffffff",
                    fontSize: "22px"
                  }}>
                    Failed
                  </h2>
                  <h3 style={{
                    // color: "#ffffff",
                    fontSize: "18px"
                  }}>
                   {err?.response?.data?.message}!
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "thread-data",
              className: 'toast-error-container toast-error-container-after ',
            }
          )
      })
  }

  return(<>
  
  {
    shareOpen && <ShareTemplate
    path={path}
    shareModel = {shareOpen}
    file={shareTemplateFileName}
    setShareModelParent={setShareOpen}
    
    />
    }

  <Modal
        className="fade bd-example-modal-lg"
        show={editModal}
        size="lg"
        data-testid="modal4"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setEditModel(false);
              setError("")
            }}
          ></button>
          <h2 className="text-center mb-4">Edit template </h2>
          <form onSubmit={(e) => handleEditFolder(e)}>
            <Row>

              <input
                type="text"
                required
                className="form-control"
                style={{ height: "37px" }}
                placeholder="Folder name"
                data-testid="folder-name"
                value={editName}
                onChange={(e) =>(setEditFolderName(e.target.value), setEditName(e.target.value))}
              />
              {error && (
                <div className="text-danger fs-12 ms-2">{error}</div>
              )}
            </Row>

            <div>
              <div className="text-center">
                <button
                  type="submit"
                  className="figma-btn text-white mt-3"
                  style={{ alignSelf: "flex-end" }}
                >
                  Edit template
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={showFileUplod}
        size="lg"
        data-testid="modal3"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setShowFileUpload(false)
              setError("")
            }}
          ></button>
          <h2 className="text-center mb-4">Uplod File </h2>
        
          <form onSubmit={handleUploadfile}>
      <div>
        {/* <h2> File Upload</h2> */}
        <input type="file" required accept=".csv, .xlsx" onChange={handleFileChange} />
        {error && <div className="text-danger fs-12 ms-2">{error}</div>}
      </div>
      <div className="text-center">
        <button type="submit" className="figma-btn text-white mt-3" style={{ alignSelf: "flex-end" }}>
          Upload
        </button>
      </div>
    </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="delete-modal" title="delete-modal" src={Vector3} />
          <h4 className="fs-20 p-2">
            Are you sure you want to delete{" "} template
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
         
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this - {deleteFolderName} 
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/viewFolder"
            onClick={(e) => {
              SetOpenDeleteModal(false);
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              selectedFolderForDelete(folderName);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

    <div>
     <i
          className="fa fa-reply fs-20"
          title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/templates")
          }}
        />
     <span className="mt-1 fs-14" style={{ marginLeft: "8px" }}><span style={{ marginLeft: "8px" }}>{path}</span> </span>
     </div>

     <div className="row mt-1">
          <div className="col">
            {/* <span className="col h3"> Templates </span> */}
          </div>
          <div className="col">
            <span>
              <button className="figma-btn float-end" onClick={(e) => uploadFileModal()} > Upload file</button>
            </span>

          </div>

        </div>



      {loader ? (
        <center>
          <div style={{ padding: "20px", margin: "300px" }}>
            <LoadingSpinner/>
          </div>
        </center>)  :(<> {!selectedTemplate ? (
        <Row>
          {url?.map((element, index) => (
            <div className="col-sm-4">
              <div className="my-2 p-2">
             
                    
                <Card id="ss" className="m-0 p-2 bg-white">
                <div style={{ display: 'flex', justifyContent: 'space-between', wordBreak:"break-word" }}>
                <h5>{element.name}</h5>
                <Dropdown className="ms-auto">
                  <Dropdown.Toggle
                    variant=""
                    as="div"
                    className="btn-link i-false"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12.4999"
                        cy="3.5"
                        r="2.5"
                        fill="#A5A5A5"
                      />
                      <circle
                        cx="12.4999"
                        cy="11.5"
                        r="2.5"
                        fill="#A5A5A5"
                      />
                      <circle
                        cx="12.4999"
                        cy="19.5"
                        r="2.5"
                        fill="#A5A5A5"
                      />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    alignRight={true}
                    data-testid="org-menu"
                    className="dropdown-menu-right"
                  >
                    <Dropdown.Item
                      data-testid="org-edit"
                      onClick={(event) => {
                        setEditModel(true)
                        handleEditModalName(event, element.name)
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      data-testid="org-edit"
                      onClick={(event) => {
                        handleUseTempateModalName(event, element?.name)
                      }}
                    >
                      Use template 
                    </Dropdown.Item>
                    <Dropdown.Item
                      data-testid="org-edit"
                      onClick={(event) => {
                        setShareOpen(true)
                        handleShareTempate(event, element.name)
                      }}
                    >
                      Share template 
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-danger"
                      data-testid="org-delete"
                      onClick={(event) => {
                        SetOpenDeleteModal(true);
                        DeleteFolderGetName(event, element?.name)

                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
             </div>

               
                  <div className="template-card p-1">
                    <Card.Body className="mb-0 p-1" style={{ height: "35vh" }}>
                    
                      <FileViewer
                        fileType={type}
                        filePath={element?.url}
                        errorComponent={CustomErrorComponent}
                        onError={onError}
                      />
                    </Card.Body>
                    <Card.Footer className="p-0 m-0 text-center">
                      <OverlayTrigger
                        delay={{ hide: 100, show: 10 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <h5 className="p text-white" data-testid="view-template" >View template!</h5>
                          </Tooltip>
                        )}
                        placement="bottom"
                      >
                        <Button
                        data-testid="View"
                          className="m-1 px-3"
                          style={{ width: "auto", borderRadius: "3px" }}
                          onClick={(e) => {
                            setSelectedTemplate(element);
                          }}
                        >
                          View
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        delay={{ hide: 1000, show: 10 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <h5 className="p text-white">
                              {" "}
                              Download template!
                            </h5>
                          </Tooltip>
                        )}
                        placement="bottom"
                      >
                        <a
                          href={element?.url}
                          download="File upload template"
                          target="_blank"
                        >
                          <Button className="mx-3 " data-testid="download-button">
                            <i class="bi bi-download p-1"></i>
                          </Button>
                        </a>
                      </OverlayTrigger>
                    </Card.Footer>
                  </div>
                  
                </Card>
               
              </div>
            </div>
          ))}
        </Row>
      ) : (
        ""
      )}</>)}

{folders?.length === 0 &&  !loader &&  <center>
      <span className="th text-center " style={{ top: "50px", position: "relative", alignItems:"center",}}>
                                Folder is Empty.
                              </span>
      </center>}

      {selectedTemplate?.url ? (
        <Card className="my-2 p-2">
          <Card.Header className="p-1">
            <div className="text-start">
              <OverlayTrigger
                delay={{ hide: 1000, show: 10 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <h5 className="p text-white">
                      Navigate back on template list!
                    </h5>
                  </Tooltip>
                )}
                placement="bottom"
              >
                <Link
                  to=""
                  onClick={() => {
                    setSelectedTemplate();
                  }}
                  style={{ float: "left" }}
                  className="float-start text-start"
                >
                  <i className="fa fa-reply fs-20" aria-hidden="true"></i>
                </Link>
              </OverlayTrigger>
            </div>
            <div className="text-end m-1">
              <OverlayTrigger
                delay={{ hide: 1000, show: 10 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    <h5 className="p text-white" data-testid = "sample-template"> Download sample template!</h5>
                  </Tooltip>
                )}
                placement="bottom"
              >
                <a href={url} download="File upload template" target="_blank">
                  <Button className="mx-2">
                    <i class="bi bi-download"></i>
                  </Button>
                </a>
              </OverlayTrigger>
            </div>
          </Card.Header>
          <Card.Body className="p-1">
            {selectedTemplate?.url && (
              <div
                style={{ position: "relative", height: "61vh", width: "100%" }}
              >
                <FileViewer
                  fileType={type}
                  filePath={selectedTemplate?.url}
                  errorComponent={CustomErrorComponent}
                  onError={onError}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      ) : (
        ""
      )}

      
    </>)
}export default ViewFolder