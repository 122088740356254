import React, { useState, useEffect } from "react";
import "../../../css/store-front.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getStoreImages } from "../../../services/StoreService";
import 'react-lazy-load-image-component/src/effects/blur.css';

function ProductSlider(props) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const delay = 5000;
  const [selectedCategory, setselectedCategory] = useState(
    JSON.parse(localStorage.getItem("store"))?.navSelectedCategory?.name);

  console.log(selectedCategory,"999")
  console.log("sssss")

  useEffect(()=>{
    setTimeout(() => {
      setselectedCategory(JSON.parse(localStorage.getItem("store"))?.navSelectedCategory?.name)
    }, 1000);
   
  },[])
  
  const [banners, setBanners] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getStoreImages();
      const categoriesData = res?.data?.data;
      // setcategories(categoriesData);
      console.log(categoriesData, "55555555555");

      const setBannersBasedOnCategory = () => {
        if (selectedCategory === "all") {
          setBanners(categoriesData["StoreHome"]);
          console.log(categoriesData["StoreHome"], "1111111111111BB");
        } else {
          const selectedCategoryArray = categoriesData[selectedCategory];
          if (selectedCategoryArray) {
            setBanners(selectedCategoryArray);
          } else {
            // Handle the case where the selectedCategory doesn't match any category
            console.error("Selected category not found:", selectedCategory);
          }
        }
      };

      // Check if props.banners is provided, use it instead of setting based on selectedCategory
      if (props.banners) {
        setBanners(props.banners);
      } else {
        setBannersBasedOnCategory();
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
   fetchData();
  }, [selectedCategory, props.banners]);


  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === banners.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
      <div className="product-slider" data-testid="product-slider" style={{ position: props.position, top: props.positionTop }}>
        {/* <div className="p-0 m-0 left-product-slider">
            </div> */}
        <div className="p-0 m-0 middle-product">
          <div className="slideshow">
            {/* <div className="overlay2"></div> */}
            <div
              className="slideshowSlider"
              style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
              {banners.map((banner, id) => (
                <div className="slide" key={id} data-testid="image">
               
              <LazyLoadImage
              src={banner?.image}
              alt={`Image ${id + 1}`}
              style={{
              height: "400px",
               width: "100%",
              borderRadius: "15px",
              position: "absolute",
              objectFit: "cover"
             }}
             width="100%"
             height="400px"
             effect="blur"
             />


                   
                  {/* Add white overlay */}
                  <div className="overlay2"></div>

                  {/* Add text */}
                  <div className="">
                    <div className="product-quote-box">
                      <h1 className="our-products text-wrap h1">{banner?.title}</h1>
                      <h2 className="our-products-quote text-wrap h2">
                        {banner?.description}
                      </h2>
                      {/* <span className="our-products2">{banner?.email}</span> */}
                    </div>
                  </div>
                </div>
              ))}

            </div>
            <div className="row d-flex justify-content-between" >
              <div className="slideshowDots" >
                {banners.map((_, idx) => (
                  <div
                    key={idx}
                    className={`slideshowDot${index === idx ? " active" : ""}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="p-0 m-0 right-product-slider">
            </div> */}
      </div>
    </>
  );
}

export default ProductSlider;

