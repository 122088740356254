import { RingLoader } from "react-spinners";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Row, Dropdown, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import orgImg from "./../../../images/org1.png";
import {
  getOrgList,
  deleteOrg,
  editOrgAction,
} from "../../../store/actions/OrganizationActions";
import { createOrgAction } from "../../../store/actions/OrganizationActions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Vector from "../../../icons/bootstrap-icons/icons/Vector.svg";
import Vector2 from "../../../images/vector/Vector.png";
import Vector3 from "../../../images/vector/Vector3.png";
import check from "../../../images/vector/check.svg";
import { getUserdetail } from "../../../store/actions/AuthActions";

export const LocationDisplayOrgList = () => {
  const location = useLocation();

  return (
    <div data-testid="location-display-org-list">{location?.pathname}</div>
  );
};

const OrganizationList = () => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const stateData = useSelector((state) => state);
  const [orgDetails, getOrgDetails] = useState("");
  const [editOrgModal, setOrgEditModal] = useState(false);
  const [Show, setShow] = useState(false);
  const [show, setshow] = useState(false);
  const [editOrgId, setEditOrgId] = useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const [countryValue, setValue] = useState({
    value: "US",
    label: "United States",
  });
  const changeHandler = (countryValue) => {
    setValue(countryValue);
  };
  const [largeModal, setLargeModal] = useState(false);
  const [org_name, setOrgname] = useState("");
  const [strategy_summary, setStrategy] = useState("");
  let errorsObj = { org_name: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [spinner, setspin] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState();
  const email = userInfoDetails?.data?.email;
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [SelectedOrgDelete, setSelectedOrgDelete] = useState();
  const [highlighOrganization, sethighlightOrganization] = useState(
    location?.state?.key
  );
  const [selectedStrategy, setSelectedStrategy] = useState(
    localStorage.getItem("store") !== undefined
      ? JSON.parse(localStorage.getItem("store"))?.SelectedStrategy
      : ""
  );
  const [errMessage, setErrMessage] = useState("");
  const errorMessage = useSelector(
    (state) => state?.org?.errorMessage?.message
  );

  

  const orgId = parseInt(localStorage.getItem("organization_id"), 10);

  useEffect(() => {
    setSelectedStrategy(
      localStorage.getItem("store") !== undefined
        ? JSON.parse(localStorage.getItem("store"))?.SelectedStrategy
        : ""
    );
  }, [localStorage.getItem("store"), largeModal]);

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
  }, []);

  const initialValues = {
    name: "",
    address_line_one: "",
    address_line_two: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    email: "",
    contact: null,
    description: "",
  };
  const [editOrgFormData, setEditOrgFormData] = useState(initialValues);

  useEffect(() => {
    sethighlightOrganization(location?.state?.key);
  }, [location?.state?.key]);

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }

    getOrgNewList();
    setspin(false);
  }, []);

  useEffect(() => {
    setErrors("");
  }, [org_name]);

  useEffect(() => {

    if (stateData.auth?.userDetails) {
      let userInfo = JSON.parse(localStorage.getItem("userDetails"));
      userInfo.data.org_access = stateData.auth?.userDetails?.data?.org_access;
      userInfo.data.user_role = stateData.auth?.userDetails?.data?.user_role;
      localStorage.setItem("userDetails", JSON.stringify(userInfo));
      localStorage.setItem(
        "org_access",
        stateData.auth?.userDetails?.data?.org_access
      );
    }
    if (stateData.org?.orgDetails === "") {
      localStorage.removeItem("organization_id");
    }
    if (stateData.org.orgDetails?.message === "Users Organization found") {
      getOrgDetails(stateData.org.orgDetails.data.organizations);
    }

    if (stateData.org?.orgDetails === "Organization deleted successfully") {
      getOrgNewList();
      getOrgDetails(stateData.org.orgDetails.data);
      getOrgNewList();
      localStorage.removeItem("organization_id");
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#ffffff",
                  fontSize: "18px",
                }}
              >
                Your organization has been deleted successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "org_delete",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    }
    if (
      stateData.org?.successMessage === "Organization Data updated successfully"
    ) {
      getOrgNewList();
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#ffffff",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#ffffff",
                  fontSize: "18px",
                }}
              >
                Your organization has been updated successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "org_update",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    }
    if (localStorage.getItem("org_access") === "false") {
      setLargeModal(true);
    }
    if (
      stateData.org.orgDetails?.message === "Organization created successfully"
    ) {
      setOrgname(stateData.org.orgDetails.data.name);
      setStrategy("");
      setShow(true);
      getOrgNewList();
      setLargeModal(false);
      if (userInfoDetails.data?.org_access === false) {
        localStorage.setItem(
          "organization_id",
          stateData.org.orgDetails.data.id
        );
      }
      if (selectedStrategy !== undefined && Object.keys(selectedStrategy).length !== 0) {
        handleStripePayment(stateData?.org?.orgDetails?.data?.id);
      } else {
        navigate("/home");
        localStorage.setItem("openChatbot", true);
      }
    }
  }, [stateData, location?.state?.Org]);

  const handleStripePayment = (orgId) => {
    navigate("/stripe");
  };

  function getOrgNewList() {
    dispatch(
      getOrgList(userInfoDetails.data.id, userInfoDetails.data.accessToken)
    );
  }

  function createOrganization(e) {
    setShow(false);
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    localStorage.setItem("org_access", "true");

    if (org_name === null || org_name === "") {
      errorObj.org_name = "Organization name is required*";
      error = true;
    }
    if (strategy_summary === null || strategy_summary === "") {
      errorObj.strategy_summary = "Description is required*";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      createOrgAction(
        org_name,
        "",
        "",
        "",
        "",
        countryValue.label,
        "",
        email,
        "",
        strategy_summary,
        userInfoDetails.data.accessToken
      )
    );

    if (errorMessage != "") {
      setErrMessage(errorMessage);
    } else {
      setLargeModal(false);
      setshow(true);
    }
    setTimeout(() => {
      dispatch(
        getUserdetail(
          userInfoDetails?.data?.id,
          userInfoDetails?.data?.accessToken
        )
      );
    }, 2000);
  }

  function openCreateOrg(e) {
    setOrgname("");
    setStrategy("");
    setShow(false);
    setLargeModal(true);
  }

  const selectedOrgForDelete = (event, orgId) => {
    event.preventDefault();
    setSelectedOrgDelete(orgId);
    setShow(false);
    setshow(false);
  };

  const handleOrgDeleteClick = () => {
    localStorage.removeItem("strategyStatus");
    setShow(false);
    dispatch(deleteOrg(SelectedOrgDelete, userInfoDetails.data.accessToken));
    setTimeout(() => {
      dispatch(
        getUserdetail(
          userInfoDetails?.data?.id,
          userInfoDetails?.data?.accessToken
        )
      );
    }, 2000);
  };

  const handleOrgEditClick = (event, org) => {
    event.preventDefault();
    setShow(false);
    setEditOrgId(org.id);
    const orgFormValues = {
      name: org.name,
      address_line_one: org.address_line_one,
      address_line_two: org.address_line_two,
      city: org.city,
      state: org.state,
      country: org.country,
      zipcode: org.zipcode,
      email: org.email,
      contact: org.contact,
      description: org?.description,
      org_image: org.org_image,
    };
    setEditOrgFormData(orgFormValues);
    setOrgEditModal(true);
  };

  const handleOrgEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editOrgFormData };
    newFormData[fieldName] = fieldValue;
    setEditOrgFormData(newFormData);
  };

  const handleOrgEditFormSubmit = (event) => {
    event.preventDefault();
    const editedOrgDetails = {
      name: editOrgFormData.name,
      address_line_one: editOrgFormData.address_line_one,
      address_line_two: editOrgFormData.address_line_two,
      city: editOrgFormData.city,
      state: editOrgFormData.state,
      country: countryValue.label,
      zipcode: editOrgFormData.zipcode,
      email: editOrgFormData.email,
      contact: editOrgFormData.contact,
      description: editOrgFormData.description,
    };
    dispatch(
      editOrgAction(
        editOrgId,
        editedOrgDetails,
        userInfoDetails.data.accessToken
      )
    );
    setEditOrgId(null);
    setOrgEditModal(false);
  };

  const handleOrgAttachmentSubmit = (event) => {
    event.preventDefault();
    const orgFormValues = {
      name: selectedOrg.name,
      address_line_one: selectedOrg.address_line_one,
      address_line_two: selectedOrg.address_line_two,
      city: selectedOrg.city,
      state: selectedOrg.state,
      country: selectedOrg.country,
      zipcode: selectedOrg.zipcode,
      email: selectedOrg.email,
      contact: selectedOrg.contact,
      description: selectedOrg.description,
      org_image: event.target.files[0],
    };
    // setEditOrgFormData(orgFormValues);
    setTimeout(() => {
      dispatch(
        editOrgAction(
          editOrgId,
          orgFormValues,
          userInfoDetails.data.accessToken
        )
      );
      getOrgNewList();
      setEditOrgId(null);
      setOrgEditModal(false);
    }, 3000);
  };

  const toastInfo = () => {
    toast.info(
      <div className="m-1">
        <h3>
          You have successfully created a organization - "{org_name}". Set your
          first strategic goal
          <Link
            style={{ cursor: "pointer", color: "red" }}
            to={"/home"}
            className="badge badge-light"
          >
            <u> &nbsp;&nbsp; here </u>
          </Link>
        </h3>
      </div>,
      { toastId: "success1" }
    );
  };

  return (
    <>
      {Show && show && toastInfo()}

      <Modal
        show={OpenDeleteModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="org-img" title="org-img" src={Vector3} />
          <h4 className="fs-20 p-2">
            Are you sure you want to delete Organization?
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this Organization!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/goals"
            onClick={(e) => {
              e.preventDefault();
              SetOpenDeleteModal(false);
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleOrgDeleteClick();
              SetOpenDeleteModal(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      {spinner ? (
        <>
          <center>
            <div style={{ padding: "20px", margin: "300px" }}>
              <RingLoader
                color="#ff0000"
                size="100"
                loading
                cssOverride={{ opacity: 1 }}
              />
            </div>
          </center>
        </>
      ) : (
        <>
          <Modal
            className="fade bd-example-modal-lg"
            show={largeModal}
            size="lg"
          >
            <Modal.Body>
              <div className="text-center m-0">
                <button
                  variant=""
                  className="btn-close btn-danger float-end align-center m-2 p-2"
                  onClick={() => {
                    setLargeModal(false);
                    setErrors("");
                    setErrMessage("");
                  }}
                ></button>
                <h2 className="text-center mb-4"> Create organization</h2>
              </div>
              <form onSubmit={createOrganization}>
                <div className="create-organization-container">
                  <div className="create-organization-input-field">
                    <input
                      type="text"
                      className="create-organization-input"
                      placeholder="Organization name"
                      defaultValue={org_name}
                      onChange={(e) => setOrgname(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        background: "transparent",
                      }}
                    />
                  </div>

                  {errors.org_name && (
                    <div className="text-danger figma-error">
                      {errors.org_name}
                    </div>
                  )}
                  <div className="create-organization-input-field">
                    <input
                      type="text"
                      autoComplete="off"
                      value={strategy_summary}
                      name="description"
                      required="required"
                      placeholder="Your strategy in five words"
                      rows={5}
                      className="create-organization-input"
                      defaultValue={strategy_summary}
                      onChange={(e) => setStrategy(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        background: "transparent",
                      }}
                    />
                  </div>

                  {selectedStrategy !== undefined &&
                    Object.keys(selectedStrategy).length !== 0 && (
                      <>
                        {/* <button
                        type="submit"
                        className="btn-close btn-danger float-center align-center m-2 p-2"
                        onClick={() => {
                          localStorage.removeItem("store");
                        }}
                      >
                        OnClick
                      </button> */}
                        <div className="selected-strategy-container">
                          {/* <div className="row"> */}
                          {/* <div className="col"> */}
  <div className="strategy-container-layout">
   
    <span className="strategy-details" >
    <img
      className="selected-strategy-image"
      src={selectedStrategy?.image}
      alt="strategy image"
      title="strategy image"
    />
      <span className="selected-strategy-title">
        {selectedStrategy?.name}
      </span>
      <div
                              className="col  d-flex justify-content-center align-items-center"
                              style={{
                                borderLeft: "1px solid rgba(52, 58, 64, 0.6)",
                              }}
                            >
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="outline-primary btn btn-primary mx-2 custom-delete-button"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedStrategy("");
                                    localStorage.removeItem("store");
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
    </span>
    <span
        className="selected-strategy-description"
        dangerouslySetInnerHTML={{ __html: selectedStrategy?.description }}
      ></span>
  </div>
</div>

                            {/* <div className="col selected-strategy-check-icon">
                              <div className="selected-strategy-container-check-icon">
                                <img className="cheack-icon" alt="check-icon" title="check-icon" src={check} />
                              </div>
                            </div> */}
                            {/* <div
                              className="col  d-flex justify-content-center align-items-center"
                              style={{
                                borderLeft: "1px solid rgba(52, 58, 64, 0.6)",
                              }}
                            >
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="outline-primary btn btn-primary mx-2 custom-delete-button"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedStrategy("");
                                    localStorage.removeItem("store");
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div> */}
                          {/* </div> */}
                        {/* </div> */}
                      </>
                    )}
                  {(selectedStrategy === undefined ||
                    Object.keys(selectedStrategy).length === 0) && (
                      <p className="mx-5 px-2 fs-16">
                        Click
                        <a href="/store-front" className="text-danger mx-1">
                          here
                        </a>{" "}
                        to select strategy
                      </p>
                    )}
                    {errMessage && (
                  <div className="alert-error-message text-center float-center">
                    
                      <div className="alert alert-danger" role="alert">
                        {errMessage}
                      </div>
                   
                  </div>
                   )}
                  <div className="createOrganizationBtnDiv text-center float-center">
                    <div className="row">
                      <button
                        type="submit"
                        className="createOrganizationButton text-center"
                        style={{
                          border: "none",
                        }}
                      >
                        Create organization
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <div className="d-flex justify-content-end align-items-center flex-wrap">
            {/* <div className="input-group contacts-search mb-4">
              {localStorage.getItem("previousLocation") !==
                localStorage.getItem("currentLocation") && (localStorage.getItem("previousLocation") !== "/login") && (
                  <span className="float-start">
                    <i
                      className="fa fa-reply fs-20"
                      title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]
                        }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(localStorage.getItem("previousLocation"));
                        if(localStorage.getItem("previousLocation") !== "/login") {
                        }
                      }}
                    />
                  </span>
                )}
            </div> */}
            {localStorage.getItem("org_access") === "true" && (
              <a
                href="/store-front"
                className="figma-btn p-2 text-white float-end mb-4"
              >
                + Use strategy
              </a>
            )}
            {localStorage.getItem("org_access") === "false" && (
              <button
                className="figma-btn text-white float-end mb-4"
                style={{ float: "right", width: "170px" }}
                onClick={(e) => openCreateOrg(e)}
              >
                + Create organization
              </button>
            )}

            <Modal
              className="fade bd-example-modal-lg"
              show={editOrgModal}
              size="lg"
            >
              <Modal.Body className="mb-5">
                <div className="text-center m-0">
                  <button
                    variant=""
                    className="btn-close btn-danger float-end align-center m-1 p-2 "
                    onClick={() => setOrgEditModal(false)}
                  ></button>
                  <h2 className="text-center mb-4">Edit Organization</h2>
                </div>
                <form>
                  <div className="form-group mb-3">
                    <h5>Organization Name</h5>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="figma-input p"
                        autoComplete="off"
                        name="name"
                        required="required"
                        value={editOrgFormData.name}
                        onChange={handleOrgEditFormChange}
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group mb-3">
                      <h5>Address Line 1</h5>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="figma-input p"
                          autoComplete="off"
                          name="address_line_one"
                          required="required"
                          value={editOrgFormData.address_line_one}
                          onChange={handleOrgEditFormChange}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="col-6 form-group mb-3">
                      <h5>Address Line 2</h5>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="figma-input p"
                          autoComplete="off"
                          name="address_line_two"
                          required="required"
                          value={editOrgFormData.address_line_two}
                          onChange={handleOrgEditFormChange}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group mb-3">
                      <h5>City</h5>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="figma-input p"
                          autoComplete="off"
                          name="city"
                          required="required"
                          value={editOrgFormData.city}
                          onChange={handleOrgEditFormChange}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="col-6 form-group mb-3">
                      <h5>State</h5>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="figma-input p"
                          autoComplete="off"
                          name="state"
                          required="required"
                          value={editOrgFormData.state}
                          onChange={handleOrgEditFormChange}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group mb-3">
                      <h5>Country</h5>
                      <div className="contact-name">
                        <Select
                          options={options}
                          styles={{ padding: "2px", borderRadius: "10px" }}
                          value={countryValue}
                          defaultValue={editOrgFormData.country}
                          onChange={changeHandler}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="col-6 form-group mb-3">
                      <h5>Zip Code</h5>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="figma-input p"
                          autoComplete="off"
                          name="zipcode"
                          required="required"
                          value={editOrgFormData.zipcode}
                          onChange={handleOrgEditFormChange}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-6 form-group mb-3">
                      <h5>Email</h5>
                      <div className="contact-occupation">
                        <input
                          type="text"
                          autoComplete="off"
                          value={editOrgFormData.email}
                          onChange={handleOrgEditFormChange}
                          name="email"
                          required="required"
                          className="figma-input p"
                          placeholder="email"
                        />
                      </div>
                    </div>
                    <div className="col-6 form-group mb-3">
                      <h5>Contact Number</h5>
                      <div className="contact-name">
                        <input
                          type="text"
                          autoComplete="off"
                          value={editOrgFormData.contact}
                          onChange={handleOrgEditFormChange}
                          name="contact"
                          required="required"
                          className="figma-input p"
                          placeholder="contact"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <h5>Description</h5>
                    <div className="contact-occupation">
                      <input
                        type="text"
                        autoComplete="off"
                        value={editOrgFormData?.description}
                        onChange={handleOrgEditFormChange}
                        name="description"
                        required="required"
                        className="figma-input p"
                        placeholder="Your strategy in five words"
                        rows={3}
                      />
                    </div>
                    <div className="my-4 mx-1">
                      <p className="fs-16">
                        Click
                        <a href="/store-front" className="text-danger mx-1">
                          here
                        </a>{" "}
                        to select strategy for your organization
                      </p>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="figma-btn text-white"
                      onClick={(event) => handleOrgEditFormSubmit(event)}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
          <div className="row">
            <div className="">
              <div className="row">
                {orgDetails ? (
                  orgDetails.map((org, index) => (
                    <div className="col-sm-4 org-edit" key={index}>
                      <div
                        className="card contact-bx item-content"
                        style={
                          org?.id === highlighOrganization
                            ? { border: "1px solid red" }
                            : {}
                        }
                      >
                        <div className="card-body user-profile pb-0">
                          <div className="d-flex align-items-center">
                            <div
                              className="py-2 d-flex"
                              style={{
                                display: "inline-flex",
                                height: "100px",
                                width: "90px",
                              }}
                            >
                              <Row style={{ width: "100px" }}>
                                <img
                                  src={org?.org_image || orgImg}
                                  alt="Organization Image"
                                  title="Organization Image"
                                  className="rounded-circle col"
                                  style={{ height: "70px", width: "70px" }}
                                  onClick={() => {
                                    fileRef.current.click();
                                    setEditOrgId(org.id);
                                    setSelectedOrg(org);
                                  }}
                                />
                              </Row>
                              <p className="">
                                <Row style={{ width: "25px", height: "25px" }}>
                                  <span
                                    class="fas fa-pen active col"
                                    onClick={(e) => {
                                      fileRef.current.click();
                                      setEditOrgId(org.id);
                                      setSelectedOrg(org);
                                    }}
                                    style={{
                                      float: "left",
                                      cursor: "pointer",
                                      borderRadius: "20px",
                                      fontSize: "10px",
                                      padding: "8px",
                                      color: "#F3F4F6",
                                      backgroundColor: "#3B65B9",
                                      width: "25px",
                                      height: "25px",
                                    }}
                                    type="file"
                                    id="edit"
                                    name="img"
                                    accept="image/*"
                                  ></span>
                                </Row>
                              </p>
                              <input
                                ref={fileRef}
                                onChange={(e) => {
                                  handleOrgAttachmentSubmit(e);
                                }}
                                multiple={false}
                                type="file"
                                accept="image/*"
                                hidden
                              />
                            </div>
                            <div className="ms-3 text-start" style={{overflowWrap:"anywhere"}}>
                              <h3 className="">{org.name}</h3>
                              <span className="p">{org.address_line_one}</span>
                              {org.city && org.state && org.zipcode &&(
                              <span className="p">
                                {org.city +
                                  ", " +
                                  org.state +
                                  ", " +
                                  org.zipcode +
                                  ", "}
                              </span>
                              )}
                              <span className="p p-0 m-0">{org.country}</span>
                            </div>
                            {org?.org_owner === userInfoDetails?.data?.id && (
                              <Dropdown className="ms-auto">
                                <Dropdown.Toggle
                                  variant=""
                                  as="div"
                                  className="btn-link i-false"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="12.4999"
                                      cy="3.5"
                                      r="2.5"
                                      fill="#A5A5A5"
                                    />
                                    <circle
                                      cx="12.4999"
                                      cy="11.5"
                                      r="2.5"
                                      fill="#A5A5A5"
                                    />
                                    <circle
                                      cx="12.4999"
                                      cy="19.5"
                                      r="2.5"
                                      fill="#A5A5A5"
                                    />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  alignRight={true}
                                  data-testid="org-menu"
                                  className="dropdown-menu-right"
                                >
                                  <Dropdown.Item
                                    data-testid="org-edit"
                                    onClick={(event) =>
                                      handleOrgEditClick(event, org)
                                    }
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="text-danger"
                                    data-testid="org-delete"
                                    onClick={(event) => {
                                      SetOpenDeleteModal(true);
                                      selectedOrgForDelete(event, org.id);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                          <div className="d-flex align-items-center" style={{overflowWrap:"anywhere"}}>
                            <span className="p">{org.description}</span>
                          </div>
                          <div className="py-3">
                            <ul>
                              <li>
                                <Link to={"#"}>
                                  <img
                                    className="float-start me-3"
                                    src={Vector2}
                                    alt="org-description"
                                    title="org-description"
                                  />
                                </Link>
                                {org.contact &&(
                                <span className="p">
                                  + {org.contact}
                                </span>
                                )}
                              </li>
                              <li className="mt-2">
                                <Link to={"#"}>
                                  <img
                                    className="float-start me-3 mt-1"
                                    src={Vector}
                                    alt="org-email"
                                    title="org-email"
                                  />
                                </Link>
                                <span className="p">{org.email}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Please create an organization....</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrganizationList;
