import React, { useEffect, useState } from "react";
import fail from "../../../images/vector/payment-fail.webp";
import { useLocation } from "react-router-dom";
import { makeSubscriptionPlanPayment } from "../../../services/StripePayment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentFail = (props) => {
  const org_id = parseInt(localStorage.getItem("organization_id"));
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [Show, setShow] = useState(false);
  const [ShowMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const datas = JSON.parse(localStorage.getItem("SubscriptionDetails"));

  useEffect(() => {
    setShow(Show);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [Show]);

  useEffect(() => {
    setShow(true);
    setShowMessage("Payment failed!");
  }, []);


  const makeSelectedSubscriptionPlanPayment = async (
    plan_type,
    user_quantity
  ) => {
    localStorage.setItem(
      "SubscriptionDetails",
      JSON.stringify({ plan_type: plan_type, user_quantity: user_quantity })
    );
    const data = makeSubscriptionPlanPayment(
      plan_type,
      user_quantity,
      org_id,
      userInfoDetails?.data?.accessToken
    );
    data?.then(
      await ((res) => {
        localStorage.setItem(
          "SubscriptionDetails",
          JSON.stringify({ plan_type: plan_type, user_quantity: user_quantity })
        );

        if (res?.data?.data?.url) {
          window.location.replace(res?.data?.data?.url);
        }
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error deleteTask");
      })
    );
  };

  const errorToast = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i class="bi bi-x-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
          <div>
            <h2
              style={{
                // color: "#131F4C",
                fontSize: "22px",
              }}
            >
              Failed
            </h2>
            <h3
              style={{
                // color: "#131F4C",
                fontSize: "18px",
              }}
            >
              {ShowMessage}
            </h3>
          </div>
        </div>
      </>,
      {
        toastId: "error-toast",
        className: "toast-error-container toast-error-container-after ",
      }
    );
  };

  return (
    <>
      {Show && ShowMessage && errorToast()}
      <div className="col-lg-12">
        <div className="profile card card-body px-3 pt-0 pb-0 text-center align-items-center">
          <img src={fail} className="stripe-fail" alt="payment-fail" title="payment-fail"></img>
          <h4> Payment Failed</h4>
          <div className="stripe17 mt-2 mb-3" style={{ padding: "0rem" }}>
            {datas && location?.state?.Subscription ? (
              <button
                className="createOrganizationButton "
                onClick={() =>
                  makeSelectedSubscriptionPlanPayment(
                    datas?.plan_type,
                    datas?.user_quantity || 1
                  )
                }
              >
                Retry again
              </button>
            ) : (
              <button
                className="createOrganizationButton "
                onClick={() => props?.handlePaymentRetry}
              >
                Retry again
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFail;
