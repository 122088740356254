import React, { Component } from "react";
import { Scrollbars } from "rc-scrollbars";

export class CustomScrollbars extends Component {
  render() {
    console.log("props", this.props);
    return (
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMax={this.props.height}
        thumbMinSize={30}
        universal={true}
        {...this.props}
      />
    );
  }
}