import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
// import NavHader from "./NavHader";
import Header from "./Header";

const JobieNav = () => {
  return (
    <Fragment>
      <Header/> 
      <SideBar />
    </Fragment>
  );
};

export default JobieNav;
