import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PlansomLogo from "../../../images/vector/Frame.png";
import StoreFooter from "./StoreFooter";
import Vector_1 from "../../../images/vector/Vector_1.png";
import Vector_2 from "../../../images/vector/Vector_2.png";
import Union from "../../../images/Plansom/Union.png";

import "../../../css/figma-style.css";
import Vector_3 from "../../../images/vector/vector_3.png";
import StoreHeaders from "./StoreHeaders";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function AboutUs() {
  const navigate = useNavigate();

  return (
    <>
      <Row className="m-0 p-0 bg-white">
        <Row className="m-0 p-0">
          <Col className="col-sm-1 m-0 p-0 col-width"></Col>
          <StoreHeaders />
          <Col className="m-0 p-0">

            {/* <Row className="m-0 p-0">
              <div className="headers">
                <div className="header1" style={{ display: "flex" }}>
                  <div className="plansom-logo float-center">
                    <img
                      className="plansom-logo"
                      alt="Plansom Logo"
                      title="Plansom Logo"
                      src={PlansomLogo}
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  </div>
                  <div className="header-item float-center">
                    <Link className="items" to="/store-front">
                      Store
                    </Link>
                    <Link className="items" to="/about-us">
                      About us
                    </Link>
                    <Link className="items" to="/#">
                      Pricing
                    </Link>
                  </div>
                  <div className="header-buttons">
                    <Link
                      className="btn try-button"
                      to="/login"
                      style={{
                        border: "1px solid #356FD4",
                        backgroundColor: "#ffffff",
                        color: "#131f4c",
                      }}
                    >
                      Log in
                    </Link>
                    <Link className="btn try-button" to="/store-front">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </Row> */}
            <Row className="mobilesizemargins">
              <div className="row p-0 titlemargin" style={{ marginTop: "7rem" }}>
                <div>
                  {" "}
                  <span
                    className="abouttitle me-2"
                    style={{ color: "#131F4C" }}
                  >
                    About
                  </span>{" "}
                  <span className="abouttitle" style={{ color: "#4DBFDB" }}>
                    us
                  </span>
                </div>
              </div>
              <div className="row mb-5">
                <div className="p aboutuspage-quote-font">
                  Plansom was started with a singular goal in mind; doubling productivity.
                </div>
                <div className="p aboutuspage-quote-font mt-4">
                  By doubling productivity we mean doubling productivity not just for some businesses, in some cases, but doubling the productivity of all work, for everyone.
                </div>
                <div className="p aboutuspage-quote-font mt-4">
                  At first glance this is a goal so ambitious it seems impossible. Maybe even ridiculous. But, we believe it is possible. We also believe it is a goal worth striving for, because the impact on peoples´ lives would be profound.
                </div>
              </div>
              <div className="row mt-3">
                <div className="aboutuspage-image-position1 col col-md-12 col-sm-12 col-lg-6">
                  {/* <img
                    className="aboutuspage-image"
                    src={Vector_1}
                    alt="Vector 1"
                    title="Vector 1"
                  /> */}
                  <LazyLoadImage src={Vector_1}
                    alt={Vector_1}
                    title={Vector_1}
                    className="aboutuspage-image"
                    effect="blur"
                  />
                </div>
                {/* <div className="col-sm-1 m-0 p-0 col-width"></div> */}
                <div className="col mt-3 col-md-12 col-sm-12 col-lg-6">
                  <div className="About-us-title mb-4">
                    How do you double productivity?
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    Why do we think it is possible to double productivity?
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    In nearly all organisations a huge amount of effort is expended on plans and projects that in the end add little value.  The root cause of this waste is that businesses are forced to use trial and error to fix the challenges facing them. This is slow and expensive.
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    With generative AI trial and error is no longer necessary. Drawing on the combined experience of millions of businesses, at Plansom we use generative AI to build benchmark strategies that provide a solid starting point.
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    Still, generative AI is not perfect. At Plansom we also work with expert partners to create best of breed strategies for common business challenges. Further, we allow customers to build their own templates.                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    The first step to doubling productivity is to start with proven strategies. It avoids expensive trial and error.                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col pl-4 col-md-12 col-sm-12 col-lg-6">
                  <div className="About-us-title mb-4 ">
                    Focus on high impact / effort
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    Once you have set strategy, success comes down to execution. But, most businesses execute slowly. Why is that?
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    At Plansom we start by recognising that some tasks and goals have much greater impact than others. Achieving these is key. We also acknowledge that there is a trade-off between the impact of a goal and the effort it takes to get there.
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    By thinking in terms of impact / effort and optimising to get the biggest possible bang for your buck, it is possible to execute radically faster without working harder. It is a key step in doubling productivity
                  </div>
                </div>
                {/* <div className="col-sm-1 m-0 p-0 col-width"></div> */}
                <div className="aboutuspage-image-position2 col col-md-12 col-sm-12 col-lg-6 text-end">
                  <svg xmlns="http://www.w3.org/2000/svg" width="318" height="319" viewBox="0 0 418 419" fill="none" className="aboutuspagesvg">
                    <path d="M418 419C327.049 419 181.902 418.348 0 418.348V329.947C181.902 329.947 330.165 183.027 330.165 0H418C417.1 91.8748 418 419 418 419Z" fill="#DCE6F5" />
                  </svg>
                  <img
                    className="aboutuspage-image aboutuspage-image2"
                    src={Vector_3}
                    alt="about-uspage-image"
                    title="about-uspage-image"
                  />
                  {/* <LazyLoadImage src={Vector_3}
                    alt={Vector_3}
                    title={Vector_3}
                    className="aboutuspage-image aboutuspage-image2"
                    effect="blur"
                  /> */}
                </div>
              </div>
              <div className="row mt-5">
                <div className="aboutuspage-image-position3 col pl-4 col-md-12 col-sm-12 col-lg-6" >
                  {/* <img
                    className="aboutuspage-image"
                    src={Vector_2}
                    alt="Vector 2"
                    title="Vector 2"
                  /> */}
                  <LazyLoadImage src={Vector_2}
                    alt={Vector_2}
                    title={Vector_2}
                    className="aboutuspage-image imagemarginsformobile"
                    effect="blur"
                  />
                </div>
                {/* <div className="col-sm-1 m-0 p-0 col-width"></div> */}
                <div className="col col-md-12 col-sm-12 col-lg-6">
                  <div className="About-us-title mb-4 mt-2">Delegate with 100% alignment</div>
                  <div className="p aboutuspage-quote-font mb-4">
                    The final step in doubling productivity is ensuring your team is 100% aligned. How?
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    Alignment most often breaks down when tasks are delegated. Delegation is hard and tasks are often delegated badly, or not at all.
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    To delegate well you have to empower individuals and at the same time offer guidance. Plansom makes delegation simpler by making it easy to do both.                   </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    First, every Plansom task is aligned with a goal and a strategy. We measure time spent on aligned tasks, and rate outcomes in terms of  success (beat, hit, miss) and on-time performance. This clarity fuels empowerment and trust and makes delegation easier.                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    Second, Plansom includes AI Guidance on all tasks and goals. Whenever an individual has a doubt or wants to brainstorm a solution to a challenge, full AI support is on hand. This access to targeted and specific guidance whenever needed makes delegation much simpler                   </div>
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col pl-3 col-md-12 col-sm-12 col-lg-6">
                  <div className="About-us-title mb-4 ">
                    Simple, affordable, effective
                  </div>
                  <div className="p aboutuspage-quote-font mb-4">
                    To double productivity, Plansom was built with three core values in mind:
                  </div>
                  <ul className="list-with-dots p-4">
                    <li className="mb-4 aboutuspage-quote-font">
                      <span
                        className="p"
                        style={{ color: "rgba(0, 0, 0, 0.80)" }}
                      >
                        {" "}
                        Simple :
                      </span>{" "}
                      <span className="p">
                        {" "}
                        The tool will always be as simple as possible. It will always be easy for anyone to use, quick to learn, and take as little time as possible to use every day
                      </span>
                    </li>
                    <li className="mb-4 aboutuspage-quote-font">
                      <span className="p"> Affordable :</span>{" "}
                      <span className="p">
                        As business owners ourselves we always provide the best value for money
                      </span>
                    </li>
                    <li className="mb-4 aboutuspage-quote-font">
                      <span className="p"> Effective : </span>{" "}
                      <span className="p">
                        Our aim is to give you a tool to make your team double productivity and we constantly refine Plansom to improve it further
                      </span>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-sm-1 m-0 p-0 col-width"></div> */}
                <div className="aboutuspage-image-position4 col col-md-12 col-sm-12 col-lg-6 text-end">
                  <LazyLoadImage src={Union}
                    alt={Union}
                    title={Union}
                    className="aboutuspage-image"
                    effect="blur"
                  />
                </div>
              </div>
            </Row>
          </Col>
          <Col className="col-sm-1 m-0 p-0 col-width"></Col>
        </Row>
      </Row>
      {/* <StoreFooter positionTop="200rem"></StoreFooter> */}
      <StoreFooter></StoreFooter>
    </>
  );
}

export default AboutUs;
