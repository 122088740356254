import {
    GET_ORG_MEM_CONFIRMED_ACTION,
    GET_ORG_MEM_FAILED_ACTION,
    CREATE_TEAM_CONFIRMED_ACTION,
    CREATE_TEAM_FAILED_ACTION,
    GET_ALL_TEAMS_CONFIRMED_ACTION,
    GET_ALL_TEAMS_FAILED_ACTION,
    EDIT_TEAM_CONFIRMED_ACTION,
    EDIT_TEAM_FAILED_ACTION,
    DELETE_TEAM_CONFIRMED_ACTION,
    DELETE_TEAM_FAILED_ACTION,
    GET_ALL_SUB_TEAMS_CONFIRMED_ACTION,
    GET_ALL_SUB_TEAMS_FAILED_ACTION,
    GET_TEAM_MEM_CONFIRMED_ACTION,
    GET_TEAM_MEM_FAILED_ACTION
} from '../actions/TeamAction';


const initialState = {
    errorMessage: '',
    successMessage: '',
    orgDetails: '',
    orgMembers: '',
    team: '',
    subteam: ''
};


export function TeamReducer(state = initialState, action) {

    if (action.type === GET_ORG_MEM_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.data.message,
            orgMembers: action.payload.data
        };
    }

    if (action.type === GET_ORG_MEM_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Organization members not found',
            orgMembers: action.payload.data
        };
    }

    if (action.type === CREATE_TEAM_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.data.message,
            team: action.payload.data
        };
    }

    if (action.type === CREATE_TEAM_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Failed to create team',
            team: action.payload.data
        };
    }
    if (action.type === EDIT_TEAM_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.data.message,
            team: action.payload.data
        };
    }

    if (action.type === EDIT_TEAM_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Failed to Edit team',
            team: action.payload.data
        };
    }
    if (action.type === GET_ALL_TEAMS_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.message,
            team: action.payload.data
        };
    }

    if (action.type === GET_ALL_TEAMS_FAILED_ACTION) {

        return {
            ...state,
            errorMessage: 'Failed to create team',
            team: action.payload.data
        };
    }
    if (action.type === DELETE_TEAM_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.data.message,
            team: action.payload.data
        };
    }

    if (action.type === DELETE_TEAM_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Failed to delete team',
            team: action.payload.data
        };
    }
    if (action.type === GET_ALL_SUB_TEAMS_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload.data.message,
            team: action.payload.data
        };
    }

    if (action.type === GET_ALL_SUB_TEAMS_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Failed to get Sub team',
            team: action.payload.data
        };
    }
    if (action.type === GET_TEAM_MEM_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: "Team Members Found",
            team: action.payload.data
        };
    }

    if (action.type === GET_TEAM_MEM_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: 'Failed to get Sub team',
            team: action.payload.data
        };
    }
    return state;
}